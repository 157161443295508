import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as log from 'loglevel';

export type FormLifecycleStates = "new"|"edit"| "all"

export type JSONFormControlFieldDefinition = {
  name: string
  label: string
  type: "button"
  lifecycle: FormLifecycleStates
  url: string
  mode?: string,
  callback():void
} | {
  name: string
  label: string
  explain: string
  type: "text"
  lifecycle: FormLifecycleStates
  defaultValue?: string
  disabled?: boolean
  required?: boolean
} | {
  name: string
  label: string
  explain: string
  type: "textarea"
  lifecycle: FormLifecycleStates
  defaultValue?: string
  disabled?: boolean
  required?: boolean
  rows: number
} | {
  name: string
  label: string
  explain: string
  type: "password"
  lifecycle: FormLifecycleStates
  required?: boolean
} | {
  name: string
  label: string
  explain: string
  type: "select"
  lifecycle: FormLifecycleStates
  required?: boolean
  choices: {
      label: string, 
      value: string
    }[]
  /**
   * For multi-select form fields, set this to a number other than 1
   * To limit selection to a specific number, specify that number
   * To allow unlimited selection, set to 0
   */
  selectLimit: number
}


@Component({
  template:""
})
/**
 * A class for rendering JSON-based form templates as an Angular form
 * in a generic way.
 * 
 * This is a re-implementation of the strategy used in the Provider details for credential entry,
 * and should eventually replace that implementaiton
 * 
 * This implementation aims to expose a generic abstract class to render the form elements
 * and write-back to a parent `FormGroup`
 * 
 */
export abstract class JsonFormControlComponent implements OnInit {
  
  
  public _formDefinition: JSONFormControlFieldDefinition[];
  protected _baseObject: any
  public values: any;

  @Input() public extraClassDefinitions: {
    label: string[]
  }
  
  /**
   * The `JsonFormControlComponent` facilitates runtime definition of fields to include on a form,
   * so we need a reference to the form object onto which these fields should be added.
   * 
   * This class will create `FormControls` and `Validators` for controls as necessary; 
   * thus ensuring the desired validation and values on the target form.
   * 
   */
  @Input() baseFormGroup: FormGroup;
  /**
   * If a document exists in the context of "editing", supply that here so we
   * can read the necessary values from the base document.  Since the `patchValue`
   * call of the parent `FormGroup` may be called before we've actually created the 
   * form controls, the values to be edited may not be `patched`.
   */
  @Input() sourceDocment: any;
  /**
   * Some forms may behave differently in `new` (create) vs `edit` context, so 
   * pass in the current lifecycle state of the form here.
   */
  @Input() lifecycleState: FormLifecycleStates

  /**
   * A JSON object `JSONFormControlFieldDefinition[]` defining which
   * `FormControls` should be populated onto `baseFormGroup`
   */
  @Input() set formDefinition(newFormDefinition: JSONFormControlFieldDefinition[]) {
    // iterate through the existing fields in the form definition, and 
    // remove any fields not present in the new definintion from the form.
    for(let d of this._formDefinition) {
      if (! newFormDefinition.find(c=>c.name == d.name)) {
        this.baseFormGroup.removeControl(d.name);
      }
    }

    this._formDefinition = newFormDefinition.filter(f=>f.lifecycle == "all" || f.lifecycle == this.lifecycleState)
    for (let d of this._formDefinition) {
      if (d.type === "button") {
        continue;
      }

      if (!this.baseFormGroup.contains(d.name)) {
        this.baseFormGroup.addControl(d.name, new FormControl(null, {
          validators: d.required ? Validators.required : Validators.nullValidator
        }))
      }
      if (this.sourceDocment && d.name in this.sourceDocment) {
        this.values[d.name] = this.sourceDocment[d.name]
      }
      else if (d.type == "text" && d.defaultValue) {
        this.values[d.name] = d.defaultValue;
      }
      log.debug("set control ", d);
    }
   
    this.writeFormState();
  }
  
  get formDefinition() {
    return this._formDefinition;
  }

  @Input() set baseObject(value: any) {
    this._baseObject = value;
  } get baseObject() {
    return this._baseObject
  }

  constructor() { 
    this.values = {};
    this._formDefinition = []
  }

  ngOnInit() {
  }

  inputChanged(event) {
    log.debug("changed: ", event);
    this.writeFormState();
  }

  writeFormState() {
    for (let p of this._formDefinition) {
      const fieldValue = this.values[p.name];
      if (fieldValue) {
        this.baseFormGroup.get(p.name).setValue(fieldValue);
      }
      else if (p.type == "text" && p.defaultValue) {
        this.baseFormGroup.get(p.name).setValue(p.defaultValue);
      }
    }
  }

  public async buttonClick(formField: Extract<JSONFormControlFieldDefinition, {type: "button"}>) {
    let url: string;
    if (formField.mode == "client-side-function") {
      //Appends the OauthUrl with preferences
      for (let ff of this._formDefinition) {
        if(ff.type == 'select'){
          let value = this.baseFormGroup.get(ff.name).value
          formField.url += `&preferences=${value}`
        }
      }
      await formField.callback();
    }
    else {
      let popup = window.open(url,"Self","width=600,height=500");
    }
    
  }

}