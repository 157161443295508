<ng-container *ngTemplateOutlet="([isLoading,numberSwitcher])[showType]"></ng-container>

<ng-template #numberSwitcher>  
    <a nz-dropdown [nzDropdownMenu]="menu" nzClickHide="false" [nzVisible]="showNumberPicker">
      <div class="full-name">
        <span>{{authnz.activeNumberProfile.full_name}}</span>
        <br>
        <small style="font-size: 10px">{{authnz.activeNumberProfile.phone_number}} </small>
        <i *ngIf="authnz.currentSecurityProvider.canAccessNumberSwitcher()" nz-icon style="font-size: 10px"
          nzType="down"></i>
      </div>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul *ngIf="authnz.currentSecurityProvider.canAccessNumberSwitcher();else notTeamsBlock" nz-menu nzSelectable
        class="txb-list-max-height" [style.overflow-y] = "(numberSharing.numbersSharedWithLoggedInUser$ | async)?.length > 6 ? 'scroll' : 'none'">
        <li [ngClass]="{'list-item-active': num.isCurrentNumber}" nz-menu-item *ngFor="let num of numberSharing.numbersSharedWithLoggedInUser$ | async">
          <i style="display: inline-block; width: 40px" nz-icon  nzType="pushpin" [nzTheme]="preferredNumber == num.phone_number ? 'fill' : 'outline'"  nz-tooltip nzTooltipTitle="Switch to this number at login" (click)="setPreferredNumber(num.phone_number)"></i>
          <a (click)="pickNumber(num)">{{num.full_name}} <br> <small>{{num.phone_number}}</small></a>
        </li>
      </ul>
      <ng-template #notTeamsBlock>
        <ul nz-menu>
          <li nz-menu-item>
            <span><b>Number Switcher</b><br> <small><i>Team plans only.</i></small></span>
          </li>
        </ul>
      </ng-template>
    </nz-dropdown-menu>
</ng-template>

<ng-template #isLoading>
  <p style="color:#fff;">Loading Numbers...</p>
</ng-template>