import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TextableService } from 'src/app/services/textable.service';
import { vars } from 'src/app/app.constants';
import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
import { MatchesField } from 'src/app/form_validators/MatchesField';
import { ValidateField } from 'src/app/form_validators/ValidateField';


export enum ResetHashConfirmationMode { 
  None="None",
  PasswordReset="PasswordReset",
  EmailVerify="EmailVerify"
}

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {

  user: any = {};
  userId: string = "";
  passwordHash: string = "";
  countdown: number = 5;
  errorMessage: string = "";
  errorModal: boolean = false;
  confirmed: boolean = false;
  loadingTitle: string = "Loading...";
  loadingMessage: string = "Please wait while the application loads...";
  appName: string = environment.appName;
  supportEmail: string = environment.supportEmail;
  logoPath: string = environment.logoPath;
  frmConfirmAccount: FormGroup;
  ResetHashConfirmationMode = ResetHashConfirmationMode;
  confirmationMode: ResetHashConfirmationMode;

  constructor(
    public afa: AngularFireAuth, 
    public db: AngularFirestore, 
    public route: ActivatedRoute, 
    public http: HttpClient,
    public msg: NzMessageService,
    public textable: TextableService
  ) {

    this.route.params.subscribe(params => {
      // console.log(params);
      this.userId = params['uid'];
      this.passwordHash = params['hash'];

      let postData = {
        uid: this.userId,
        hash: this.passwordHash
      };
      this.confirmationMode = ResetHashConfirmationMode.None
     

      this.http.post(environment.apiBase + 'validateUserPasswordHash', postData)
          .subscribe((response:any) => {
              if (response.error) {
                this.errorMessage = response.error;
                this.errorModal = true;
              } else {
                // console.log(result.user);
                // this.router.navigateByUrl('user/' + result.user.uid);
                //this.modalSaving = false;
                if (this.passwordHash.substr(this.passwordHash.length-3,3) === "+ev" ){
                  this.confirmationMode = ResetHashConfirmationMode.EmailVerify;
                }
                else {
                  this.confirmationMode = ResetHashConfirmationMode.PasswordReset;
                }
              }
          });
    });


  }

  ngOnInit() {

    this.frmConfirmAccount = new FormGroup({
      "password": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.password),  
        ValidateField("cPassword")
      ]),
      "cPassword": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.password),
        MatchesField("password")
      ])
    });

  }

  confirmAccount() {

    this.msg.create('info', 'Please wait while we confirm your account...');

    let postData = {
      uid: this.userId,
      hash: this.passwordHash,
      password: this.frmConfirmAccount.get('password').value
    };

    this.http.post(environment.apiBase + 'confirmUser', postData)
      .subscribe(async (response:any) => {
        if (response.error) {
          this.msg.create('error', response.error);
        } else {
          this.msg.create('success', 'Your account has been confirmed.');
          this.confirmed = true;
          // We need to refresh the current user so that when they 
          // go to the `/` route, our local copy of their AFA user
          // object has the "emailVerified" set to true
          if (this.afa.user) {
            firebase.auth().currentUser.reload();
            this.startCountdown();
          }
        }
    });
  }

  startCountdown() {
    setInterval(()=>{
      this.countdown--;
      if (this.countdown==0){
        this.goToApp();
      }
    },1000)
  }

  goToApp() {
    // this is janky; we should be able to use Rotuer to go to `/`, 
    // but something in the app state doesn't like it
    window.location.assign('/');
  }
}