import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/compat/firestore";
import { arrayUnion, arrayRemove } from '@angular/fire/firestore'
import { getLogger } from "src/shared/logging";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthNZService } from "../core/authnz.service";
import {
  ObservableContexUpdateSignal,
  SubscriptionManagerService,
} from "../core/subscription-manager.service";
import { GenericFrontendFirestoreDocument } from "../interfaces";
import { BackendService } from "./backend.service";
import { TextableService } from "./textable.service";
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from "ng-zorro-antd/table";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TextableOrganization, TextableUser } from "../backported_types/users";
const log = getLogger("UserManagementService");

export type UMSOperationResponse = {
  status: "success" | "info" | "warning" | "error" | "blank";
  title: string;
  message: string;
};

export interface ColumnItem<T> {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<T> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<T> | null;
}

export interface MessageSettings{
  awayMessageResponseInterval?:number;
  signatureEnabled?:boolean;
  signatureText?:string;
}
@Injectable({
  providedIn: "root",
})
export class UserManagementService {
  constructor(
    private backendService: BackendService,
    private authnz: AuthNZService,
    private textable: TextableService,
    private submanager: SubscriptionManagerService,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private notification: NzNotificationService,
  ) {}

  /**
   * Updates the list of `sharedWith` on a TextableUser object by calling the
   * backend endpoint authenticated by the curent Firebase user's token
   *
   * @param id UID of the user to update
   * @param sharedWith The full array of UIDs with whom the user's number should be shared
   * @returns
   */
  async updateUserSharedWith(id: string, sharedWith: string[]) {
    try {
      const result = await this.backendService.backendPost(
        "frontend/users/" + id + "/shared",
        null,
        { sharedWith: sharedWith }
      );
    } catch (err) {
      log.error("Error updating number sharing for user " + id, err);
      throw new Error("Unable to modify number sharing: " + err.error.error);
    }
  }


  /**
   * Updates the list of `sharedWith` on a TextableUser object by calling the
   * backend endpoint authenticated by the curent Firebase user's token
   *
   * @param id UID of the user to update
   * @param sharedWith The full array of UIDs with whom the user's number should be shared
   * @returns
   */
  async addUserDeviceToken(id: string, newDeviceToken: string) {
    try {
      await this.afs.doc("users/" + id).update({
        deviceTokens: arrayUnion(newDeviceToken)
      });
      log.debug("Added user device token ", newDeviceToken)
    } catch (err) {
      throw new Error("Error adding deviceTokens for user: " + err.error.error);
    }
  }

  /**
   * Updates the list of `sharedWith` on a TextableUser object by calling the
   * backend endpoint authenticated by the curent Firebase user's token
   *
   * @param id UID of the user to update
   * @param sharedWith The full array of UIDs with whom the user's number should be shared
   * @returns
   */
  async removeUserDeviceToken(id: string, oldDeviceToken: string) {
    try {
      await this.afs.doc("users/" + id).update({
        deviceTokens: arrayRemove(oldDeviceToken)
      });
      log.debug("Removed user device token ", oldDeviceToken)
    } catch (err) {
      log.warn("Error removing deviceTokens for user: " + err);
    }
  }
  
  /**
   * Deletes the user either via privatebackend or Firebase functions, depending on how the current environment is configured.
   *
   * @param user - The user to delete TODO: TXBDEV-193 replace this with TextableUser when we can use TextableCommon Types after Angular/TypeScript upgrade
   * @param force - By default, users are "soft" deleted and can be recovered for a time after deletion.  If force is true, then the user will be immediately deleted
   * @returns
   */
  async deleteUser(
    user: TextableUser,
    force: boolean
  ): Promise<UMSOperationResponse> {
    if (
      this.authnz.currentSecurityProvider.usePrivateBackendForUserOperations() // TODO: TXBDEV-1372 - Unify these two mechanisms to prevent duplicate code.
    ) {
      try {
        const result = await this.backendService.backendDelete(
          "api/v2/users/" + user.id,
          {
            force: force
          }
        ) as any
        if (result.warnings && result.warnings.length > 0) {
          return {
            status: "warning",
            title: "Completed with warnings",
            message:
              "User '" +
              user.full_name +
              "' has been deleted with warnings: " +
              result.warnings.join(","),
          };
        }

        return {
          status: "success",
          title: "Sucess",
          message: "User '" + user.full_name + "' has been deleted",
        };
      } catch (err) {
        log.debug("Error deleting user: ", err);
        return {
          status: "error",
          title: "Error",
          message:
            "There was a problem deleting user '" +
            user.full_name +
            "': " +
            err.error.error,
        };
      }
    } else { // TODO: TXBDEV-1372 - Unify these two mechanisms to prevent duplicate code.
      try {
        const response = await this.textable
          .deprovisionRetailTeamUser({ uidToDelete: user.id })
          .toPromise();
        return {
          status: "success",
          title: "Sucess",
          message: "User '" + user.full_name + "' has been deleted.",
        };
      } catch (err) {
        return {
          status: "error",
          title: "Error",
          message:
            "There was a problem deleting user '" +
            user.full_name +
            "': " +
            err.error.error,
        };
      }
    }
  }

  async undeleteUser(user: TextableUser): Promise<UMSOperationResponse> {
    try {
      const result = await this.backendService.backendPost(
        "api/v2/users/" + user.id,
        {},
        {
          deleted: 0,
          is_disabled: false
        },
        {
          method:"patch"
        }
      ) as any
      return {
        status: "success",
        title: "Sucess",
        message: "User '" + user.full_name + "' has been undeleted",
      };
    } catch (err) {
      log.debug("Error undeleting user: ", err);
      return {
        status: "error",
        title: "Error",
        message:
          "There was a problem undeleting user '" +
          user.full_name +
          "': " +
          err.error.error,
      };
    }
  }

  
  /**
   * Returns an observable of users for which the logged-in user has authorization to see
   *
   * Will publish new values anytime the logged-in user's profile or authorizations changes.
   *
   * @returns an observable of users for which the logged-in user has authorization to see
   */
  getUsers(includeDeleted: boolean = false): Observable<TextableUser[]> {
    return this.submanager
      .observe(
        "currentuser-visibleusers",
        () => {
          return this.afs
            .collection("users", (ref) => {
              let query: Query = ref;
              query =
                this.authnz.currentSecurityProvider.trimFirestoreUserQuery(
                  query
                );
              query.orderBy("full_name", "desc");
              return query;
            })
            .snapshotChanges();
        },
        ObservableContexUpdateSignal.Immediate
      )
      .pipe(
        map((users) =>
          users
            .filter(
              // filter out users who are deleted
              includeDeleted ? 
                () => true :
                (user) => (user.payload.doc.data().hasOwnProperty("deleted") ?  (user.payload.doc.data() as any).deleted == 0 : true)
            ) 
            .map((a) => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
              return {
                id,
                ...(data as object),
              } as TextableUser;
            })
        )
      );
  }

  /**
   * Fetches the requested User document from Firestore
   *
   * Semi-gracefully handles Firestore errors by re-throwing the error with more helpful context.
   *
   * This is an area of interest for TXBDEV-854
   *
   * @param id UID of the desired user document
   * @returns User document from firestore; with the `id` property added
   */

  async getUser(id: string): Promise<TextableUser> {
    if (!id || id == undefined) {
      throw "Blank UID requested";
    }
    return this.afs
      .doc("users/" + id)
      .get()
      .toPromise()
      .then(
        (user) => {
          if (!user || user == undefined) {
            throw "User does not exist";
          }
          const u = user.data() as any; // TODO: TXBDEV-193 Refactor this to use TextableUser interface when TextableCommon become available in PF
          u.id = user.id;
          return u;
        },
        (err) => {
          throw "Unable to fetch User document " + id + ": " + err.toString();
        }
      );
  }

  /**
   * Fetches the array of User documents which have been shared with the requested user
   * This is an area of interest for TXBDEV-854
   *
   * If there are UIDs in the user's `sharedWith` array to which the current user is not authorized
   * then the UID is returned with display attributes set to "Restricted"
   *
   * @param user
   * @returns
   */
  async getSharedUsers(user: TextableUser): Promise<Partial<TextableUser>[]> {
    if (!user.sharedWith || user.sharedWith.length < 1) {
      return [];
    }

    let promises: Promise<Partial<TextableUser>>[] = [];

    user.sharedWith.forEach((id) => {
      promises.push(
        this.getUser(id).catch((reason) => {
          log.warn(
            "Unable to read user document: " +
              id +
              ". Returning 'Restricted' user object.  Reason: " +
              reason.toString()
          );
          return {
            id: id,
            firebase_document_id: id,
            full_name: "Restricted",
            phone_number: "restricted",
            email: "restricted",
            organizationId: "restricted"
          };
        })
      );
    });

    return Promise.all(promises);
  }

  /**
   * Returns an observable of all numbers (other users) shared with the current user.
   *
   * @param uid
   * @returns
   */
  getUsersNumbers(uid): Observable<TextableUser[]> {
    this.submanager.destroyObservable("currentuser-usablenumbers");
    return this.submanager
      .observe(
        "currentuser-usablenumbers",
        () =>
          this.afs
            .collection("users", (ref) => {
              let query = ref.where("sharedWith", "array-contains", uid);
              query =
                this.authnz.currentSecurityProvider.trimFirestoreUserQuery(
                  query
                );
              return query;
            })
            .snapshotChanges(),
        ObservableContexUpdateSignal.Immediate
      )
      .pipe(
        map((numbers: any) =>
          numbers.map((a) => {
            const num: any = a.payload.doc.data();
            num.id = a.payload.doc.id;
            return num;
          })
        )
      );
  }

  /**
   * Updates the user profile with the new data
   * 
   * @param profile 
   * @returns 
   */
  async editUserProfile(profile:any){
    return this.backendService.backendPost('frontend/editUserProfile', {}, profile);
  }

  /**
   * Updates the user profile only by it's away message interval
   * @param profile 
   * @returns 
   */
    async editUserMessageSettings(profile:MessageSettings){
      return this.backendService.backendPost('frontend/editUserMessageSettings', {}, profile);
    }

  public async AddUser(newUser: TextableUser) {
    if (this.authnz.currentSecurityProvider.usePrivateBackendForUserOperations()) {
      try{ 
        const response = await this.backendService.backendPost('api/v2/users/', {}, newUser)
        log.debug("Created user via backend call; response: ", response)
        return response
      }
      catch (err) {
        log.error("Error creating user via backend", err);
        throw new Error(err.error.error);
      }
      
    }
    else {
      try {
        const response = await this.fns.httpsCallable('provisionRetailTeamUser')({
          newUserData: {
            ...newUser
          }
        }).toPromise();
        log.debug("Created user via functions call; response: ", response)
        return response;
      }
      catch (err) {
        log.debug("Error creating user via functions", err);
        throw new Error("Error creating User");
      }
    }
  }

  /**
   * Deletes an organization by ID
   * 
   * @param orgId 
   */
  public async deleteOrganization(orgId: string, force: boolean) {
    try {
      const result = await this.backendService.backendDelete(
        "api/v2/organizations/" + orgId,
        {
          force: force
        }
      )

    }
    catch (error) {
      log.warn("Failed to delete organization " +orgId , error)
      throw new Error(error.error.error)
    }
  }


  public async undeleteOrg (org:TextableOrganization): Promise<UMSOperationResponse> {
    try {
      const result = await this.backendService.backendPost(
        "api/v2/organizations/" + org.id,
        {},
        {
          deleted: 0,
          is_disabled: false
        },
        {
          method:"patch"
        }
      ) as any
      return {
        status: "success",
        title: "Sucess",
        message: "Org '" + org.organizationName + "' has been undeleted",
      };
    } catch (err) {
      log.debug("Error undeleting user: ", err);
      return {
        status: "error",
        title: "Error",
        message:
          "There was a problem undeleting user '" +
          org.organizationName +
          "': " +
          err.error.error,
      };
    }
  }

  resendTeamEmail(uid: string) {
    this.backendService.backendPost('api/v2/users/'+uid+'/sendInviteLink', {}, {})
      .then((result: any) => {
        this.notification.create(
          'success',
          'Success',
          'Resent welcome email.'
        );
      }, (error) => {
          this.notification.create(
            'error',
            'Error',
            'Failed to resend welcome email.'
          );
      });
  }

  /**
   * Makes a call to PB that changes the password of the specified user
   * 
   * @param newPassword - new password
   * @param uid - user to update; defaults to currently logged in user.
   */
  async changePassword(newPassword: string, userToChange: TextableUser = this.authnz.currentUserAsTextableUser()) {
    /**
     * Whether this request is to update the currently logged in user's password
     */
    let isSelf = false;
    if (userToChange.firebase_document_id == this.authnz.currentUserAsTextableUser().firebase_document_id) {
      isSelf = true;
      this.authnz.selfPasswordChangeInProgress = true;
    }
    log.debug("Starting password reset for user", userToChange)

    let postData = {
      password: newPassword
    };

    try {
      this.authnz
      const response = await this.backendService.backendPost("api/v2/users/" + userToChange.firebase_document_id+"/changePassword", {}, postData)
      if (response.status) {
        this.notification.create('success', "Success", 'User&rsquo;s password has been updated.');
        if (isSelf) {
          await this.authnz.signInWithCustomToken(response.newToken)
          this.authnz.selfPasswordChangeInProgress = false;
        }
      } else {
        this.notification.create('error', "Error", 'User&rsquo;s password could not be updated.');
      }
    }
    catch (err) {
      this.notification.create('error', "Error", 'User&rsquo;s password could not be updated. Error: ' + err);
    }
  }
}
