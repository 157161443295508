import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthNZService } from "../core/authnz.service";
import { ApplicationStates } from "../core/auth-nz/interfaces";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { LoadingModalComponent } from "../components/loading-modal/loading-modal.component";
import { BackendService } from "./backend.service";
import { getLogger } from "src/shared/logging";
import { BackgroundOperationStatusService } from "../background-operation-status.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { sleep } from "../functions";
import { UserContactWatcher } from "./contacts.service.UserContactWatcher";
import { AFSBatcher } from "../firestoreBatcher";
import { TextableContact, TextableContactList } from "../backported_types/contact";
import { MS_PER_MINUTE } from "../app.constants";
import { PhoneNumber } from "@shared/PhoneNumber";
import { ContactDTO } from "../../classes/contactDTO";
import { tap } from "rxjs/operators";
import { TitleBarService } from "./title-bar.service";
import { distinctUntilKeyChanged, map } from "rxjs/operators";

const log = getLogger("ContactService");

export const CONVERSATION_SUBSCRIPTION_SCAVENGE_TIMEOUT = 1 * MS_PER_MINUTE;

export type ContactWithoutId = Omit<TextableContact, "id"|"firebase_document_id">;

export type ContactCreateRequest = Partial<ContactWithoutId> & Pick<TextableContact,"phone_number"|"full_name">

export type ContactImportAction = {
  action: "create"
  data: ContactWithoutId
} |
{
  action: "update"
  id: string
  data: Partial<ContactWithoutId>
}

export type ContactSubscription = {
  contacts: ContactDTO[];
  isLoading: boolean;
};

@Injectable({
  providedIn: "root",
})
/**
 * This service coordinates patching through the various UserContactWatcher instances for
 * shared numbers used by the current user.
 */
export class ContactsService {
  // #region Properties (6)

  private ContactWatchers: Record<string, UserContactWatcher> = {};
  private activeWatcherSubscriptions: Subscription[] = [];

  public activeWatcher: UserContactWatcher
  /**
   * Observable of contacts for the active number
   *
   * Fires when any contact for the active number changes\
   *
   * DO NOT debounceTime this observable; debounce is handled inside of ContactsService
   */
  public contacts$ = new BehaviorSubject<ContactSubscription>({contacts: [], isLoading: true});
  /**
   * Observable of active conversations for the active number
   *
   * Fires when any active conversations for the active number changes
   * 
   * DO NOT debounceTime this observable; debounce is handled inside of ContactsService
   */
  public conversations$ = new BehaviorSubject<ContactSubscription>({contacts: [], isLoading: true});
  public loadingStatusText: string;

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(
    private afs: AngularFirestore,
    public authnz: AuthNZService,
    private nzModal: NzModalService,
    public backendService: BackendService,
    private backgroundOperationStatus: BackgroundOperationStatusService,
    private titleService: TitleBarService
  ) {
    log.debug("instantiated contacts service");
    this.authnz.contextChanged.pipe(
        map(c=> c.activeNumberProfile),
        distinctUntilKeyChanged("uid")
      ).subscribe((x)=>{
      log.debug("activeNumber changed to '"+this.authnz.activeNumberUID+"'; updating contacts", x)
      while (this.activeWatcherSubscriptions.length){
        this.activeWatcherSubscriptions.pop().unsubscribe();
      }
      const uid = this.authnz.activeNumberUID;
      this.getContactWatcher(uid, true);
      this.activeWatcher = this.ContactWatchers[uid];
      this.activeWatcherSubscriptions.push(
        this.activeWatcher.contacts$.pipe(
          tap(contacts=>{
            this.titleService.SetPageTitle({NotificationCount: contacts.contacts.filter(c=>!c.readStatus.value.isRead).length})
          })
        ).subscribe(this.contacts$));
      this.activeWatcherSubscriptions.push(this.activeWatcher.conversations$.subscribe(this.conversations$));
    });
    
    authnz.authChanged.subscribe((state) => {
      if (state == ApplicationStates.PreLogout) {
        for (let w of Object.values(this.ContactWatchers)) {
          w.callUnsub();
        } 
      }
    });
    setInterval(() => {
      if (authnz.authState == ApplicationStates.LoggedIn) {
        for (let w of Object.values(this.ContactWatchers)) {
          //w.ScavengeMessages();  TODO: Maybe move this to ScavengableCache
        } 
      }
    }, 5000);
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)

  /**
   * Deletes all contacts for the active number.
   *
   *
   */
  public async DeleteAllContacts() {
    this.activeWatcher.DeleteAllContacts();
  }

  public getContactWatcher(uid: string, start: boolean = false): UserContactWatcher {
    if (!this.ContactWatchers.hasOwnProperty(uid)) {
      this.ContactWatchers[uid] = new UserContactWatcher(this.backendService, this.authnz, this.afs, this.backgroundOperationStatus, uid);
    }
    if (start) {
      this.ContactWatchers[uid].Watch();
    }
    return this.ContactWatchers[uid];

  }
  public async LoadContactsWithModal(mode: "initial" | "all" = "initial") {
    /**
     * When loading contacts takes too long, we show a modal to indicate to the user that something
     * is happening.  Store a reference to it so we can kill it when finished.
     */
    let contactsModal: NzModalRef<unknown, any>;
    if (mode == "all") {
      this.loadingStatusText =
        "<p>Loading more contacts for <b>" +
        this.authnz.activeNumberProfile.full_name +
        " (" +
        this.authnz.activeNumberProfile.phone_number +
        ")</b></p>";
      contactsModal = this.nzModal.create({
        nzTitle: null,
        nzBodyStyle: {
          "text-align": "center",
        },
        nzContent: LoadingModalComponent,
        nzFooter: null,
        nzClosable: false,
      });
    }
    await this.activeWatcher.LoadContacts(mode);
    if (contactsModal) {
      contactsModal.close();
    }
  }

  /**
   * Uploads the contact list to backend for import and processing.
   *
   *
   * @param list
   * @returns a promise of the backend operation
   */
  public async uploadImport(list: ContactImportAction[]) {
    log.debug("Processing ContactImportAction list", list);
    this.backgroundOperationStatus.updateStatus({
      operation: "Processing Contact Import",
      statusText: "In Progress " + 0 + " / " + list.length,
      percentComplete: ((0)/list.length) * 100,
    });
    try {
      this.activeWatcher.callUnsub();
      const chunkSize = 500;
      let i = 0;
      let start = 0;
      let end = 0;
      do {
        start = i*chunkSize;
        end = Math.min (start + chunkSize, list.length);
        log.debug(`Sending contacts ${start} - ${end-1}`);
        const resut =  await this.backendService.backendPost("api/beta/contacts",{
          },{
            contacts: list.slice(start,end)
          }
        );
        this.backgroundOperationStatus.updateStatus({
          operation: "Processing Contact Import",
          statusText: "In Progress " + end + " / " + list.length,
          percentComplete: ((end)/list.length) * 100,
        });
        i++;
      } while (end < list.length);
      await Promise.all([sleep(200)]);
      this.backgroundOperationStatus.updateStatus({
        operation: "Processing Contact Import",
        statusText: "Complete",
        percentComplete: 100,
        finished: true,
      });
    } catch (err) {
      log.warn("Failed processing import", err);
      this.backgroundOperationStatus.updateStatus({
        operation: "Processing Contact Import",
        statusText: "Failed",
      });
    }
    this.activeWatcher.LoadContacts("initial");
  }

  /**
   * Creates a new contact list.  Returns the document id of the newly created list
   * 
   * TODO: Maybe move this to a new service?
   * 
   * 
   * @param listName the name of the list to create
   * @returns 
   */
  public async createContactList(listName: string): Promise<string> {
    const newList: TextableContactList = {
      listName: listName,
      uid: this.authnz.activeNumberUID,
      isDeleted: false,
      count: 0
    } 
    try {
      const result = await this.afs.collection("contact-lists").add(newList)
      log.debug("Created contact list '" + listName + "'. Document id: '" + result.id +"'");
      return result.id
    }
    catch (err) {
      log.warn("Failed creating contact list '" + listName + "'", err);
      throw new Error("Failed creating contact list '" + listName + "'");
    }
  }

    /**
   * Gets a contact list by the specified ID
   * TODO: Maybe move this to a new service?
   * 
   * 
   * @param listName the name of the list to create
   * @returns 
   */
    public async getContactList(id: string): Promise<TextableContactList> {
      try {
        const result = await this.afs.collection("contact-lists").doc(id).get().toPromise()
        const doc: TextableContactList = result.data() as any;
        doc.id = result.id
        log.debug("Fetched contact list " + id,doc)
        return doc;
      }
      catch (err) {
        log.warn("Failed fetching contact list '" + id + "'", err);
        throw new Error("Failed fetching contact list '" + id + "'");
      }
    }

    /**
     * @param id 
     */
    public async updateContactListCount(id: string) {
      try {
        log.debug("Updating count for contact list '"+id+"'")
        const list = await this.afs.firestore.doc("contact-lists/"+id).get();
        const contacts = await this.afs.firestore.collection("contacts")
          .where("lists","array-contains",id)
          .where("uid","==",this.authnz.activeNumberUID)
          .get();
        await list.ref.update({
          count: contacts.size
        })
        log.debug("Updated contact list '"+id+"' count to " + contacts.size)
      }
      catch(err){
        log.warn("Failed updating count for contact list '"+id+"'", err)
      }
    }

    /**
     * Finds a contact by PhoneNumber;
     * 
     * First looking in the client-side contact cache.
     * If there are no cache hits, then we query the database
     * 
     * If there are no hits in either; return null.
     * 
     * @param phone_number 
     * @returns 
     */
    public async getContactByPhoneNumber(phone_number: PhoneNumber): Promise<ContactDTO | null> {
      let contactSearch = this.activeWatcher.contacts$.value.contacts.filter(c=>phone_number.ToCommonFormats().includes(c.contact.phone_number));
      if (contactSearch.length == 0) {
        return await this.activeWatcher.QueryDatabaseForContact({phone_number: phone_number});
      }
      if (contactSearch.length == 1) {
        log.debug("Got contact from cache", contactSearch[0])
        return contactSearch[0];
        
      }
      log.warn(`More than 1 contact for phone_number '${phone_number.ToE164()}`, contactSearch)
      return contactSearch[0];
    }

    public async getContactById(id: string): Promise<ContactDTO> {
      let contact: ContactDTO
      contact = await this.activeWatcher.QueryDatabaseForContact({firebase_document_id: id})
      if (!contact) {
        let contactOwnerUID: string;
        try {
          const contactData = (await this.afs.firestore.doc(`contacts/${id}`).get());
          contactOwnerUID = contactData.data().uid;
        }
        catch(err) {
          log.warn(`Failed to query for contact ${id}`)
        }
        if (!contactOwnerUID) {
          throw new Error(`Contact ${id} does not exist`);
        }
        this.getContactWatcher(contactOwnerUID, false)
        contact = (await this.ContactWatchers[contactOwnerUID].QueryDatabaseForContact({firebase_document_id: id}));
      }
      return contact;
    }

    /**
     * Creates a contact in the context of the active number
     * 
     * @param contactDetails 
     * @returns 
     */
    public async createContact(contactDetails: ContactCreateRequest): Promise<ContactDTO> {
      return this.activeWatcher.createContact(contactDetails)
    }

    /**
     * Checks if the provided contact is archived, and unarchives if necessary.
     * @param contact 
     */
    public async unArchiveContact(contact: TextableContact) {
      if (contact.isArchived) {
        await this.afs.doc('contacts/' + contact.firebase_document_id).update({isArchived:false});
      }
    }

  // #endregion Public Methods (3)
}
