import { AngularFirestore, Query } from "@angular/fire/compat/firestore";
import { toBoolean } from "@shared/toBoolean";
import { Logger } from "loglevel";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { PausableCountdown } from "src/classes/PausableCountdown";
import { getLogger } from "src/shared/logging";
import { TextableContact } from "../app/backported_types/contact";
import { TextableMessage } from "../app/backported_types/messaging";
import { AuthNZService } from "../app/core/authnz.service";
import { BackendService } from "../app/services/backend.service";
/**
 * When a conversation is active and has focus, we
 * show a countdown progress circle for this many MS
 * before actually marking the conversation as read
 *
 * This prevents "acidental" marking as read.
 */
const CONVERSATION_FOCUS_TIME_MARK_AS_READ_MS = 2 * 1000;
/**
 * While the conversation counter is active, we update the UI
 * progress spinner this many milliseconds
 */
const CONVERSATION_FOCUS_TIME_MARK_AS_READ_UI_UPDATE_MS = 100;

export type ContactConsentDTO = { sendRequired: boolean; message?: string };

export type ConversationReadStatus = {
  isRead: boolean;
  countdownMS?: number;
  countdownPercent?: number;
};

export function isContactDTO(value: any): value is ContactDTO {
  return value?.__proto__.hasOwnProperty("refreshContactData");
}

/**
 * This is a "glue" class for managing "contacts" as a cohesive
 * set of the contact's details, messages (i.e. conversation),
 * and all of the observables that go along with it.
 */
export class ContactDTO {
  // #region Properties (11)

  private _consentRequirement = new BehaviorSubject<ContactConsentDTO>(null);
  private _messages = new BehaviorSubject<TextableMessage[]>([]);
  private conversationFocus = new BehaviorSubject<boolean>(false);
  private log: Logger;
  private messageSubscription?: Subscription;
  private readCountdown?: PausableCountdown;
  private tearDownMarkAsReadCountdown?: () => void;

  public consentRequirement: Observable<ContactConsentDTO>;
  public lastActivity: number;
  public messages: Observable<TextableMessage[]>;
  public readStatus: BehaviorSubject<ConversationReadStatus>;

  /**
   * whether the contact is blocked according to the 
   * block list owned by the contact's owner
   */
  public isBlocked: boolean = false;

  // #endregion Properties (11)

  // #region Constructors (1)

  /**
   *
   * @param contact The contact
   */
  constructor(
    public contact: TextableContact,
    private afs: AngularFirestore,
    private authnz: AuthNZService,
    private backendService: BackendService,
    blockedNumbers: Observable<string[]>
  ) {
    blockedNumbers.subscribe((bns) => {
      if (bns.includes(contact.phone_number)) {
        this.isBlocked = true;
      }
      else {
        this.isBlocked = false;
      }
    })
    this.log = getLogger(`ContactDTO-${this.contact.firebase_document_id!}`);
    this.log.setLevel("INFO");

    this.isArchived = typeof this.contact?.isArchived == "undefined"
      ? true
      : this.contact.isArchived;
    this.id = this.contact.firebase_document_id;

    this.readStatus = new BehaviorSubject<ConversationReadStatus>({
      isRead: toBoolean(this.contact.read_status, true),
    });

    this.messages = new Observable<TextableMessage[]>((subscriber) => {
      this.watchMessages();
      this._messages.subscribe(subscriber);
    });

    this.consentRequirement = new Observable<ContactConsentDTO>(
      (subscriber) => {
        this.watchConsentRequirement();
        this._consentRequirement.subscribe(subscriber);
      }
    );
  }

  // #endregion Constructors (1)

  // #region Public Accessors (1)

  public isArchived: boolean;

  // #endregion Public Accessors (1)

  // #region Private Accessors (2)

  /**
   * This DTO's ContactId
   */
  private id: string;

  /**
   * The UID of this DTO's contact
   */
  private get uid() {
    return this.contact.uid;
  }

  // #endregion Private Accessors (2)

  // #region Public Methods (10)

  /**
   * Called at the end of the logged in user's session to
   * cleanup observables that depend on AngularFirestore
   */
  public Unsubscribe() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  public activate() {
    this.log.info("Contact activated");
    this.conversationFocus.next(true);
    this.tearDownMarkAsReadCountdown = this.setupMarkAsReadCountdown();
  }

  public async archive() {
    await this.afs.doc("contacts/" + this.id).update({ isArchived: true });
  }

  /**
   * Logic for comparing this contact with another; used for sorting.
   *
   * returns -1 if this contact should be before `b`
   * returns 1 if this contact should be after `b`
   *
   * @param b The contact to compare against
   * @returns
   */
  public compareDate(b: ContactDTO): -1 | 0 | 1 {
    if (this.contact.last_message_date == null && b.contact.last_message_date == null) {
      /**
       * If both contacts being compared have no last message date, then sort
       * the contacts by name to avoid random sort order
       */
      return this.compareName(b);
    }
    else if (this.contact.last_message_date == null) {
      return -1;
    }
    else if (b.contact.last_message_date == null) {
      return 1;
    }
    if (this.contact.last_message_date <= b.contact.last_message_date) {
      return 1;
    }
    return -1;
  }

  public compareName(b: ContactDTO): -1 | 0 | 1 {
    if (this.contact.full_name == null) {
      return -1;
    }
    if (b.contact.full_name == null) {
      return 1;
    }
    const lc = this.contact.full_name.localeCompare(b.contact.full_name);

    return lc == 0 ? 0 : lc < 0 ? -1 : 1
     
  }

  public compareNumber(b: ContactDTO): -1 | 0 | 1 {
    if (this.contact.phone_number == null) {
      return -1;
    }
    if (b.contact.phone_number == null) {
      return 1;
    }
    const lc =this.contact.phone_number.localeCompare(b.contact.phone_number)
    return lc == 0 ? 0 : lc < 0 ? -1 : 1
  }


  public deactivate() {
    this.log.info("Contact deactivated");
    this.conversationFocus.next(false);
    this.tearDownMarkAsReadCountdown();
  }

  public async markUnread() {
    await this.afs.doc("contacts/" + this.id).update({ read_status: false });
    this.readStatus.next({ isRead: false });
  }

  /**
   * Called when the underlying contact document for this DTO updates.
   *
   * TODO: Figure out if we need to do anything here.
   *
   * @param newC
   */
  public refreshContactData(newC: TextableContact) {
    this.log.debug("Contact Data Updated ", { old: this.contact, new: newC });
    this.contact = newC;
    if (this.readStatus.value.isRead != toBoolean(newC.read_status, true)) {
      this.readStatus.next({
        isRead: toBoolean(this.contact.read_status, true),
      });
    }
    this.isArchived = typeof this.contact?.isArchived == "undefined"
    ? true
    : this.contact.isArchived;
  }

  public setConversationHasFocus(focused: boolean) {
    this.log.info(`Contact focus changed to ${focused}`);
    this.conversationFocus.next(focused);
  }

  /**
   * Given a search string, test whether this contact matches the string
   * @param searchString
   * @returns
   */
  public testMatchesSearchString(searchString: string) {
    const searchStringLower = searchString.toLowerCase();
    return (
      this.contact.full_name?.toLowerCase().indexOf(searchStringLower) > -1 ||
      this.contact.phone_number?.indexOf(searchStringLower) > -1 ||
      this.contact.last_message?.toLowerCase().indexOf(searchStringLower) > -1
    );
  }

  public async unarchive() {
    await this.afs.doc("contacts/" + this.id).update({ isArchived: false });
  }

  // #endregion Public Methods (10)

  // #region Private Methods (3)

  private setupMarkAsReadCountdown() {
    this.log.info("Watching for changes to read status");
    const countdownSubscription = this.readStatus
      .pipe(filter((x) => !x.isRead && !x.hasOwnProperty("countdownMS")))
      .subscribe((v) => {
        this.log.info("Starting mark as read countdown");
        this.readCountdown = new PausableCountdown(
          CONVERSATION_FOCUS_TIME_MARK_AS_READ_MS,
          CONVERSATION_FOCUS_TIME_MARK_AS_READ_UI_UPDATE_MS,
          async (elapsed, duration) => {
            this.readStatus.next({
              isRead: false,
              countdownMS: elapsed,
              countdownPercent: (elapsed / duration) * 100,
            });
          },
          async (reason) => {
            timerMessagesSub.unsubscribe();
            focusedSub.unsubscribe();
            if (reason == "completed") {
              this.log.info("Completed mark as read countdown");
              await this.afs
                .doc("contacts/" + this.contact.id)
                .update({ read_status: true });
              this.readStatus.next({ isRead: true });
              this.log.info("marked as read " + this.id);
            } else if (reason == "cancelled") {
              this.log.info("Cancelled mark as read countdown");
              this.readStatus.next({ isRead: false });
            } else {
              this.log.info(`Ended mark as read countdown: ${reason}`);
            }
          }
        );

        /**
         * Watch for changes to this conversation's messages,
         * and reset the read timer if a new one arrives.
         */
        const timerMessagesSub = this.messages.subscribe(() => {
          this.readCountdown?.reset();
        });

        /**
         * Watch for changes to this conversation's focus after starting
         * the PausableCountdownm, and pause/resume it as necessary
         */
        const focusedSub = this.conversationFocus.subscribe((focused) => {
          if (focused) {
            this.readCountdown?.resume();
          } else {
            this.readCountdown?.pause();
          }
        });
      });

    return () => {
      countdownSubscription.unsubscribe();
      this.readCountdown?.cancel();
      this.log.info("Not watching for changes to read status");
    };
  }

  /**
   *
   * Called when the first subscriber connects to this consentRequirement observable
   *
   * Checks whether the specified contact will recieve a consent message
   * according to the current Textable tenant's configuration parameters
   * and the current User's Organization's congfiguration parameters
   *
   * @param contact
   */
  private async watchConsentRequirement() {
    if (this._consentRequirement.value) {
      return;
    }

    if (this.contact.contactConsentMessageId) {
      this.log.info(
        "Contact had contactConsentMessageId set, no sendRequired: " +
          this.contact.id
      );
      this._consentRequirement.next({
        sendRequired: false,
      });
    } else {
      this.log.info(
        "Querying backend for consent data for contact: " + this.contact.id
      );
      const consentData = (await this.backendService.backendGet(
        "getContactConsentRequirement",
        {
          contact: this.contact.id,
        }
      )) as ContactConsentDTO;
      this.log.info(
        "Got consent data for contact: " + this.contact.id,
        consentData
      );
      this._consentRequirement.next(consentData);
    }
  }

  /**
   * Called when the first subscriber connects to this messages observable
   *
   * Sets up the Firestore snapshot query for messsages that belong to
   * this contact.
   *
   * @returns
   */
  private watchMessages() {
    if (typeof this.messageSubscription == "object" && !this.messageSubscription.closed) {
      return;
    }
    this.log.info("Creating new message subscription for contact " + this.id);

    this.messageSubscription = this.afs
      .collection<TextableMessage>("messages", (ref) => {
        let query: Query = ref;
        query = this.authnz.currentSecurityProvider
          .trimFirestoreMessageQuery(query)
          .where("contact_id", "==", this.id)
          .where("uid", "==", this.uid)
          .orderBy("date", "asc");
        return query;
      })
      .snapshotChanges()
      .subscribe(
        (changes) => {
          const messages = changes.map((a) => {
            const mdata: any = a.payload.doc.data();
            mdata.firebase_document_id = a.payload.doc.id;
            return mdata as TextableMessage;
          });
          this.log.info(
            "Updated " +
              changes.length +
              " messages for conversation: " +
              this.id
          );
          if (changes.length > 1000) {
            this.log.warn(
              "Count of messages (" +
                changes.length +
                ") in this thread (cid: " +
                this.id +
                ") exceeds recommended performance range"
            );
          }
          this._messages.next(messages);
        },
        (err) =>
          this.log.warn("Error watching conversation: " + this.id + " ", err)
      );
  }

  // #endregion Private Methods (3)
}
