import { environment } from "src/environments/environment";

type ProxyReceiveOptions = {
  /**
   * The name of the function to be rendered in the body
   */
  functionName?: string
  /**
   * Whether or not the script should close the window when the function is called
   */
  debugMode?: boolean,
  /**
   * The domain from which the `postMessage` call must have been sent.
   * Generally should be `environment.apiBase`
   */
  eventSenderSourceDomain?: string
}

type ProxyDTOMetadata = {
  version: number
  functionName: string
  debugMode: boolean
}

type ProxyDTOSerialization = {
  metadata: ProxyDTOMetadata, 
  data: any
}

const DTO_PROXY_VERSION = 1;

/**
 * Registers the event handler on the supplied `Window` object, and 
 * resolves when the data object has been received.
 * 
 * 
 * @param options 
 * @param window 
 * @returns 
 */
export function ProxyReceiveObject(options: ProxyReceiveOptions, window: Window): Promise<ProxyDTOSerialization> {

  if ( !("functionName" in options) ) {
    options.functionName = "ProxySendObject"
  }

  if (! ("eventSenderSourceDomain" in options)) {
    options.eventSenderSourceDomain = environment.apiBase
  }

  return new Promise(
    (resolve,reject) => {
      window.addEventListener("message",event=>{
        if (event.origin+"/" != options.eventSenderSourceDomain) {
          return;
        }
        if (event.data.error) {
          reject("Provider login returned an error");
          return;
        }
        const deserialized = event.data as ProxyDTOSerialization;

        if (!deserialized.hasOwnProperty("metadata") || deserialized.metadata.version != DTO_PROXY_VERSION) {
          reject("Mismatch between DTOProxy versions")
          return;
        }
        if (deserialized.metadata.functionName != options.functionName) {
          reject("Mismatch between DTOProxy configuration")
          return;
        }

        deserialized.metadata.debugMode = deserialized.metadata.debugMode || options.debugMode;

        resolve(deserialized)
      });
    }
  );
}