import { Injectable } from '@angular/core';
import { vars } from '../app.constants';
const ct = require('countries-and-timezones');

export interface TimezoneData {
  alias: string
  countries: string[]
  dstOffset: number
  dstOffsetStr: string
  name: string
  utcOffset: number
  utcOffsetStr: string
}

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  public timezones:TimezoneData[] = []
  public allTimezones:TimezoneData[]

  constructor() { 
    this.allTimezones = ct.getTimezonesForCountry('US')
  }
  /**
   * Builds a timezone array built from the app's allowed TZs.
   */
  buildAvailableTimezones(){
    vars.timezones.forEach(tz => {
      const matchingTz = this.allTimezones.find(alltz => alltz.name == tz.name)
      if(matchingTz){
        matchingTz.alias = tz.alias
        this.timezones.push(matchingTz)
      }
    })
  }
}
