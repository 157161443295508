import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getLogger } from 'src/shared/logging';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { environment } from 'src/environments/environment';
import { AuthNZService } from '../core/authnz.service';
import { isStringBlank, stringToColor } from '../functions';
import { ApplicationContextService } from './application-context.service';
import { CSSThemeProperties } from './theme.service.themeables';
import { CSSColorProperty, TextableBrandingDetails, TextableTheme, getPropertyFunction, CSSPropertyNames, TextableThemePropertySource, getPropertySourceFunction, CSSThemeProperty } from './theme.service.types';
import { TitleBarService } from './title-bar.service';

const log = getLogger("ThemeService");
log.disableAll();

 /**
   * Inline function in the context of the current PropertyDefinition
   * that wraps the definition's validator call in an error handler
   * 
   * @param property 
   * @returns 
   */
let validatorFunction = (PropertyDefinition: CSSThemeProperty, property: string)=> {
  try {
    return (PropertyDefinition.cssType.validator(property));
  }
  catch (err) {
    log.warn("Failed validating theme property '" + PropertyDefinition.name + "': " + err);
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public activeBranding: TextableBrandingDetails;
  public themeDebugMode: boolean
  private themePropertySources: {[key in CSSPropertyNames] : TextableThemePropertySource} = {};

  constructor(
    private applicationContextService: ApplicationContextService,
    private http: HttpClient,
    private titleService: TitleBarService,
    private authnz: AuthNZService,
    private nzConfigService: NzConfigService
  ) { 
    this.activeBranding = {};
    this.applicationContextService.applicationContextChanged.subscribe(context=> {
      this.setBranding(context.branding)
    })
    this.DeriveThemeFromEnvironment().then(b=> this.setBranding({
      theme: b
    } as TextableBrandingDetails));
  }

  private resolveThemeProperty(PropertyDefinition: CSSThemeProperty ,theme: TextableTheme): string{
     /**
     * Inline functions that gets a property from the current theme by name and returns it to 
     * the requestor
     * 
     * This function exists to grant the `defaultValue` function access
     * to the properties and sources of the current theme.
     * 
     */
    const propertyGetters: {getPropertyValue: getPropertyFunction, getPropertySource: getPropertySourceFunction} = {
      getPropertyValue: (propertyName: string)=> { 
        if (theme.properties.hasOwnProperty(propertyName) && theme.properties[propertyName]) {
          return theme.properties[propertyName]
        }
        const requestedProperty =  CSSThemeProperties.find(p=>p.name == propertyName)
        if (requestedProperty) {
          return this.resolveThemeProperty(requestedProperty,theme);
        }       
        throw new Error("Requested property '"+propertyName+"' does not exist in this theme")
      },
      getPropertySource: (propertyName: string)=>{
        return this.themePropertySources[propertyName]
      }
    }
    let value = theme.properties[PropertyDefinition.name] 


    if (!value || !validatorFunction(PropertyDefinition,value)) {
      if(typeof(PropertyDefinition.defaultValue) === "function") {
        // If the theme value either doesn't exist, or doesn't pass validation, attempt to generate a default value for it.
        try {
          value = PropertyDefinition.defaultValue(propertyGetters);
          this.themePropertySources[PropertyDefinition.name] = "calculated"
          log.debug("Calculated default theme value for '" + PropertyDefinition.name+"': '" + value+ "'.  Old value was '"+theme.properties[PropertyDefinition.name]+"'")
        }
        catch(err) {
          log.debug("Failed calculating " + PropertyDefinition.name +": " + err);
          value = null
        }
      }
      else {
        throw new Error("Unable to calculate a theme value for '" + PropertyDefinition.name+"'")
      }
    }
    return value;
  }

  /**
   * Sets CSS variables for each of the `themeableProperties` according to the value
   * for each property in the supplied theme definition
   * 
   * For CSSThemeProperty values where a `zorroThemeName` is set, the `NzConfigService` will
   * be used to set the CSS variable, since this gets us automatic pallete generation and 
   * is recommended at https://ng.ant.design/version/13.4.x/docs/customize-theme-variable/en#dynamically-change-configurations
   * 
   * 
   * For CSSThemeProperty values where a `zorroThemeName` is not set, we will directly set 
   * the CSS variable on the DOM
   * 
   * @param theme 
   */
  public applyTheme(theme: TextableTheme) {
    if (!theme) {
      return
    }
    let root = document.documentElement;

    for (let PropertyDefinition of CSSThemeProperties) {

      const value = this.resolveThemeProperty(PropertyDefinition, theme);


      if (validatorFunction(PropertyDefinition,value)) {
        log.debug("Applying theme property '"+PropertyDefinition.name+"' from source '"+this.themePropertySources[PropertyDefinition.name]+"': '" + value+"'")
        if (PropertyDefinition.zorroThemeName) {
          this.nzConfigService.set('theme', { [PropertyDefinition.zorroThemeName]: value })
        }
        else {
          root.style.setProperty("--"+PropertyDefinition.name, value);
        }
      }
      else {
        // TODO: Is this necessary? root.style.removeProperty("--"+PropertyDefinition.name);
      }

    }
  }

  /**
   * Creates some boilerplate branding details used to debug / develop
   * branding features
   * 
   * @returns
   */
  private getDefaultBranding(): TextableBrandingDetails {
    
    const b: TextableBrandingDetails = {
      displayName: "",
      siderLogoURL: "",
      logoutURL: "",
      theme: {
        properties: {}
      }
    }
    /**
     * Iterate through all "Theme properties" and set a color for each property.
     * 
     * Colors are generated "randomly, but idempotently"
     */
    CSSThemeProperties.filter(p=>p.hasOwnProperty("generateDebug") ? p.generateDebug : true).forEach(k=> {
      const generatedDefaultValue = k.cssType == CSSColorProperty ? stringToColor(k.name) : null
      b.theme.properties[k.name] = generatedDefaultValue
      this.themePropertySources[k.name] = 'debug'
    })

    log.debug("Generated development branding", b)

    return b;
  }


  /**
   * Sets branding and decides whether or not to treat branding as "development mode"
   * based on whether or not we're in debug mode.
   * 
   * @param branding 
   * 
   */
  public setBranding(branding?: TextableBrandingDetails) {
    if (this.themeDebugMode) {
      if (!this.activeBranding?.hasOwnProperty("displayName")) {
        const defaultBranding = this.getDefaultBranding();
        this.activeBranding.theme = {
          properties: { 
            ...defaultBranding.theme?.properties,
            ...this.activeBranding.theme?.properties
          }
        }
        this.activeBranding = {
          ...defaultBranding,
          ...this.activeBranding
        }
        log.debug("Created debug override branding", this.activeBranding)
      }
      else {
        log.debug("Applying debug override branding")
      }
    }
    else if(branding) {
      this.activeBranding = branding
    }

    if (!this.activeBranding) {
      return
    }

    if (isStringBlank(this.activeBranding.siderLogoURL)) {
      this.activeBranding.siderLogoURL = ((environment as any).siderLogoPath) || environment.logoPath
    }
   
    this.titleService.SetPageTitle({
      appName: this.activeBranding.displayName
    })

    this.authnz.logoutURL = this.activeBranding.logoutURL;

    this.applyTheme(this.activeBranding.theme)
  }

  public setThemeDebugMode(mode:boolean) {
    this.themeDebugMode = mode;
    this.setBranding();
  }

  /**
   * Fetches the existing custom.less file and maps it to the dynamioc theme
   * platform
   * 
   * @returns TextableBrandingDetails
   */
  private async DeriveThemeFromEnvironment(): Promise<TextableTheme> {
    try {
      const CustomThemeRaw = await this.http.get("./environments/custom.less",{
        responseType: "text"
      }).toPromise();
      return this.ParseCustomLESSFile(CustomThemeRaw);
    }
    catch (err){
      log.debug("Unable to load / parse custom.less file; using blank theme");
      return { properties: {} }
    }
  }

  private ParseCustomLESSFile(CustomThemeRaw: string): TextableTheme {
    let newTheme: TextableTheme = {
      properties: {}
    };
    /**
     * Other replacements
     * e.g. from node_modules\ng-zorro-antd\src\style\color\colors.less
     * 
     * TODO: Maybe dynamically generate this during build
     */
    const otherReplacements = {
      "@blue-6": "#1890ff"
    }

    const cssMatch = /^(.*?):\s*(.*?);/
    const CustomThemeCSSRules = CustomThemeRaw.split('\n').map(l=> {
      try{ 
        const [line,cssProperty,cssValue] = cssMatch.exec(l);
        return {
          cssProperty: cssProperty,
          cssValue: cssValue
        }
      }
      catch (e)
      {}
    }).filter(x=>!(!x)) // Make sure the value is not falsey

    for(let CustomThemeCSSRule of CustomThemeCSSRules) {
        const ThemeableProperties =  CSSThemeProperties.filter(tp=>tp.legacyCustomName == CustomThemeCSSRule.cssProperty || "@"+tp.name == CustomThemeCSSRule.cssProperty)
        if (!ThemeableProperties) {
          log.warn("could not map '"+CustomThemeCSSRule.cssProperty+"' from custom CSS to themeable property");
          continue;
        }
        for (let ThemeableProperty of ThemeableProperties) {
          let value = CustomThemeCSSRule.cssValue
          if (value.startsWith("@")) {
            const ref = CustomThemeCSSRules.find(l=>l.cssProperty == CustomThemeCSSRule.cssValue)
            const newValue = ref?.cssValue || otherReplacements[CustomThemeCSSRule.cssValue] || null
            //log.debug("dereferencing " + CustomThemeCSSRule.cssProperty + " from " + value + " to " + newValue)
            value = newValue
          }

          newTheme.properties[ThemeableProperty.name] = value
          this.themePropertySources[ThemeableProperty.name] = 'legacyTheme'
          log.debug("mapped custom.less property '"+CustomThemeCSSRule.cssProperty+"' to theme property '" + ThemeableProperty.name+"': '"+ value+"'");
        }
    }
    return newTheme;
  }
}
