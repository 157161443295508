<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div nz-row>
    <div nz-col class="left-pane">
      <nz-spin [nzSpinning]="loading">
        <div class="pad-15">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Search Contact Lists..." #txtFilter />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
        </div>
        <ul class="txb-list">
          <ng-container *ngFor="let list of contactList$ | async">
    
            <li *ngIf="!list.isDeleted" (click)="setActiveList(list);addressBook?.resetValues()"
              [ngClass]="{'list-item-active': activeList && list.id == activeList.id}">
              <div style="float: right">
                <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this list?"
                  (nzOnConfirm)="deleteList(list.id)">Delete</a>
              </div>
              {{list.listName | ellipsis:16}}<br />
            </li>
          </ng-container>
        </ul>
      </nz-spin>
    </div>
    <div nz-col class="right-pane">
      <p *ngIf="!activeList;else active" class="text-center pad-25">Select a Contact List.</p>
      <ng-template #active>
        <nz-card class="pad-25">

            <div nz-row style="margin-bottom: 12px">
              <div nz-col nzSpan="10">
                List Name:<br/><input type="text" nz-input [(ngModel)]="activeList.listName" />
              </div>
              <div nz-col nzSpan="8"></div>
              <div nz-col nzSpan="6">
                List Member Count:<br/>{{ activeList.count }}
              </div>
            </div>
            <div nz-row style="margin-bottom: 12px">
              <div nz-col nzSpan="24">
                Auto-Subscribe Keywords (case insensitive):<br/>
                <nz-select 
                  [(ngModel)]="activeList.autoSubscribeKeywords" 
                  (ngModelChange) = "FormatAutoSubscribeKeywords()"
                  nzMode="tags" 
                  [nzTokenSeparators]="[',']" 
                  nzPlaceHolder="Automatically subscribe contacts when these keywords are present in incoming messages" 
                  style="width: 100%"
                  >

                </nz-select>
                <app-keyword-notice></app-keyword-notice>
              </div>
            </div>
            <div nz-row style="margin-bottom: 6px">
              <div nz-col nzSpan="8" style="text-align: left;">
                <button (click)="changeListName()" nz-button nzType="default" >Save List Changes</button>
              </div>
              <div nz-col nzSpan="8" style="text-align: center;">
                <button  
                  (click)="openDrwContacts()"
                  nz-button 
                  nzType="primary">
                    <i class="far fa-plus"></i> Add Contacts
                </button>
              </div>
              <div nz-col nzSpan="8" style="text-align: right;">
                <button 
                  nz-button 
                  nzType="primary" 
                  [nzSize]="small"
                  nz-popconfirm 
                  [disabled] = "numberOfChecked == 0"
                  nzPopconfirmTitle="Confirm Delete?"
                  (nzOnConfirm)="removeContacts()"> 
                  <i class="far fa-trash-alt"></i> Remove {{ numberOfChecked }} Contact{{ numberOfChecked !== 1 ? 's' : ''}}
                </button>
              </div>
            </div>

          <nz-table class="txb-list" #listTable [nzData]="contactsInActiveList" [nzSize]="small" [nzNoResult]="empty" nzShowSizeChanger [nzFrontPagination]="true">
            <thead>
              <tr>
                <th nzShowCheckbox [(nzChecked)]="allDataChecked" [nzIndeterminate]="isIndeterminate"
                  (nzCheckedChange)="checkAll($event)"></th>
                <th>Contact Name</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of listTable.data">
                <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" (nzCheckedChange)="refreshStatus()"></td>
                <td>{{data.full_name}}</td>
                <td>{{data.phone_number}}</td>
              </tr>
            </tbody>
          </nz-table>
        </nz-card>

      </ng-template>    
    </div>
  </div>
</nz-card>

<ng-template #empty>
</ng-template>

<ng-template #extraTemplate>
  <button (click)="openDrwNewList()" nz-button nzType="primary"><i class="far fa-plus"></i> New List</button>
</ng-template>


<nz-drawer [nzClosable]="true" [nzVisible]="drwList" nzPlacement="right" nzTitle="Contact Lists" (nzOnClose)="close();addressBook?.resetValues()"
  [nzWidth]="400">
  <ng-container *nzDrawerContent>
    <div class="pad-15">
      <div class="pad-15">
        <form nz-form nzLayout="vertical" [formGroup]="frmList" (ngSubmit)="addList()">
          <nz-form-item>
            <nz-form-label>List Name</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please enter a valid name. (Min. 3 chars.)">
              <input nz-input formControlName="listName" placeholder="List Name" type="text" />
            </nz-form-control>
          </nz-form-item>

          <button (click)="addList()" nz-button nzType="primary" nzBlock [nzLoading]="loading"
            [disabled]="!frmList.valid">Add List</button>
        </form>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<nz-drawer [nzClosable]="true" [nzVisible]="drwContacts" nzPlacement="right" nzTitle="Contacts" (nzOnClose)="close();addressBook?.resetValues()"
  [nzWidth]="400">
  <ng-container *nzDrawerContent>
    <nz-spin [nzSpinning]="loading">
        <app-address-book 
          #addressBook 
          [activated]="drwContacts" 
          (selectedListChanged)="selectedListChanged($event)" 
          [clearOnSelect]="true" 
          [addressBookMode]="'table'" 
          [perPage]="10">
        </app-address-book>
        <br>
        <button (click)="addSelectedContactsToList();addressBook?.resetValues()" nz-button nzType="primary" nzBlock [nzLoading]="loading">Add Contacts</button>
    </nz-spin>
  </ng-container>
</nz-drawer>

<ng-template #pageTitleTemplate>
  Contact Lists <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Contact Lists'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>