import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthNZService } from 'src/app/core/authnz.service';
import { StripeService } from 'src/app/services/stripe.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  private stripeSessionUrl: string;
  public isLoading: boolean = false;

  constructor(private stripe: StripeService, private msg: NzMessageService,
    public authnz: AuthNZService,) { }

  ngOnInit(): void {
  }

  public async openBillingPortal() {
    try {
      this.isLoading = true
      this.stripeSessionUrl = await this.stripe.getPortalSession();
      window.open(this.stripeSessionUrl);
      this.isLoading = false
    }
    catch (err) {
      this.msg.create('error', err);
      this.isLoading = false
    }
  }
}
