import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class StatisticsComponent implements OnInit {

  loading: boolean = true;
  numDays: string = "7";
  report: any = null;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getReport();
  }

  getReport() {
    this.loading = true;
    this.http.get(environment.apiBase + 'statistics?length=' + this.numDays)
      .subscribe((response) => {
        this.report = response;
        this.loading = false;
      });
  }

}
