import { getLogger } from "@shared/logging";
import { Logger } from "loglevel";

export class ExternalScriptLoadError extends Error {
  // #region Constructors (1)

  constructor(message: string) {
    super(message);
    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, ExternalScriptLoadError.prototype);
  }

  // #endregion Constructors (1)
}

export abstract class ExternalScriptLoader {
  // #region Properties (3)

  /**
   * Promise to indicate the loading status of this resource
   *
   * await this member in child classes to ensure loading has completed
   *
   * This promise will reject if the resource fails to load
   */
  public loading: Promise<void>;
  protected log: Logger;
  /**
   * Timeout after which to report script loading as failed
   */
  protected timeoutMs: number = 3000;

  // #endregion Properties (3)

  // #region Constructors (1)

  constructor(protected resourceName: string, protected document?: Document) {
    this.log = getLogger(`ExternalResource: ${resourceName}`);
    this.log.setLevel("DEBUG")
  }

  // #endregion Constructors (1)

  // #region Protected Methods (1)

  /**
   * Call this in the child-class constructor to begin loading the external script
   * @returns
   */
  protected initialize(): Promise<void> {
    this.loading = new Promise<void>(async (resolve, reject) => {
      const loadP = this.loadExternal();
      setTimeout(() => {
        reject(
          new ExternalScriptLoadError(
            `Timed out loading resource '${this.resourceName}' after ${this.timeoutMs}ms; check for ad blockers`
          )
        );
      }, this.timeoutMs);
      try {
        await loadP;
      } catch (err) {
        this.log.warn("Failed loading resource");
      }
      resolve();
    });
    return this.loading;
  }

  protected loadScriptFromURL(
    src: string,
    attributes?: Record<string, string>
  ): Promise<void> {
    const p = new Promise<void>((resolve, reject) => {
      let scriptFromURL = this.document.createElement("script");
      scriptFromURL.type = "text/javascript";
      scriptFromURL.addEventListener("load", () => {
        resolve();
      });

      scriptFromURL.src = src;
      scriptFromURL.async = true;
      for (let [key, value] of Object.entries(attributes)) {
        scriptFromURL.setAttribute(key, value);
      }

      this.document.body.appendChild(scriptFromURL);
    });
    return p;
  }

  protected loadScriptFromSource(src: string) {
    const p = new Promise<void>((resolve, reject) => {
      let scriptFromSrc = this.document.createElement("script");
      scriptFromSrc.type = "text/javascript";
      scriptFromSrc.async = false;
      scriptFromSrc.addEventListener("load", () => {
        resolve();
      });
      scriptFromSrc.innerHTML = src;
      this.document.body.appendChild(scriptFromSrc);
      setTimeout(resolve, 500); // TODO: It seems like scripts added like this may not always be available right away
    });
    return p;
  }

  // #endregion Protected Methods (1)

  // #region Protected Abstract Methods (1)

  /**
   * Do all work of loading the resource in this method
   */
  protected abstract loadExternal(): Promise<void>;

  // #endregion Protected Abstract Methods (1)
}
