import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { vars } from 'src/app/app.constants';
import { AvailablePhoneNumber, DidSuggestionService, SuggestionDetails } from 'src/app/services/did-suggestion.service';
import { getLogger } from '@shared/logging';
const log = getLogger("DIDInputComponent");

//TODO : Figure out how to apply form validation to ControlValueAccessor components;  Help possibly at: http://prideparrot.com/blog/archive/2019/2/applying_validation_custom_form_component
@Component({
  selector: 'app-did-input',
  templateUrl: './did-input.component.html',
  styleUrls: ['./did-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DidInputComponent
    }
  ]
})
export class DidInputComponent implements OnInit, ControlValueAccessor, OnChanges {

  /**
   * The selected provider for which number selections should be shown
   * 
   * As part of suggesting DID's, we need to know the context into which the 
   * user-to-be will be added
   */
  @Input() providerId: string
  /**
  * Values of the input fields.
  */
  @Input() userformValues: FormGroup


  public allowedSuggestionProviders = {skyetel:true}
  /**
     * If enabled in the current environment, this will be populated with the
     * phone numbers to which the current user has access with their carrier
     */
  public suggestedPhoneNumbers: AvailablePhoneNumber[] = [];

  /**
   * Wehter or not to expand the suggested phone nubmers panel
   */
  public expandSuggestedPhoneNumbers: boolean = false
  /**
     * Used to format the DID 
     */
  public phoneMask = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; //ensures the first digit is not +, 0, or 1. This allows the user to paste '+1##########' formatted numbers.


  /**
     * phonePrefix should change depending on location in the future.
     */
  public phonePrefix: string = '+1';

  /**
    * countryCodes should change depending on location in the future.
    */
  public countryCodes = vars.countryCodes;

  /**
   * TODO: TXBDEV-1168 - Make this some kind of dynamic, environment-driven input
   * so we can know whether to query PB for phone numbers 
   * available to the current (admin) user.
   */
  public shouldShowSuggestedPhoneNumbers: boolean = true;

/**
 * Instance member to store the UI-masked formatted phone number.
 * This value should exclude the prefix (i.e. no leading +1)
 * 
 */
  public phone_number_formatted: string
  /**
   * Instance member to store the E164 phone number
   */
  public phone_number: string

  /**
   * The DID Suggestion Service may display a sub-header along with actually 
   * providing numbers.
   * 
   * This will generally be something indicating how / where the suggestions are coming from
   */
  public DIDSuggestionHeader: SuggestionDetails = null

  /**
   * 
   * @param _ 
   */
  public onChangeFn = (_: any) => {};
 
  /**
   * 
   */
  public onTouchedFn = () => {};
 
  constructor(private didSuggestions: DidSuggestionService) { }

  ngOnInit() {
    this.expandSuggestedPhoneNumbers = false;
    this.didSuggestions.DIDSuggestionResults.subscribe(({ Numbers, SuggestionDetails }) => {
      this.DIDSuggestionHeader = SuggestionDetails
      this.expandSuggestedPhoneNumbers = Numbers !== null && Numbers.length > 0;
      this.suggestedPhoneNumbers = Numbers;
    });
  }

  ngOnChanges(): void {
    log.debug("received change in inputs for DIDInput component");
    if(!this.userformValues.value.provider){
      this.didSuggestions.SetFilter(null, null);
    }
    this.checkIfSuggestionAllowed(this.providerId)
    this.onNumberChange();
  }

  writeValue(obj: any): void {
    this.phone_number = obj as string;
    this.phone_number_formatted =  this.phone_number
  }
  /**
   * 
   * @param fn 
   */
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  /**
  * 
  * @param fn 
  */
  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  private stripFormatting (phone: string)
  {
   return (phone as any)
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll("_", "")
      .replaceAll(" ", "")
      .replaceAll(this.phonePrefix, "")    
  }

  onNumberChange() {
    if(!this.phone_number_formatted) { 
      return;
    }
    // TODO: need ES2021 for replaceAll to work https://stackoverflow.com/questions/63616486/property-replaceall-does-not-exist-on-type-string
    this.phone_number = this.phonePrefix + this.stripFormatting(this.phone_number_formatted)
    this.onChangeFn(this.phone_number);
    this.didSuggestions.SetFilter(this.phone_number, this.userformValues.value);
  }

  /**
   * Called when a phone number is selected from the table of suggested phone numbers
   * @param selectedPhoneNumber 
   */
  selectPhoneNumber(selectedPhoneNumber: string) {
    log.debug("Selected suggested phone number: " + selectedPhoneNumber);
    this.phone_number_formatted =  this.stripFormatting(selectedPhoneNumber);
    this.phone_number = this.phonePrefix + this.phone_number_formatted
    this.onChangeFn(this.phone_number);
  }

  /**
   * Checks the condition to hide or show phone number suggestion based on user document provider.
   */
  checkIfSuggestionAllowed(provider:string){
    this.shouldShowSuggestedPhoneNumbers = this.allowedSuggestionProviders[provider] ? true : false
  }
}
