<nz-modal [nzStyle]="{ top: '20px' }"  [(nzVisible)]="isVisible" nzTitle="Upload" [nzFooter]="modalFooter" (nzOnCancel)="closeMMSModal(false)">
    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="closeMMSModal(false)">Close</button>
        <button nz-button [disabled]="!messageAttachment || isMessageAttachmentLoading" nzType="primary" (click)="isSendMode() ? send() : saveCompose()"
            [nzLoading]="isUploading">Upload</button>
    </ng-template>

    <ng-container *nzModalContent>
        <nz-progress [nzPercent]="50" nzStatus="active" *ngIf="isUploading"></nz-progress>
        <div *ngIf="!isUploading">

            <p>
                <i class="info-popover" nz-icon nz-popover [nzType]="'info-circle'" [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#faad13'" [nzPopoverTitle]="titleTemplate"
                    [nzPopoverContent]="contentTemplate"></i>
                &nbsp;
                Please choose a file to send:
                <ng-template #titleTemplate>
                    File Sizes
                </ng-template>
                <ng-template #contentTemplate>
                    To ensure best deliverability, keep files under 10MB.
                </ng-template>
            </p>
            <input type="file" #attachment (change)="handleUpload($event.target.files)" class="file-uploader">

            <ng-container *ngIf="isMessageAttachmentLoading">
                <div class="preview-container">
                    <span>Loading Attachment <br /><br />
                        <nz-spin></nz-spin>
                    </span>
                </div>
            </ng-container>

            <!-- Preview Container -->
            <ng-container *ngIf="messageAttachment && !isMessageAttachmentLoading">
                <div nz-row>
                    <div nz-col>
                        <span>
                            <b>File size:</b>
                            {{ (messageAttachment?.contentLength >=1000) 
                                ? (messageAttachment?.contentLength/1000 | number: '1.0-0') + ' kb'
                                : (messageAttachment?.contentLength | number: '1.0-0') + ' b'
                            }}

                        </span><br>
                        <span><b>Maximum size:</b> {{mms.MAX_ATTACHMENT_SIZE/1000 | number: '1.0-0'}} kb</span><br />
                        <span *ngIf="messageAttachment?.scaledSize">
                            <b>Rescaled size:</b>
                            {{messageAttachment?.scaledSize/1000 | number: '1.0-0'}} kb
                        </span><br />
                    </div>
                </div>
                <ng-container *ngIf="messageAttachment?.contentLength > mms.MAX_ATTACHMENT_SIZE">
                    <small *ngIf="!this.messageAttachment?.contentType.startsWith('image')">Large attachments may encounter
                        deliverability problems</small><br />
                    <small *ngIf="this.messageAttachment?.contentType.startsWith('image')">Large images are automatically
                        rescaled
                        to mitigate deliverability problems</small>
                </ng-container>




                <div class="preview-container">
                    <!-- Image Preview-->
                    <img class="image-preview img-responsive img-thumbnail" [src]="messageAttachment?.data"
                        *ngIf="messageAttachment?.data && mms.isImageFile(messageAttachment?.contentType)">

                    <!-- Video Preview-->
                    <video class="video-preview" controls
                        *ngIf="messageAttachment?.data && mms.isVideoFile(messageAttachment?.contentType)">
                        <source [src]="messageAttachment?.data" type="video/mp4">
                    </video>

                    <!-- Audio + Other files-->
                    <div class="other-preview"
                        *ngIf="messageAttachment?.data &&  (mms.isOtherFile(messageAttachment?.contentType) || mms.isAudioFile(messageAttachment?.contentType))">
                        <i nz-icon nzType="paper-clip"></i>&nbsp;
                        <b>{{messageAttachment?.name | ellipsis:25}}</b>
                        <span>{{messageAttachment?.contentType}}</span>
                    </div>
                </div>
                <br>
                <span *ngIf='messageAttachment?.filename || messageAttachment?.name'>
                    <b>Filename:</b> 
                    {{messageAttachment?.filename ? messageAttachment?.filename : messageAttachment?.name}}
                </span>
                <br>
            </ng-container>

            <!-- Caption Input field will only show if the mode is send. -->
            <div class="form-group" *ngIf="isSendMode()">
                <label class="caption">Caption</label>
                <textarea class="caption-textarea" placeholder="Compose your message here or paste an image from your clipboard..." [(ngModel)]="newMessage"
                    autocorrect="on" (paste)="onPaste($event)"></textarea>
            </div>
        </div>
    </ng-container>
</nz-modal>