<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <nz-tabset (nzSelectChange)="tabChanged($event)">
    <nz-tab nzTitle="Users">
      <ng-container *ngTemplateOutlet="userGrid"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Deleted Users" *ngIf="authnz.currentSecurityProvider.canAccessDeletedUsers()">
      <ng-container *ngTemplateOutlet="userGrid"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Organizations" *ngIf="shouldShowOrganizationsTab()">
       <ng-container *ngTemplateOutlet="orgGrid"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Deleted Organizations" *ngIf="authnz.currentSecurityProvider.canAccessDeletedOrganizations()">
      <ng-container *ngTemplateOutlet="orgGrid"></ng-container>
    </nz-tab>
  </nz-tabset>
</nz-card>


<ng-template #orgGrid>
  <!-- Org Search Bar-->
  <nz-input-group [nzSuffix]="suffixIconSearch" class="search-box">
    <input [(ngModel)]="orgSearchValue" (ngModelChange)="orgSearch()" type="text" nz-input
      placeholder="Search Organizations" />
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <i nz-icon nzType="search"></i>
  </ng-template>
  <!-- Org Table-->
  <div>
    <ng-container *ngIf="visibleOrganizations?.length>0;else noOrgs">
      <nz-table #tblOrgs2 [nzData]="visibleOrganizations" [nzLoading]="isLoadingOrgs" class="txb-list" [nzScroll]="{ x: '900px' }"> 
        <thead>
          <tr>
            <th
              *ngFor="let column of listOfOrgColumns"
              [(nzSortOrder)]="column.sortOrder"
              [nzSortFn]="column.sortFn"
            >
              {{ column.name }}
            </th>
            <th
              *ngIf="this.authnz.currentSecurityProvider.getPlanSelectionDetails().granularity == 'organization'"
            >Plan
            </th>
            <th nzRight nzWidth="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of tblOrgs2.data; trackBy: trackByDocumentId">
            <td>{{ data.organizationName }}</td>
            <td>{{ data.userCount }}</td>
            <td *ngIf="this.authnz.currentSecurityProvider.getPlanSelectionDetails().granularity == 'organization'" [ngStyle]="{ 'font-style': getPlanDisplayName(data.billing?.plan) == 'Invalid Plan Type' ? 'italic' : 'normal' }">
              {{ getPlanDisplayName(data.billing?.plan) == 'virtualpbx-starter' ? 'Starter' : getPlanDisplayName(data.billing?.plan) == 'virtualpbx-pro' ? 'Professional' : getPlanDisplayName(data.billing?.plan) }}
            </td>
            <td nzRight>
              <a (click)="btnEditOrg(data)">View</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a 
                *ngIf="this.authnz.currentSecurityProvider.canDisableOrganizations() && !data.is_disabled && ! data.deleted > 0" 
                nz-popconfirm 
                [nzPopconfirmTitle]="orgDisableTemplate" 
                (nzOnConfirm)="setOrganizationIsDisabled(data.id, true)">Disable</a>
              <a 
                *ngIf="this.authnz.currentSecurityProvider.canDisableOrganizations() && data.is_disabled && ! data.deleted > 0" 
                nz-popconfirm 
                [nzPopconfirmTitle]="orgEnableTemplate" 
                (nzOnConfirm)="setOrganizationIsDisabled(data.id, false)">Enable</a>
              <nz-divider *ngIf="!data.deleted" nzType="vertical"></nz-divider>
              <a 
                nz-popconfirm
                (mouseover)="keypress.PreventSelection(true)"
                (mouseout)="keypress.PreventSelection(false)"
                [nzPopconfirmTitle]="'Are you sure you want to' + (permanentDelete ? ' permanently ' : ' ') + 'delete this organization?'"
                (nzPopconfirmVisibleChange)="deleteShow($event)" 
                (nzOnConfirm)="confirmOrganizationDelete(data.id, permanentDelete)"
                *ngIf="canDeleteOrganization(data)"
                >
                Delete
              </a>
              <a 
                nz-popconfirm 
                [nzPopconfirmTitle]="'Are you sure you want to undelete this organization?'" 
                (nzOnConfirm)="confirmOrgUndelete(data)" 
                *ngIf="canUndeleteOrganization(data)">
                <nz-divider nzType="vertical"></nz-divider>Undelete
              </a>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <ng-template #orgEnableTemplate> <!--TODO: TXBDEV-1723 Refactor this when NG-Zorro supports template context -->
        <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="display:block; float:left; height:100%"></i>
        <div style="margin-left:20px;">
          Enabling this organization will enable ALL users in the organization. 
          <br/>
          <br/>
          This may affect billing.
          <br/>
          <br/>
          Are you sure you want to enable this organization?
        </div>
      </ng-template> 
      <ng-template #orgDisableTemplate> <!--TODO: TXBDEV-1723 Refactor this when NG-Zorro supports template context -->
        <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="display:block; float:left; height:100%"></i>
        <div style="margin-left:20px;">
          Disabling this organization will disable ALL users in the organization. 
          <br/>
          <br/>
          This may affect billing.
          <br/>
          <br/>
          Are you sure you want to disable this organization?
        </div>
      </ng-template> 
    </ng-container> 
    <ng-template #noOrgs>
      <p>There are no organizations.</p>
    </ng-template>
  </div>
</ng-template>


<ng-template #userGrid>
  <!-- User Search Bar-->
  <nz-input-group [nzSuffix]="suffixIconSearch" class="search-box">
    <input [(ngModel)]="userSearchValue" (ngModelChange)="userSearch()" type="text" nz-input
      placeholder="Search User" />
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <i nz-icon nzType="search"></i>
  </ng-template>
  <!-- User Table-->
  <div>
    <nz-table #userTable2 [nzData]="listOfDisplayUsers" [nzLoading]="isLoadingUsers" class="txb-list" [nzScroll]="{ x: '900px' }">
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfUserColumns"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
            [nzShowFilter]="column?.listOfFilter?.length > 0"
          >
            {{ column.name }}
          </th>
          <th *ngIf="this.applicationContextService.applicationContext.enabledProviders.length>1 && this.authnz.currentSecurityProvider.canViewProviderDetails()">Provider</th>
          <th *ngIf="this.authnz.currentSecurityProvider.hasFullAdminRole()">Is Full Admin?</th>
          <th nzRight nzWidth="120px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of userTable2.data; trackBy: trackByDocumentId">
          <td>{{ data.organizationName }}</td>
          <td>{{ data.full_name }}
                <!--
                <ng-container *ngIf="data.emailVerified == false">
                    <br><small><i><a (click)="resendEmail(data.uid)">Unverified User - (Resend Email)</a></i></small>
                </ng-container>
                 -->
          </td>        
          <td>{{ data.email }}</td>
          <td>{{ data.phone_number }}</td>
          <td *ngIf="this.applicationContextService.applicationContext.enabledProviders.length>1 && this.authnz.currentSecurityProvider.canViewProviderDetails()" >{{ data.providerName }}</td>
          <td *ngIf="this.authnz.currentSecurityProvider.hasFullAdminRole()">{{ data.isAdmin ? 'Yes' : 'No' }}</td>
          <td nzRight>
            <a (click)="btnEditUser(data)">View</a>
            <a nz-popconfirm 
              (mouseover)="keypress.PreventSelection(true)"
              (mouseout)="keypress.PreventSelection(false)"
              [nzPopconfirmTitle]="'Are you sure you want to' + (permanentDelete ? ' permanently ' : ' ') + 'delete this user?'" 
              (nzPopconfirmVisibleChange)="deleteShow($event)" 
              (nzOnConfirm)="confirmUserDelete(data, permanentDelete)" 
              *ngIf="canDeleteUser(data)">
              <nz-divider nzType="vertical"></nz-divider>Delete
            </a>
            <a nz-popconfirm 
            [nzPopconfirmTitle]="'Are you sure you want to undelete this user?'" 
            (nzOnConfirm)="confirmUserUndelete(data)" 
            *ngIf="canUndeleteUser(data)">
            <nz-divider nzType="vertical"></nz-divider>Undelete
            </a>
            <a *ngIf="data.reset_hash?.substr(data.reset_hash.length-3,3) === '+ev'" (click)="resendEmail(data)">
            <nz-divider nzType="vertical"></nz-divider>{{ userSendingInvite == data.id ? "Sent!" : "Resend Invite" }}
            </a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>

</ng-template>

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="activeUser"
  nzPlacement="right"
  nzTitle="Edit User Details"
  (nzOnClose)="clearActiveUser()"
  nzWidth="60%"
>
  <ng-container *nzDrawerContent>
    <nz-tabset [(nzSelectedIndex)]="this.editUserTabNumber">
      <nz-tab nzTitle="User Details">
          <form nz-form nzLayout="vertical" [formGroup]="frmEditUser" (ngSubmit)="updateUser()">
            <nz-form-item>
              <nz-form-label nzRequired>Full Name</nz-form-label>
              <nz-form-control 
                nzHasFeedback
                nzErrorTip="Please enter user's full name."
                >
                <input nz-input formControlName="full_name" placeholder="John Doe" type="text"/>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="this.samlDef == true">
              <nz-form-label>External Identifier</nz-form-label>
              <nz-form-control
                nzExtra="For users who sign in with SSO, an identifier may be required to link the external user account."
                >
                <input nz-input formControlName="external_id" type="text" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label nzRequired>E-mail</nz-form-label>
              <nz-form-control 
                nzHasFeedback
                nzErrorTip="Please enter user's e-mail address."
                >
                <input nz-input formControlName="email" placeholder="john.doe@gmail.com" type="email"/>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzRequired>Organization&nbsp; <ng-container *ngIf="frmEditUser.get('organizationId').enabled"><a (click)="btnAddOrg()">Add Organization +</a></ng-container></nz-form-label>
              <nz-form-control 
                nzHasFeedback
                nzErrorTip="Please select an organization to add the user to."
                >
                <nz-select formControlName="organizationId">
                  <nz-option nzValue="" nzLabel="== Select an Organization =="></nz-option>                
                  <nz-option *ngFor="let org of AllOrganizations" nzValue="{{org.id}}" nzLabel="{{org.organizationName}}" [nzDisabled]="org.is_disabled || org.deleted > 0"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <ng-container *ngIf="this.authnz.currentSecurityProvider.canEditUserPhoneNumber()">
              <app-provider-editor
                [baseFormGroup]="frmEditUser"
                [baseDocument]="activeUser"
                baseFormType="user"
                >
              </app-provider-editor>
            </ng-container>

            <div nz-row>
              <div nz-col nzSpan="24">
                <app-did-input formControlName="phone_number"
                  [providerId]="frmEditUser.get('provider').value"
                  [userformValues]="frmEditUser">
                </app-did-input>
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label nzRequired>Is Disabled?</nz-form-label>
                  <nz-form-control
                    [nzExtra] = "(targetOrgIsDisabled(frmEditUser.get('organizationId').value) || targetOrgIsDisabled(activeUser?.organizationId)) ? (frmEditUser.get('organizationId').value == activeUser?.organizationId ? 'This user may not be enabled because they belong to a disabled or deleted organization' : 'This user belongs to a disabled or deleted organization, but is being moved to an enabled organization. You may specify whether the user should be enabled or disabled') : '' " >
                    <nz-switch formControlName="is_disabled" [nzDisabled]="targetOrgIsDisabled(frmEditUser.get('organizationId').value)"></nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <nz-form-item *ngIf="this.authnz.currentSecurityProvider.hasFullAdminRole()">
                  <nz-form-label nzRequired>Is Full Admin?</nz-form-label>
                  <nz-form-control>
                    <nz-switch formControlName="isAdmin" [nzDisabled]="(activeUser?.isSuperAdmin ? true : false)"></nz-switch>
                    <div *ngIf="activeUser?.isSuperAdmin">
                      <small>(Disabled - User is Super Admin.)</small>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <button nz-button nzType="primary" nzBlock [nzLoading]="isSavingUser" [disabled]="!frmEditUser.valid">
              Save User Details
            </button>
          </form>
      </nz-tab>
      <nz-tab nzTitle="Change Password">
        <h3>Change User Password</h3>
        <p>Please enter a new password and confirm it to change the password.</p>
        <p>This will immediately force the user to reauthenticate any active sessions</p> 
        <nz-divider></nz-divider>
        <form nz-form nzLayout="vertical" [formGroup]="frmEditPassword" (ngSubmit)="updateUserPassword()" autocomplete="off">
            <nz-form-item nzRequired>
                <nz-form-label nzRequired>
                  Enter New Password  
                  <span nz-tooltip nzTooltipTitle="Please enter a password containing at least 8 characters, 1 lowercase and uppercase letter, and 1 number."><i nz-icon nzType="info-circle" nzTheme="outline"></i></span> 
                </nz-form-label>
                <nz-form-control 
                  nzHasFeedback
                  nzErrorTip="Please enter a password containing at least 8 characters, 1 lowercase and uppercase letter, and 1 number.">
                  <input nz-input formControlName="password" placeholder="**********" type="password" autocomplete="abc123"/>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item nzRequired>
                <nz-form-label nzRequired>Confirm New Password</nz-form-label>
                <nz-form-control 
                  nzHasFeedback
                  nzErrorTip="Passwords must match.">
                  <input nz-input formControlName="cPassword" placeholder="**********" type="password" autocomplete="abc123"/>
                </nz-form-control>
              </nz-form-item>
          <button nz-button nzType="primary" nzBlock [disabled]="!frmEditPassword.valid" [nzLoading]="isUpdatingPassword">Update Password</button>
        </form>
      </nz-tab>
      <nz-tab nzTitle="Permissions" *ngIf="canAccessUserPermissionsTab()">
        <p>You can control a user's access to features individually by toggling the switch next to each feature:</p>
        <nz-divider nzText="Choose Permissions"></nz-divider>
        <form nz-form nzLayout="vertical" [formGroup]="frmRestrictions" (ngSubmit)="updateRestrictions()">
          <nz-checkbox-group formControlName="restrictionList"></nz-checkbox-group><br><br>
          <button nz-button nzType="primary" nzBlock [disabled]="!frmRestrictions.valid" [nzLoading]="isUpdatingRestrictions">Update Permissions</button>
        </form>
      </nz-tab>
      <nz-tab nzTitle="Number Sharing" *ngIf="canAccessUserNumberSharingTab()">
        <nz-divider nzText="Share ({{activeUser?.phone_number}}) with other {{getOrgByID(activeUser?.organizationId)?.organizationName}} Users" nzOrientation="left"></nz-divider>

        <form nz-form nzLayout="vertical" [formGroup]="frmAddSharedWith" (ngSubmit)="addShare()">
          <div nz-row>
            <div nz-col nzSpan="18">
              <nz-select
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Select a user to share {{activeUser?.phone_number}} with."
                formControlName="shareWithID"
              >
              <ng-template ngFor let-u [ngForOf]="activeOrgUsers">
                <nz-option [nzLabel]="u.full_name + ' ('+u.email+' / '+u.phone_number+')'" [nzValue]="u.id" *ngIf="activeUser && u.id!=activeUser.id && !isAlreadyShared(u.id)"></nz-option>
              </ng-template>
              </nz-select>
            </div>
            <div nz-col nzSpan="6">
              <button nz-button nzType="primary" nzBlock [disabled]="!frmAddSharedWith.valid" [nzLoading]="isAddingSharedWith">Share Number</button>
            </div>
          </div>
        </form>
        <br>

        <p>These are the other users that currently have access to this user's phone number.</p>

        <ng-container>
          <nz-table #tblSharedWith [nzData]="activeUserSharing" [nzNoResult]="noSharedResult" class="txb-list">
            <thead>
              <tr>
                <th nzWidth="33%">Name</th>
                <th nzWidth="33%" class="text-center">Shared Phone #</th>
                <th nzWidth="33%">E-mail Address</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tblSharedWith.data">
                <td>{{ data.full_name }}</td>
                <td>{{ data.phone_number }}</td>
                <td>{{ data.email }}</td>
                <td><i nz-icon nzType="delete" nzTheme="outline" nz-popconfirm nzPopconfirmTitle="Are you sure you want to stop sharing the number with this user?" (nzOnConfirm)="removeShare(data.id)"></i></td>
              </tr>
            </tbody>
          </nz-table>
        </ng-container>
        <nz-divider nzText="Numbers Shared with User" nzOrientation="left"></nz-divider>
        <p>These are numbers that are currently shared with the user.</p>
        <ng-container *ngIf="activeUserNumbers$ | async as activeUserNumbers">
          <nz-table #tblSharedNumbers [nzData]="activeUserNumbers" [nzNoResult]="noSharedWithResult" class="txb-list">
            <thead>
              <tr>
                <th nzWidth="33%">Name</th>
                <th nzWidth="33%" class="text-center">Shared Phone #</th>
                <th nzWidth="33%">E-mail Address</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of tblSharedNumbers.data">
                    <td>{{ data.full_name }}</td>
                    <td>{{ data.phone_number }}</td>
                    <td>{{ data.email }}</td>
                </tr>

            </tbody>
          </nz-table>
        </ng-container>
        <ng-template #noSharedResult>
          This number is not currently shared with other users.
        </ng-template>
        <ng-template #noSharedWithResult>
          There are no numbers shared with this user.
        </ng-template>
      </nz-tab>
      <nz-tab *ngIf="this.authnz.currentSecurityProvider.canModifyUserWebhookRelay() || this.authnz.currentSecurityProvider.canModifyUserAPITokens()  " nzTitle="API & Relay">
        <div nz-col nzSpan="24" *ngIf="this.authnz.currentSecurityProvider.canModifyUserWebhookRelay()">
          <form nz-form nzLayout="vertical" [formGroup]="frmRelayWebhook" (ngSubmit)="updateRelayWebhook()">
            <nz-divider nzText="Relay Webhook" nzOrientation="left"></nz-divider>
            <nz-form-item>
              <nz-form-label>Relay Webhook URL <a (click)="showRelayDocModal()">(View Docs)</a></nz-form-label>
              <nz-form-control>
                <nz-input-group [nzSuffix]="suffixTemplate">
                  <input type="text" nz-input formControlName="relayWebhook"/>
                </nz-input-group>
                <ng-template #suffixTemplate><i nz-icon nz-tooltip nzTooltipTitle="When a relay webhook is set, we will attempt to relay inbound and outbound messages via a HTTP POST request." nzType="info-circle"></i></ng-template>
              </nz-form-control>
            </nz-form-item>
            <button nz-button nzType="primary">Update Relay Webhook</button>
          </form>
        </div>
        <div nz-col nzSpan="24" *ngIf="this.authnz.currentSecurityProvider.canModifyUserAPITokens()">
          <nz-divider nzText="API Access Tokens" nzOrientation="left"></nz-divider>
          <p>Create API access tokens for sending SMS / MMS messages, managing contacts and more!  <a (click)="showAPIDocs()">(View Docs)</a></p>
          <h3>Account UID: {{activeUser?.uid}}</h3>
          <nz-table #tblAPITokens [nzData]="activeUserTokens" [nzNoResult]="noTokens" class="txb-list">
            <thead>
              <tr>
                <th nzWidth="70%">Token</th>
                <th nzWidth="15%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tblAPITokens.data">
                <td>{{ data.id }}</td>
                <td><a nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this token?" (nzOnConfirm)="confirmTokenDelete(data)">Delete</a></td>
              </tr>
            </tbody>
          </nz-table>
          <button nz-button nzType="primary" (click)="createAccessToken()"><i class="far fa-user-plus"></i> Generate Token</button>
          <ng-template #noTokens>
            This user does not have any access tokens.
          </ng-template>
        </div>
      </nz-tab>
    </nz-tabset>
  </ng-container>
</nz-drawer>
<ng-template #extraTemplate>
  <button nz-button nzType="primary" (click)="btnAddUser()" *ngIf="shouldShowAddUserButton()"><i class="far fa-user-plus"></i> Add User</button>
  <button nz-button nzType="primary" (click)="btnAddOrg()" *ngIf="shouldShowAddOrgsButton()"><i class="far fa-sitemap"></i> Add Organization</button>
</ng-template>

<nz-modal
  [(nzVisible)]="showAddUserModal" 
  nzTitle="Add New User"
  (nzOnCancel)="cancelNewUserModal()" 
  [nzFooter]="null"
  nzWidth="80%"
  >
  <ng-container *nzModalContent>
    <app-native-add-user-modal
      [visible]="showAddUserModal"
      [samlDef]="samlDef"
      (addOrg)="btnAddOrg()"
      (onComplete)="cancelNewUserModal()"
    ></app-native-add-user-modal>
  </ng-container>
</nz-modal>

<app-organization-editor
  [visible]="showOrgEditorModal"
  [selectedOrganization]="activeOrg"
  [mode]="organizationEditorModalMode"
  (dismissed)="organizationEditorModalDismissed()">
</app-organization-editor>

<ng-template #pageTitleTemplate>
  Manage Users and Organizations <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Admin Menu'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>