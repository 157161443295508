import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthNZService } from 'src/app/core/authnz.service';
import { RewardfulService } from 'src/app/services/rewardful.service';
import { getLogger } from '@shared/logging';
import { CheckoutSessionRequest, StripeService } from 'src/app/services/stripe.service';

const log = getLogger("PlanSelector")

@Component({
  selector: 'app-plan-selector',
  templateUrl: './plan-selector.component.html',
  styleUrls: ['./plan-selector.component.scss']
})
export class PlanSelectorComponent implements OnInit {

  @Input() isVisible: boolean = false
  @Input() isClosable: boolean = false
  @Input() userProfile = {}

  @Output() closeModal = new EventEmitter<boolean>();

  environment: any = environment;
  isLoading: boolean = false;
  isLoggingOut:boolean = false;
  hasFailed: boolean = false;
  errorMessage:string = ""

  constructor(
    private authnz: AuthNZService,
    private rewardful: RewardfulService,
    private stripe: StripeService
  ) { }

  ngOnInit() {  }
  
  public async goToCheckout(plan) {
    this.isLoading = true;

    const checkoutSessionProps: CheckoutSessionRequest = {
      priceId: plan,
      referralId: await this.rewardful.getRewardfulKey()
    }

    try {
      let sessionId = await this.stripe.getCheckoutSession(checkoutSessionProps)
      await this.stripe.redirectToCheckout(sessionId);
      this.isLoading = false;
    } 
    catch(err) {
      log.warn("Failed redirecting to checkout")
        this.hasFailed = true;
        this.isLoading = false;
        if (typeof err.error?.error == "string") {
          this.errorMessage = err.error.error;
      } else {
        this.errorMessage = err.message;
      }
    }

  }
 
  hideModal() {
    this.isVisible = false;
    this.isLoading = false;
    this.hasFailed = false;
    this.closeModal.emit(false);
  }

  logout() {
    this.isLoggingOut = true;
    this.authnz.logout();
  }
}
