import { Injectable } from '@angular/core';
import { ClearableReplaySubject } from "./ClearableReplaySubject";
import { Observable, Subscription } from 'rxjs';
import { getLogger } from 'src/shared/logging';
import { TextableDate } from 'src/classes/TextableDate';

// This module generates a bunch of logspam, which isn't necessarily that useful.
// set this to silent.  If a developer needs to debug subscriptions, 
// then this can be set to debug.
const log = getLogger("SubscriptionManager");
log.disableAll();

/**
 * Provides a framework for maintaining active Observable (rxjs) subscriptions
 * throught the lifecycle of the application.  
 * 
 * Accounts for cleanup of subscriptions at logout (where the "observed" becomes inaccessbile),
 * and provides mechanisms for "replacing" the observed (when the context sufficiently
 * changes so as to require re-creation of the subscription)
 */

 interface ActiveObservable {
  OuterObservable: ClearableReplaySubject<any>;
  Subscription: Subscription,
  CreationFunction: ()=>Observable<any>,
  UpdateMode: ObservableContexUpdateSignal[],
  LastActionTime?: number
}

/**
 * Anytime one of these signals is "Triggered" from within the application, 
 * SubscriptionManager will evaluate each managed observable to determine 
 * whether the subscription needs to be re-created or destroyed.
 * 
 * The OnDemand signal is special, in that it will ONLY cause the subscription 
 * to be re-created if it already exists AND another signal has been received.
 */
export enum ObservableContexUpdateSignal {
  "ProfileChanged",
  "LoggedIn",
  "Prelogout",
  "ActiveNumberChanged",
  "ContactPageChanged",
  "Immediate",
  "OnDemand"
}

@Injectable({
  providedIn: 'root'
})
/**
 * Provides mechanisms for establishing, renewing, and cleaning up subscriptions to observables
 * Provides a signaling mechanism to renew subcriptions according to specific events.
 * 
 * 
 */
export class SubscriptionManagerService {

  private activeObservables: {[key:string]: ActiveObservable};

  constructor(
  ) {

    this.activeObservables = {};

  }


  /**
   * Returns a new observable of the same type as supplied; however, the returned observable 
   * will be a proxy for the subscription defined in the predicate.  
   * 
   * The subscription defined in the predicate will be recreated when conditions are matched according to updateMOde
   * 
   * Additionally, the subscription from the supplied observable will be terminated gracefully
   * before completing a logout action.
   * 
   * @param purpose a string to uniquely identify the scope / purpose of this observable.  If this same scope is requested again during the lifecycle of the current
   *  application (or authentication) context, then the previously created observable will be destroyed, and a new one will be created according to the new `fn`
   * @param fn lambda function to generate the higher-level observable; invoked anytime user context changes. Subscription will be destroyed before a logout
   * @returns an observable of the same type which is long-lived across user context changes and to which events from the higher-level observable are proxied.
   */
  observe<T>(
    purpose: string, 
    fn: ()=>Observable<T>, 
    updateMode: ObservableContexUpdateSignal | ObservableContexUpdateSignal[]
    ): Observable<T> {

    let updateModeArray: ObservableContexUpdateSignal[];
   
    if (this.activeObservables.hasOwnProperty(purpose)) {
      log.debug("Re-using observable for purpose: " + purpose);
      // updating the function here is necessary so that any scope-specific variables passed with the instantiation function
      // are updated
      this.activeObservables[purpose].CreationFunction = fn;
    }
    else {
      if (! Array.isArray(updateMode)) {
        updateModeArray = [ updateMode as ObservableContexUpdateSignal ]
      }
      else {
        updateModeArray = updateMode as ObservableContexUpdateSignal[]
      }
      this.activeObservables[purpose] = {
        Subscription: null,
        CreationFunction: fn,
        OuterObservable: new ClearableReplaySubject<T>(),
        UpdateMode: updateModeArray
      };
    }

    if (updateMode == ObservableContexUpdateSignal.Immediate)
    {
      (async ()=>{
        this.instantiateObservable(purpose);
      }) ();
    }
   
    return this.activeObservables[purpose].OuterObservable;
  }

  /**
   * Delivers a signal to the Subscription manager.  If the signal is PreLogout, then all managed subscriptions are disposed of
   * 
   * For all non-logout signals, subscriptions having the passed signal are re-created
   * 
   * @param signal 
   */
  public signal(signal: ObservableContexUpdateSignal, purpose?: string[] ) {
    if (!purpose) {
      purpose = Object.keys(this.activeObservables)
    }
    log.debug("received signal:" + signal);
    if (signal === ObservableContexUpdateSignal.Prelogout) {
      for(let purpose of Object.keys(this.activeObservables)) {
        this.destroyObservable(purpose);

      }
    }
    else {
      for(let purpose of Object.keys(this.activeObservables)) {
        log.debug("Sending signal " + signal + " to observable: " + purpose);
        if (this.activeObservables[purpose].UpdateMode.includes(signal)) {
          (async () => {
            if (! this.activeObservables[purpose].Subscription && this.activeObservables[purpose].UpdateMode.includes(ObservableContexUpdateSignal.OnDemand) && signal == ObservableContexUpdateSignal.OnDemand)
            {
              // There is no active subscription (OnDemand has not yet been triggered), but the current signal is OnDemand
              // Then we instantiate
              this.instantiateObservable(purpose);
              log.debug("sending signal " + signal + " to observable: " + purpose + " as first-shot OnDemand");
            }
            else if (! this.activeObservables[purpose].Subscription && this.activeObservables[purpose].UpdateMode.includes(ObservableContexUpdateSignal.OnDemand))
            {
              // There is no active subscription (OnDemand has not yet been triggered), and the current signal is not OnDemand
              // then we don't instantiate.
              log.debug("Not sending signal " + signal + " to observable: " + purpose + " because it has been flagged as OnDemand");
            }
            else {
              // We instantiate (this handles cases where there is an active subscription, and OnDemand has been trigered, and
              // the current signal is not OnDemand)
              this.instantiateObservable(purpose);
            }
          })();
        }
      }
    }
  }

  /**
   * Executes the CreationFunction of the observable with the named purpose; thereby actually creating the subscription
   * @param purpose string representing the purpose
   */
  public instantiateObservable(purpose: string) {
    try{ 
      if (this.activeObservables[purpose].Subscription) {
        log.debug("closed old subscription for: " + purpose);
        this.activeObservables[purpose].Subscription.unsubscribe();
      }
      this.activeObservables[purpose].LastActionTime = TextableDate.now();
      log.debug("running instantiation function for " + purpose + " at " + this.activeObservables[purpose].LastActionTime);
      this.activeObservables[purpose].Subscription = this.activeObservables[purpose]
        .CreationFunction()
        .subscribe(
          innerObservableEmitted => {
            try{
              const received = TextableDate.now();
              log.debug("proxying subscription for " + purpose + " at " + received + "; ms since last: " + (received - this.activeObservables[purpose].LastActionTime), innerObservableEmitted);
              this.activeObservables[purpose].LastActionTime = TextableDate.now();
              this.activeObservables[purpose].OuterObservable.next(innerObservableEmitted);
            } catch (err) {
              log.error("error proxying subscription for " + purpose, err);
            }
          },
          (err)=>log.warn("Error with lambda observable " + purpose + ": ", err)
        );
    }
    catch(err){
      log.warn("Error creating observable " + purpose + ": ", err)
    }
  }

  /**
   * Cleans up subscriptions and emits closed siglal to observers
   * @param purpose 
   */
  public destroyObservable(purpose: string){ 
    if (!this.activeObservables.hasOwnProperty(purpose)) {
      log.debug("Not cleaning up nonexistant observable: "+ purpose);
      return;
    }
    try {
      log.debug("Cleaning up observable: " + purpose);
      this.activeObservables[purpose].Subscription.unsubscribe();
      this.activeObservables[purpose].OuterObservable.clearBuffer();
      //this.activeObservables[purpose].OuterObservable.complete();
      //delete this.activeObservables[purpose];
    }
    catch(e){
      log.warn("Failed to cleanup observable for: " + purpose, e);
    }
  }


  /**
   *  TODO: future enhancement.  Provides a method to update the properties (not yet implemented) which are passed to a CreationFunction
   * after updating the props, the instantiateObservable method is called.
   */
  public updateProps(purpose:string, props: any) {
    throw new Error('Method not implemented.');
  }




}
