import { TextableBaseFirestoreDocument } from "./base"

type TextableStatusDescription = {
  message: string
  level: TextableMessageStatusEventLevel
}

/**
 * We want to preserve the keys on the actual definition of TextableMessageStatusDescriptions
 * whilst also enforcing the values of each key.
 * 
 * This `satisfies` solution seems to be the best out of:
 * *  https://github.com/microsoft/TypeScript/issues/47920#issuecomment-1278235809
 * *  https://stackoverflow.com/questions/64466363/typescript-enforce-object-values-without-losing-explicit-keys
 * 
 * @returns 
 */
 const satisfies = <SuperType>() => <Actual extends SuperType>(value: Actual): Actual => value


/**
 * TODO: Map these TextableMessageStatusDescriptions to ServerEventTypes
 */
 export const TextableMessageStatusDescriptions =  satisfies<Record<string,TextableStatusDescription>>()({
  USER_DRAFT_STARTED: {
    message: "Draft Started by",
    level: "info"
  },
  USER_MESSAGE_SENT: {
    message: "Sent by",
    level:"success"
  },
  USER_BLOCKED_SENDER: {
    message: "Sender blocked by",
    level: "info"
  },
  BACKEND_CREATED_CONSENT: {
    message: "Consent message created",
    level: "info"
  },
  BACKEND_ATTACHMENTS_FETCHED: {
    message: "Fetched all attachments from",
    level: "success"
  },
  BACKEND_ATTACHMENTS_FAILED: {
    message: "Failed fetching one or more attachments from",
    level: "error"
  },
  BACKEND_RECEIVED: {
    message: "Received by backend from",
    level: "success"
  },
  BACKEND_OBSERVED: {
    message: "Observed by backend",
    level:"success"
  },
  BACKEND_REJECTED: {
    message: "Rejected by backend",
    level: "error"
  },
  PROVIDER_ACCEPTED: {
    message: "Accepted by",
    level:"success"
  },
  PROVIDER_REJECTED: {
    message: "Rejected by",
    level:"error"
  },
  PROVIDER_QUEUED: {
    message: "Queued with",
    level:"info"
  },
  PROVIDER_SENT: {
    message: "Sent by",
    level:"success"
  },
  PROVIDER_SENDING: {
    message: "Sending through",
    level:"info"
  },
  PROVIDER_DELIVERED: {
    message: "Delivered by",
    level:"success"
  },
  PROVIDER_UNKNOWN: {
    message: "In an unknown state with",
    level:"error"
  },
  PROVIDER_SEND_FAILED: {
    message: "Failed to send through",
    level:"error"
  },
  OTHER: {
    message: "Other",
    level:"info"
  },
  MESSAGE_CONTACT_CHANGED: {
    message: "Contact for message was changed",
    level: "info"
  }
});

export type TextableMessageStatusEventActionType = keyof typeof TextableMessageStatusDescriptions

export type TextableMessageStatusEventLevel =  "info" | "error" | "success"
export function isTextableMessageStatusEvent(o: any): o is TextableMessageStatusEvent {
  return o.hasOwnProperty("timestamp") && o.hasOwnProperty("action")
}

export type TextableMessageStatusEvent = {
  timestamp: number
  action: {
    type: TextableMessageStatusEventActionType
    object: string
  }
  messageId?: string
  eventId?: string
  backendId?: string
  /**
   * Raw data to be stored with the event
   */
  data? :any,
  /**
   * Signature validation result for the individual event
   */
  signatureValidationResult?: string
}

export interface TextableAttachment {
  contentType?:string
  contentLength?:number
  filename:string
  url? :string
  processed: boolean
}

export type TextableSendStatus = "scheduled" | "pending" | "sent" | "fail" | "note" // TODO: TXBDEV-2042 - Value uses of send_status are inconsistent


export interface TextableMessage extends TextableBaseFirestoreDocument {
  body: string;
  to: string;
  from: string;
  date: number;
  direction: TextableMessageDirection;
  attachments?: TextableAttachment[];
  id?: string;
  uid?: string;
  contact_id?: string;
  send_status?: TextableSendStatus;
  sentBy?: string;
  sentByLabel?: string;
  metadata?: TextableMessageMetadata;
  /**
   * Map of evnts for this message
   * 
   * The key here is of no  value other than to facilitate asynchronous
   * updates of what would otherwise be an array in firebase.
   * 
   */
  events?: Record<string,TextableMessageStatusEvent>;
  disableDripCampaignDelete?:boolean;
  scheduleTimezone?: string
}

export enum TextableMessageDirection {
  IN="in",
  OUT="out"
}

export type TextableMessageMetadata = {
  /**
   * The ID of the message in the remote provider's system
   */
  messageId?: string
  /**
   * The name of the remote carrier associated with the phone
   * number of the contact of this message
   */
  remoteCarrier?: string
  segmentCount?: number
  cost?: string
  encoding?: string
  /**
   * Message type.
   * 
   * should generally be SMS,MMS, or RCS. 
   */
  type?: string
  subscriptionChange?: "optin" | "optout"
  // These properties may be provider-specific
  owner?: string
  createdTime?: Date
  receivedAt?: string
  sentAt?: string,
  /**
   * Signature validation result for the base message
   */
  signatureValidationResult?: string
  /**
   * Allow storage of provier-specific properties not defined here 
   */
  [key:string]: any
}


export interface TextableReminder extends TextableMessage {
  Recipient: string
}



type BlastCustomRecipientList = {
  recipientListSource: "custom" 
  recipientList:   string[]
}

type BlastRecipientList = {
  recipientListSource: "select"
  recipientList: {
      count: number
      id: string
      listName: string
    } 
}

type BlastRecipients = BlastCustomRecipientList | BlastRecipientList

export type TextableBlast = TextableBaseFirestoreDocument & BlastRecipients  & {
  blastComplianceFooter: string
  blastMessage: string
  blastName: string
  lastModifiedDate: number
  scheduleTime: number
  scheduleTimezone: string
  status: string
  uid: string
} 

export interface TextableCannedResponse extends TextableBaseFirestoreDocument {
  auto_keyword: string,
  body: string,
  name: string,
  uid: string
}