import { Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ThemeService } from './theme.service';
import { TinyColor } from '@ctrl/tinycolor';
import "../extensions/CanvasRenderingContext2D.extensions";
import { getLogger } from 'src/shared/logging';
const log = getLogger("TitleBarService")

@Injectable({
  providedIn: 'root'
})
/**
 * Manages the Browser's `<title>` attribute during 
 * the entire lifecycle of the application
 */
export class TitleBarService {

  private favicon: Favicon;
  private titleComponents: PageTitleComponents = {
    appName: environment.appName,
    NotificationCount: 0
  }

  constructor(
    private title: Title,
    private injector: Injector
  ) {
    this.favicon = new Favicon();
    this.SetPageTitle({},true);
   }

   /**
    * Sets the page title, and updates the favicon
    * 
    * @param titleComponents 
    * @param skipBranding When called from the class constructor, this method will not be able to obtain a reference to the ThemeService, so we skip it.
    */
  public SetPageTitle(titleComponents: PageTitleComponents, skipBranding: boolean = false ) {

    let titleStringComponents: string[] = [];

    this.titleComponents = {
      ...this.titleComponents,
      ...titleComponents
    }

    if (!this.titleComponents.appName) {
      this.titleComponents.appName = environment.appName
    }

    if (this.titleComponents.NotificationCount > 0) {
      titleStringComponents.push("("+this.titleComponents.NotificationCount+")");
    }
    titleStringComponents.push(this.titleComponents.appName);
    this.title.setTitle(titleStringComponents.join(" "));


    let textColor = "#000000"
    let backgroundColor = "#FFFFFF";
    if (!skipBranding) {
      let themeService = this.injector.get(ThemeService);
      if ( themeService.activeBranding?.theme?.properties.hasOwnProperty("ant-primary-color") && themeService.activeBranding?.theme?.properties["ant-primary-color"]) {
        const primaryColor = themeService.activeBranding.theme.properties["ant-primary-color"];
        textColor = new TinyColor(primaryColor).saturate(6).darken(50).toHexString();
        backgroundColor = new TinyColor(primaryColor).saturate(6).lighten(50).toHexString();
        //log.debug("Icon foreground/background: " + textColor +"/"+backgroundColor);
      }
    }
    this.favicon.UpdateIconSubtext(
      this.titleComponents.NotificationCount == 0 ? "" : ""+this.titleComponents.NotificationCount, 
      textColor,
      backgroundColor
      );
  }
}


export type PageTitleComponents = {
  /**
   * Name of application to show in Title Bar
   */
  appName?: string
  /**
   * The count of unread messages to show in the browser title bar.
   */
  NotificationCount?: number
}


class Favicon {

  private faviconLink: HTMLLinkElement;
  private baseFavicon: HTMLImageElement;
  private canvas = document.createElement('canvas');
  private context = this.canvas.getContext('2d');
  private subtext: string;
  private textColor: string;
  private backgroundColor: string;
  private loadedFavicon: Promise<void>;

  constructor() {
    this.loadedFavicon = this.readExistingFavicon();
  }

  private async readExistingFavicon(): Promise<void> {
    return new Promise((resolve,reject) => {
      this.faviconLink = (document.querySelectorAll('link[rel="icon"]') as NodeListOf<HTMLLinkElement>)[0]
      this.baseFavicon = new Image();
      this.baseFavicon.onload = (event) => {
        this.canvas.width = this.baseFavicon.width;
        this.canvas.height = this.baseFavicon.height
        log.debug("Favicon loaded: '" +this.canvas.width + "x"+this.canvas.height+"'")
        resolve()
        return;
      }
      this.baseFavicon.src = this.faviconLink.href 
    });
  }

  public async UpdateIconSubtext(text: string, textColor: string, backgroundColor: string) {
    if (this.subtext == text && this.textColor == textColor && this.backgroundColor == backgroundColor ) {
      return
    }
    await this.loadedFavicon;
  
    this.subtext = text;
    this.backgroundColor = backgroundColor
    this.textColor = textColor
    this.context.clearRect(0,0,this.canvas.width,this.canvas.height);
    this.context.drawImage(this.baseFavicon, 0, 0);
    if (this.subtext !== "") {
      const textVerticalMargin = 1
      const textX = this.canvas.width / 2;
      const textY = this.canvas.height - textVerticalMargin;
      const textSize = (this.canvas.height / 2) + 8;
      this.context.globalAlpha = .9;
      this.context.fillStyle = backgroundColor;
      this.context.roundRect(
        0, 
        this.canvas.height / 2, 
        this.canvas.width, 
        this.canvas.height / 2,
        { upperLeft: 25, upperRight: 25, lowerLeft: 0, lowerRight: 0 },
        true,
        false
      )
  
      this.context.globalAlpha = 1;
      this.context.fillStyle = textColor;
      this.context.strokeStyle = '#ffffff';
      this.context.textAlign = "center"
      this.context.lineWidth = 4;
      this.context.font = textSize + "px math"
      this.context.strokeText(text,textX,textY)
      this.context.fillText(text,textX,textY)
    }
    var imageString = this.canvas.toDataURL("image/x-icon");
    this.UpdateFavicon(imageString);
    log.debug("Favicon updated: '"+this.subtext+"'")
  }

  private UpdateFavicon(data: string) {
    this.faviconLink.href = data;
  }
}