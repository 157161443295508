import { Injectable } from '@angular/core';
import { getLogger } from '@shared/logging';
import { TextableDate } from 'src/classes/TextableDate';
import { TimeSync, RPCRequest, RPCResponse } from '@crossan007/timesync';
import { AuthNZService } from '../core/authnz.service';
import { BackendService } from './backend.service';
import { VisibilityService } from './visibility-service.service';
import { pairwise, startWith } from 'rxjs/operators';
const log = getLogger("DateService");

@Injectable({
  providedIn: 'root'
})
export class DateService {
  // #region Properties (1)

  //@ts-ignore
  private ts: TimeSync;

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(
    private backend: BackendService,
    visibility: VisibilityService
  ) {
    log.debug("DateService starting");


    this.setupTimeSync();
 
    visibility.pageVisiblity$.pipe(startWith({focused: false, visible: false}),pairwise()).subscribe(async ([prev,next])=>{
      const changed = (! prev.visible && next.visible) || (!prev.focused && next.focused)
      if (changed && this.ts) {
        this.ts.sync();
      }
    })
    TextableDate.timeSync = this.ts
  }

  private setupTimeSync() {
    this.ts = new TimeSyncBrowser(this.backend)
    this.ts.on('change', (offset)=>{
      log.info(`changed offset: ${offset} ms`);
    });
    this.ts.on('sync', (state)=>{
      log.info(`Sync operation ${state}.  Server Time: ${new Date(this.ts.now()).toISOString()}, Local time: ${new Date().toISOString()}, Offset: ${this.ts.offset}`);
    });
    this.ts.on('error', (err)=>{
      log.warn(`TimeSync error: `, err);
    });
  }


  // #endregion Constructors (1)
}


class TimeSyncBrowser extends TimeSync {
  constructor(private backend: BackendService) {
    super({
      server:"backend",
      repeat: 2,
      interval: 30 * 60 * 1000,
    });
  }
  protected _send(
    to: string,
    data: RPCRequest
  ): Promise<RPCResponse> {
    return this.backend.backendPost("api/v2/time",{},data,{authenticated: true})
  }
}
