import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getLogger } from '@shared/logging';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { TextableService } from 'src/app/services/textable.service';
import { environment } from '../../../environments/environment';

const log = getLogger("ProviderEditor")
log.disableAll()

interface TextableProvider {
  name: string;
  credentials: {[key:string]: string};
}

/**
 * A codified interface for translating the credentials properties
 * established within this component into the format required for 
 * the various document types (user profile vs tenant)
 */
interface ProviderFormTransactor {
  /**
   * extracts a TextableProvider from the target form
   */
  getProviderDetailsFromBaseDocument(): TextableProvider
  /**
   * Writes the given TextableProvider to the target form
   * @param details TextableProvider object
   */
  writeProviderDetailsToForm(details: TextableProvider, keysToRemove?: string[] ): void
}


/**
 * User profiles store credential details as top-level attributes
 * on the document
 */
class UserProfileProviderTransactor implements ProviderFormTransactor {

  private userProfileFormGroup: FormGroup;
  private userDocument: any;

  constructor(
    baseFormGroup: FormGroup, 
    baseDocument: any,
    private textable: TextableService
    ) {
    this.userProfileFormGroup = baseFormGroup;
    this.userDocument = baseDocument;
    log.debug("constructing UserProfileProviderTransactor")
  }
  getProviderDetailsFromBaseDocument(): TextableProvider {

    if(!this.userDocument || !this.userDocument.provider){
      log.debug("No base document or provider; using clean form");
      return {
        name: null,
        credentials: {}
      };
    }

    const provider = this.textable.getProvider(this.userDocument.provider)
    if (!provider) {
      throw new Error("Invalid provider '"+this.userDocument.provider+"'")
    }
    let credentials = {};
    Object.keys(provider.fields).map((fieldName) => {
      if (this.userDocument[fieldName]) {
        log.debug("extracting " + fieldName+":", this.userDocument[fieldName])
        credentials[fieldName] = this.userDocument[fieldName]
      }
    });
    
    const retVal =  {
      name: provider.id,
      credentials: credentials
    };
    log.debug("extracted creds: " , retVal);
    return retVal;

  }
  writeProviderDetailsToForm(details: TextableProvider, keysToRemove?: string[] ): void {
    this.userProfileFormGroup.get("provider").setValue(details.name);
    if (keysToRemove) {
      keysToRemove.forEach(element => {
        log.debug("removing key", element)
        this.userProfileFormGroup.removeControl(element)
      });
    }
    for(let key of Object.keys(details.credentials)) {
      const newValue = details.credentials[key];
      let existingControl = this.userProfileFormGroup.get(key);
      if (existingControl) {
        log.debug("updated " + key + "control to new value", {control: existingControl, newValue: newValue });
        existingControl.setValue(details.credentials[key])
      }
      else {
        log.debug("created " + key + " control with value", {newValue: newValue});
        this.userProfileFormGroup.addControl(
          key,
          new FormControl(details.credentials[key],[]) // TODO: Do not validate these fields for now until TXBDEV-1017 does it right.
        );
      }
    }

    this.userProfileFormGroup.updateValueAndValidity();

    log.debug("Wrote form",{newData: details, formState: this.userProfileFormGroup});
  }

  
}

@Component({
  selector: 'app-provider-editor',
  templateUrl: './provider-editor.component.html',
  styleUrls: ['./provider-editor.component.scss'],
})
export class ProviderEditorComponent implements OnInit, OnChanges {

  private formTransactor: ProviderFormTransactor;
  @Input() baseFormGroup: FormGroup;
  @Input() baseDocument: any;
  @Input() baseFormType: "user" ;
  @Input() disabled: boolean ;

  providerSettings: TextableProvider
  showCredentialEntryForm: boolean = environment.credentialSource=='user';
  credentialFields: {id:string, label: string}[];
  providers 
  /**
   * If the supplied provider value is not present in application_context,
   * then we can treat it as invalid, and show a warning
   */
  public invalidProvider: boolean = false;


  constructor(
    private textable:TextableService,
    private applicationContextService: ApplicationContextService
  ) { 
    this.providerSettings = {
      name: null,
      credentials: {}
    }
    this.providers = applicationContextService.applicationContext.enabledProviders
  }
  ngOnChanges(changes: SimpleChanges): void {
    log.debug("baseFormType" , this.baseFormType);
    log.debug("baseDocument", this.baseDocument);
    if(this.baseFormType == "user") {
      this.formTransactor = new UserProfileProviderTransactor(this.baseFormGroup,this.baseDocument, this.textable);
    }
    else {
      throw new Error("Invalid provider form type");
    }
    if (this.baseDocument) {
      log.debug("Refreshing from base document")
      try {
        this.providerSettings = this.formTransactor.getProviderDetailsFromBaseDocument();
        this.refreshCredentialFields();
        // remove all possible credential keys which do not exist on the
        // now-currently selected provider
        const keysToRemove = this.textable.getAllCredentialFields().filter(
          (p)=> ! Object.keys(this.providerSettings.credentials).includes(p)
        )
        this.formTransactor.writeProviderDetailsToForm(this.providerSettings, keysToRemove);
      }
      catch (err) {
        log.warn(err);
        this.invalidProvider = true;
      }
    }
    else {
      log.debug("not refreshing from base document")
    }
  }

  ngOnInit() {
   
  }

  shouldAllowCredentialEntry() {
    return environment.credentialSource=='user';
  }

  /**
   * Detect changes in the Provider select box.  Trigger appropriate updates to the data model and form elements
   * @param change 
   */
  changedProviderName(change:any) {
    log.debug("Selected provider changed: ", {"new":change, "old": this.providerSettings.name});
    let keysToRemove :string[];
    if (this.providerSettings.name) {
      keysToRemove = Object.keys(this.textable.getProvider(this.providerSettings.name).fields)
    }
    else {
      // in the case we've nullified a base document 
      keysToRemove = this.textable.getAllCredentialFields();
    }
    const originalProviderSettings = this.formTransactor.getProviderDetailsFromBaseDocument();
    if (originalProviderSettings.name == change) {
      // The user selected the provider which was on their user document at the time the form loaded
      // let's recall the original settings so they don't loose any saved settings
      this.providerSettings = originalProviderSettings
    }
    else {
      // the user selected a different provider from what was on their form at time of opening
      // set up a new settings object / credentials
      this.providerSettings.name = change;
      this.providerSettings.credentials = {};
    }
    this.refreshCredentialFields();
    this.formTransactor.writeProviderDetailsToForm(this.providerSettings, keysToRemove);
  }


  /**
   * Updates credentialFields with id,label objects so the fields can
   * be rendered in the UI
   */
  private refreshCredentialFields(){ 
    if(!this.providerSettings.name) {
      this.credentialFields = []
      return;
    }
    const selectedProvider = this.textable.getProvider(this.providerSettings.name);
    if (!this.showCredentialEntryForm) {
      this.credentialFields = [];
      this.providerSettings.credentials = {};
      return;
    }
    this.credentialFields = Object.keys(selectedProvider.fields)
    .map(
      (key:string) => {
        log.debug("processing field ", key);
        if(!this.providerSettings.credentials.hasOwnProperty(key)){
          this.providerSettings.credentials[key] = "";
        }
        return {
          id: key,
          label: selectedProvider.fields[key]
        }
      }
    )
  }





  inputChanged(change: string) {
    log.debug("Provider credentials changed: ", {"new":change, "old": this.providerSettings})
    this.formTransactor.writeProviderDetailsToForm(this.providerSettings);    
  }
}
