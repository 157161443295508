import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-timezone';
import { getLogger } from 'src/shared/logging';
import levenshtein from "fast-levenshtein";
import { TextableDate } from 'src/classes/TextableDate';
const log = getLogger("Functions");

const MS_PER_DAY = 86400000;
/**
 * Validates that a DateRange component on an Angular form 
 * has fewer than the `maxDays` selected 
 * 
 * https://angular.io/guide/form-validation#defining-custom-validators
 * @param maxDays 
 * @returns 
 */
export function DateRangeValidator(maxDays: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.hasOwnProperty("value") || control.value == null) { 
      return null;
    }
    const numberofSelectedDays =  (control.value[1] - control.value[0]) / MS_PER_DAY
    return numberofSelectedDays > maxDays ?
      {invalidRange: numberofSelectedDays } : 
      null
  };
}
