import { Injectable, ViewContainerRef } from '@angular/core';
import { getLogger } from '@shared/logging';
import { BackgroundOperationStatusComponent } from './components/background-operation-status/background-operation-status.component';

const log = getLogger("BackgroundOperationStatusService")

export type OperationStatusUpdate = {
  /**
   * Title of the operation
   * 
   * Must match for each operation update; and must be unique across possible concurrent opreations.
   */
  operation: string
  /**
   * Determines whether the close button is available on the notification; and whether the auto-dismiss timer shoud start.
   * 
   * If unset, will be determined based on `percentComplete == 100`
   */
  finished?: boolean
  /**
   * Text to be displayed in the dialog
   */
  statusText: string
  /**
   * Percent complete to be shown in the progress bar.
   * 
   * If non-numeric, the progress bar will be hidden.
   */
  percentComplete?: number
  /**
   * Ng-zorro icon to show.  Defaults to `loading` 
   * 
   * Icon sources: https://ng.ant.design/version/13.4.x/components/icon/en
   */
  icon?: string
}

@Injectable({
  providedIn: 'root'
})
export class BackgroundOperationStatusService {

    /**
   * The container into which we should push notification
   */
    private container: ViewContainerRef
    /**
     * Local variable to store the DI-instantiated reference to the notification component.
     * 
     * This is really only necessary since we can't create a template within an Angular Service
     * and the NzNotificationModule needs a TemplateRef, so we use the component here as a proxy
     * to create the template ref from this service.
     * 
     * Watch this issue for possible improvements on this approach: 
     * https://github.com/NG-ZORRO/ng-zorro-antd/issues/6119
     */
    private notificationComponentInstance: BackgroundOperationStatusComponent;

  constructor() { }

    /**
   * When the service needs to manage a long-running notification for an import
   * it will spawn a `BackgroundOperationStatusComponent` inside the container defined here
   * 
   * @param container 
   */
  public setContainer(container: ViewContainerRef) {
    this.container = container;
    log.debug("Set container to ", this.container)
    const c = this.container.createComponent(BackgroundOperationStatusComponent)
    this.notificationComponentInstance = c.instance;
  }


  public updateStatus(status: OperationStatusUpdate){
    this.notificationComponentInstance.updateStatus(status);
  }

}
