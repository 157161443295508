import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conversation-results',
  templateUrl: './conversation-results.component.html',
  styleUrls: ['./conversation-results.component.scss']
})
export class ConversationResultsComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goBack(){
    this.router.navigate(['conversations'])
  }
}
