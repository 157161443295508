import { Query, CollectionReference } from '@angular/fire/compat/firestore';
import { UserGuideConfig } from 'src/app/components/user-guides/user-guides.component';

export interface TextableSubscriptionPlan {
  id: string
  displayName: string
  description: string
  /**
   * when true, only isAdmin users can make changes to objects (users / organizations) having this plan type selected
   */
  restricted: Boolean
}
export interface PlanSelectionProperties {
  granularity: "user"|"organization"|"none",
  choices: TextableSubscriptionPlan[]
}

export interface UISecurityProvider {
  // #region Public Methods (46)

  allowProviderCopyOnUserCreate(): boolean
  canAccessAdminMenu(): boolean
  canAccessAdminTools(): boolean
  canAccessBlasts(): boolean
  canAccessBulkMMS(): boolean
  canAccessCannedResponses(): boolean
  canAccessContactLists(): boolean
  canAccessContacts(): boolean
  canAccessConversations(): boolean
  canAccessDripCampaigns(): boolean
  canAccessIntegrations(): boolean
  canAccessManageUsers(): boolean
  canAccessNumberSwitcher(): boolean
  canAccessOwnProfile(): boolean
  canAccessReminders(): boolean
  canAccessServiceAccounts(): boolean
  canAddOrgs(): boolean
  canAddUsers(): boolean
  canAccessDeletedUsers(): boolean
  canAccessDeletedOrganizations(): boolean
  canDeleteAllContacts(): boolean
  canDeleteContacts(): boolean
  canDeleteUsers(): boolean
  canDisableOrganizations(): boolean
  canEditAdminIntegrations(): boolean
  canEditOrgConsentMessages(): boolean
  canEditOwnFullName(): boolean
  canEditOwnProviderSettings(): boolean
  canEditUserDisabled(): boolean
  canEditUserFullName(): boolean
  canEditUserIsAdmin(): boolean
  canEditUserOrganization(): boolean
  canEditUserPhoneNumber(): boolean
  canExportConversations(): boolean
  canImportContacts(): boolean
  canManageBilling(): boolean
  canModifyUserAPITokens(): boolean
  canModifyUserNumberSharing(): boolean
  canModifyUserPermissions(): boolean
  canModifyUserWebhookRelay(): boolean
  canViewProviderDetails(): boolean
  canViewProviderName(): boolean
  canViewDLRObject(): boolean
  canViewMetadata(): boolean
  getUserGudeOverrides(): UserGuideConfig[]
  getDebugUserRole(): string
  getEditOwnProviderSettingsContact(): string
  getPlanSelectionDetails(): PlanSelectionProperties
  hasFullAdminRole(): boolean
  isSubscriptionActive(): boolean
  requiresEmailConfirmation(): boolean
  shouldForcePlanSelector(): boolean
  shouldShowSubscriptionStatus(): boolean
  /**
   * This is a weird implemtation on the interface, since non-retail classes need to implement a method that returns false
   */
  showRetailBrandingDetails(): boolean
  storeManagedByAttributeOnUsers(): boolean
  /**
   * Trims the Message query according to the rules defined for the implementing sceurity context
   * @param query 
   */
  trimFirestoreMessageQuery(query: Query|CollectionReference): Query;
  /**
   * Trims the Organization query according to the rules defined for the implementing sceurity context
   * @param query 
   */
  trimFirestoreOrgQuery(query: Query|CollectionReference): Query;
  /**
   * Trims the User query according to the rules defined for the implementing sceurity context
   * @param query 
   */
  trimFirestoreUserQuery(query: Query|CollectionReference): Query;
  unrestrictedGrant();
  /**
   * 
   * Updates the security provider's user model based on changes to ApplicationState
   * 
   * @throws an exception if the current user document is "incomplete" so that an error page may be displayed
   * 
   */
  updateUser(): void
  /**
   * Retail and PL use different mechanisms for user management operations.
   * 
   * TODO: TXBDEV-1372 - Unify these two mechanisms to prevent duplicate code.
   */
  usePrivateBackendForUserOperations(): boolean

  // #endregion Public Methods (46)
}

/**
 * Current State of the application
 * 
 * Used to define the template of the primary app.component component
 * 
 */
export enum ApplicationStates {
  "LoggedIn",
  "LoggedOut",
  "PreLogout",
  "PendingEmailVerification",
  "Error"
}

export interface LoginProvider {
  type: string;
  backendURL: string,
  name: string,
  color: string,
  showLoginButton: boolean
}