
    <button style="margin-bottom:12px" *ngIf="addressBookMode == 'blast'" nz-button nzType="primary" (click)="toggleSelectAll()">Toggle Select All</button>
    <span *ngIf="numberOfChecked > 0">  Selected {{ numberOfChecked }} items. </span>

    <div class="pad-15 searchBox">
        <nz-input-group [nzSuffix]="suffixTemplate">
          <input type="text" nz-input placeholder="Search Contacts..." #txtFilter [(ngModel)]="searchTerm"/>
        </nz-input-group>
        <ng-template #suffixTemplate
        ><i
          nz-icon
          nz-tooltip
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="searchTerm"
          (click)="clearSearch()"
        ></i
      ></ng-template>
      </div>

  <ng-container *ngIf="addressBookMode == 'list'">
    <ng-container *ngIf="isSearchActive && curPage.length < 1">
      <nz-alert nzType="info" nzMessage="No Matches"
        nzDescription="There are no contacts that match your search. Please try broadening your search." nzShowIcon>
      </nz-alert><br>
      <ng-container *ngIf="contactService.activeWatcher.hasMoreContacts">
        <button (click)="contactService.LoadContactsWithModal('all')" nz-button nzBlock nzType="default">Load more (this
          could take a while)</button><br><br>
      </ng-container>
      <button nz-button nzBlock nzType="primary" (click)="clearSearch();">Clear Search</button>
  
    </ng-container>
    <ng-container *ngIf="!isContactsLoading; else tplContactsLoading">
      <div class="outer-container">
  
        <div class="list-container">
          <ul *ngIf="addressBookMode =='list'" class="txb-list">
            <li *ngFor="let contact of curPage; trackBy: trackByDocumentId" (click)="selectContact(contact)"
              class="list-item list-item-single-select"
              [ngClass]="{'list-item-active': activeContact && contact.id == activeContact.id, 'disabled': contact.optedOut}">
              <div class="pull-right">
                <a nz-dropdown [nzDropdownMenu]="menu"> Action <i nz-icon nzType="down"></i> </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
  
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item *ngIf="hasSendMessageAction">
                      <a (click)="sendMessage(contact)">Send Message</a>
                    </li>
                    <li nz-menu-item *ngIf="this.authnz.currentSecurityProvider.canDeleteContacts()">
                      <a nz-popconfirm (nzOnConfirm)="deleteContact(contact)" [nzPopconfirmTitle]="deleteInfo">Delete</a>
                      <ng-template #deleteInfo>
                        <i nz-icon nzType="question-circle-o" style="color: red;"></i>&nbsp;&nbsp;&nbsp;
                        <span>Are you sure you want to delete this contact?</span>
                        <br><i><small>This will also delete all related conversations.</small></i>
                      </ng-template>
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </div>
              {{contact.full_name}}<br />
              <small>{{contact.phone_number}}</small>
            </li>
          </ul>
        </div>
  
  
        <div class="contact-pagination" *ngIf="!isSearchActive">
          <nz-pagination [nzPageIndex]="curPageNumber" [nzTotal]="this.baseContacts?.length" [nzPageSize]="perPage"
            (nzPageIndexChange)="getPage($event)" [nzSimple]="true"></nz-pagination>
        </div>
  
      </div>
  
    </ng-container>
  </ng-container>


 <ng-container  *ngIf="addressBookMode == 'table' || addressBookMode == 'blast'">
    <nz-table #listTable [nzSize]="small" [nzNoResult]="empty" class="txb-list">
        <thead>
          <tr>
            <th> 
              <label nz-checkbox (ngModelChange)="checkAll($event)" [(ngModel)]="allDataChecked" [nzIndeterminate]="isIndeterminate"></label>
            </th>
            <th>Contact Name</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of curPage">
              <tr *ngIf="!data.optedOut" class="list-item">
                    <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" (nzCheckedChange)="refreshStatus()"></td>
                    <td>{{data.full_name}}</td>
                    <td>{{data.phone_number}}</td>
                </tr>
                <tr *ngIf="data.optedOut && optedOut" class="optedOutListItem">
                      <td nzShowCheckbox nzDisabled></td>
                      <td>{{data.full_name}} <sup><small><i>*Opted Out</i></small></sup> </td>
                      <td>{{data.phone_number}}</td>
                </tr>
          </ng-container>
        </tbody>
      </nz-table>
      <div class="contact-pagination" *ngIf="!isSearchActive">
          <nz-pagination [nzPageIndex]="curPageNumber" [nzTotal]="this.baseContacts?.length" [nzPageSize]="perPage" (nzPageIndexChange)="getPage($event)" [nzSimple]="true"></nz-pagination>
        </div>
 </ng-container>

   
 

  <ng-container  *ngIf="addressBookMode == 'reminders'">
      <ng-container *ngIf="isSearchActive && curPage.length < 1">
          <nz-alert
            nzType="info"
            nzMessage="No Matches"
            nzDescription="There are no contacts that match your search. Please try broadening your search."
            nzShowIcon
          >
          </nz-alert><br>          
          <ng-container *ngIf="contactService.hasMoreContacts"> 
            <button (click)="contactService.LoadContactsWithModal('all')" nz-button nzBlock nzType="default">Load more (this could take a while)</button><br><br>
          </ng-container>
          <button nz-button nzBlock nzType="primary" (click)="clearSearch();">Clear Search</button>
        
        </ng-container>
        <ng-container *ngIf="!isContactsLoading; else tplContactsLoading">
          <div class="reminders">
            <ul *ngIf="addressBookMode =='reminders'" class="txb-list">
              <ng-container *ngFor="let contact of curPage; trackBy: trackByDocumentId">

                  <li *ngIf="!contact.optedOut" (click)="selectContact(contact)" class="list-item list-item-single-select" [ngClass]="{'list-item-active': activeContact && contact.id == activeContact.id, 'disabled': contact.optedOut}">
                      {{contact.full_name}}
                      <div class="pull-right">
                          <small>({{contact.phone_number}})</small>
                      </div>
                </li>
                <li *ngIf="contact.optedOut && optedOut" class="optedOutListItem" nzDisabled>
                    {{contact.full_name}} <sup><small><i>*Opted Out</i></small></sup> 
                    <div class="pull-right">
                        <small>({{contact.phone_number}})</small>
                    </div>
              </li>

              </ng-container>
            </ul>
            <div class="contact-pagination" *ngIf="!isSearchActive">
              <nz-pagination [nzPageIndex]="curPageNumber" [nzTotal]="this.baseContacts?.length" [nzPageSize]="perPage" (nzPageIndexChange)="getPage($event)" [nzSimple]="true"></nz-pagination>
            </div>
          </div>
        </ng-container>
        
  </ng-container>


  <ng-template #empty>
  </ng-template> 

  <ng-template #tplContactsLoading>
    <p class="text-center" style="padding:2rem;">{{contactsLoadingMessage}}</p>
  </ng-template>