import { Injectable } from "@angular/core";
import { AuthNZService } from "../core/authnz.service";
import { getLogger } from "@shared/logging";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { UserPreferencesService } from "./user-preferences.service";

const log = getLogger("MessageSignatureService");

export type MessageSignature = {
  type: "own" | "shared" | "local";
  source: string;
  text: string;
  icon?: string;
};

const SignatureTypeIcons = {
  own: "fa-user",
  shared: "fa-share-alt",
  local: "fa-laptop"
};

@Injectable({
  providedIn: "root",
})
export class MessageSignatureService {
  /**
   * Aggregate available signatures
   */
  public availableSignatures: BehaviorSubject<MessageSignature[]> = new BehaviorSubject([]);
  private localSignatureLoad: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private localSignatures: MessageSignature[] = [];

  constructor(
    private authnz: AuthNZService,
    private userPreferenceService: UserPreferencesService
    ) {
    this.loadLocalSignatures();
    this.createSubscription();
  }

  private loadLocalSignatures() {
    const prefsString = this.userPreferenceService.getLocalPreference("localSignatures"); 
    try {
      const prefs = JSON.parse(prefsString);
      if (Array.isArray(prefs)) {
        for(let p of prefs) {
          if (typeof p.type == "string" && typeof p.source == "string" && typeof p.text == "string") {
            this.localSignatures.push(p);
          }
        }
      }
    }
    catch (e) {
      this.userPreferenceService.setLocalPreference("localSignatures", JSON.stringify([]));
    }
    this.localSignatureLoad.next();
  }

  private saveLocalSignatures() {
    this.userPreferenceService.setLocalPreference("localSignatures", JSON.stringify(this.localSignatures));
  }

  private createSubscription() {
    combineLatest([
      this.localSignatureLoad.pipe(map(() => this.localSignatures)),
      this.authnz.contextChanged.pipe(
      map(({ activeNumberProfile, activeUserProfile }) => {
        let sigs = [
            (activeNumberProfile.firebase_document_id != activeUserProfile.firebase_document_id ? activeNumberProfile: null), 
            activeUserProfile
          ]
          .filter(x=>x)
          .map((profile) => {
            if(!profile.signatureEnabled || ! (profile.signatureText?.length>0)) {
              return null
            }
            return {
              source: profile.firebase_document_id,
              type:
                profile.firebase_document_id == activeUserProfile.firebase_document_id
                  ? "own"
                  : "shared",
              text: profile.signatureText,
            } as MessageSignature;
        });
        return sigs
      })
      )
    ]).pipe(
      map(([local, remote]) => {
        const c = local.concat(remote)
        return c.filter(x=>x).map(x=>({
          ...x,
          icon: SignatureTypeIcons[x.type]
        }))
      })
    ).subscribe(this.availableSignatures);
  }

  public addLocal(signature: any) {
    this.localSignatures.push({
      type: "local",
      source: "local",
      text: signature,
    });
    this.saveLocalSignatures();
    this.localSignatureLoad.next();
  }

  public removeLocal(signature: MessageSignature) {
    this.localSignatures = this.localSignatures.filter(
      (s) => s.text != signature.text
    );
    this.saveLocalSignatures();
    this.localSignatureLoad.next();
  }
}
