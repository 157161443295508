<ng-container *ngIf="invalidProvider; else validProvider">
  <div nz-row nzGutter="12">
    <div nz-col nzSpan="12">
      <nz-form-item>
        Invalid provider
      </nz-form-item>
    </div>
  </div>
</ng-container>

<ng-template #validProvider>
  <div nz-row nzGutter="12">
    <div nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired>Provider</nz-form-label>
        <!-- 
          NOTICE! The order of the attributes on this input tag MATTERS.  
          ngModelChange MUST occur after ngModel for the downstream event handlers to function properly
          https://github.com/angular/angular/issues/28107
        -->
        <nz-select 
        (ngModelChange)="changedProviderName($event)"
          [(ngModel)]="providerSettings.name"
          [nzDisabled]= "disabled"
          >
          <nz-option 
            *ngFor="let provider of providers" 
            nzValue="{{provider.id}}" 
            nzLabel="{{provider.label}}">
          </nz-option>
        </nz-select>
      </nz-form-item>
    </div>
  </div>

  <div nz-row nzGutter="12">
    <ng-container *ngIf="shouldAllowCredentialEntry() else noCredentialEntry">
      <div nz-col nzSpan="12" *ngFor="let field of credentialFields">
        <nz-form-item>
          <nz-form-label>{{field.label}}</nz-form-label>
          <nz-form-control nzHasFeedback>
            <nz-input-group [nzSuffix]="tplToggleCreds">
              <!-- 
                NOTICE! The order of the attributes on this input tag MATTERS.  
                ngModelChange MUST occur after ngModel for the downstream event handlers to function properly
                https://github.com/angular/angular/issues/28107
              -->
              <input 
                nz-input 
                [(ngModel)]="providerSettings.credentials[field.id]" 
                (ngModelChange)="inputChanged($event)" 
                placeholder="" 
                [type]="showCredentials ? 'text' : 'password'"
                [disabled]= "disabled"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #tplToggleCreds>
  <i nz-icon [nzType]="showCredentials ? 'eye-invisible' : 'eye'" (click)="showCredentials = !showCredentials"></i>
</ng-template>

<ng-template #noCredentialEntry>
  <div nz-row>
    <div nz-col nzSpan="24" >
      <span style="margin-left: 1em;">Credentials for this provider are managed by your administrator</span>
    </div>
  </div>
</ng-template>




