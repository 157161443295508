<div class="tool-header">
{{tool.name}}
<small>{{tool.description}}</small>
</div>

<div class="tool-parameters">
  <span>Parameters</span><br/>
  <form nz-form nzLayout="vertical" [formGroup]="toolParamsForm">
    <div *ngFor="let param of tool.parameters">
      <nz-form-item>
        <nz-form-label 
          [nzRequired]="param.required"
          >{{param.name}}</nz-form-label>
        <nz-form-control 
          nzHasFeedback
          [nzExtra]="param.description + ' (' + param.type + ')'"
          >
          <input *ngIf="param.type!=='user'" nz-input type="text" [formControlName]="param.name"/>
          <app-user-multi-selector *ngIf="param.type=='user'" [formControlName]="param.name"></app-user-multi-selector>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
  <button 
    nz-button 
    nzType="primary" 
    style="width:50%;" 
    nz-popconfirm nzPopconfirmTitle="Are you sure you want run this tool?" 
    (nzOnConfirm)="runTool()"
    >Run Tool</button>
</div>

<div class="tool-result">
  <span>Results</span><br/>
  <ng-container class="tool-result" *ngIf="toolResponse.state=='idle'">
    Results will be displayed after tool execution.
  </ng-container >
  <ng-container class="tool-result" *ngIf="toolResponse.state=='loading'">
    Loading... <nz-spin nzSimple></nz-spin>
  </ng-container >
  <ng-container *ngIf="toolResponse.state=='resolved'">
    <span>Result: {{toolResponse.response.resultStatus}}</span><br/>
    <span>Records Affected: {{toolResponse.response.resultRecordsAffected}}</span><br/>
    <pre style="height: 100%;">
      {{toolResponse.response.resultText}}
    </pre>
  </ng-container>
</div>


