import { VisibilityService } from "../app/services/visibility-service.service";
import {
  CdkVirtualScrollViewport,
  CdkVirtualForOfContext,
} from "@angular/cdk/scrolling";
import { ListRange } from "@angular/cdk/collections";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
} from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
} from "rxjs/operators";
import { getLogger } from "@shared/logging";

const log = getLogger("ScrollableContentRegion");
log.setLevel("INFO");

/**
 * Watches for changes in a CdkVirtualScrollViewport as well as in the
 * underlying data source
 *
 * Provides mechanisms for:
 * *  auto-scrolling to the bottom
 * *  showing when new items have arrived when not at the bottom
 * *  manually scrolling to the bottom
 *
 */
export class ScrollableContentRegion {
  // #region Properties (13)

  /**
   * The CDK Virtual Scroll Viewport under observation
   */
  private cdkViewport: CdkVirtualScrollViewport;
  /**
   * Set to true when the data source changes;
   * Changes to false after the data source first emits
   */
  private dataSourceFirstLoad = false;
  /**
   * The underlying data source
   */
  private datasource: Observable<any[]>;
  /**
   * Subscription to the datasource
   * closed if necessary before setting a new data source
   * opened when the data source is set
   */
  private datasourceSubscription: Subscription;
  /**
   * Reference to the last HTMLElement of the viewport
   */
  private lastItemElementRef: Element;
  private lastItemVisibilityDetailed = new BehaviorSubject<{
    visible: boolean;
    cause: string;
    index: number;
  }>({ visible: false, cause: "initial", index: 0 });
  /**
   * Subscription to the lat item visibility
   * closed when the last item is removed from the DOM and when the data source changes
   * opened when the last item is added to the DOM
   */
  private lastItemVisibilitySubscription: Subscription;
  /**
   * CDK's ScrollToIndex sometimes "cuts off" the target element
   * if the jump was large.
   *
   * If we're attempting to scroll to an index (like in scrollToBottom),
   * we'll `next` this observable when `ngAfterViewChecked` runs
   * so that we can re-run the `scrollToIndex` command to get a more
   * accurate scroll
   */
  private cdkDOMChanged = new Subject<void>();
  private stopDomWatcher: ()=>void = null;
  /**
   * Emits whenever the rendered range of the viewport changes.
   */
  private renderedRangeChanged = new Subject<ListRange>();
  /**
   * Subscription to the datasource
   * closed if necessary before setting a new data source
   * opened when the data source is set
   */
  private renderedRangeSubscription: Subscription;

  /**
   * Emits whenever new items are available in the datasource when
   * the rendered range does not include the new items.
   */
  public hasNewItems = new BehaviorSubject<boolean>(false);
  /**
   * Observable that emits whenever the visibility of the last item changes
   *
   * False when the last item is either just out of the viewport but still
   * rendered to the DOM, or when the DOM rendering does not include the last
   * element
   *
   * True when the last item is visible somewhere in the viewport.
   *
   */
  public lastItemVisibility = new BehaviorSubject<boolean>(false);

  // #endregion Properties (13)

  // #region Constructors (1)

  constructor(private visibilityService: VisibilityService) {
    log.debug("Creating");
    this.lastItemVisibilityDetailed
      .pipe(
        tap((x) =>
          log.info(`last item visibility changed ${JSON.stringify(x)}`)
        ),
        map((x) => x.visible),
        distinctUntilChanged()
      )
      .subscribe(this.lastItemVisibility);
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public destroy() {
    log.info("Destroying observables");
    this.stopWatchingDatasource();
    this.stopWatchingRenderedRange();
    this.datasource = null;
    this.cdkViewport = null;
  }

  /**
   * Adjusts the viewport scroll to include the last item
   *
   * Runs async because a second scroll is sometimes necessary to fine-tune
   * the scrolled position to avoid cutting off the bottom edge of the last
   * item
   *
   *
   * @param runAgain
   */
  public async scrollToBottom(runAgain: Boolean = true) {
    this.validate();
    const DL = this.getViewportLastItemIndex();
    log.info(`Scrolling to last index ${DL}; runAgain: ${runAgain}`);
    //this.cdkViewport.scrollToIndex(DL);
    this.cdkViewport.scrollTo({bottom: 0})
    this.hasNewItems.next(false);
    if (runAgain) {
      /**
       * Sometimes `CdkVirtualScrollViewport.scrollToIndex` puts the viewport a few pixels above
       * the bottom of the last element, so the last element is a little cut off.
       *
       * Waiting a little bit, and then re-scrolling seems to mitigate this cutoff.
       *
       * It's a little janky, but works
       *
       * TODO: When we upgrade to RxJS 7 convert this block to `await firstValueFrom...`
       */
      log.debug("Waiting for view to update to fine-tune scroll");
      await this.cdkDOMChanged.pipe(debounceTime(200)).nThEmitAsPromise(1);
      log.debug("Finished waiting for view to update to fine-tune scroll");
      await this.scrollToBottom(false);
    }
  }

  /**
   * Tells this class to watch a new `<cdk-virtual-scroll-viewport/>` element
   * @param region
   */
  public setCDKViewport(region: CdkVirtualScrollViewport) {
    this.cdkViewport = region;
    log.debug(
      "CdkVirtualScrollViewport changed, setting up watcher for the rendered range"
    );
    this.stopWatchingRenderedRange();
    this.renderedRangeSubscription =
      this.cdkViewport.renderedRangeStream.subscribe((e) => {
        this.evaluateWatcherForListRange(e);
        this.renderedRangeChanged.next();
      });

    const domWatcher = this.visibilityService
      .watchForDOMChanges(this.cdkViewport.getElementRef().nativeElement)
    const domWatchersub = domWatcher.subscribe(()=>{
      this.cdkDOMChanged.next()
    })
    this.stopDomWatcher = ()=>{
      log.debug("stopping domwatcher");
      domWatchersub.unsubscribe();
      domWatcher.complete();
    }

  }

  /**
   * Tells this class to watch a new observable for items in the specified region
   * @param datasource
   * @returns
   */
  public async setDataSource(datasource: Observable<any[]>) {
    if (this.datasource === datasource) {
      // The new datasource is the same as the old one; no action necessary
      return;
    }
    this.dataSourceFirstLoad = true;
    this.stopWatchingDatasource();
    this.datasource = datasource;
    log.debug("Creating new datasource observable");
    this.datasourceSubscription = datasource.subscribe(async (data) => {
      this.evaluateDataSourceChange(data);
    });
  }

  // #endregion Public Methods (4)

  // #region Private Methods (9)

  private async evaluateDataSourceChange(data: any[]) {
    if (!Array.isArray(data) || data.length == 0) {
      log.info(`Datasource emitted (${data.length} items); No data to render`);
      this.hasNewItems.next(false);
      return;
    }

    if (this.dataSourceFirstLoad) {
      log.info(
        `Datasource emitted (${data.length} items); first load, so scrolling to bottom`
      );
      await this.cdkDOMChanged.nThEmitAsPromise(1);
      this.scrollToBottom();
      this.dataSourceFirstLoad = false;
      return;
    } else if (this.lastItemVisibility.value) {
      log.info(
        `Datasource emitted (${data.length} items); currently at bottom, so scrolling to new bottom`
      );
      // The last item is visible, and the data source emitted; go to the bottom
      this.scrollToBottom();
    } else {
      // the last item isn't visible, so don't auto-scroll
      log.info(
        `Datasource emitted (${data.length} items); not currently at bottom, so staying at current location`
      );
      this.hasNewItems.next(true);
    }
  }

  /**
   * Evaluates whether the new rendered range
   * necessates a change to the last item visibility watcher
   *
   *
   * @param newRenderedRange
   * @returns
   */
  private async evaluateWatcherForListRange(newRenderedRange: ListRange) {
    if (newRenderedRange.end < this.getViewportLastItemIndex()) {
      // The end of the range doesn't have a last item.
      log.debug(
        `Rendered range (${newRenderedRange.start}-${
          newRenderedRange.end
        }) doesn't include last item ${this.getViewportLastItemIndex()}`
      );
      this.lastItemElementRef = null;
      this.lastItemVisibilityDetailed.next({
        visible: false,
        cause: "not in rendered range",
        index: this.getViewportLastItemIndex(),
      });
      if (
        this.lastItemVisibilitySubscription &&
        !this.lastItemVisibilitySubscription.closed
      ) {
        this.stopWatchingLastItemVisibility();
      }
      return;
    }
    await this.cdkDOMChanged.nThEmitAsPromise(1);
    const lastElement = this.findLastViewportElement();
    if (!lastElement) {
      log.warn(
        `Rendered range (${newRenderedRange.start}-${
          newRenderedRange.end
        }) should include the last item ${this.getViewportLastItemIndex()}, but could not obtain reference`
      );
      this.lastItemVisibilityDetailed.next({
        visible: false,
        cause: "couldn't obtain element",
        index: this.getViewportLastItemIndex(),
      });
      this.stopWatchingLastItemVisibility();
      return;
    }

    if (
      lastElement === this.lastItemElementRef &&
      !this.lastItemVisibilitySubscription.closed
    ) {
      log.debug(
        `Rendered range (${newRenderedRange.start}-${
          newRenderedRange.end
        }) includes the last item ${this.getViewportLastItemIndex()}, but Last item is already being watched; not creating new watcher`,
        this.lastItemElementRef
      );
      return;
    }

    log.debug(
      `Rendered range (${newRenderedRange.start}-${
        newRenderedRange.end
      }) includes the last item ${this.getViewportLastItemIndex()}, setting up new visibility watcher`,
      lastElement
    );

    this.watchLastItemVisibility(lastElement);
  }

  /**
   * Finds the Element corresponding with the last item in the data source
   * @param startingPoint
   * @returns
   */
  private findLastViewportElement(startingPoint?: Element): Element {
    if (!startingPoint) {
      return this.findLastViewportElement(
        this.cdkViewport
          .getElementRef()
          .nativeElement.querySelector(".cdk-virtual-scroll-content-wrapper")
      );
    }

    /**
     * Given an Element, determines whether the __ngContext__ supports 
     * the element having been rendered inside *cdkVirtualFor.
     * 
     * If so, returns the *cdkVirtualFor context;
     * 
     * @param n 
     * @returns 
     */
    const getNodeCdkVirtualForOfContext = (
      n: (Element | ChildNode) & { __ngContext__?: Array<Object> }
    ): CdkVirtualForOfContext<any> => {
      if (
        !n.hasOwnProperty("__ngContext__") ||
        !Array.isArray(n.__ngContext__)
      ) {
        return null;
      }
      const context = n.__ngContext__.find(
        (x) => x && typeof x == "object" && x.hasOwnProperty("cdkVirtualForOf")
      );
      return context as unknown as CdkVirtualForOfContext<any>;
    };

    let lastElement: Element = null;

    startingPoint.childNodes.forEach((v) => {
      const vContext = getNodeCdkVirtualForOfContext(v);
      if (vContext && vContext.index == this.getViewportLastItemIndex()) {
        log.debug(
          `Found last element; index ${
            vContext.index
          } same as ${this.getViewportLastItemIndex()}`, v
        );
        lastElement = v as Element;
      }
    });

    if (!lastElement) {
      if (!startingPoint.firstElementChild) {
        return null;
      }
      return this.findLastViewportElement(startingPoint.firstElementChild);
    }

    return lastElement;
  }

  private getViewportLastItemIndex() {
    return this.cdkViewport.getDataLength() - 1;
  }

  private stopWatchingDatasource() {
    this.stopWatchingLastItemVisibility();
    if (this.datasourceSubscription) {
      this.datasourceSubscription.unsubscribe();
    }
  }

  private stopWatchingLastItemVisibility() {
    if (this.lastItemVisibilitySubscription) {
      this.lastItemVisibilitySubscription.unsubscribe();
    }
  }

  private stopWatchingRenderedRange() {
    this.stopWatchingLastItemVisibility();
    if (this.renderedRangeSubscription) {
      this.renderedRangeSubscription.unsubscribe();
    }
    if(typeof this.stopDomWatcher == "function") {
      this.stopDomWatcher();
    }
  }

  private validate() {
    if (!this.datasource) {
      throw new Error("No datasource set");
    }
    if (!this.cdkViewport) {
      throw new Error("No region set");
    }
  }

  /**
   * Continuously watches the visibility of the last item of the
   * CDK Content area
   *
   * When the content within the content area changes, this adjusts
   * and watches the visibility of the _new_ last item.
   *
   */
  private watchLastItemVisibility(lastElement: Element) {
    this.stopWatchingLastItemVisibility();
    this.lastItemElementRef = lastElement;
    this.lastItemVisibilitySubscription = this.visibilityService
      .elementInViewport(this.lastItemElementRef)
      .pipe()
      .subscribe((x) => {
        if (x) {
          this.hasNewItems.next(false);
        }
        this.lastItemVisibilityDetailed.next({
          visible: x,
          cause: "intersection observer",
          index: this.getViewportLastItemIndex(),
        });
      });
  }

  // #endregion Private Methods (9)
}
