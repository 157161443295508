import { filter, first } from "rxjs/operators";
import { log, BaseCoordinatedMessageChannel, Statuses, CoordinatedControlMessage, DraftCoordinatedControlMessage, DraftCoordinatedDataMessage, CoordinatedDataMessage } from "./BaseCoordinatedMessageChannel";

export class DOMCoordinatedMessageChannel extends BaseCoordinatedMessageChannel {
  /**
   * Use a MessageChannel instead of postMessage and onMessage since 
   * MessageChannels mitigate the need to check the origin of each 
   * message and _is more secure_?
   */
  public mc: MessageChannel;


  public postMessage(message: any): void {
    throw new Error("Method not implemented.");
  }
  constructor(
    private context: ServiceWorkerContainer
    ) {
    super();
    if (typeof context == "undefined" || context == null) {
      throw new Error("Context must not be null")
    }
    this.mc = new MessageChannel();
    this.mc.port1.onmessage = this.handlePortMessage.bind(this);
    this.context.onmessage = this.handleContextMessage.bind(this);
     this.controlMessages.subscribe(controlMessage => {
      if (controlMessage.command == "messageChannelInitRequest") { 
        /**
         * It seems we need to re-create the whole message channel 
         * when the service worker comes back from a stopped state
         */
        this.mc = new MessageChannel(); 
        this.mc.port1.onmessage = this.handlePortMessage.bind(this);
        log.debug("Sending MessagePorts (port2) to service worker");
        this.sendControlMessage({
          command: "messageChannelInit"
          },
          [this.mc.port2]
        )
      }
    })
    this.sendMessagePorts();

  }
  
  private async sendMessagePorts() {
    this.status.next("Preparing");
    await this.context.ready
    await navigator.serviceWorker.ready
    await this.waitForPing()
    log.debug("Sending MessagePorts (port2) to service worker");
    this.sendControlMessage({
      command: "messageChannelInit"
      },
      [this.mc.port2]
    )
    await this.dataMessages.pipe(
      filter(m=>m.command == "messageChannelInitSuccess"),
      first()).toPromise()
    this.status.next("Ready")
  }

  /**
 * Pings the service worker using context postMessage.
 * 
 * Resolves if "ack" is received in less than RX_TIMEOUT
 * Otherwise rehjects
 */ 
  protected async ping() {
    this.sendControlMessage({
      command: "ping"
    })
    await this.waitForAck();
   
  }

  protected async waitForAck(timeout = 300) {
    await new Promise<void>((resolve,reject)=>{
      const to = setTimeout(()=>{
        reject("No ping received in " + timeout + "ms")
      }, timeout)
      this.controlMessages.subscribe(ev=> {
        if (ev.command == "ack") {
          clearTimeout(to);
          resolve();
        }
      })
    })
  }


  /**
  * Ensures that the ServiceWorkerContainer is ready
  * and that the controlling service worker responds
  * to "ping" messages
  * 
  * Resolves only when both have ocurred or the max_retries has been exceeded.
  * 
  * @param swc 
  */
  private async waitForPing(max_retries = 3, tx_retry = 200) {
    let attempts = 0;
    log.debug("Waiting for ServiceWorker ping")
    await new Promise<void>(async (resolve,reject) => {
      const interval = setInterval(async ()=>{
        attempts++;
        try {
          await this.ping()
          clearInterval(interval);
          log.debug("Service Worker ping response received.")
          resolve();
        }
        catch (err) {
          log.debug("ServiceWorker not ready yet; waiting. " + err)
        } 
        if (attempts > max_retries) {
          clearInterval(interval);
          reject("Failed to ping service worker after " + max_retries + " attempts")
        }
      }, tx_retry)
    });
  
  }

  public sendDataMessage(message: DraftCoordinatedDataMessage) {
    log.debug("Sending CoordinatedDataMessage", message);
    this.mc.port1.postMessage({
      type: "CoordinatedDataMessage",
      data: message
    } as CoordinatedDataMessage 
    );

  }

  protected sendControlMessage(message: DraftCoordinatedControlMessage, transfer?: Transferable[]) {
    log.debug("Sending CoordinatedControlMessage", message);
    this.context.controller.postMessage(
      {
        type: "CoordinatedControlMessage",
        ...message
      } as CoordinatedControlMessage,
      transfer 
    )
  }

}