import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PhoneNumber, PhoneNumberParseError } from '@shared/PhoneNumber';
import { TextableContact } from 'src/app/backported_types/contact';
import { getLogger } from 'loglevel';
import { ParseError } from 'papaparse';
import { animationFrameScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { OperationStatusUpdate } from 'src/app/background-operation-status.service';
import { AuthNZService } from 'src/app/core/authnz.service';
import { arrayOfAll } from 'src/app/interfaces';
import { ContactImportAction, ContactsService, ContactWithoutId } from 'src/app/services/contacts.service';
import { HashJoiner } from 'src/classes/hash-joiner';
import { ColumnDataFormatHint, CSVParser, ImportColumnMapping } from 'src/classes/import-helper';
import levenshtein from "fast-levenshtein";
import { vars } from 'src/app/app.constants';
import { ReminderImportAction, ReminderService } from 'src/app/services/reminder.service';
import { TextableMessageDirection, TextableReminder, TextableSendStatus } from 'src/app/backported_types/messaging';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import moment from 'moment';
import { TimezoneData, TimezoneService } from 'src/app/services/timezone.service';
import { TextableDate } from 'src/classes/TextableDate';
const log = getLogger("ReminderImport")


/**
 * When reviewing an import, show this many rows from the import source
 */
 const IMPORT_PREVIEW_COUNT = 10

   interface ImportableReminder {
    phone_number: string;
    full_name?:string;
    email?:string;
    body: string;
    scheduleDateTime:string;
    scheduleTimezone:string
  }
 
 /**
  * A string array of all fields which are eligible for import.
  */
 const reminderImportFieldHeaders = arrayOfAll<keyof ImportableReminder>()(
   "phone_number",
   "email",
   "full_name",
   "body",
   "scheduleDateTime",
   "scheduleTimezone"
 )
 
 export type ReminderImportProblem = {
   description: string
   blocksImport: boolean
   resolution: string
   context?: any
 }
 
 /**
  *
  */
 const columnTests: Record<string, ColumnDataFormatHint> = { }

class DateParseError extends Error {
  constructor(message: string, private context: string) {
    super(message);
  }
  public getDescription(): string {
    return `${this.message}: '${this.context}'`
  }
}


@Component({
  selector: 'app-reminder-import',
  templateUrl: './reminder-import.component.html',
  styleUrls: ['./reminder-import.component.scss']
})
export class ReminderImportComponent implements OnInit {

  // #region Properties (19)
  private joins: HashJoiner<ImportableReminder,TextableContact>;
  private parser: CSVParser<ImportableReminder>;

  /**
   * Fires when imports have completed
   * Should close the import drawer
   */
  @Output() public ReminderImportQueued = new EventEmitter();
  public ContactsToCreate: ContactWithoutId[]
  public RemindersToStage: TextableReminder[] = []
  public RemindersToCreate: TextableReminder[] = []

  public busyParsing: boolean

  public currentStep: number = 0;
  public frmImportReminders: FormGroup;

  public importAlerts: ReminderImportProblem[]
  public blockingImportAlerts: number

  public pastedImportData: string = "";
  public importErrors: ParseError[]
  public importPreviewData: ImportableReminder[];
  public importedData: ImportableReminder[];
  public importColumnMapping: Partial<ImportColumnMapping<ImportableReminder>>;
  /**
   * Use the same interface as the BackgroundOperationService but in the context of the import dialog (without a modal)
   */
  public parseStatus: OperationStatusUpdate = {
    operation: "Parse CSV",
    statusText: "Waiting for data"
  }

  // #endregion Properties (19)

  // #region Constructors (1)

  constructor(
    private authnz: AuthNZService,
    private contactService: ContactsService,
    private reminderService: ReminderService,
    private afs: AngularFirestore,
    private timezoneService: TimezoneService

  ) { 
    this.parser = new CSVParser<ImportableReminder>(reminderImportFieldHeaders,columnTests)
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public async finishImport() {
    const contactImportActions = [
      ...this.ContactsToCreate.map<ContactImportAction>(cc=>({
        action: "create",
        data: cc
      }))
    ]

    if(this.ContactsToCreate.length > 0){
      await this.contactService.uploadImport(contactImportActions)
    }

    for(let reminderStage of this.RemindersToStage){
      const contact = await this.afs.collection('contacts', ref => 
      ref.where('uid', '==', this.authnz.activeNumberUID)
      .where('phone_number', '==', reminderStage.to)
    ).get().toPromise();
  
      let c = contact.docs[0];
      let contactData = c.data() as any;
      if(reminderStage.Recipient.length === 0 || reminderStage.Recipient === reminderStage.to){
        reminderStage.Recipient = contactData.full_name;
      }
      this.RemindersToCreate.push({...reminderStage, contact_id: c.id})
    }

    const ReminderImportActions  = [
      ...this.RemindersToCreate.map<ReminderImportAction>(reminder=>({
        action: "create",
        data: reminder
      }))
    ]
    await this.reminderService.uploadImport(ReminderImportActions)


    this.ReminderImportQueued.emit();

  }

  public async importCSV(files: FileList | File, hasHeader: boolean) {
    this.RemindersToStage = [];
    let file: File;
    if (typeof files == "object" && "length" in files) {
      if (files.length == 0) {
        log.debug("No files to process")
        return
      }
      file= files[0]
    }
    else if (typeof files == "object" && "name" in files){
       file = files
    }
    else {
      log.debug("No file to process")
      return
    }

    this.busyParsing = true
    this.parseStatus = {
      operation: "Parse CSV", 
      statusText: "Beginning parse",
      percentComplete: 0
    }
    const importResult = this.parser.ParseCSV(file, hasHeader)
    
    importResult.pipe(
      observeOn(animationFrameScheduler)
    ).subscribe(status=> {
      this.parseStatus ={
        operation: "Parse CSV",
        statusText: (
          status.percentComplete == 100 ? 
          "Complete; parsed " + status.rowsParsed + " Reminders" : 
          "Parsed " + status.rowsParsed + " Reminders"
        ),
        percentComplete: status.percentComplete
      }
    })
    const [r] = await Promise.all([
      importResult.toPromise()
    ]);
   
    this.importedData = r.result.data;
    this.importErrors = r.result.errors;
    this.importColumnMapping = r.result.columnMapping;

    this.joins = new HashJoiner<ImportableReminder,TextableContact>(
      (v)=>{
        try {
          return new PhoneNumber(v.phone_number).ToE164()
        }
        catch (err) {
          return v.phone_number
        }
      },
      this.importedData,
      this.contactService.activeWatcher.GetContactMapKeyedByPhoneNumber()
    )
    log.debug(`Parse Result length: ${this.importedData.length}`);
    this.importAlerts = [];

    this.cleanupupReminders();
    this.populatePreviewData();
    this.scanForAlerts(); 
    this.buildContactsToCreate();
    this.buildRemindersToCreate();
    this.blockingImportAlerts = this.importAlerts.filter(ia=>ia.blocksImport).length
    this.busyParsing = false;
  }


  public ngOnInit(): void {        
    this.frmImportReminders = new FormGroup({
      "importFile": new FormControl(null),
      "pastedImportData": new FormControl(null),
    })

    this.frmImportReminders.get("pastedImportData").valueChanges.subscribe( (pasteData: string)=>{
      const f = new File([pasteData.trim()],"pasted.csv")
      this.importCSV(f, true);
    })
  }

  // #endregion Public Methods (4)

  // #region Private Methods (3)
  /**
   * Check Reminder for a valid email.
   */
  private cleanupEmail(importedReminder:ImportableReminder) {
    try {
      if (importedReminder.email) {
        if (importedReminder.email.includes("@")) {
          return;
        } else {
          throw new Error()
        }
      }
    }
    catch (e) {
      this.importAlerts.push({
        description: "Invalid email format",
        blocksImport: false,
        resolution: "Ensure that the email is formatted correctly.",
        context: importedReminder.email
      })
      return;
    }
  }

  /**
   * Check Reminder for a usable timezone and valid timestamp.
   */
  private cleanupDateTimezone(importedReminder:ImportableReminder) {

    try {
      const timestamp = Date.parse(importedReminder.scheduleDateTime)
      //ISO DATE
      if(isNaN(timestamp) == false){
        //Future Check
        if(TextableDate.now() >= timestamp){
          throw new DateParseError('Date is in the past', `${timestamp}`)
        }

        //Timezone check if included
        if(importedReminder.scheduleTimezone.length > 0){
          const validTZ = vars.timezones.some(tz => (tz.alias == importedReminder.scheduleTimezone))
          if (!validTZ) {
            throw new DateParseError('Invalid Timezone', `${importedReminder.scheduleTimezone}`)
          }
        }else{
          log.info(`Checking timezone ${importedReminder.scheduleDateTime}`)
          const tz = this.checkISOValidTimezone(importedReminder.scheduleDateTime)
          if(!tz)     {
            throw new DateParseError('Invalid Timezone', `${importedReminder.scheduleTimezone}`)
          }else{
            this.importedData.find(data => data == importedReminder).scheduleTimezone = tz.alias;
          }
        }
      //MS EPOCH 
      }else{
        //Future Check
        if(TextableDate.now() >= Number(importedReminder.scheduleDateTime)){
          throw new DateParseError('Date is in the past', `${importedReminder.scheduleDateTime}`)
        }
        //Required TZ
        const validTZ = vars.timezones.some(tz => (tz.alias == importedReminder.scheduleTimezone))
        if (!validTZ) {
          throw new DateParseError('Invalid Timezone', `${importedReminder.scheduleTimezone}`)

        }
      }


    }
    catch (e) {
      let description = 'Invalid Date Format.'
      if (e instanceof DateParseError) {
        description = e.message;
      }

      this.importAlerts.push({
        description: description,
        blocksImport: true,
        resolution: "Must be a future ISO 8601 or MS Epoch times. Available timezones are (Pacific, Mountain, Central, Eastern).",
        context: `${importedReminder.scheduleDateTime} (${importedReminder.scheduleTimezone}) `
      })

      return;
    }
  }
  /**
   * Ensure the phone numbers conforms to E.164
   */
  private cleanupPhoneNumber(importedReminder:ImportableReminder) {
    let number: PhoneNumber
    try{
      number = new PhoneNumber(importedReminder.phone_number)
      importedReminder.phone_number = number.ToE164();
      if (number.GetE161ConversionStatus()){
        this.importAlerts.push({
          description: `E.161 conversion was applied to the phone number '${number.GetRawNumber()}'`,
          blocksImport: false,
          resolution: "Confirm that the conversion was intended",
          context: importedReminder.phone_number
        })
      }


    }
    catch(err){ 
      if (err instanceof PhoneNumberParseError) {
        this.importAlerts.push({
          description: err.message,
          blocksImport: true,
          resolution:  err.resolution,
          context: importedReminder.phone_number
        })
      }
      else {
        this.importAlerts.push({
          description: "Unable to parse phone number",
          blocksImport: true,
          resolution:  "Ensure that the phone number can be formatted as +1xxxyyyzzzz",
          context: importedReminder.phone_number
        })
       }
      return;
    }
  }
  /**
   * iterate the reminders and ensure validity
   */
  private cleanupupReminders() {
    this.importedData.forEach(importedReminder=>{
      this.cleanupDateTimezone(importedReminder)
      this.cleanupEmail(importedReminder)
      this.cleanupPhoneNumber(importedReminder)
    });
  }

  private populatePreviewData() {
    const previewImportCount = Math.min(this.importedData.length,IMPORT_PREVIEW_COUNT)
    this.importPreviewData = [
      ...this.importedData.slice(0,previewImportCount-1),
      this.importedData[this.importedData.length-1]
    ]
    log.debug("Import preview data", this.importPreviewData)
  }

  /**
   * Iterates over rows to be imported looking for duplicates and bad data
   * Initializes the `this.importAlerts` array
   */
  private async scanForAlerts(){
    const hasColumnTitleInValue = (c: ImportableReminder): string[] =>{
      return Object.values(c)
        .filter((lk: any): lk is string => typeof lk === "string")
        .filter(lk=> typeof lk == "string" && reminderImportFieldHeaders.filter(bk=>levenshtein.get(lk,bk) < 3).length >0 )
    }

    const newAlerts = [
      ...this.importedData.flatMap(value=>{
        if (hasColumnTitleInValue(value).length == Object.keys(this.importColumnMapping).length) {
          return ({
            description: "Field title is present in the field value",
            resolution: "Ensure that the CSV header row is correct and that the proper header row import option is selected",
            blocksImport: true,
            context: value.phone_number
          })
        }
      }).filter(n=>n)
    ]

    this.importAlerts = [...this.importAlerts, ...newAlerts].sort((a,b)=>a.blocksImport ? -1 : 1)
  }

  private buildContactsToCreate() {  
    this.ContactsToCreate = Object.entries(this.joins.NewItemsNotInCurrent).flatMap(([tn,importedContact])=>{
      if(importedContact.full_name.length === 0){
        importedContact.full_name = importedContact.phone_number
      }
      const c: any = {
        ...importedContact,
        uid: this.authnz.activeNumberUID,
        createdBy: 'reminder-import',
        createdAt: TextableDate.now(),
        isArchived: true,
        read_status: true
      }
      return c;
    })

    log.debug(`Contacts to create ${this.ContactsToCreate.length}`)
  }

  /**
   * Builds a staging set of reminder data without id
   */
  private buildRemindersToCreate(){
    this.importedData.forEach(data => {
      const reminderWithoutContactId = {
        Recipient: data.full_name,
        attachments:[],
        body: data.body,
        contact_id:null,
        date: this.convertToMSEpoch(data.scheduleDateTime),
        direction:TextableMessageDirection.OUT,
        from:this.authnz.activeNumberProfile.phone_number,
        reminder:true,
        scheduleTimezone: this.findTimezoneNameByAlias(data.scheduleTimezone),
        selectedCannedResponse:null,
        send_status:"scheduled" as TextableSendStatus,
        sentBy: this.authnz.currentFireauthUser.uid,
        sentByLabel: this.authnz.currentUserDocument.full_name,
        to: data.phone_number,
        uid:this.authnz.activeNumberUID
      }
      this.RemindersToStage.push(reminderWithoutContactId)
    })
  }

  /**
   * @returns The name of the timezone from alias
   */
  private findTimezoneNameByAlias(alias:string):string{
    return vars.timezones.find(zone => zone.alias === alias)?.name
  }

  /**
   * Converts an ISO format datetime to MS for reminders.
   * @param datetime 
   */
  private convertToMSEpoch(datetime:string):number{
    const timestamp = Date.parse(datetime);
    if(isNaN(timestamp) == false){
      return timestamp;
    }else{
      return Number(datetime);
    }
  }

  /**
   * Checks the ISO date if there is a valid timezone.
   * @returns Timezone object from app.constants
   */
     private checkISOValidTimezone(isodate):TimezoneData{
      const isoArray:string[] = isodate.split("-");
      const offset = isoArray.pop();
      const isDST = new TextableDate(isodate).isDST()
      const validTZ = this.timezoneService.timezones.find(tz => isDST ? tz.dstOffsetStr.includes(offset) : tz.utcOffsetStr.includes(offset));
      return validTZ;
    }
  // #endregion Private Methods (3)
}
