<nz-card [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <nz-table
    #ssoProviderTable
    [nzData]="displayListSSOProviders"
    [nzLoading]="isLoadingSSOProviders"
    class="txb-list"
  >
    <thead>
      <tr>
        <th>Type</th>
        <th>Name</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of ssoProviderTable.data">
        <td>{{ data.type }}</td>
        <td>{{ data.name }}</td>
        <td>Action</td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>

<ng-template #pageTitleTemplate>
  Manage SSO
  <i
    class="far fa-question-circle guideButton"
    (click)="guideVisibleBool = !guideVisibleBool"
  ></i>
</ng-template>
