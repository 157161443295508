import { Injectable } from "@angular/core";
import {
  ApplicationStates,
  UISecurityProvider,
} from "../core/auth-nz/interfaces";
import { AuthNZService } from "../core/authnz.service";
import {
  BehaviorSubject,
} from "rxjs";
import { environment } from "../../environments/environment";

interface NavItemObject {
  id: string;
  title: string;
  icon: string;
  route?: string;
  children?: NavItemObject[];
  parent?: string;
  badge?: {
    text: string;
    color: string;
  };
  accessFunction: (
    security: UISecurityProvider,
    authnz?: AuthNZService
  ) => boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  navigation$: BehaviorSubject<NavItemObject[]> = new BehaviorSubject(null);

  constructor(private authnz: AuthNZService) {}

  /**
   * Publishes NavItemObject[] events to the navigation$ observable whenever called.
   * This is called anytime the currently logged-in user's profile changes.
   *
   */
  async refreshNavigation(): Promise<void> {
    let nav: NavItemObject[] = [];

    // If nobody's logged in, we publish an empty NavItemObject array and do no additional processing.
    if (
      this.authnz.currentFireauthUser == null ||
      this.authnz.authState !== ApplicationStates.LoggedIn
    ) {
      this.navigation$.next(nav);
      return;
    }

    //this filters out every "route" based on several different types of permission checks, into an array that we can use to build a NavItemObject[] for the UI to display.
    let navList = NavigationList.filter((ni) =>
      ni.accessFunction(this.authnz.currentSecurityProvider, this.authnz)
    );

    navList.forEach((navItem) => {
      //skip all items that have a "parent" set because the parent will filter out for children below
      if (navItem.parent) {
        return;
      }
      let n = this.getNavChildren(navItem, navList);
      nav.push(n);
    });
    this.navigation$.next(nav);

    return;
  }

  getNavChildren(navItem, filteredList) {
    navItem.children = filteredList
      .filter((fSetting) => fSetting.parent == navItem.id)
      .map((f) => {
        return this.getNavChildren(f, filteredList);
      });
    return navItem;
  }

  mapRouteToFeature(route) {
    return NavigationList.filter((x) => x.route == route)[0];
  }
}

// Currently, all NavItemObjects in NavigationList must be root-level routes, since the canActivate route guard
// looks at the first URL portion to map a route to a feature

const NavigationList: Array<NavItemObject> = [
  {
    id: "conversations",
    title: "Conversations",
    icon: "far fa-sms",
    route: "conversations",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessConversations();
    },
  },
  {
    id: "contacts",
    title: "Contacts",
    icon: "far fa-address-book",
    route: "contacts",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessContacts();
    },
  },
  {
    id: "lists",
    title: "Lists",
    icon: "far fa-address-book",
    route: "contact-list",
    parent: "contacts",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessContactLists();
    },
  },
  {
    id: "cannedResponses",
    title: "Canned Responses",
    icon: "far fa-reply-all",
    route: "canned-responses",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessCannedResponses();
    },
  },
  {
    id: "dripCampaign",
    title: "Drip Campaigns",
    icon: "far fa-tint",
    route: "campaign",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessDripCampaigns();
    },
  },
  {
    id: "blasts",
    title: "Blasts",
    icon: "far fa-rocket",
    route: "blasts",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessBlasts();
    },
  },
  {
    id: "reminders",
    title: "Reminders",
    icon: "far fa-calendar",
    route: "reminders",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessReminders();
    },
  },
  {
    id: "profile",
    title: "My Profile",
    icon: "far fa-address-card",
    route: "profile",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessOwnProfile();
    },
  },
  {
    id: "integrations",
    title: "Integrations",
    icon: "far fa-link",
    route: "integrations",
    badge: {
      text: "Beta",
      color: "green",
    },
    accessFunction: (security: UISecurityProvider, authnz: AuthNZService) => {
      return (
        security.canAccessIntegrations() &&
        authnz.activeNumberUID == authnz.currentFireauthUser.uid
      ); // only show this when the logged in user is the active user
    },
  },
  {
    id: "admin",
    title: "Admin Menu",
    icon: "far fa-sliders-v-square",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessAdminMenu();
    },
  },
  {
    id: "users",
    title: "Manage Users",
    icon: "",
    route: "users",
    parent: "admin",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessManageUsers();
    },
  },
  {
    id: "service-accounts",
    title: "Service Accounts",
    icon: "",
    route: "service-accounts",
    parent: "admin",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessServiceAccounts();
    },
  },
  {
    id: "admin-tools",
    title: "Admin Tools",
    icon: "",
    route: "admin-tools",
    parent: "admin",
    accessFunction: (security: UISecurityProvider) => {
      return security.canAccessAdminTools();
    },
  },
  {
    id: "sso",
    title: "Manage SSO",
    icon: "",
    route: "sso",
    parent: "admin",
    accessFunction: (security: UISecurityProvider) => {
      return !environment.production;
    },
  },
  {
    id: "billing",
    title: "Manage Billing",
    icon: "",
    route: "billing",
    parent: "admin",
    accessFunction: (security: UISecurityProvider) => {
      return security.canManageBilling();
    },
  },
];
