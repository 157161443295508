import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FrontendAdminTool, AdminToolResponse } from '../../../app/services/admin-tools.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getLogger } from '@shared/logging';
const log = getLogger("AdminToolComponent");
@Component({
  selector: 'app-admin-tool',
  templateUrl: './admin-tool.component.html',
  styleUrls: ['./admin-tool.component.scss']
})
export class AdminToolComponent implements OnInit, OnChanges {

  @Input() tool: FrontendAdminTool;

  public toolResponse: {
    state: "idle"|"loading"|"resolved",
    response?: AdminToolResponse
  } = { state: "idle"}

  public toolParamsForm: FormGroup;

  constructor() { }

  ngOnInit() {
    
  }


  ngOnChanges(changes: SimpleChanges): void {
    log.debug("received change in inputs for AdminTool component", changes);
    this.toolResponse = { state: "idle"}
    this.toolParamsForm = new FormGroup({});
    for(let param of this.tool.parameters) {
      this.toolParamsForm.addControl(param.name, new FormControl(null, [
        param.required ? Validators.required : null 
      ]));
    }

  }

  runTool() {
    const params = this.toolParamsForm.value;
    for (let key of Object.keys(params)) {
      if (Array.isArray(params[key])) {
        params[key] = params[key][0];
      }
    }
    log.debug("Triggering tool run: " + this.tool.name + " with params: ", params)
    this.toolResponse.state = "loading";
    this.tool.run(params).then((result) =>{
      this.toolResponse.state = "resolved";
      this.toolResponse.response = result
      log.debug("Tool execution complete: ", result)
    });

  }

}
