import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NzNotificationService } from 'ng-zorro-antd/notification';;
import { AuthNZService } from '../core/authnz.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { TextableService } from './textable.service';
import mime from "mime";
import { FrontendAttachment } from '../components/mms-uploader/mms-uploader.component';
import { TextableMessageStatusEvent } from '../backported_types/messaging';
import { getLogger } from '@shared/logging';
import { TextableContact } from '../backported_types/contact';
import { TextableDate } from 'src/classes/TextableDate';
const log = getLogger("MessagingService")

export type SendProps = { 
  contact: TextableContact
  newMessage: string
  composeStart?: number
  messageAttachment?: FrontendAttachment
  isNote?: boolean
}
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(
    private afs: AngularFirestore,
    private authnz: AuthNZService,
    private notification: NzNotificationService,
    private storage: AngularFireStorage,
    private textable: TextableService,
  ) {}

  /**
   * @param contact
   * @param newMessage
   * @param messageAttachment
   * @returns
   * Composes a message document to send.
   */
  public async send({
      contact, 
      newMessage,
      composeStart, 
      messageAttachment,
      isNote}: SendProps): Promise<void> {
    // Error Notification if empty message.
    if (newMessage === '' && !messageAttachment) {
      this.notification.create(
        'error',
        'Could not send message',
        'You must enter a message or upload a picture to send.',
      );
      return;
    }
    let events: Record<string,TextableMessageStatusEvent> = {};
    if (composeStart) {
      const e = {
        action: {
          object: (this.authnz.currentUserDocument.full_name || this.authnz.currentUserDocument.phone_number),
          type: "USER_DRAFT_STARTED"
        },
        timestamp: composeStart,
        eventId: "",
        messageId: ""
      } as TextableMessageStatusEvent
      events[generateStatusEventKey(e)] = e
    }
    const e = {
      action: {
        object: (this.authnz.currentUserDocument.full_name || this.authnz.currentUserDocument.phone_number),
        type: "USER_MESSAGE_SENT"
      },
      timestamp: TextableDate.now(),
      eventId: "",
      messageId: ""
    } as TextableMessageStatusEvent
    events[generateStatusEventKey(e)] = e

    let msg:any = {
      contact_id: contact.id,
      direction: 'out',
      to: contact.phone_number,
      from: this.authnz.activeNumberProfile.phone_number,
      body: newMessage,
      date: TextableDate.now(),
      send_status: 'pending',
      uid: this.authnz.activeNumberUID,
      events: events,
      attachments: [],
      sentBy: this.authnz.currentFireauthUser.uid,
      sentByLabel:  this.authnz.currentUserDocument.full_name,
    };

    if(isNote){
      msg.send_status = 'sent';
      msg.type = 'note';
      msg.direction = 'note'
    }

    // Add Attachment if valid
    if(messageAttachment && messageAttachment.hasOwnProperty('data') && messageAttachment.data !== null){
      msg.attachments = await this.createFirestoreAttachmentObject(messageAttachment);
    } else if(messageAttachment?.url){
      msg.attachments = [messageAttachment]
    }
    
    try {
      log.debug("Storing message", msg)
      const storeResult = await this.afs.collection('messages').add(msg);
      log.debug("Stored message", storeResult.id)
      await this.afs
        .doc('contacts/' + msg.contact_id)
        .update({ isArchived: false, last_message: msg.body, last_message_date: TextableDate.now() });
      log.debug("Updated contact '"+ msg.contact_id+"'")
      return;
    } catch (err) {
      log.error('Failed to store message' + err, msg);
    }
  }

  public async createFirestoreAttachmentObject(messageAttachment: FrontendAttachment){
    let imgName = this.authnz.activeNumberUID + '/' + this.authnz.activeNumberUID + '_' + TextableDate.now() * Math.random() + TextableDate.now();
    let extension =  '.' + mime.getExtension(messageAttachment.contentType)
    imgName += extension;
    let img = this.storage.ref('mms/' + imgName);
    let attachments = [];
    log.debug('[createFirestoreAttachmentObject] trying to save to mms/' + imgName);
    try{
      const savedImage = await img.putString(messageAttachment.data, 'data_url', {contentType: messageAttachment.contentType,});
      const downloadURL = await img.getDownloadURL().toPromise();
      attachments.push({
        url: downloadURL,
        contentType: messageAttachment.contentType,
        contentLength: messageAttachment.contentLength,
        filename: messageAttachment.name,
      });
    }catch(err){
      log.error('Could not save photo');
    }

    return attachments;
  }
}


/**
 * the `crypto` library from Node.js is not available in browsers, and 
 * a polyfill is necessary.
 * 
 * Since the key of the events here is insignificant, we can just generate
 * something random.
 * 
 * @param event 
 * @returns 
 */
export function generateStatusEventKey (event: TextableMessageStatusEvent) {
  const hex = '0123456789abcdef';
    let output = '';
    for (let i = 0; i < 16; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length));
    }
    return output;
}
