import { Component, OnInit } from '@angular/core';
import { TextableService } from 'src/app/services/textable.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';;
import { AuthNZService } from 'src/app/core/authnz.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss']
})
export class StatusMessageComponent implements OnInit {

  /**
   * Stores the filtered Observable of Canned Responsed
   */
  public canned$: any;
  /**
   * Controls the visibility of the empty canned response warning in the UI
   */
  public showEmptyAwayMessage = false;
  
  constructor(
    private textable: TextableService,
    public authnz: AuthNZService,
    private notification: NzNotificationService
    ) { }

  ngOnInit() {
   this.canned$ = this.textable.cannedResponses$.pipe(
     map(canned => canned.filter(c => c.isAwayMessageOption)),
     tap(canned => canned.length == 0 ? this.showEmptyAwayMessage = true : this.showEmptyAwayMessage = false)
    )
  }

  setAutoReplyMessage(val) {
    this.textable.setAutoReplyMessage(val)
    .catch((error) => {
      console.error(error);
      this.notification.create(
        'error',
        'There was an error updating your Away Message',
        ''
        );
    });
  }
}
