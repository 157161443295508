<nz-alert nzBanner [nzMessage]="tplVerifyEmail" *ngIf="this.authnz.currentSecurityProvider.requiresEmailConfirmation() && !isEmailVerified">
  <ng-template #tplVerifyEmail>Action Required: Your e-mail address has not been verified. Please verify to prevent your account from becoming disabled. <a (click)="sendVerificationEmail()">Send Verification E-mail</a></ng-template>
</nz-alert>
<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div>
    <nz-tabset [nzTabBarExtraContent]="tabSetButton" class="pad-main-content">
      <nz-tab nzTitle="Account Details" class="pad-25">
          <form nz-form nzLayout="vertical" [formGroup]="frmProfile" (ngSubmit)="updateProfile()">
            <div nz-row nzGutter="12">
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired>Full Name</nz-form-label>
                  <nz-form-control 
                    nzHasFeedback
                    nzErrorTip="Please enter your full name."
                    >
                    <!-- Change to textablecommon enums when properly installed-->
                    <!-- Read Only Retail Team Admin && PL User-->
                    <input nz-input formControlName="full_name" placeholder="John Doe" 
                    [readonly]="!this.authnz.currentSecurityProvider.canEditOwnFullName()"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired>E-mail</nz-form-label>
                  <nz-form-control
                    nzHasFeedback
                    nzErrorTip="Please enter a valid e-mail address."
                    >
                    <!-- Read Only except for PL Admin-->
                    <input nz-input formControlName="email" placeholder="E-mail" type="email" [readonly]="true"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

             <!-- Read Only Retail Team User or PL User-->
            <ng-container *ngIf="! this.authnz.currentSecurityProvider.canEditOwnProviderSettings()">
                <div nz-row nzGutter="12" >
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                          <nz-form-label >Phone Number</nz-form-label>
                          <nz-form-control>
                            <input nz-input type="text" formControlName="phone_number" readonly>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                </div>
            </ng-container>

            <ng-container *ngIf="this.authnz.currentSecurityProvider.canEditOwnProviderSettings()">
              <div nz-row nzGutter="12">
                <div nz-col nzSpan="8">
                  <nz-form-item>
                    <nz-form-label nzRequired>Phone Number</nz-form-label>
                    <nz-form-control
                      nzErrorTip="Invalid number format. Must match e.164 format. (e.g. +1 (555) 555-5555)">
                        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                            <ng-template #addOnBeforeTemplate>
                              <nz-select [(ngModel)]="phonePrefix" style="width: 60px" [ngModelOptions]="{standalone: true}" [nzDropdownMatchSelectWidth]="false">
                                <nz-option style="width: 200px" nzCustomContent *ngFor="let country of countryCodes" [nzLabel]="country.code" [nzValue]="country.code">{{country.name}}<span style="color: lightgray"> ({{country.code}})</span></nz-option>
                              </nz-select>
                            </ng-template>
                            <input nz-input type="text" formControlName="phone_number" type="tel"
                            [textMask]="{mask: phoneMask}">
                          </nz-input-group>
                      <ng-template #numberHidden>
                        <nz-alert nzType="warning" nzMessage="Please configure a provider before entering your number."></nz-alert>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <nz-divider nzText="Provider Details" nzOrientation="left"></nz-divider>
              
              <p>For instructions on configuring your specific provider, please visit our guides at <a href="https://www.textable.co/docs-category/setup-guides/" target="_blank">https://www.textable.co/docs-category/setup-guides/</a>.</p>
              <!--- Why use credential source? Instead of just TEAMS && TEAM ADMIN-->
              <app-provider-editor
                  [baseFormGroup]="frmProfile"
                  [baseDocument]="userProfile"
                  baseFormType="user"
                >
              </app-provider-editor>
              <button nz-button nzType="primary" nzBlock [nzLoading]="isUpdatingProfile">Update Profile Details</button>
            </ng-container>
          
            <ng-container *ngIf="! this.authnz.currentSecurityProvider.canEditOwnProviderSettings()">
              <p>Please contact {{ this.authnz.currentSecurityProvider.getEditOwnProviderSettingsContact() }} to update account details.</p>
            </ng-container>
 
          </form>
      </nz-tab>
      <nz-tab nzTitle="Change Password" class="pad-20">
        <h3>Change Your Password</h3>
        <p>Please enter a new password and confirm it to change your password.</p>
        <nz-divider></nz-divider>
        <form nz-form nzLayout="vertical" [formGroup]="frmPassword" (ngSubmit)="updatePassword()" autocomplete="off">
          <nz-form-item >
            <nz-form-label nzRequired>
              Enter New Password  
               <span nz-tooltip nzTooltipTitle="Please enter a password containing at least 8 characters, 1 lowercase, and 1 uppercase letter."><i nz-icon nzType="info-circle" nzTheme="outline"></i></span> 
            </nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please enter a password containing at least 8 characters, 1 lowercase, and 1 uppercase letter."
              >
              <input nz-input formControlName="password" placeholder="**********" type="password" autocomplete="abc123"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item >
            <nz-form-label nzRequired>Confirm New Password</nz-form-label>
            <nz-form-control 
                nzHasFeedback
                nzErrorTip="Passwords must match.">
              <input nz-input formControlName="cPassword" placeholder="**********" type="password" autocomplete="abc123"/>
            </nz-form-control>
          </nz-form-item>
          <button nz-button nzType="success" nzBlock [disabled]="!frmPassword.valid" [nzLoading]="isUpdatingPassword">Update Password</button>
        </form>
      </nz-tab>
      <nz-tab nzTitle="Messaging Settings" class="pad-20">
        <nz-card>
          <form nz-form nzLayout="vertical" [formGroup]="frmPreferences" (ngSubmit)="updateMessageSettings()" autocomplete="off">
            <nz-divider nzPlain nzText="Canned Response" nzOrientation="left"></nz-divider>
            <div nz-row nzGutter="12">
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired>
                    Away Message Interval (Hours)
                    <span nz-tooltip
                      nzTooltipTitle="Sets the hourly interval that an away message awaits before sending a new one. Default: 24"><i
                        nz-icon nzType="info-circle" nzTheme="outline" class="info-circle"></i></span>
                  </nz-form-label>
                  <nz-form-control nzHasFeedback nzErrorTip="Number must be in the greater than or equal to 0(hrs)">
                    <input nz-input formControlName="awayMessageResponseInterval" type="number" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <nz-divider nzPlain nzText="Signature" nzOrientation="left"></nz-divider>
            <div nz-row nzGutter="12">
                <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired>Enable Messaging Signature
                    <span nz-tooltip nzTooltipTitle="Enables signatures on all outbound messages."><i nz-icon
                        nzType="info-circle" nzTheme="outline" class="info-circle"></i></span>
                  </nz-form-label>
                  <nz-form-control>
                    <nz-switch formControlName="signatureEnabled"></nz-switch>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8">
                <nz-form-item>
                  <nz-form-label nzRequired>Signature Text   
                    <span nz-tooltip
                    nzTooltipTitle="Outbound message signature text. Leave empty to disable the toggle switch in the message composer."><i
                      nz-icon nzType="info-circle" nzTheme="outline" class="info-circle"></i></span>
                    </nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="signatureText"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
      
      
            <button nz-button nzType="success" nzBlock [disabled]="!frmPreferences.valid" [nzLoading]="isUpdatingProfile">Update
              Settings</button>
          </form>
         </nz-card>
      </nz-tab>
      <nz-tab [nzTitle]="blockedSendersTemplate" class="pad-20">
        <ng-template #blockedSendersTemplate>
          Blocked Senders <i class="far fa-question-circle guideButton" (click)="guideSection ='Blocked Senders'"></i>
        </ng-template>

          <nz-card 
            *ngIf="(activeUserProfile | async) as profile1"
            nzTitle="Blocked Senders - {{profile1.full_name}} ({{profile1.phone_number}})">
            <app-blocked-sender-manager 
              [UserId]="profile1.firebase_document_id"
              >
            </app-blocked-sender-manager>
          </nz-card>

          <nz-card 
            *ngIf="(activeNumberProfile | async) as profile2"
            nzTitle="Blocked Senders - {{profile2.full_name}} ({{profile2.phone_number}})">
            <app-blocked-sender-manager 
              [UserId]="profile2.firebase_document_id"
              >
            </app-blocked-sender-manager>
          </nz-card>

      </nz-tab>
    </nz-tabset>
  </div>
</nz-card>
<ng-template #extraTemplate>
  Ver. {{environment.version}}
</ng-template>

<!-- USER GUIDE -->
<ng-template #pageTitleTemplate>
  My Profile
</ng-template>
<nz-modal [nzStyle]="{ top: '30px' }"  [(nzVisible)]="guideSection" nzTitle="User Guides" (nzOnCancel)="guideSection = null" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="guideSection"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>
</ng-template>

<ng-template #tabSetButton>
  <button nzType="primary" nz-button (click)="openBillingPortal()" *ngIf="
        this.authnz.currentSecurityProvider.canManageBilling()"
        [nzLoading]="isLoading">
    Manage Billing</button>
</ng-template>

<nz-modal [(nzVisible)]="billingModalVisible" nzTitle="Billing" (nzOnCancel)="billingModalVisible=false"
[nzFooter]="null">
  <ng-container *nzModalContent>
    <p style="text-align: center">Click the link below to navigate to the billing page</p>
    <a href="{{stripeSessionUrl}}" target="_blank" nzType="primary" nz-button nzBlock>Go To Billing Portal</a>
  </ng-container>
</nz-modal>
