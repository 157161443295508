import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { MmsService } from "src/app/services/mms.service";
import { FrontendAttachment } from "src/app/components/mms-uploader/mms-uploader.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { capitalizeFirstLetter } from "src/app/functions";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TextableService } from "src/app/services/textable.service";
import * as moment from 'moment-timezone';
import { TextableMessage, TextableMessageStatusDescriptions, TextableMessageStatusEvent } from "src/app/backported_types/messaging";
import { environment } from 'src/environments/environment';
import { ApplicationContextService } from "src/app/services/application-context.service";
import { getLogger } from 'src/shared/logging';
import { DateService } from "src/app/services/date.service";
import { TextableDate } from "src/classes/TextableDate";
import { DateIsInFuture } from "src/app/form_validators/DateIsInFuture";
import { AuthNZService } from "src/app/core/authnz.service";
const log = getLogger("MessageViewComponent");

@Component({
  selector: "app-message-view",
  templateUrl: "./message-view.component.html",
  styleUrls: ["./message-view.component.scss"],
})
export class MessageViewComponent implements OnInit, OnChanges, OnDestroy  {

  public environment = environment

  public colors: { [key: string ]:string } = {
    "info":"gray",
    "error":"red",
    "success":"green"
  }

  @Input() message: any;

  /**
   * Controls whether the edit scheduled message modal is visible
   */
  editScheduledModal: boolean = false;

  /**
   * Form group used in the edit scheduled message modal
   */
  frmEditScheduled;

  /**
   * Indicates active loading operations (update/delete) for scheduled messages
   */
  isLoading: boolean = false;

  /**
   * If the user wants to view the full event data, we 
   * store a stringified version of it in this string
   * and conditionally render it to the Deliverability Details popover
   */
  public eventDataView: TextableMessageStatusEvent = null;

  /**
   * When a timeline event is selected, we need to track the index of the 
   * event so that we can "highlight" it on the timeline view
   * 
   * TODO: Make this "bold" the whole timeline event instead of only the text; 
   * e.g. make the "Dot" and the timestam bold too.  This might be hard
   * since nz-timeline is rather terse.
   */
  public selectedTimelineEvent: number

  /**
   * Model for Monaco/VSCode editor for DLR event JSON
   */
  public eventDataCodeModel: any;
  /**
   * Options for Monaco/VSCode editor for DLR event JSON
   */
  public codeEditorOptions = { 
    contextmenu: true,
    minimap: {
      enabled: true
    }
  }

  status: {
    message: string,
    by: string,
    showRetry: boolean,
    extraClasses: string[]
    send_status: string
  } = {  } as any

  /**
   * Instance value to store message events as an array of TextableMessageStatusEvent
   * 
   * TOOD:TXBDEV-193
   */
  messageEvents: any[]

  public deprecatedGropuMMSPopoover: boolean = false;

  constructor(
    public mms: MmsService,
    private afs: AngularFirestore,
    private notification: NzNotificationService,
    private textable: TextableService,
    public applicationContext: ApplicationContextService,
    private dateService: DateService,
    private authnz: AuthNZService
    ) {}

  ngOnDestroy(): void {
    this.deprecatedGropuMMSPopoover=false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.status.message = capitalizeFirstLetter((changes.message.currentValue.direction == "out" ? changes.message.currentValue.send_status : "received") || "Unknown")
    this.status.by = changes.message.currentValue.direction == "out" ? ( this.message.sentByLabel ? (this.message.sentByLabel) : "unknown" ) : ""
    this.status.send_status = changes.message.currentValue.send_status
    this.status.showRetry = changes.message.currentValue.send_status == "fail"
    this.messageEvents = changes.message?.currentValue?.events ?  Object.values(changes.message.currentValue.events) : [];
    if (this.messageEvents){
      this.messageEvents = this.messageEvents.sort((a:any ,b: any)=>a.timestamp-b.timestamp) // TODO: TXBDEV-193; this is TextableMessageStatusEvent
    }
  }

  ngOnInit(): void {}

  public clickMessage(message) {
    log.debug("Clicked message", message);
  }

  openPhoto(attachment: FrontendAttachment) {
    this.mms.openPhoto(attachment);
  }

  async deleteMessage(id) {
    this.isLoading = true;
    try { 
      await this.afs.doc("messages/" + id).delete()
      log.debug("Deleted message '" + id + "'")
      this.notification.create(
        "success",
        "Message Deleted",
        "The scheduled message has been deleted."
      );
      this.cancelEditScheduledModal();
    }
    catch(err) {
      log.warn("Failed to delete scheduled message '"+id+"'", err)
      this.notification.create(
        "error",
        "Message Not Deleted",
        "The scheduled message could not be deleted. Please try again."
      );
      this.cancelEditScheduledModal();
    }
  }

  updateScheduledMessage() {
    this.isLoading = true;
    this.textable
      .update(
        "messages",
        this.frmEditScheduled.value,
        this.frmEditScheduled.value.id,
        { title: "Success", subtitle: "Scheduled Message has been updated." }
      )
      .then((res) => {
        this.cancelEditScheduledModal();
      });
  }

  openScheduledModal(msg: TextableMessage) {
    this.frmEditScheduled = new FormGroup(
      {
        date: new FormControl(null, [Validators.required]),
        scheduleTimezone: new FormControl(null, [Validators.required]),
        id: new FormControl(null),
      },
      {
        validators: [DateIsInFuture("date", "scheduleTimezone")],
        updateOn: "change",
      }
    );
    this.frmEditScheduled.get("id").setValue(msg.firebase_document_id);
    this.frmEditScheduled
      .get("scheduleTimezone")
      .setValue(msg.scheduleTimezone);
    this.frmEditScheduled.get("date").setValue(msg.date);
    this.editScheduledModal = true;
  }

  cancelEditScheduledModal() {
    this.isLoading = false;
    this.editScheduledModal = false;
    /**
     * This block is necessary to maintain backward compatibility for
     * messages with attachments stored in a pre-TXBDEV-133 format
     * @param attach
     * @returns
     */
  }

  retrySend(msgId) {
    this.afs
      .doc("messages/" + msgId)
      .update({ date: TextableDate.now(), send_status: "pending" });
  }
  public formatTime(time: number) {
    return new TextableDate(time).format('MM/DD/YY h:mm:ss a z')
  }

  public formatAction(event: TextableMessageStatusEvent) {
    const showProviderName = this.authnz.currentSecurityProvider.canViewProviderName();
    if (!event.action?.hasOwnProperty("type") || !event.action?.hasOwnProperty("object")) {
      return "Unknown"
    }
    if (event.action.type.startsWith("PROVIDER") && !showProviderName) {
      return TextableMessageStatusDescriptions[event.action.type].message +  " Provider";

    }
    // TODO: Fetch the mappings from textablecommon / TextableMessageStatusDescriptions so we have plain-English strings
    return TextableMessageStatusDescriptions[event.action.type].message + (event.action.object ? (" " + event.action.object) : "");
  }

  public selectMessageEvent(event: TextableMessageStatusEvent, index: number) {
    if (!this.authnz.currentSecurityProvider.canViewDLRObject()) {
      return;
    }
    this.eventDataView = event;
    this.selectedTimelineEvent = index;
    this.eventDataCodeModel = {
      language: 'json',
      uri: 'main.json',
      value: JSON.stringify(event,null,"  ")
    }
  }

  getEventColor(e: TextableMessageStatusEvent): string {
    return this.colors[TextableMessageStatusDescriptions[e.action?.type]?.level] || 'gray'
  }

  formatMetadata(md: {[key: string]: string}): {key:string, value:string}[] {
    return Object.keys(md)?.map(k => ({key:k,value:md[k]}) )
  }

  public showMetatdata(): boolean {
    return this.authnz.currentSecurityProvider.canViewMetadata()
  }

}
