

<nz-table 
  #table 
  [nzData]="BlockedNumbers | async"  [nzBordered]="false"
  [nzShowPagination] = "false" [nzScroll]="{ y: '200px' }"
  [nzFooter] = "blockNewNumberTemplate"
  >
  <thead>
    <tr>
      <th>Sender</th>
      <th>Blocked By</th>
      <th>Blocked Messages</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let blockedNumber of table.data">
      <td>{{blockedNumber.phoneNumber}}<span *ngIf="blockedNumber.name"> ({{blockedNumber.name}})</span></td>
      <td>
        {{blockedNumber.blockedBy.name}} at {{blockedNumber.blockedAt | date:"short"}}
      </td>
      <td>
        {{blockedNumber.receivedMessages || 0}}
      </td>
      <td>
        <app-sender-blocker
          [UserId]="UserId"
          [sender] = "blockedNumber">
        </app-sender-blocker>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #blockNewNumberTemplate> 
  
  <form nz-form nzLayout="vertical" [formGroup]="frmBlockedNumbers" autocomplete="off">
    <div nz-row [nzGutter]="20">
      <div nz-col [nzSpan]="16">
        <phone-number-input formControlName="newPhoneNumberToBlock"></phone-number-input>
      </div>
      <div nz-col [nzSpan]="4">
        <app-sender-blocker
          [UserId]="UserId"
          (onComplete) = "frmBlockedNumbers.reset()"
          [disabled] = "!frmBlockedNumbers.valid"
          [sender] = "frmBlockedNumbers.value?.newPhoneNumberToBlock">
        </app-sender-blocker>
      </div>
  </div>
</form>
</ng-template>