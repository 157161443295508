import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TextableService } from 'src/app/services/textable.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { fromEvent, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthNZService } from 'src/app/core/authnz.service';
import { TextableContact, TextableContactList } from 'src/app/backported_types/contact';
import { arrayUnion, arrayRemove } from '@angular/fire/firestore'


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  host: {
    "class": "app-view app-view-two-pane"
  }
})
export class ContactListComponent implements OnInit {

  @ViewChild('txtFilter', { static: true }) txtFilter: ElementRef;
  @ViewChild('addressBook') addressBook; 

  allDataChecked = false;
  isIndeterminate = false;
  /**
   * Checked contacts in the active list
   */
  public mapOfCheckedId: Record<string,boolean> = {};
  numberOfChecked = 0;

  loading = false;
  drwList = false;
  drwContacts = false;
  guideVisibleBool = false;
  frmList;

  /**
   * Observable of all contact lists;
   */
  contactList$: Observable<TextableContactList[]>;
  /**
   * the currently displayed contact list
   */
  public activeList: TextableContactList;
  /**
   * String array of contact IDs; updated whenever the 
   * AddressBookComponent.selectedListChanged callback fires with new data
   * 
   * These are added to the current contact list when the user clicks
   * "Add Contacts" in the drawer
   */
  public selectedContacts: string[];

  public contactsInActiveList: TextableContact[];

  constructor(
    private textable:TextableService,
    private afs: AngularFirestore,
    public authnz: AuthNZService
    ) { }

  ngOnInit() {
    fromEvent(this.txtFilter.nativeElement, 'keyup').pipe(
      map((event:any)=>{
        return event.target.value;
      }),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((text)=>{
      this.handleFilterValue(text);
    });

    this.authnz.contextChanged.subscribe(()=>{
      this.activeList = null;
    });
    
    this.makeForm();
    this.contactList$ = this.textable.contactLists$
    this.activeList = null;
  }

  handleFilterValue(val) {
    this.loading = true;
    // set val to the value of the searchbar
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.contactList$ = this.afs
        .collection('contact-lists', ref => ref.where('uid', '==', this.authnz.activeNumberUID)).snapshotChanges().pipe(map( contacts => {
        return contacts.map(a => {
          const contact:any = a.payload.doc.data();
          contact.id = a.payload.doc.id;
          return contact;
        }).filter((c: any) => {
          this.loading = false;
          return c.listName.toLowerCase().indexOf(val.toLowerCase()) > -1
        });
      }));
    } else {
      this.loading = false;
      this.contactList$ = this.textable.contactLists$
    }
  }

  makeForm() {
    this.frmList = new FormGroup({
      "listName": new FormControl(null,[Validators.required,Validators.minLength(3)]),
      "uid": new FormControl(null),
      "isDeleted": new FormControl(false),
    })
  }
  
  ////////////////
  // Textable Service Methods
  ////////////////
  
  /**
   * Sets up a subscription to get the contacts in the
   * active list
   */
  private watchContactsInList(listID) {
    this.textable.getContactsInList(listID).subscribe((data) => {
      this.contactsInActiveList = data.sort((a: TextableContact, b: TextableContact) => a.full_name.localeCompare(b.full_name))
    })
  }

  addList() {
    if (this.frmList.value.listName !== null) {
      this.loading = true;
      this.frmList.get('uid').setValue(this.authnz.activeNumberUID);
      this.frmList.get('isDeleted').setValue(false);
      this.textable.create('contact-lists', this.frmList.value)
        .then((res) => {
          this.loading = false;
        }).catch((err) => {
          console.log(err)
          this.loading = false;
        })
      this.close();
    }
  }

  deleteList(listID) {
    
    this.loading = true
    this.textable.update("contact-lists" , {isDeleted:true} , listID)
      .then((res) => {
      }).catch((err) => {
        console.log(err)
      })
    this.loading = false
    this.activeList = null;
  }

  //work on refactoring this!
  removeContacts() {
    this.loading = true;
    let keys = Object.entries(this.mapOfCheckedId)
    let count = 0
    keys.forEach(key => {
      if (key[1]) {
        count++;
        this.afs.doc('contacts/' + key[0]).get().forEach(doc => {
          let list = (doc.data() as any).lists; // TODO: TXBDEV-193 Refactor this to use TextableUser interface when TextableCommon become available in PF
          let index = list.indexOf(this.activeList.id)
          list.splice(index, 1);

          this.afs.doc("contacts/" + key[0]).update({ lists: list }).then(() => {
            this.loading = false;
            this.resetTableValues();
          })
        })
      }
    })    
    this.afs.doc('contact-lists/' + this.activeList.id).update({count: this.contactsInActiveList.length - count})
    this.activeList.count = this.contactsInActiveList.length - count
  }

  /**
   * Adds to the active list the current selection of contacts from the AddressBookComponent
   */
  public async addSelectedContactsToList() {
    this.loading = true;
    let countOfContactsAdded = 0;
    await Promise.all(
      this.selectedContacts.map(async contactIdToAdd => {
        const contactAlreadyInList = this.contactsInActiveList.filter(c=>c.firebase_document_id == contactIdToAdd).length > 0
        if (!contactAlreadyInList) { 
          countOfContactsAdded++;
          const contactDocToUpdate =  this.afs.doc('contacts/' + contactIdToAdd);
          return await contactDocToUpdate.update({
            lists: arrayUnion(this.activeList.id)
          });
        }
      })
    )
    this.afs.doc('contact-lists/' + this.activeList.id).update({count: this.contactsInActiveList.length + countOfContactsAdded})
    this.activeList.count = this.contactsInActiveList.length + countOfContactsAdded
    this.close()
    this.loading = false;
  }

  FormatAutoSubscribeKeywords() {
    if(!this.activeList.hasOwnProperty("autoSubscribeKeywords")){
      this.activeList.autoSubscribeKeywords = [];
    }
    for(let i=0; i< this.activeList.autoSubscribeKeywords.length;i++){ 
      console.log("uppering " + this.activeList.autoSubscribeKeywords[i]);
      this.activeList.autoSubscribeKeywords[i] = this.activeList.autoSubscribeKeywords[i].toUpperCase();
    }
  }

  changeListName() {
    this.FormatAutoSubscribeKeywords()
    this.loading = true;
    this.textable.update('contact-lists', { 
        listName: this.activeList.listName,
        autoSubscribeKeywords: this.activeList.autoSubscribeKeywords
      }, this.activeList.id)
      .then(res => {
        this.loading = false
      }).catch(e => {
        this.loading = false
        console.log(e)
      })
  }
  //////////////////////////
  // Table Methods
  /////////////////////////
  refreshStatus() {
    this.allDataChecked = this.contactsInActiveList.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate = this.contactsInActiveList.some(item => this.mapOfCheckedId[item.id]) && !this.allDataChecked;
    this.numberOfChecked = this.contactsInActiveList.filter(item => this.mapOfCheckedId[item.id]).length;
  }

  checkAll(value: boolean): void {
    this.contactsInActiveList.forEach((item) => {
      this.mapOfCheckedId[item.id] = value
    });
    this.refreshStatus();
  }

  resetTableValues() {
    this.allDataChecked = false;
    this.isIndeterminate = false;
    this.mapOfCheckedId = {};
    this.numberOfChecked = 0;
  }

  /**
   * Callback method for AddressBookComponent.refreshStatus
   * 
   * @param event string array of all currently selected contact ids
   */
  selectedListChanged(event: string[]) {
    this.selectedContacts = event;
  }
  ///////////////////////
  //DRAWERS/MODALS AND SELECTIONS
  //////////////////////
  
  openDrwContacts() {
    // wait for collaboration meeting and merges, will use new contacts component  to add to list
    this.drwContacts = true;
  }

  openDrwNewList(edit?: any) {
    this.drwList = true;

    if (edit) {
    } else {
    }
  }

  close() {
    this.drwContacts = false;
    this.drwList = false;
    this.frmList.reset()
  }
  /**
   * Resets the view to the provided list; called from the UI
   * 
   * @param list 
   */
  setActiveList(list: TextableContactList) {
    this.resetTableValues();
    this.activeList = list;
    this.watchContactsInList(list.id)
  }
}
