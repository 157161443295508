import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NzNotificationService } from 'ng-zorro-antd/notification';;
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TextableService } from 'src/app/services/textable.service';
import log from 'loglevel';
import { AuthNZService } from 'src/app/core/authnz.service';
import { QRCodeComponent } from 'angularx-qrcode';
import { MMSUploaderMode } from 'src/app/components/mms-uploader/mms-uploader.component';
import { MmsService } from 'src/app/services/mms.service';

interface ClipboardItem { //TODO: TXBDEV-1285 - Remove Clipboard type overrides after updating TypeScript
  readonly types: string[];
  readonly presentationStyle: "unspecified" | "inline" | "attachment";
  getType(): Promise<Blob>;
}

interface ClipboardItemData { //TODO: TXBDEV-1285 - Remove Clipboard type overrides after updating TypeScript
  [mimeType: string]: Blob | string | Promise<Blob | string>;
}

declare var ClipboardItem: { //TODO: TXBDEV-1285 - Remove Clipboard type overrides after updating TypeScript
  prototype: ClipboardItem;
  new (itemData: ClipboardItemData): ClipboardItem;
};

interface Clipboard { //TODO: TXBDEV-1285 - Remove Clipboard type overrides after updating TypeScript
  read(): Promise<DataTransfer>;
  write(data: ClipboardItem[]): Promise<void>;
}

@Component({
  selector: 'app-canned-responses',
  templateUrl: './canned-responses.component.html',
  styleUrls: ['./canned-responses.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class CannedResponsesComponent implements OnInit {
  @ViewChild('contactQR') qrcode: QRCodeComponent
  public showMMSModal: boolean = false;
  public mmsUploaderMode = MMSUploaderMode.COMPOSE

  canned$;
  frmEditResponse: FormGroup;
  drwEditResponse: boolean = false;
  activeResponse;
  isUpdating: boolean = false;
  isDeleting: boolean = false;
  guideVisibleBool = false;
  public copiedToClipboard: string = ""
  /**
   * The ClipboardItem API does not have consistent browser support: https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem#browser_compatibility
   * 
   * e.g. Firefox requires chaning the about:config value for dom.events.asyncClipboard.clipboardItem to true to support images
   * https://bugzilla.mozilla.org/show_bug.cgi?id=1619251
   * 
   */
  public clipboardAPISupported: boolean;


  constructor(
    private afs: AngularFirestore, 
    private notification: NzNotificationService, 
    private textable: TextableService,
    public authnz: AuthNZService,
    ) { }

  ngOnInit() {

    this.clipboardAPISupported = typeof(ClipboardItem) != "undefined"

    this.frmEditResponse = new FormGroup({
      "name": new FormControl(null, [
        Validators.required,
        Validators.minLength(3)
      ]),
      "body": new FormControl(null, [
        Validators.required,
        Validators.minLength(3)
      ]),
      "auto_keyword": new FormControl(null, [
        Validators.minLength(4)
      ]),
      "attachments": new FormControl([]),
      "isAwayMessageOption": new FormControl(false)
    });

    this.canned$ = this.textable.cannedResponses$;
  }

  addResponse() {
    this.activeResponse = null;
    this.drwEditResponse = true;
  }

  closeDrwEditResponse() {
    this.drwEditResponse = false;
    this.frmEditResponse.reset();
  }

  editResponse(response) {
    this.activeResponse = response;
    this.drwEditResponse = true;
    this.frmEditResponse.patchValue(response);
  }

  deleteResponse() {
    this.isDeleting = true;
    this.afs.doc('canned-responses/' + this.activeResponse.id)
      .delete()
      .then(()=>{
        this.drwEditResponse = false;
        this.isDeleting = false;
        this.activeResponse = null;
        this.notification.create(
          'error',
          'Canned Response Deleted',
          'The canned response has been deleted.'
        );
      });

  }
  /**
   * Updates a user's last timestamp if a canned message is updated.
   */
  public updateUserLastTimeStamp(){
    this.textable.setAutoReplyMessage({...this.activeResponse, body: this.frmEditResponse.value.body}).then(() => {
      this.notification.create(
        'success',
        'Canned Response Updated',
        'The canned response has been updated.'
      );
      this.isUpdating = false;
      this.drwEditResponse = false;
      this.activeResponse = null;
      this.frmEditResponse.reset();
    })
    .catch((error) => {
      console.error(error);
      this.notification.create(
        'error',
        'There was an error updating your Away Message',
        ''
        );
    });
  }

  public updateResponse() {
    if (this.activeResponse) {
      this.isUpdating = true;
      if (!this.frmEditResponse.valid) {
        this.notification.create(
          'error',
          'Canned Response Not Updated',
          'Please correct the issues before updating canned response.'
        );
      } else {
        this.formatKeywordPreSubmit()
        this.afs
          .doc('canned-responses/' + this.activeResponse.id)
          .update(this.frmEditResponse.value)
          .then(() => {
            if (this.authnz.activeNumberProfile.autoReplyMessageId == this.activeResponse.id) {
              this.updateUserLastTimeStamp()
            } else {
              this.notification.create(
                'success',
                'Canned Response Updated',
                'The canned response has been updated.'
              );
              this.isUpdating = false;
              this.drwEditResponse = false;
              this.activeResponse = null;
              this.frmEditResponse.reset();
            }
          });

      }
    } else {
      this.isUpdating = true;
      if (!this.frmEditResponse.valid) {
        this.notification.create(
          'error',
          'Canned Response Not Created',
          'Please correct the issues before creating canned response.'
        );
        this.isUpdating = false;
      } else {
        this.formatKeywordPreSubmit()

        this.frmEditResponse.value.uid = this.authnz.activeNumberUID;

        this.afs
          .collection('canned-responses')
          .add(this.frmEditResponse.value)
          .then(() => {
            this.notification.create(
              'success',
              'Canned Response Created',
              'The canned response has been created.'
            );
            this.isUpdating = false;
            this.drwEditResponse = false;
            this.activeResponse = null;
            this.frmEditResponse.reset();
          });

      }
    }
  }

  formatKeywordPreSubmit(){
    if(!this.frmEditResponse.get('auto_keyword').value){
      return;
    }

    if(this.frmEditResponse.get('auto_keyword').value.length === 0){
      this.frmEditResponse.get('auto_keyword').setValue(null);
    }

    this.frmEditResponse.get('auto_keyword').setValue(this.frmEditResponse.get('auto_keyword').value.toUpperCase());
  }

  CopyQRCodeImageToClipboard() {
    const element = this.qrcode.qrcElement.nativeElement
    const children = element.children as HTMLCollection
    const img = children.item(0) as HTMLImageElement// TODO: This could cause copy to clipboard to break if the 'angularx-qrcode' library changes how they render the QR Code element
    const item =  new ClipboardItem({
      'image/png': this.dataURItoBlob(img.currentSrc)
    });
    (navigator.clipboard as unknown as Clipboard).write([item]); //TODO: TXBDEV-1285 - Remove Clipboard type overrides after updating TypeScript
    this.copiedToClipboard = "Copied!";
    window.setTimeout(()=>{
      this.copiedToClipboard = "";
    },1500)

  }

  /**
   * Converts a DataURI to a blob
   * 
   * Copied from https://stackoverflow.com/a/12300351/11125318
   * @param dataURI 
   * @returns 
   */
  private dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    var ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  
  }

  /////////////////////////////
  // MMS Related Functions
  ////////////////////////////
  public closeMMSUploader(event){
    this.attachUploadToMessage(event)
    this.showMMSModal = false
  }

  private attachUploadToMessage(event){
    if(event){
      this.frmEditResponse.get('attachments').setValue(event)
    }
  }

  public removeAttachment(){
    this.frmEditResponse.get('attachments').setValue([])
  }
}
