import { Query, CollectionReference } from "@angular/fire/compat/firestore";
import { PlanSelectionProperties, UISecurityProvider } from './interfaces';
import { BaseSecurityProvider } from "./base";
import { environment } from '../../../environments/environment';
import { AuthNZService } from "../authnz.service";
import { UserGuideConfig } from "src/app/components/user-guides/user-guides.component";
import { Injector } from "@angular/core";

export class PrivateLabelSecurityProvider extends BaseSecurityProvider implements UISecurityProvider {
  // #region Constructors (1)

  constructor(service: AuthNZService, injector: Injector) {
    super(service);
  }

  // #endregion Constructors (1)

  // #region Public Methods (43)

  public allowProviderCopyOnUserCreate(): boolean {
    return false;
  }

  canAccessAdminTools(): boolean {
    return this.service.currentUserDocument.hasOwnProperty("isSuperAdmin")
      && this.service.currentUserDocument.isSuperAdmin === true
  }

  public canAccessAdminMenu(): boolean  {
    // Since Users is the only sub-menu of admin, we check that here also.
    return (this.isAdmin() || this.isOrgAdmin()) 
      && this.getUserAndEnvironmentFeatureGrant("users")  
      && this.getUserAndEnvironmentFeatureGrant("admin");
  }

  public canAccessBlasts(): boolean {
    return true &&  this.getUserAndEnvironmentFeatureGrant("blasts");
  }

  public canAccessBulkMMS(): boolean {
  return this.hasValidPhoneNumber() && this.getUserAndEnvironmentFeatureGrant("bulkMMS");
  }


  public canAccessCannedResponses(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("cannedResponses");
  }

  public canAccessContactLists(): boolean {
    return true &&  this.getUserAndEnvironmentFeatureGrant("lists");
  }

  public canAccessContacts(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("contacts");
  }

  public canAccessConversations(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("conversations");
  }

  public canAccessDripCampaigns(): boolean {
     return true &&  this.getUserAndEnvironmentFeatureGrant("dripCampaign");
  }

  public canAccessManageUsers(): boolean {
    // Currently, this is the only item in the admin menu, so we'll use the same security check
    // at some point in the future, it may be possible that items exist in the admin menu
    // which are granted, but manage users is still restricted.
    return this.canAccessAdminMenu();
  }

  public canAccessNumberSwitcher(): boolean {
    return true;
  }

  public canAccessOwnProfile(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("profile");
  }

  public canAccessReminders(): boolean {
    return true &&  this.getUserAndEnvironmentFeatureGrant("reminders");
 }

  public canAccessServiceAccounts(): boolean {
    return this.isAdmin() && this.getUserAndEnvironmentFeatureGrant("serviceAccounts");
  }

  public canAddOrgs() : boolean {
    return this.isAdmin();
  }

  public canAddUsers(): boolean {
    return this.isAdmin();
  }

  public canAccessDeletedUsers(): boolean{
    return this.isAdmin();
  }

  public canAccessDeletedOrganizations() { 
    return this.canAccessDeletedUsers();
  }
  
  public canDeleteContacts(): boolean {
    return this.isAdmin() || (!environment.disableDelete && this.getUserFeatureGrant("disableDelete"));
  }

  public canDeleteUsers(): boolean {
    return this.isAdmin();
  }

  public canDisableOrganizations(): boolean {
    return this.isAdmin();
  }

  public canAccessIntegrations(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("integrations");;
  }

  public canEditAdminIntegrations(){
    return this.isAdmin()
  }
  
  public canEditOrgConsentMessages(): boolean {
    return true;
  }

  public canEditOwnFullName(): boolean {
    return this.isAdmin();
  }

  public canEditOwnProviderSettings(): boolean  {
    return false;
  }

  public canEditUserDisabled(): boolean {
    return this.isAdmin() || this.isOrgAdmin();
  }

  public canEditUserFullName(): boolean {
    return this.isAdmin() || this.isOrgAdmin();
  }

  public canEditUserIsAdmin(): boolean {
    return this.isAdmin();
  }

  public canEditUserOrganization(): boolean {
    return this.isAdmin();
  }

  public canEditUserPhoneNumber(): boolean {
    return this.isAdmin();
  }

  public canExportConversations(): boolean {
    return true;
  }

  public canManageBilling(): boolean {
    return this.isAdmin() && this.getUserAndEnvironmentFeatureGrant("billing");
  }

  public canModifyUserAPITokens(): boolean {
    // TODO: TXBDEV-878 - Discuss adding this.getUserAndEnvironmentFeatureGrant("apiAccess");
    return this.isAdmin() &&  this.getUserAndEnvironmentFeatureGrant("crmRelay");  
  }

  public canModifyUserNumberSharing(): boolean {
    return this.isAdmin() || this.isOrgAdmin();
  }

  public canModifyUserPermissions(): boolean {
    return this.isAdmin(); 
  }

  public canModifyUserWebhookRelay(): boolean {
    return this.isAdmin() &&  this.getUserAndEnvironmentFeatureGrant("crmRelay");
  }

  public getUserGudeOverrides(): UserGuideConfig[] {
    return;
  }

  public getDebugUserRole(): string {
    if (this.isAdmin()){
      return "admin";
    }
    else{
      return "not admin";
    }
  }

  public getEditOwnProviderSettingsContact(): string {
    return environment.supportEmail
  }

  public getPlanSelectionDetails(): PlanSelectionProperties {
    return {
      choices: [],
      granularity: "none"
    }
  }

  public hasFullAdminRole(): boolean {
    return true;
  }

  public isSubscriptionActive(): boolean {
    return true;
  }

  public requiresEmailConfirmation(): boolean {
    return false;
  }

  public shouldForcePlanSelector(): boolean {
    return false;
  }

  public shouldShowSubscriptionStatus(): boolean {
    return false;
  }

  public showRetailBrandingDetails(): boolean {
    return false;
  }

  public canViewProviderDetails(): boolean {
    return this.isAdmin();
  }

  public canViewProviderName(): boolean { 
    return this.canViewProviderDetails()
  }

  public canViewMetadata(): boolean{
    return this.isUserFullOrSuperAdmin() 
  }

  public storeManagedByAttributeOnUsers(): boolean {
    return false;
  }

  public trimFirestoreOrgQuery(query: Query | CollectionReference): Query {
    if (this.isAdmin()) {
      return query;
    }
    if (this.isOrgAdmin())
    { 
      query = query.where('organizationAdmins', 'array-contains', this.service.currentFireauthUser.uid);
    }
    return query;
  }

  public trimFirestoreUserQuery(query: Query|CollectionReference): Query {
    if (this.isAdmin()) {
      return query;
    }
    if (this.isOrgAdmin)
    { 
      return query.where("organizationId","in", this.orgsUserManages);
    }
    return query;
  }

  public async updateUser(): Promise<void> {
    await super.updateUser();
    if (
      ! this.service.currentUserDocument.hasOwnProperty("email") 
    ) {
      throw new Error("Incomplete user profile")
    }
  }

  public usePrivateBackendForUserOperations(): boolean {
    return true;
  }

  

  // #endregion Public Methods (43)

  // #region Private Methods (1)

  private isAdmin() {
    return this.service.currentUserDocument.isAdmin;
  }

  // #endregion Private Methods (1)
}