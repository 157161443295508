import { Injectable } from '@angular/core';
import { JSONFormControlFieldDefinition } from '../components/json-form-control/json-form-control.component';
import { ProxyReceiveObject } from '../PopupCrossOriginDTOProxy';
import { BackendService } from './backend.service';
import { getLogger } from 'src/shared/logging';
const log = getLogger("IntegrationService");

export type IntegrationInstallation =   {
  InstallationName: string
  InstallationId: string
  /**
   * This is used as an identifier for the IntegrationInstaller to define how it should handle installation and instantiation
   * of the integration
   */
  InstallationBaseType:  string
  enabled: boolean
  platform: string
  AdminIntegrationId: string
   /**
   * Preferences are set by the user, and may be edited at any time by the user
   */
  preferences: IntegrationInstantiationProperties,
  /**
   * Properties are set by the system, on behalf of the user, and are not modifiable or exposed to the user, and 
   * may not be edited by the user
   */
  userProperties: IntegrationInstantiationProperties
  /**
   * The definition property of `IntegrationInstallation` is only present for Frontent instances of this object; 
   * It's not populated on the backend until the time the API request is made.  All backend instances of 
   */
  definition: IntegrationDefinition
}

/**
 * This interface should be extended by each integration implementation with 
 * the necessary properties to instantiate the integration
 */
 export interface IntegrationInstantiationProperties {
  [key:string]: any
}

/**
 * Many integrations have multiple installation types, each of which may have a discrete set
 * of properties required for that installation type
 * 
 * E.g. OAuth installations will have a different set of properties than an Auth Token or an API key based installation
 * 
 * 
 * This type provides a mechanism to communicate the fields the user must enter in order to complete the related installation type
 * 
 */
 export type IntegrationInstallationType = {
  name: string
  summary: string
  InstallationBaseType:  string
  fields: JSONFormControlFieldDefinition[],
  allowUserDefinedName: boolean
}

/**
 * 
 * 
 */
export type IntegrationDefinition = {
  name: string
  id: string
  description: string
  installationTypes: IntegrationInstallationType[]
}

export type InstallationRequest = {
  name: string
  id: string
  installationOptions: IntegrationInstantiationProperties
}

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
   private backendService: BackendService
  ) { }

  public async GetAvailableIntegrations(): Promise<IntegrationDefinition[]> {
    return (await this.backendService.backendGet("frontend/integrations/available")) as IntegrationDefinition[]
  }

  
  public async GetInstalledIntegrations(): Promise<IntegrationInstallation[]> {
    return (await this.backendService.backendGet("frontend/integrations/installed")) as IntegrationInstallation[];
  }

  /**
   * Executes the full OAuth application install for the given integration.  
   * 
   * This includes:
   *   *  Opening the popup
   *   *  Waiting for the user to complete the remote party's authentication/authorization
   *   *  Waiting for the OAuth callback  
   * 
   * @param backendRedirectURL 
   * @param integrationName 
   * @returns 
   */
  public async InstallOAuthIntegration(backendRedirectURL: string, integrationName: string) : Promise<{message:string ,color:string}> {
    return new Promise( (resolve,reject) => {
      /**
       * Prepare to receive an object back from the popup we're about to open below.
       */
     ProxyReceiveObject({},window)
      .then((data) =>{ 
        if (!data.metadata.debugMode) { 
          popup.window.close();
        }
        return resolve({
          message: "Installation successful!",
          color: "black"
        });
        
      })
      .catch((err) => {
        log.warn(err)
        popup.window.close()
        reject(err);
      })
  
     /** 
      * Open the popup before we actually have the URL to avoid popup blockers
      * 
      * since we have separate domains (and therefore auth cookes) between frontend
      * and backend, we can't simply open the popup to the PB URL here (which would allow a simple 302 redirect);
      * instead, we have to poll PB from the currently authenticated context, and the tell the popup to go to
      * the URL that PB gives us.
      * 
      * We also can't call the `window.open` function from within the await/async stack of the `backendGet`, since most
      * browsers will trap this popup attempt via the popup blocker.
      * 
     */
     let popup = window.open("about:blank","Self","width=600,height=500"); 
     popup.window.onload = () => {
       popup.document.body.innerHTML = "Loading installer for " + integrationName
     }
     this.backendService.backendGet(backendRedirectURL).then( (d: {installURL: string})=>{
       popup.location.href = d.installURL;
     },
     (err) => {
        popup.window.close()
        reject(err.error);
     })
    });
  }

  public async InstallIntegration(platform: string, installationRequest: any) {
    return this.backendService.backendPost("frontend/integrations/" +  platform + "/install", {}, {
      installationRequest: installationRequest
    })
  }

  public async UninstallIntegration(platform: string, installationId: string) {
    return this.backendService.backendPost("frontend/integrations/" + platform + "/uninstall", {}, {
      InstallationId: installationId
    })
  }

  public async UpdateIntegration(platform: string, installationId: string, updateRequest: any) {
    return this.backendService.backendPost("frontend/integrations/" +  platform + "/" + installationId + "/update", {}, {
      updateRequest: updateRequest
    })
  }


}
