export interface XHRResponseEventTarget extends EventTarget {
  response: any;
  responseURL: string
  responseType: XMLHttpRequestResponseType;
  responseText: string;
  responseXML: Document | null;
  status: number;
  statusText: string;
}



const originalOpen = XMLHttpRequest.prototype.open;

/**
 * Monkey-patches XMLHttpRequest to spy on all requests.
 * 
 * Once a request to the supplied URL completes, the promise resolves, 
 * and the monkey-patch is removed.
 * 
 * @param url 
 * @returns 
 */
export function WaitForURLToLoad(url: string): Promise<XHRResponseEventTarget> {
  return new Promise((resolve,reject) => {
    /** @ts-ignore */
    XMLHttpRequest.prototype.open = function(method, url, async, user, pass) {
      this.addEventListener('loadend', function(event: Event) {
        const t = event.target as XHRResponseEventTarget
        if(t.responseURL == url) {
          XMLHttpRequest.prototype.open = originalOpen
          return resolve(t);
        }
      });
      return originalOpen.apply(this, arguments);
    };
  });
}