
<div [ngClass]="{
    'msg-in': message.direction == 'in',
   'msg-out': message.direction == 'out',
    'msg-note': message.direction == 'note'
  }" style="white-space: pre-line;" (click)="clickMessage(message)">
  <div *ngIf="message.body!='(Image Attached)'"
    [innerHTML]="message.body | linky:{className: 'linkyClass', stripPrefix: false}">
  </div>
  <ng-container *ngFor="let attach of message.attachments">
    <ng-container *ngTemplateOutlet="mmsTemplate;context:{attach: attach}"></ng-container>
  </ng-container>
  <ng-container *ngTemplateOutlet="status.send_status == 'scheduled' ? scheduledStatusFooter : regularStatusFooter">
  </ng-container>
</div>
<div style="clear:both;"></div>


<ng-template #regularStatusFooter>
  <div *ngIf="message.direction !== 'note'" class="sendStatus" nz-popover nzPopoverTrigger="click"
    [nzPopoverTitle]="deliverabilitytitle" [nzPopoverContent]="deliverabilitydetail" nzPopoverPlacement="topRight">
    {{ message.direction == 'note' ? 'Note' : status.message }}
    <ng-container *ngIf="status.showRetry">
      | <a (click)="retrySend(message.firebase_document_id)">Retry <i nz-icon nzType="redo" nzTheme="outline"></i></a>
    </ng-container>
  </div>
  <div *ngIf="message.direction == 'note'" class="sendStatus" style="cursor: default;">
    Note
  </div>

  <nz-tag 
    *ngIf="message.direction=='in' && (message.metadata?.groupMMSBeta)" 
    class="betaGroupMMS" 
    nz-popover
    nzPopoverTrigger="click"
    nzPopoverPlacement="left"
    [nzPopoverTitle] = "null"
    [nzPopoverVisible] = "deprecatedGropuMMSPopoover"
    [nzPopoverContent] = "groupMMSBeta"
    nzPopoverOverlayClassName="groupMMSBetaOverlay"
    nzColor="green"> Group MMS [Beta]
  </nz-tag>
  <div style="font-size:10px;">
    <span *ngIf="message.direction == 'out'">{{status.by}} at </span>
    <span *ngIf="message.direction == 'note'">{{message.sentByLabel}} at </span>
    {{message.date | date:"short"}}
  </div>
</ng-template>

<ng-template #scheduledStatusFooter>
  <div class="sendStatus"
  >
    <i *ngIf="message.reminder" class="far fa-calendar" style="margin-right:0;"></i>
    <i *ngIf="!message.reminder" class="far fa-tint" style="margin-right:0;"></i>
    Scheduled for {{message.date | date:"short"}} 
    <nz-divider nzType="vertical"></nz-divider>
    <a nz-dropdown [nzDropdownMenu]="scheduleMenu"> <i class="far fa-bars"></i> </a>
    <nz-dropdown-menu #scheduleMenu="nzDropdownMenu" [nzPlacement]="'topRight'">
      <ul nz-menu nzSelectable>
          <li nz-menu-item (click)="openScheduledModal(message)">
              <a> 
                <i class="far fa-edit" ></i>Edit
              </a>
            </li>
            <nz-modal [(nzVisible)]="editScheduledModal" nzTitle="Edit Message Date" (nzOnCancel)="editScheduledModal=false" [nzFooter]="editScheduledModalFooter">
              <ng-container *nzModalContent>
                <form nz-form nzLayout="vertical" [formGroup]="frmEditScheduled"
                  >
                  <nz-form-item>
                    <nz-form-label>Time</nz-form-label>
                    <nz-form-control >
                      <tz-date-time-picker
                        [formGroup]="frmEditScheduled"
                        timeZoneFieldName="scheduleTimezone"
                        dateFieldName="date"
                        ></tz-date-time-picker>
                    </nz-form-control>
                  </nz-form-item><br/>
                  <span *ngIf="frmEditScheduled.errors?.dateError" style="color: red;">Please select a date in the future.</span>
                </form>
              </ng-container>
              <ng-template #editScheduledModalFooter>
                  <button style="float: left" nz-button nzType="danger" nz-popconfirm nzPopconfirmTitle="Are you sure you want to cancel this scheduled message?" (nzOnConfirm)="deleteMessage(frmEditScheduled.value.id)" [nzLoading]="isLoading">Delete</button>
                  <button nz-button nzType="primary" [disabled]="!frmEditScheduled.valid" (click)="updateScheduledMessage()" [nzLoading]="isLoading">Save</button>
              </ng-template>
            </nz-modal>
        <li nz-menu-item nz-popconfirm nzPopconfirmTitle="Are you sure you want to cancel this scheduled message?" (nzOnConfirm)="deleteMessage(message.firebase_document_id)">
          <a> 
            <i class="far fa-trash"></i>Delete
          </a>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <div style="font-size:10px;">{{status.by}}</div>
</ng-template>

<ng-template #deliverabilitytitle>
  <div nz-row>
    <div nz-col nzSpan="7">Deliverability Details</div>
    <div nz-col nzSpan="10" style="text-align: center"><b>{{message.from}} => {{message.to}}</b></div>
    <div nz-col nzSpan="7" style="text-align: right">
      <ng-container *ngIf="applicationContext.debugMode">
        <a target="_blank" href="https://console.firebase.google.com/project/{{ environment.firebase.projectId }}/firestore/data/~2Fmessages~2F{{ message.firebase_document_id }}">Open in Firestore</a>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #groupMMSBeta>
  <ng-template #groupMMSBetaTitle>
    Group MMS Support is in Beta<br/>
    <small style="float:left">Some features may not work, or are only partially implemented.</small>
  </ng-template>
  <nz-card [nzTitle]="groupMMSBetaTitle" nzBorderless="true" class="groupMMSBetaCard">
    <nz-collapse>
      <nz-collapse-panel nzHeader="Inbound messages" nzActive="panel.active">
        <p>
          Not all carriers / providers support Group MMS, and those that do have variations in implementations.  Messages in a conversation may be missing, delayed, or displayed in unexpected locations.
        </p>
      </nz-collapse-panel>
      <nz-collapse-panel nzHeader="Replying to messages" nzActive="panel.active">
        <p>We currently do not support replying to the whole group; instead, replies will be sent to the currently active contact - in this case: <inline-contact [contact]="message.from"></inline-contact>
        <p>Other group members <b>will not receive</b> messages you send.</p>
      </nz-collapse-panel>
      <nz-collapse-panel nzHeader="Other members in this group" nzActive="panel.active">
        <p>While replies will not be sent to the group as a whole, you can still reply indidvidually to the group members.</p>
        <p><b>Not all carriers / providers deliver complete group memberhsip information.</b></p>
        <ng-container *ngIf="message.metadata?.otherRecipients?.length > 0; else noOtherRecipients">
          <p>The phone numbers below also received a copy of this message:</p>
          <ul>
            <li *ngFor="let c of message.metadata?.otherRecipients">
              <inline-contact [contact]="c"></inline-contact>
            </li>
            <li *ngFor="let c of message.metadata?.invalidRecipients">
              <inline-contact [contact]="c"></inline-contact>
            </li>
          </ul>
        </ng-container>
        <ng-template #noOtherRecipients>
          No other recipients were supplied by the provider for this message.
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse> 
  </nz-card>
</ng-template>

<ng-template #deliverabilitydetail >

  <div [ngClass]="{'deliverabilityDetail': true, 'deliverabilityDetailWide':eventDataView }">
    <nz-tabset>
      <nz-tab nzTitle="Events">
        <ng-container *ngIf="!messageEvents">
          No deliverability data exists for this message.
        </ng-container>  
        <ng-container *ngIf="messageEvents">
          <div nz-row>
            <div nz-col nzFlex="8" >
              <nz-timeline>
                <ng-container *ngFor="let event of messageEvents; let i = index">
                  <nz-timeline-item 
                    [nzColor]="getEventColor(event)" 
                    [nzLabel]="formatTime(event.timestamp)"
                    >
                    <ng-container *ngIf="authnz.currentSecurityProvider.canViewDLRObject()">
                      <a (click)="selectMessageEvent(event,i)" [ngClass]="{'selectedTimelineEvent': selectedTimelineEvent == i}">{{ formatAction(event) }}</a>
                    </ng-container>
                    <ng-container *ngIf="!authnz.currentSecurityProvider.canViewDLRObject()">
                      {{ formatAction(event) }}
                    </ng-container>
                  </nz-timeline-item>
                </ng-container>
              </nz-timeline>
            </div>
            <div nz-col nzFlex="16" class="eventDataView" *ngIf="eventDataView">
              <ngs-code-editor
                theme="vs-dark"
                [codeModel]="eventDataCodeModel"
                [options]="codeEditorOptions"
                readOnly="true"
                >
              </ngs-code-editor>
            </div>
          </div>
        </ng-container>
      </nz-tab>
      <nz-tab nzTitle="Metadata" *ngIf="showMetatdata()">
        <ng-container *ngIf="!message.metadata">
          No metadata exists for this message.
        </ng-container>  
        <ng-container *ngIf="message.metadata">
          <nz-table #metadataTable [nzData]="formatMetadata(message.metadata)">
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of metadataTable.data">
                <td>{{data.key}}</td>
                <td>{{data.value}}</td>
              </tr>
            </tbody>
          </nz-table>
        </ng-container>  
      </nz-tab>
    </nz-tabset> 
  </div>

</ng-template>


<ng-template #mmsTemplate let-attach="attach">
  <!-- TODO: OTHER types download as a jpg https://textable.atlassian.net/browse/TXBDEV-945-->
  <div style="margin: 12px">

  </div>
  <!-- Image-->
  <div *ngIf="mms.isImageFile(attach.contentType)" [ngClass]="{'mms-preview-wrapper': message.attachments.length >= 1}">
    <img  class="mms-preview" [src]="mms.getAttachmentURL(attach)" (load)="attach.loaded=true" (click)="openPhoto(attach)">
  </div>
  <!-- Video-->
  <div *ngIf="mms.isVideoFile(attach.contentType)">
    <nz-row>
      <nz-col  nzSpan="23" class="mms-preview-wrapper">
          <video *ngIf="attach.contentType=='video/mp4'" class="video-player" disablepictureinpicture controls controlsList="nodownload noplaybackrate">
            <source [src]="mms.getAttachmentURL(attach)">
          </video>
      </nz-col>
      <nz-col nzSpan="1" class="mms-preview-wrapper">
        <a nz-dropdown [nzDropdownMenu]="videoMenu">  
          <i nz-icon nzType="more" nzTheme="outline" style="font-size:24px"></i>
        </a>
        <nz-dropdown-menu #videoMenu="nzDropdownMenu" nzPlacement="topRight" class="mms-dropup">
          <ul nz-menu nzSelectable>
            <li nz-menu-item >
              <a (click)="mms.downloadAttachment(attach)"> 
                <i nz-icon nzType="download"></i> Download
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-col>
    </nz-row>
  </div>

  <!-- Audio -->
  <ng-container *ngIf="mms.isAudioFile(attach.contentType)">
      <nz-row>
          <nz-col nzSpan="23" class="mms-flat-60">
            <audio style="width: 100%" controls [src]="mms.getAttachmentURL(attach)"  class="mms-dropup" controlsList="nodownload noplaybackrate">
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </nz-col>
          <nz-col nzSpan="1" class="mms-flat-60">
            <a nz-dropdown [nzDropdownMenu]="audioMenu">  
              <i nz-icon nzType="more" nzTheme="outline" style="font-size:24px"></i>
            </a>
            <nz-dropdown-menu #audioMenu="nzDropdownMenu" nzPlacement="topRight" class="mms-dropup">
              <ul nz-menu nzSelectable>
                <li nz-menu-item >
                  <a (click)="mms.downloadAttachment(attach)"> 
                    <i nz-icon nzType="download"></i> Download
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </nz-col>
        </nz-row>
  </ng-container>

  <!-- PDF + Other files-->
  <div class="download-mms" *ngIf="mms.isOtherFile(attach.contentType)">
    <nz-row nzGutter="16"> 
      <nz-col style="overflow-wrap: anywhere;" nzSpan="20">
          <b>{{attach.filename}}</b><br>
          <i><small> ({{ mms.getFileSize(attach.contentLength) }})</small></i>
      </nz-col>
      <nz-col nzSpan=4>
          <button  (click)="mms.downloadAttachment(attach)" nz-button nzType="primary" nzSize="large" style="float: right;width: 90%;" class="download-button-margin">
              <i nz-icon nzType="download"></i>
          </button>
      </nz-col>
    </nz-row>

  </div>
</ng-template>