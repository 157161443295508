import { Injectable } from '@angular/core';
import { AuthNZService } from '../core/authnz.service';
import { BackendService } from './backend.service';
import * as log from 'loglevel';

/**
 * This interface is defined in both privatefrontend and privatebackend.  
 * Any changes to either copy of ths interface must be made in both repositories
 */

export interface AdminToolParameter {
  name: string
  type: "string" | "boolean" | "date" | "user"
  description: string
  required: boolean
}
/**
 * This interface is defined in both privatefrontend and privatebackend.  
 * Any changes to either copy of ths interface must be made in both repositories
 */
export interface AdminToolDefinition {
  name: string,
  description: string,
  parameters: AdminToolParameter[]
}

/**
 * This interface is defined in both privatefrontend and privatebackend.  
 * Any changes to either copy of ths interface must be made in both repositories
 */
export interface AdminToolResponse {
  resultStatus: "sucess"|"fail"|"warn",
  resultRecordsAffected: number,
  resultText: string
}


/**
 * This interface is just for frontend
 */
export interface FrontendAdminTool extends AdminToolDefinition {
  run: (params: {[key:string]:any}) => Promise<AdminToolResponse>
}

export interface AdminToolResponse {
  resultStatus: "sucess"|"fail"|"warn",
  resultRecordsAffected: number,
  resultText: string
}

@Injectable({
  providedIn: 'root'
})
export class AdminToolsService {

  constructor(
    private backendService: BackendService,
    private authnz: AuthNZService
  ) {}

  async GetTools(): Promise<FrontendAdminTool[]> { 

    const tools = await this.backendService.backendGet("frontend/admin-tools") as {
      tools: FrontendAdminTool[]
    }

    return tools.tools.map(t => {
      t.run = (params) => this.run(t,params)
      return t;
    });

  }

  run = async (tool: FrontendAdminTool, params: {[key:string]:any}): Promise<AdminToolResponse> => {

    const result = await this.backendService.backendPost("frontend/admin-tools/run",
    {},
    {
      toolName: tool.name,
      toolParams: params
    });

    return result.result as AdminToolResponse

  }

}
