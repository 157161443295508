<form nz-form nzLayout="vertical" [formGroup]="frmAddUser" (ngSubmit)="addNewUser()">
  <nz-form-item>
    <nz-form-label nzRequired>Full Name</nz-form-label>
    <nz-form-control 
      nzHasFeedback
      nzErrorTip="Please enter user's full name.">
      <input nz-input formControlName="full_name" placeholder="John Doe" type="text" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="this.samlDef == true">
    <nz-form-label>External Identifier</nz-form-label>
    <nz-form-control
      nzExtra="For users who sign in with SSO, an identifier may be required to link the external user account.">
      <input nz-input formControlName="external_id" type="text" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzRequired>E-mail</nz-form-label>
    <nz-form-control 
      nzHasFeedback
      nzErrorTip=" Please enter user's e-mail address."
      >
      <input nz-input formControlName="email" placeholder="john.doe@gmail.com" type="email"/>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Organization&nbsp; <a (click)="btnAddOrg()">Add Organization +</a></nz-form-label>
    <nz-form-control 
      nzHasFeedback
      nzErrorTip="Please select an organization to add the user to."
      >
      <nz-select formControlName="organizationId">
        <nz-option *ngFor="let org of orgs$" nzValue="{{org.id}}" nzLabel="{{org.organizationName}}" [nzDisabled]="org.is_disabled"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <div nz-row>
    <div nz-col nzSpan="12">
      <nz-form-item *ngIf="this.authnz.currentSecurityProvider.allowProviderCopyOnUserCreate()">
        <nz-form-label nzRequired>Use my provider?</nz-form-label>
        <nz-form-control>
          <nz-switch formControlName="copyProviderCredentials" (ngModelChange)="changeCopyProviderCredentials($event)"></nz-switch>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
        <app-provider-editor [baseFormGroup]="frmAddUser" [baseDocument]="SourceProviderDocument" baseFormType="user"
          [disabled]="disableProviderEntry">
        </app-provider-editor>
    </div>
  </div>
  
  <div nz-row>
    <div nz-col nzSpan="24">
      <app-did-input formControlName="phone_number"
        [providerId]="frmAddUser.get('provider').value"
        [userformValues]="frmAddUser">
      </app-did-input>
    </div>
  </div>
    


  <div nz-row *ngIf="this.authnz.currentSecurityProvider.hasFullAdminRole()">
    <div nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzRequired>Is Full Admin?</nz-form-label>
        <nz-form-control>
          <nz-switch formControlName="isAdmin"></nz-switch>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <button nz-button nzType="primary" nzBlock [nzLoading]="isNewUserSaving" [disabled]="!frmAddUser.valid">
    Add New User
  </button>
</form>