import { Component, OnInit } from '@angular/core';
import { TextableUser } from 'src/app/backported_types/users';
import { AuthNZService } from 'src/app/core/authnz.service';
import { NumberSharingService } from 'src/app/services/number-sharing.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';

@Component({
  selector: 'app-number-switcher',
  templateUrl: './number-switcher.component.html',
  styleUrls: ['./number-switcher.component.scss']
})
export class NumberSwitcherComponent implements OnInit {

  /**
   * determines whether to show the loading template or the number switcher template
   */
  public showType: 0 | 1  = 0;

  public showNumberPicker: boolean;
  public preferredNumber: string = "";

  constructor(
    public authnz: AuthNZService,
    public numberSharing: NumberSharingService,
    private userPreferences: UserPreferencesService
  ){
    this.numberSharing.numbersSharedWithLoggedInUser$.subscribe(sn=>{
      this.showType = 1
    })

    this.preferredNumber = userPreferences.getLocalPreference("preferredActiveNumber");
    
  }

  ngOnInit() {  }

  public pickNumber(num: TextableUser) { 
    this.showNumberPicker = false;
    this.authnz.setActiveNumber(num.id);
  }

  public setPreferredNumber(num: string) {
    const newPreferred = num == this.preferredNumber ? null : num
    this.userPreferences.setLocalPreference("preferredActiveNumber",newPreferred);
    this.preferredNumber = newPreferred;
  }
}
