import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TextableContact } from 'src/app/backported_types/contact';
import { MessagingService } from 'src/app/services/messaging.service';
import { MmsService } from 'src/app/services/mms.service';

export interface FrontendAttachment {
  contentType: string;
  contentLength: number;
  name: string;
  /**
   * Stores a Base64 encoded string containing the user-supplied attachment content
   *
   * Populated via: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
   *
   */
  data?: string;
  scaledSize?: number;
  filename?:string;
  url?: string
}

export enum MMSUploaderMode{
  COMPOSE = 'compose',
  SEND = 'send'
}

@Component({
  selector: 'app-mms-uploader',
  templateUrl: './mms-uploader.component.html',
  styleUrls: ['./mms-uploader.component.scss'],
})
export class MmsUploaderComponent implements OnInit {
  @ViewChild('attachment') attachment: ElementRef;
  @Input() isVisible: boolean = false;
  @Input() contact: TextableContact = null;
  @Input() mode:string = MMSUploaderMode.SEND;
  @Output() closeMMSUploaderEvent = new EventEmitter();

  public isUploading: boolean = false;
  public messageAttachment: FrontendAttachment = null;
  public newMessage: string = '';
  /**
   * In cases where a resize is required, we need to block the upload 
   * until the resize operation completes.
   */
  public isMessageAttachmentLoading = false;

  constructor(
    public mms: MmsService,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {}

  /**
   * Sends a message with an attachment,
   * then emits an event to close and reset the component's fields.
   */
  public async send() {
    this.isUploading = true;
    await this.messagingService.send({
      contact: this.contact, 
      newMessage: this.newMessage, 
      messageAttachment: this.messageAttachment as FrontendAttachment
    });
    this.closeMMSModal(true);
  }
  /**
   * Emits the savedAttachments with the closeModal output.
   */
  public async saveCompose(){
    this.isUploading = true;
    const savedAttachments = await this.messagingService.createFirestoreAttachmentObject(this.messageAttachment)
    this.closeMMSModal(true,savedAttachments)
  }
  /**
   * Determines the uploader mode. Send or Compose
   */
  public isSendMode():boolean{
    return this.mode === MMSUploaderMode.SEND
  }

  public async onPaste(event: ClipboardEvent) {
    // Update the attachment if onPaste gives us one.  Otherwise, use what's already in place
    this.messageAttachment = (await this.mms.onPaste(event, this.messageAttachment !== null)) || this.messageAttachment
  }

  async handleUpload(files) {
    this.isMessageAttachmentLoading = true
    this.messageAttachment = await(this.mms.createTextableAttachmentFromFiles(files))
    this.isMessageAttachmentLoading = false;
  }

  closeMMSModal(isSaving, messageAttachment?) {
    this.closeMMSUploaderEvent.emit(isSaving ? messageAttachment : null)
    this.isVisible = false;
    this.messageAttachment = null;
    this.newMessage = '';
    this.isUploading = false;
  }
}
