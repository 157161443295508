  <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle" class="loginBody" *ngIf="this.pageToShow=='Login'">
    <div nz-col nzSpan="10">
        <nz-card class="border-radius-10px">
        <img [src]="environment.logoPath" style="width:250px;display:block;margin:20px auto;">
        <ng-template #prefixUser><i nz-icon nzType="user"></i></ng-template>
        <ng-template #prefixLock><i nz-icon nzType="lock"></i></ng-template>
        <nz-tabset>
          <nz-tab nzTitle="Log in">
            <form nz-form nzLayout="vertical" [formGroup]="frmLogin" class="login-form pad-25" (ngSubmit)="login()">
              <nz-form-item>
                <nz-form-control
                  nzErrorTip="Please enter your e-mail address.">
                  <nz-input-group [nzPrefix]="prefixUser">
                    <input type="text" nz-input formControlName="emailAddress" placeholder="john.doe@gmail.com" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control
                  nzErrorTip="Please enter your password."
                  >
                  <nz-input-group [nzPrefix]="prefixLock">
                    <input type="password" nz-input formControlName="password" placeholder="**********" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <ng-container *ngIf="loginError" [nzGutter]="16">
                <nz-alert class="error-message" nzType="error" [nzMessage]="errorMessage"></nz-alert>
              </ng-container>
              <nz-form-item>
                <nz-form-control>
                  <div nz-row nzJustify="center" [nzGutter]="[0, 4]"> 
                    <div nz-col [nzXs]="{ span: 24 }" [nzSm]="{ span: 24 }" [nzMd]="{ span: 24 }" [nzLg]="{ span: 11 }">
                      <button *ngIf="!isLoading" nz-button [nzType]="'primary'" nzBlock>Log in</button>
                      <button *ngIf="isLoading" nz-button [nzType]="'primary'" [nzLoading]="isLoading">Logging in...</button>
                    </div>
                    <div class="login-form-forgot-container" nz-col nzSpan="11" [nzXs]="{ span: 24 }" [nzSm]="{ span: 24 }" [nzMd]="{ span: 24 }" [nzLg]="{ span: 11 , offset: 2}">
                      <button nz-button (click)="modalForgotPassword=true;" nzBlock>Forgot password?</button>
                    </div>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </form>
            <ng-container *ngIf="this.authProviderLoginButtons.length > 0 ">
              <nz-divider nzText="OR"></nz-divider>
              <ng-container  *ngFor="let item of this.authProviderLoginButtons">
                <div nz-row>
                  <div nz-col nzSpan="16" nzOffset="4">
                    <button (click)="providerLogin(item)" nz-button [ngStyle]="{'background-color': item.color, 'color':'white', 'width':'100%'}" >Login with {{item.name}}</button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="ssoErrorMessage">
                <br/>
                <div nz-row>
                  <div nz-col nzSpan="16" nzOffset="4">
                    <nz-alert nzType="error" [nzMessage]="ssoErrorMessage"></nz-alert>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          
          </nz-tab>
          <nz-tab nzTitle="Sign up" *ngIf="this.authnz.currentSecurityProvider.showRetailBrandingDetails()">
            <form nz-form nzLayout="vertical" [formGroup]="frmSignUp" class="login-form pad-25" (ngSubmit)="signup()">
              <div nz-row nzGutter="8">
                <div nz-col nzSpan="24">
                  <b style="font-size: 24px;text-align:center">Welcome to Textable</b><br>
                  <span style="text-align:center">You'll be able to select your plan after creating an account.</span><br>             
                  <span style="text-align:center"><a href="https://www.textable.co/our-pricing/" target="_blank" style="text-align:center" >Click here to view our plans &amp; pricing</a></span><br><br>
                  <span style="text-align:center">Please enter an e-mail address and a password to begin.</span><br><br>
                </div>
              </div>
              <div nz-row nzGutter="8">
                <div nz-col nzSpan="24">
                  <nz-form-item>
                    <nz-form-label>E-mail</nz-form-label>
                    <nz-form-control>
                      <input type="email" nz-input formControlName="email" placeholder="john.doe@gmail.com"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row nzGutter="8">
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label>Password 
                       <span nz-tooltip nzTooltipTitle="Please enter a password containing at least 8 characters, 1 lowercase and uppercase letter, and 1 number."><i nz-icon nzType="info-circle" nzTheme="outline"></i></span> 
                    </nz-form-label>
                    <nz-form-control>
                      <input type="password" nz-input formControlName="password" placeholder="**********"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                  <nz-form-item>
                    <nz-form-label>Confirm Password</nz-form-label>
                    <nz-form-control>
                      <input type="password" nz-input formControlName="cPassword"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row nzGutter="8" *ngIf="frmSignUp.get('cPassword').touched && !frmSignUp.get('cPassword').valid">
                <nz-alert nzType="error" nzMessage="Passwords must match and contain at least 8 characters, 1 lowercase, and 1 uppercase letter." nzShowIcon></nz-alert>
              </div>
              <ng-container *ngIf="signupError">
                <nz-alert nzType="error" [nzMessage]="signupErrorMessage"></nz-alert>
              </ng-container>
              <br/>
              <nz-form-item>
                <nz-form-control>
                  <button *ngIf="!isLoading" nz-button [nzType]="'primary'">Sign up</button>
                  <button *ngIf="isLoading" nz-button [nzType]="'primary'" [nzLoading]="isLoading">Signing Up...</button>
                </nz-form-control>
              </nz-form-item>
            </form>
          </nz-tab>
        </nz-tabset>
      </nz-card>
      <ng-container *ngIf="this.authnz.currentSecurityProvider.showRetailBrandingDetails()">
        <div class="footer-links">
          <a href="https://app.termly.io/document/privacy-policy/792c4805-5765-42a5-8002-4e13d0d1266a" target="_blank">Privacy Policy</a> | <a href="https://app.termly.io/document/terms-of-use-for-saas/bb798c32-7f5e-4286-b1fa-8779c1e5603e" target="_blank">Terms of Service</a>
        </div>
      </ng-container>
    </div>
  </div>

  <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle" class="loginBody" *ngIf="this.pageToShow=='Loading'">
    <div nz-col nzSpan="10">
        <nz-card class="border-radius-10px">
        <img [src]="environment.logoPath" style="width:250px;display:block;margin:20px auto;">
      
        <div class="loading-wrapper">
            <ng-template #indicatorTemplate><i nz-icon nzType="loading" style="font-size: 36px;"></i> </ng-template>
            <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
        </div>
      </nz-card>
    </div>
  </div>

  <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle" class="loginBody" *ngIf="this.pageToShow=='EmailVerification'">
    <div nz-row nzType="flex" nzJustify="space-around" nzAlign="middle" class="loginBody">
      <div nz-col nzSpan="10">
        <nz-card nzTitle="Verify E-mail Address">
          <p>We have sent a verification link to your e-mail address ({{user.email}}). Please check your e-mail and click the link to verify your account.</p>
          <ol>
            <li>Check your e-mail inbox for verification. Click link in e-mail to verify.</li>
            <li>Return to this page and click "Continue - I've verified my e-mail address.".</li>
          </ol>
          <h3>Didn't receive a verification e-mail?</h3>
          <p style="padding-top:0;"><a (click)="sendVerificationEmail()">Click here to request a verification e-mail.</a></p>
          <div style="padding:2em;">
            <button nz-button [nzSize]="'large'" nzType="primary" [disabled]="" nzBlock (click)="reloadPage()">Continue - I've verified my e-mail address.</button>
            <br>
            <br>
            <button nz-button [nzSize]="'large'" nzType="default" [disabled]="" nzBlock (click)="logout()">Logout</button>
          </div>
          <h3 style="padding-bottom:0;">Can't access the e-mail listed above?</h3>
          <div style="padding:0 2em 2em 2em;">
            <form nz-form nzLayout="vertical" [formGroup]="frmUpdateEmail" (ngSubmit)="updateEmailAddress()">
              <nz-form-item>
                <nz-form-control>
                  <label>Please enter a new e-mail address.</label>
                  <input type="email" nz-input formControlName="emailAddress" placeholder="john.doe@gmail.com" />
                </nz-form-control>
              </nz-form-item>
              <button nz-button nzType="primary" [disabled]="!frmUpdateEmail.valid">Change & Verify E-mail Address</button>
            </form>
          </div>
        </nz-card>
      </div>
    </div>
  </div>


<nz-modal  
  [nzOkText] = 'null'
  (nzOnCancel) = "BlockedPopupFunction=null"
  [nzVisible]="BlockedPopupFunction !== null">
  <ng-container *nzModalContent>
    <p>Your browser's blocker has prevented this site from completing SSO.</p>
    <p>To enable future SSO, please allow popups for this site</p>
    <br/>
    <a href="javascript:void(0);" (click)="BlockedPopupFunction()">Please click here to continue</a>
  </ng-container>

</nz-modal>

<nz-modal
  [(nzVisible)]="modalForgotPassword"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="modalForgotPassword=false;frmResetPassword.reset();"
>
  <ng-template #modalTitle>
    Request a Password Reset
  </ng-template>

  <ng-template #modalContent>
    <form nz-form nzLayout="vertical" [formGroup]="frmResetPassword" class="login-form pad-25" (ngSubmit)="login()">
      <nz-form-item>
        <nz-form-control
          nzErrorTip="Please enter your e-mail address."
          >
          <label>Please enter your e-mail address.</label>
          <nz-input-group [nzPrefix]="prefixUser">
            <input type="text" nz-input formControlName="emailAddress" placeholder="john.doe@gmail.com" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <ng-container *ngIf="resetError">
        <nz-alert nzType="error" [nzMessage]="errorMessage"></nz-alert>
      </ng-container>
      <ng-container *ngIf="successMessage">
        <nz-alert nzType="success" [nzMessage]="successMessage"></nz-alert>
      </ng-container>
    </form>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="modalForgotPassword=false;frmResetPassword.reset();">Cancel</button>
    <button nz-button nzType="primary" (click)="resetPassword()" [nzLoading]="isRequestingPassword" [disabled]="!frmResetPassword.valid">Request Password Reset</button>
  </ng-template>
</nz-modal>

