import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

/**
 * Triggers validation for another field
 * 
 * https://angular.io/guide/form-validation#defining-custom-validators
 * @returns 
 */
export function ValidateField(otherFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.hasOwnProperty("value") || control.value == null) { 
      return null;
    }
    const otherControl = control.parent.get(otherFieldName)
    otherControl.updateValueAndValidity();
    return null;
  };
}