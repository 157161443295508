import { environment } from "src/environments/environment";

/**
 * Initialized to all features which can be restricted on a per-user or per-environment (in `environment.ts`) basis
 * 
 * When the component loads, this list is mutated to exclude features which have been disallowed in the current environment.
 * 
 * The final state of this list is used as the source of the checkboxes in the user permissions page.
 */
export const RestrictList = [
    {
        label: 'Blasts',
        value: 'blasts',
        checked: true,
        ignoreEnvFeatures: false,
        disabled: false
    },
    {
        label: 'Reminders',
        value: 'reminders',
        checked: true,
        ignoreEnvFeatures: false,
        disabled: false
    },
    {
        label: 'Drip Campaign',
        value: 'dripCampaign',
        checked: true,
        ignoreEnvFeatures: false,
        disabled: false
    },
    {
        label: 'Import Contacts',
        value: 'importContacts',
        checked: true,
        ignoreEnvFeatures: false,
        disabled: false
    },
    {
        label: 'Contact Lists',
        value: 'lists',
        checked: true,
        ignoreEnvFeatures: false,
        disabled: false
    },
    {
        label: 'Delete All Contacts',
        value: 'deleteAll',
        checked: environment.disableDelete ? false : true,
        ignoreEnvFeatures: false,
        disabled: environment.disableDelete ? true : false
    },
    {
        label: 'Delete Contacts and Messages',
        value: 'disableDelete',
        checked: environment.disableDelete ? false : true,
        ignoreEnvFeatures: true,
        disabled: environment.disableDelete ? true : false
    },
    {
        label: 'Integrations',
        value: 'integrations',
        checked: true,
        ignoreEnvFeatures: false,
        disabled: false
    },
    {
        label: 'Manage Billing',
        value: 'billing', 
        checked: true,
        ignoreEnvFeatures: true,
        disable:false,

      }
];