<nz-row>
        <nz-col nzSpan="24" class="media-holder">
                <img [src]="src" (click)="doZoom()" [ngClass]="{ 'zoom': isZoomed, 'default': !isZoomed }">
        </nz-col>
</nz-row>
<nz-row>
        <nz-col>
                <button style="margin-top:8px" (click)="mms.downloadAttachment(attachment)"
                        nzBlock nz-button nzType="primary">
                        <i nz-icon nzType="download" style="font-size: 18px;"></i>
                        Download
                </button>
        </nz-col>
</nz-row>