<nz-collapse>
  <nz-collapse-panel
    [nzHeader]="header"
    class = "keywordPanel"
  >
    Keywords should be a single short word. Received messages will only trigger a keyword if the entire message exactly matches the keyword.
    <br/><br/>
    For example, the message &quot;Please subscribe me to promotional notifications&quot; will NOT trigger the keyword &quot;promotion&quot;
  </nz-collapse-panel>
</nz-collapse>


<ng-template #header>
  <span nz-icon nzType="info-circle" nzTheme="outline"></span> Choosing Keywords
</ng-template>


