import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as log from 'loglevel';
import { ServiceAccountService, TextableServiceAccountCreationRequest, TextableServiceAccountCreationResponse, TextableServiceAccountTokenUI } from 'src/app/services/service-account-service.service';

@Component({
  selector: 'app-service-accounts',
  templateUrl: './service-accounts.component.html',
  styleUrls: ['./service-accounts.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class ServiceAccountsComponent implements OnInit {

  /**
   * Stores the array of service accounts to be displayed in the list view.
   */
  public serviceAccounts: TextableServiceAccountTokenUI[]
  /**
   * Controls whether the new account creation modal is visible
   */
  public showNewAccountCreationModal: boolean = false
  /** 
   * Form Group for collecting user-supplied details about the account to create
   */
  public frmAddServiceAccount: FormGroup;
  /**
   * True when a save operation is pending
   */
  public isServiceAccountSaving: boolean = false
  /**
   * Backend-provided list of possible scopes to be issued with new token creation
   */
  public scopes: string[]
  /**
   * Date format used in the Expiration Date Picker
   */
  public dateFormat = 'long';

  /**
   * Trackes the state of whether the UI should show the account creation Request form or the Response data.
   */
  public AccountCreationStatus: string

  /**
   * 
   */
  public ServiceAccountCreationResponse: TextableServiceAccountCreationResponse;

  /**
   * String to show the status of copying the token to clipboard
   */
  public copiedToClipboard: string

  /**
   * String displayed on the account creation form when a server-side error occurs while creating the account
   */
  public ServiceAccountCreationError: string

  /**
   * Instance member to track the visibility of the guide page
   */
  public guideVisibleBool: boolean


  constructor(
    private serviceAccountService: ServiceAccountService
  ) { }

  ngOnInit() {
    this.refreshServiceAccounts();
    this.initializeForms();
  }

  private  refreshServiceAccounts() { 
    this.serviceAccountService.GetSerivceAccounts().then((accounts) => {
      this.serviceAccounts = accounts;
    });
    this.serviceAccountService.GetScopes().then((scopes)=>{
      this.scopes = scopes;
    });
  }

  initializeForms() {
    this.AccountCreationStatus = "request";
    this.frmAddServiceAccount = new FormGroup({
      "name": new FormControl(null,[
        Validators.required
      ]),
      "scopes": new FormArray([]),
      "expirationDate": new FormControl(null,[
        Validators.required
      ]),
    });

    const defaultDate = new Date();
    defaultDate.setUTCHours(0,0,0,0); // Default to Midnight; UTC
    defaultDate.setDate(defaultDate.getDate()+398) // Default to 398 day lifespan
    // TODO: When we upgrade ng-zorro-antd to 9.x, we can use nzDefaultPickerValue (https://ng.ant.design/version/9.3.x/components/date-picker/en#api)
    this.frmAddServiceAccount.get("expirationDate").setValue( defaultDate);

  }


  onCheckboxChange(e) {
    const checkArray: FormArray = this.frmAddServiceAccount.get('scopes') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    log.debug("selected scopes", checkArray.value)
  }

  btnAddServiceAccount() { 
    this.initializeForms()
    this.showNewAccountCreationModal = true;
  }

  cancelNewAccountCreationModal() {
    this.showNewAccountCreationModal = false;
    this.refreshServiceAccounts();
  }


  async SubmitAddServiceAccountRequest() {
    this.isServiceAccountSaving = true;
    this.ServiceAccountCreationError = null;
    const formData = this.frmAddServiceAccount.value;
    // Just create a random token for now.
    const creationRequest: TextableServiceAccountCreationRequest = {
      name: formData.name,
      expirationDate: (formData.expirationDate as Date).getTime(),
      scopes: formData.scopes
    }
    try {
      this.ServiceAccountCreationResponse = await this.serviceAccountService.CreateSerivceAccount(creationRequest);
      
      this.isServiceAccountSaving = false;
      this.AccountCreationStatus = "response";
    }
    catch (err) {
      log.debug("Error creating account", err);
      this.ServiceAccountCreationError = err.error.error;
      this.isServiceAccountSaving = false;
    }
  }

  async revokeTokenByName(tokenName: string) {
    await this.serviceAccountService.RevokeTokenByName(tokenName);
    this.refreshServiceAccounts();
  }


  formatDate(timestamp: number) {
    return new Date(timestamp).toISOString();
  }
 
  CopyTokenToClipboard() {
    log.debug("Copied!");
    navigator.clipboard.writeText(this.ServiceAccountCreationResponse.token);
    this.copiedToClipboard = "Copied!";
    window.setTimeout(()=>{
      this.copiedToClipboard = "";
    },1500)
  }


}
