import { TextableError } from "./TextableError";

type APIErrorProps = {
  message: string
  statusCode: number
  userFriendlyMessage?: string
  referenceCode?: string
}

export class APIError extends TextableError {
  
  /**
   * Status code to be sent along with HTTP response
   */
  public statusCode: number;
  public userFriendlyMessage: string;
  public referenceCode: string;

  constructor(props: APIErrorProps) {
    super(props.message);
    this.referenceCode = props.referenceCode;
    this.statusCode = props.statusCode;
  }

  public toJSON() {
    return {
      _errType: "TXBDEV_API_ERROR_V1",
      message: this.message,
      userFriendlyMessage: this.userFriendlyMessage,
      referenceCode: this.referenceCode
    };
  }
}