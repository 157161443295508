import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal"
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TextableService } from 'src/app/services/textable.service';
import { AuthNZService } from 'src/app/core/authnz.service';
import { MMSUploaderMode } from 'src/app/components/mms-uploader/mms-uploader.component';
import { formatTimeZone, TextableDate } from 'src/classes/TextableDate';
import { DateIsInFuture } from 'src/app/form_validators/DateIsInFuture';
import { Observable, ReplaySubject, combineLatest, concat, fromEvent } from 'rxjs';
import { concatAll, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { TextableMessage, TextableCannedResponse } from 'src/app/backported_types/messaging';
import { getLogger } from '@shared/logging';
import { NzCalendarComponent } from 'ng-zorro-antd/calendar';
import { TextableContact } from 'src/app/backported_types/contact';
import { trackByDocumentId } from 'src/app/functions';

const log = getLogger('RemindersComponent')

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class RemindersComponent implements OnInit {
  @ViewChild('focusThis', { static: false }) focusThis: ElementRef;
  public showMMSModal:boolean = false;
  public mmsUploaderMode = MMSUploaderMode.COMPOSE

  public selectedMessage: TextableMessage;
  public messageModal:  NzModalRef<unknown, any>;
  public messageForm: FormGroup;
  private baseMessageForm: any;
  public editBool: boolean = false;
  public loading: boolean = false;
  public guideVisibleBool: boolean = false;
  private selectedRecipient: TextableContact = null;
  public cannedResponses: Observable<TextableCannedResponse> = this.textable.cannedResponses$;

  /**
   * Local instance pointer to the utility function `trackByDocumentId`
   */
  public trackByDocumentId = trackByDocumentId;

  /**
   * Shows the import sider visibility.
   */
  public drwImportReminders = false

  public formatTimeZone = formatTimeZone;

  /**
   * Observable for the selected date.
   */
  private selectedDate$ = new ReplaySubject<Observable<Date>>();
  /**
   * Observable for messages on the selected date
   */
  public selectedDateMessages$: Observable<TextableMessage[]>;

  /**
   * Reference to the nz-calendar component so we can
   * watch for changes on it.
   */
  @ViewChild(NzCalendarComponent, { static: true }) 
  calendar: NzCalendarComponent;
  
  /**
   * A map of the number of reminders for each date (as timestamp)
   */
  public ReminderDateCounts: Record<number,number> = {}
  
  constructor(private modalService: NzModalService,
    private textable: TextableService,
    private authnz: AuthNZService,
    private modal: NzModalService
  ) {
    this.selectedDateMessages$ = combineLatest([
      this.selectedDate$.pipe(concatAll(),startWith(new Date())),
      (this.textable.reminders$ as Observable<TextableMessage[]>).pipe(
        tap((reminders)=>{
          log.debug("Updating reminder day count cache")
          /**
           * Update our cache of counts of reminders on each day
           */
          this.ReminderDateCounts = {};
          reminders.forEach((reminder)=>{
            const sdts = new TextableDate(reminder.date).startOfDayTimestamp();
            this.ReminderDateCounts[sdts] = this.ReminderDateCounts[sdts] ? this.ReminderDateCounts[sdts] + 1 : 1
          })
          log.debug("Updated reminder day count cache")
        }),
        startWith([]),
      ),
    ]).pipe(
      map(([date,reminders])=>{
        log.debug(`Finding Messages for date ${date}`)
        const dayList = reminders.filter((msg)=>{
          return new TextableDate(msg.date).startOfDayTimestamp() == new TextableDate(date).startOfDayTimestamp()
        });
        this.loading = false;
        log.debug(`Messages for date ${date}`,dayList)
        return dayList; 
      }),
      startWith([]),
      shareReplay(1)
    )
   }

  ngOnInit() {
    this.makeMessageForm();
  }

  ngAfterViewInit() {
    this.selectedDate$.next(this.calendar.nzSelectChange);
  }
  /**
   * Reads from cache the number of reminders for a given 
   * Date adjusted to look at the start of the day.
   * @param date 
   * @returns 
   */
  public getCountForDate(date: Date):number{
    const d = new TextableDate(date).startOfDayTimestamp();
    const c = this.ReminderDateCounts[d];
    return c
  }


  ///////////////////////////////////////
  // CRUD OPERATIONS
  ///////////////////////////////////////

  createReminder() {
    this.loading = true;

    this.addValueToFormCREATE();   
    this.messageForm.value.sentBy = this.authnz.currentFireauthUser.uid;
    this.messageForm.value.sentByLabel = this.authnz.currentUserDocument.full_name;
    this.textable.create('messages', this.messageForm.value)
      .then((res) => {
        this.refresh();
      })
  }
  addValueToFormCREATE() {
    this.messageForm.get('send_status').setValue('scheduled');
    this.messageForm.get('uid').setValue(this.authnz.activeNumberUID);
    this.messageForm.get('Recipient').setValue(this.selectedRecipient.full_name); // TODO: why is it Recipient (titlecase) and not contacts?
    this.messageForm.get('contact_id').setValue(this.selectedRecipient.id);
    this.messageForm.get('direction').setValue('out');
    this.messageForm.get('from').setValue(this.authnz.activeNumberProfile.phone_number);
    this.messageForm.get('reminder').setValue(true);
  }

  updateReminder() {
    this.loading = true;
    this.textable.update('messages', this.messageForm.value, this.selectedMessage.id)
      .then((res) => {
        this.refresh();
      })
  }

  deleteReminder() {
    this.loading = true;
    this.textable.delete('messages',this.selectedMessage.id)
      .then(() => {
        this.refresh();
      })
  }

  makeMessageForm() {
    this.messageForm = new FormGroup({
      'Recipient': new FormControl(null),
      'to': new FormControl(null),
      'from': new FormControl(null),
      'scheduleTimezone': new FormControl(null , [Validators.required]),
      'body': new FormControl(null, [Validators.required]),
      'send_status': new FormControl('scheduled'),
      'date': new FormControl(null, [Validators.required]),
      'uid': new FormControl(null),
      'direction': new FormControl('out'),
      'contact_id': new FormControl(null),
      'reminder': new FormControl(true,[]),
      "attachments": new FormControl([]),
      "selectedCannedResponse": new FormControl(null)
    },
    {
      validators: [
        DateIsInFuture('date','scheduleTimezone'),
      ]
    })
    this.baseMessageForm = this.messageForm.value
  }
  
  createMessageModal(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplfooter: TemplateRef<{}>, message?: TextableMessage): void {
    if (message) {
      this.messageForm.patchValue(message);
      this.selectedMessage = message;
      this.editBool = true; 
    } else {
      this.editBool = false;
    }

    this.messageModal = this.modalService.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplfooter,
      nzOnCancel: () => {
        this.refresh()
      }
    });
  }

  chooseRecipient(c) {
    this.selectedRecipient = c
    this.messageForm.get('Recipient').setValue(this.selectedRecipient?.full_name);
    this.messageForm.get('to').setValue(this.selectedRecipient?.phone_number);
  }

  clearRecipient() {
    this.messageForm.get('Recipient').setValue(null);
  }


  refresh() {
    this.messageModal.close()
    this.clearRecipient();
    this.messageForm.patchValue(this.baseMessageForm)
    this.selectedMessage = null;
  }
  /////////////////////////////
  // Sent Conditions
  ////////////////////////////
  public isSelectedMessageSent():boolean{
    return this.selectedMessage?.send_status === 'sent'
  }
  
  /////////////////////////////
  // MMS Related Functions
  ////////////////////////////
  public closeMMSUploader(event){
    this.attachUploadToMessage(event)
    this.showMMSModal = false
  }

  private attachUploadToMessage(event){
    if(event){
      this.messageForm.get('attachments').setValue(event)
    }
  }

  public removeAttachment(){
    this.messageForm.get('attachments').setValue([])
  }

  public selectCannedResponse(selectedCannedResponseBody:string){
    if(!selectedCannedResponseBody){
      return;
    }

    if(this.messageForm.get('body').value && this.messageForm.get('body').value.length > 0 ){

      this.modal.confirm({
        nzTitle: '<i>Are you sure you want to overwrite the message body with this canned response??</i>',
        nzOnOk: () => this.updateMessageBody(selectedCannedResponseBody)
      });

    }else{
      this.updateMessageBody(selectedCannedResponseBody);
    }

    this.messageForm.get('selectedCannedResponse').setValue(null);
  }

  updateMessageBody(messageBody:string){
    this.messageForm.get('body').setValue(messageBody);
  }

}
