import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { getLogger } from 'src/shared/logging';
const log = getLogger("VisibilityService");

export type PageVisibility = {
  visible: boolean
  focused: boolean
}

/**
 * Adapted from https://medium.com/angular-in-depth/improve-performance-with-lazy-components-f3c5ff4597d2
 * and updated to use non-deprecated methods
 */
@Injectable({
  providedIn: "root"
})
export class VisibilityService {
  
  /**
   * Observable that emits whenever the page visibility changes
   * 
   * The frist value of this is the current state of `!document.hidden` at the time the first
   * subscriber subscribes.
   * 
   * Subsequent emissions come from document `visibilitychange` events.
   * 
   */
  public pageVisiblity$: BehaviorSubject<PageVisibility>;
    
  constructor(document: Document) {
    this.pageVisiblity$ = new BehaviorSubject({ 
      visible: document.visibilityState == "visible",
      focused: document.hasFocus()
    });

    merge(
      fromEvent(document,"visibilitychange"),
      fromEvent(document.defaultView,"focus"),
      fromEvent(document.defaultView,"blur")
    ).subscribe((v) => {
      this.pageVisiblity$.next({ 
        visible: document.visibilityState == "visible",
        focused: document.hasFocus()
      });
    });

    this.pageVisiblity$.subscribe((g)=>log.debug("PageVisibility changed ",g));
  }

  public elementInViewport(element: Element): Observable<boolean> {
    const elementVisible$ = new Observable<IntersectionObserverEntry[]>(observer => {
      const intersectionObserver = new IntersectionObserver(entries => {
        observer.next(entries);
      });
      intersectionObserver.observe(element);
    
      return () => { intersectionObserver.disconnect(); };
    })
    .pipe (
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map((entry: IntersectionObserverEntry) => entry.isIntersecting),
    );
    return elementVisible$
  }
  
  /**
   * Creates an observable for the supplied ElementRef which emits when 
   * the visibility of the ElementeRef changes whether by viewport changes,
   * or by browser minimize / tab change / focus loss
   */
  public elementInSight(element: Element):Observable<boolean> {  
    const elementInSight$ = combineLatest([this.pageVisiblity$,this.elementInViewport(element)])
      .pipe(
        map(
          ([pageVisiblity, elementVisible]) => pageVisiblity.visible && pageVisiblity.focused && elementVisible
        )
      ) 
    return elementInSight$;
  }

  /**
   * Watches for DOM changes on an element, emits when changes are made
   * 
   * When finished, call the Subject's `complete` method to cleanup the MutationObserver.
   * 
   * @param element 
   * @returns 
   */
  public watchForDOMChanges(element:Element): Subject<void> {
    const subject = new Subject<void>();
    const mutationObserver = new MutationObserver((mutations, observer) => {
      subject.next();
    });
    mutationObserver.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    subject.subscribe(null,null,()=>{
      log.debug("Done watching for DOM Changes; disconnecting MutationObserver")
      mutationObserver.disconnect();
    });
    return subject;
  }
    
}