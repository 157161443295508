import { Component, OnInit, ViewChild, ElementRef, Output, Input, OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { take, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TextableService } from 'src/app/services/textable.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthNZService } from 'src/app/core/authnz.service';
import { ObservableContexUpdateSignal, SubscriptionManagerService } from 'src/app/core/subscription-manager.service';
import { getLogger } from 'src/shared/logging';
import { trackByDocumentId } from 'src/app/functions';
import { ContactsService } from 'src/app/services/contacts.service';
import { TextableContact } from 'src/app/backported_types/contact';
import { TextableDate } from 'src/classes/TextableDate';
const log = getLogger("AddressBookComponent")

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {

  public trackByDocumentId = trackByDocumentId;
  @ViewChild('txtFilter', { static: true }) txtFilter: ElementRef; // handles filtering

  @Output() contactSelected = new EventEmitter(); //emits a contact object... e.g. (contactSelected)="handleContactSelect($event)"
  /**
   * Emits the string array of selected contact IDs whenever the selection changes
   */
  @Output() selectedListChanged = new EventEmitter<string[]>();

  @Input() addressBookMode: 'list' | 'reminders' | 'blast' | 'table' = 'list';
  @Input() multiSelect: boolean = false;
  @Input() clearOnSelect: boolean = false; //clearActive
  @Input() perPage: number = 10;
  /**
   * Array of contact IDs which should be "checked"
   * 
   * When "set"; update the "checked" map to include all provided contacts
   */
  @Input() set selectedContacts(value: string[]){
    if(!Array.isArray(value)) {
      return;
    }
    value.forEach(v=>{
      this.mapOfCheckedId[v] = true
    })
  }

  @Input() disabledList: Array<{id:string}> = [];
  @Input() optedOut: boolean = true;
  @Input() hasSendMessageAction: boolean = true;


  /**
   * The app-address-book component relies on an expensive query to Firestore to retrieve contacts
   * In cases where this component is part of the DOM, but not actually visible, we want to 
   * hold-off on executing that query until it actually becomes necessary (TXBDEV-971).  By gating this 
   * query, we can avoid a majority of cases of unnecessary poor preformance (TXBDEV-955).
   * 
   * At some point, this query may not be as expensive (or be paginated - TXBDEV-888), so this may become less
   * useful
   * 
   */
  @Input() activated: boolean = false;


  allDataChecked = false;
  isIndeterminate = false;
  /**
   * Key-value pair of contact ids and the "checked" status of the contact
   */
  mapOfCheckedId: Record<string,boolean> = {}
  numberOfChecked = 0;
  environment: any = environment;


  /**
   * Contacts to render on the AddresBook page when no search is active
   */
  baseContacts:TextableContact[] = [];
  /**
   * Contacts to render on the AddresBook page when a search is active
   */
  searchContacts:TextableContact[] = [];

  curPageNumber = 1;
  pageIndex = this.curPageNumber - 1;
  public curPage = [];
  pageLength = 0;
  isSearchActive: boolean;
  searchTerm: any;
  isContactsLoading: boolean = true;
  activeContact: any = null;

  contactsLoadingMessage: string = "Loading Contacts...";

  constructor(
    private textable: TextableService,
    private afs: AngularFirestore,
    private router: Router,
    private authnz: AuthNZService,
    public contactService: ContactsService
    ) { }

  ngOnInit() {

    log.debug("ngOnInit")
      
      this.baseContacts = [];

      this.contactService.contacts$.subscribe(({contacts, isLoading})=>{
        log.debug("Received new contacts$")
        this.isContactsLoading = isLoading;
        if (isLoading) {
          return
        }
        this.baseContacts = contacts.map(c=>c.contact).sort((a: TextableContact, b: TextableContact) => a.full_name?.localeCompare(b.full_name));
        this.searchContacts = contacts.map(c=>c.contact).sort((a: TextableContact, b: TextableContact) => a.full_name?.localeCompare(b.full_name));

        this.getPage(this.curPageNumber);
        if (this.searchTerm?.trim() !== null) {
          this.handleFilterValue(this.searchTerm);
          if (!this.curPage.find(c=>c.id == this.activeContact?.id))
          {
            log.debug("Clearing active contact '" + this.activeContact +"' because it is no longer in search scope")
            /**
             * If we have a search term and a selected contact, but the list
             * of contacts has changed and our search term no longer matches
             * the selected in the new list, then we should un-show the 
             * selected contact _without_ clearing the search term.
            */ 
             this.activeContact = null;
             this.contactSelected.emit(null);
          }
        }
      });

    fromEvent(this.txtFilter.nativeElement, 'keyup').pipe(
      map((event:any)=>{
        return event.target.value;
      }),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((text)=>{
      this.handleFilterValue(text);
    });

    this.authnz.contextChanged.subscribe(({activeNumberProfile, previousActiveNumberProfile})=>{
      if (previousActiveNumberProfile.phone_number !== activeNumberProfile.phone_number) {
        this.clearSearch();
      }
    });
  }

  

  getPage(pageNum) {
    if(!this.baseContacts || this.baseContacts?.length == 0){
      this.contactsLoadingMessage = "No Contacts"
      return;
    }
    this.refreshStatus();
    this.curPageNumber = pageNum;
    this.pageIndex = this.curPageNumber - 1;
    this.curPage = this.baseContacts.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/this.perPage)
  
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
  
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])[this.pageIndex];
  }

  handleFilterValue(val) {
    //this.showList = true;
    this.searchTerm = val;
    this.isSearchActive = true;
    this.curPage = [];
    // set val to the value of the searchbar
    
    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      this.curPage = this.searchContacts.filter((c:any) => {
        return c.full_name?.toLowerCase().indexOf(val.toLowerCase()) > -1 || c.phone_number?.indexOf(val.toLowerCase()) > -1
      });
    }else{
      this.clearSearch();
    }
  }

  clearSearch(){
    if (this.isSearchActive) {
      log.debug("Clearing Address Book Search");
      this.curPageNumber = 1;
      this.getPage(this.curPageNumber);
      this.isSearchActive = false;
      this.searchTerm = null;
      this.activeContact = null;
    }
  }
  
  selectContact(contact){
    this.activeContact = contact;
    this.contactSelected.emit(contact);
    if(this.clearOnSelect){
      this.clearSearch();
    }
  }

  sendMessage(contact) {
    this.afs.doc('contacts/' + contact.id).update({last_message_date:TextableDate.now()})
      .then(()=>{
        this.router.navigate(['/conversations', contact.id]);
      });
  }

  deleteContact(contact) {
    this.textable.delete("contacts", contact.id, { title: 'Success', subtitle: 'Contact: ' + contact.full_name + ' (' + contact.phone_number + ')' + ' has been deleted.' })
  }

  ///////////////////////
  // Table Mode Functions
  //////////////////////

  refreshStatus() {
    if (this.baseContacts) {
      this.allDataChecked = this.baseContacts.every(item => this.mapOfCheckedId[item.id]);
      this.isIndeterminate = this.baseContacts.some(item => this.mapOfCheckedId[item.id]) && !this.allDataChecked;
      this.numberOfChecked = this.baseContacts.filter(item => this.mapOfCheckedId[item.id]).length;
      const checkedStringArray = Object.entries(this.mapOfCheckedId).filter(([k,v])=>v==true).map(([k,v])=>k)
      this.selectedListChanged.emit(checkedStringArray);
    }
  }

  checkAll(value: boolean): void {
    //this.mapOfCheckedId = {}
    this.curPage.forEach((item) => {
      if (!item.optedOut || item.optedOut == null) {
        this.mapOfCheckedId[item.id] = value
      }
    });
    this.refreshStatus();
  }

  toggleSelectAll() {
    this.mapOfCheckedId = {}
    let bool = true
    if (this.allDataChecked || (this.isIndeterminate && this.optedOut) ) {
      bool = false
    }
    this.baseContacts.forEach(item => {
      if (!item.optedOut || item.optedOut == null) {
        this.mapOfCheckedId[item.id] = bool
      }
    })
    this.refreshStatus();
  }

  resetValues() {
    log.debug("Resetting Address book values");
    this.refreshStatus()
    this.allDataChecked = false;
    this.isIndeterminate = false;
    this.mapOfCheckedId = {}
    this.numberOfChecked = 0;
    this.curPageNumber = 1;
  }
}
