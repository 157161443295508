export  interface SelectOptionList {
  label: string, 
  value: string
}

/**
 * This interface is really just a placeholder until
 * we can fully unify the frontent/backend types.
 * 
 * It represents Firestore documents which have 
 * at least an `id` attribute, and also any number 
 * of properties of any type.
 * 
 */
export interface GenericFrontendFirestoreDocument {
  /**
   * This `id` field is analgous to `firebase_document_id` in the textablecommon code:
   * It does not exist on the document itself, but is added to the JavaScript object
   * by the query function with code like: `const id = a.payload.doc.id;`
   *  
   */
  id: string,
  [key: string]: any
}

export interface TypeAheadSelectFilterStatus {
  message: string
  iconType: string
}

/**
 * Generic interface for "Objects" where 
 * all keys of the object are just indexes for sub-objects
 */
export interface KeyedArray<T> {
  [key: string]: T
}

export interface NzFilterItem {
  value:string
  text:string
}

/**
 * Modifies a type so that the specified properties are required.
 * 
 * https://stackoverflow.com/a/69328045/11125318
 * 
 * The `-?` syntax is explained at:
 * https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#mapping-modifiers
 */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }



type Invalid<T> = ['Needs to be all of', T];
/**
 * Guarantees that a developer-implemented string array
 * contains all properties of an interface.
 * 
 * Courtesy of https://stackoverflow.com/a/73457231/11125318 and https://stackoverflow.com/a/53395649/11125318
 * 
 */
export const arrayOfAll = <T>() => <U extends T[]>(
    ...array: U & ([T] extends [U[number]] ? unknown : Invalid<T>[])
  ) => array;
