import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConversationsComponent } from './views/conversations/conversations.component';
import { ContactsComponent } from './views/contacts/contacts.component';
import { ProfileComponent } from './views/profile/profile.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth.guard';
import { CannedResponsesComponent } from './views/canned-responses/canned-responses.component';
import { BlastsComponent } from './views/blasts/blasts.component';
import { UsersComponent } from './views/users/users.component';
import { StatisticsComponent } from './views/statistics/statistics.component';
import { ConfirmAccountComponent } from './views/confirm-account/confirm-account.component';
import { LoginComponent } from './views/login/login.component';
import { DripCampaignComponent } from './views/drip-campaign/drip-campaign.component';
import { RemindersComponent } from './views/reminders/reminders.component';
import { ContactListComponent } from './views/contact-list/contact-list.component';
import { ServiceAccountsComponent } from './views/service-accounts/service-accounts.component';
import { AdminToolsComponent } from './views/admin-tools/admin-tools.component';
import { ManageSsoComponent } from './views/manage-sso/manage-sso.component';
import { IntegrationSettingsPageComponent } from './views/integration-settings-page/integration-settings-page.component';
import { ConversationResultsComponent } from './conversation-results/conversation-results.component';
import { BillingComponent } from './views/billing/billing.component';

const routes: Routes = [
  { path: '', redirectTo: 'conversations', pathMatch: 'full'},
  { path: 'conversations', component: ConversationsComponent, data: { title: 'Conversations', icon: 'fa-sms', featureCheck: 'conversations'}, canActivate: [AuthGuard] },
  { path: 'conversations/:id', component: ConversationsComponent, data: { title: 'Conversations', icon: 'fa-sms', featureCheck: 'conversations'}, canActivate: [AuthGuard] },
  { path: 'contacts', component: ContactsComponent, data: { title: 'Contacts', icon: 'fa-address-book', featureCheck: 'contacts'}, canActivate: [AuthGuard] }, 
  { path: 'contacts/:id', component: ContactsComponent, data: { title: 'Contacts', icon: 'fa-address-book', featureCheck: 'contacts'}, canActivate: [AuthGuard] }, 
  { path: 'blasts', component: BlastsComponent, data: { title: 'Blasts', icon: 'fa-rocket', featureCheck: 'blasts'}, canActivate: [AuthGuard] },
  { path: 'canned-responses', component: CannedResponsesComponent, data: { title: 'Canned Responses', icon: 'fa-rocket', featureCheck: 'cannedResponses'}, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, data: { title: 'My Profile', icon: 'fa-address-card', featureCheck: 'profile'}, canActivate: [AuthGuard] },
  { path: 'integrations', component: IntegrationSettingsPageComponent, data: { title: 'Integrations', icon: 'fa-link', featureCheck: 'integrations'}, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, data: { title: 'Manage Users', icon: 'fa-address-card'}, canActivate: [AuthGuard] },
  { path: 'sso', component: ManageSsoComponent, data: { title: 'Manage SSO', icon: 'fa-address-card'}, canActivate: [AuthGuard] },
  { path: 'billing', component: BillingComponent, data: { title: 'Manage Billing', icon: 'fa-address-card'}, canActivate: [AuthGuard] },
  { path: 'statistics', component: StatisticsComponent, data: { title: 'Statistics', icon: 'fa-address-card'}, canActivate: [AuthGuard] },
  { path: 'confirm-account/:uid/:hash', component: ConfirmAccountComponent },
  { path: 'login', component: LoginComponent },
  { path: 'conversation-results', component: ConversationResultsComponent },
  { path: 'campaign', component: DripCampaignComponent , canActivate: [AuthGuard]},
  { path: 'reminders', component: RemindersComponent , canActivate: [AuthGuard]},
  { path: 'contact-list', component: ContactListComponent, canActivate: [AuthGuard]},
  { path: 'service-accounts', component: ServiceAccountsComponent, data: { title: 'Manage Service Accounts', icon: 'fa-black-tie'}, canActivate: [AuthGuard] },
  { path: 'admin-tools', component: AdminToolsComponent, data: { title: 'Admin Tools', icon: 'tool'}, canActivate: [AuthGuard] }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
