import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NzResultModule } from 'ng-zorro-antd/result';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DOCUMENT, CommonModule, registerLocaleData, TitleCasePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ConversationsComponent } from './views/conversations/conversations.component';
import { ContactsComponent } from './views/contacts/contacts.component';
import { ProfileComponent } from './views/profile/profile.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { LoginComponent } from './views/login/login.component';
import { MessageComposerComponent } from './components/message-composer/message-composer.component';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { TextMaskModule } from 'angular2-text-mask';
import { CannedResponsesComponent } from './views/canned-responses/canned-responses.component';
import { BlastsComponent } from './views/blasts/blasts.component';
import { LinkyModule } from 'ngx-linky';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UsersComponent } from './views/users/users.component';
import { UserDetailsComponent } from './views/user-details/user-details.component';
import { StatisticsComponent } from './views/statistics/statistics.component';
import { ConfirmAccountComponent } from './views/confirm-account/confirm-account.component';
import { TextableService } from './services/textable.service';
import { NumberSwitcherComponent } from './components/number-switcher/number-switcher.component';
import { DripCampaignComponent } from './views/drip-campaign/drip-campaign.component';
import { StatusMessageComponent } from './components/status-message/status-message.component';
import { RemindersComponent } from './views/reminders/reminders.component';
import { AddressBookComponent } from './components/address-book/address-book.component';
import { OptedOutBannerComponent } from './components/opted-out-banner/opted-out-banner.component';
import { ConsentMessageBannerComponent } from './components/consent-message-banner/consent-message-banner.component';
import { ContactListComponent } from './views/contact-list/contact-list.component';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { UserGuidesComponent } from './components/user-guides/user-guides.component';
import { PlanSelectorComponent } from './components/plan-selector/plan-selector.component';
import { MarkdownModule } from 'ngx-markdown';
import { MmsViewerComponent } from './components/mms-viewer/mms-viewer.component';
import { UserMultiSelectorComponent } from './components/user-multi-selector/user-multi-selector.component';
import { DebugDataViewerComponent } from './components/debug-data-viewer/debug-data-viewer.component';
import { SubscriptionStatusMessageComponent } from './components/subscription-status-message/subscription-status-message.component';
import { ProviderEditorComponent } from './components/provider-editor/provider-editor.component';
import { NativeAddNewUserModalComponent } from './components/native-user-editor/native-user-editor.component';
import { ServiceAccountsComponent } from './views/service-accounts/service-accounts.component';
import { OrganizationEditorComponent } from './components/organization-editor/organization-editor.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AdminToolsComponent } from './views/admin-tools/admin-tools.component';
import { AdminToolComponent } from './components/admin-tool/admin-tool.component';
import { ManageSsoComponent } from './views/manage-sso/manage-sso.component';
import { MmsUploaderComponent } from './components/mms-uploader/mms-uploader.component';
import { ControlMessagingService } from './services/control-messaging.service';
import { TzDateTimePickerComponent } from './components/tz-date-time-picker/tz-date-time-picker.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzConfigService } from  'ng-zorro-antd/core/config'
import { NzNotificationService } from  'ng-zorro-antd/notification'
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { IntegrationMessageBannerComponent } from './components/integration-message-banner/integration-message-banner.component';
import { IntegrationSettingsPageComponent } from './views/integration-settings-page/integration-settings-page.component';
import { IntegrationSettingsComponent, IntegrationInstallationFormControlComponent} from './components/integration-settings/integration-settings.component';
import { UserNotificationComponent } from './components/user-notification/user-notification.component';
import { DidInputComponent } from "./components/did-input/did-input.component";
import { MessageViewComponent } from './components/message-view/message-view.component'
import { CodeEditorModule } from '@ngstack/code-editor';
import { KeywordNoticeComponent } from './components/keyword-notice/keyword-notice.component';
import { BackgroundOperationStatusComponent } from './components/background-operation-status/background-operation-status.component';
import { ContactImportComponent } from './components/contact-import/contact-import.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { ReminderImportComponent } from './components/reminder-import/reminder-import.component';
import { ConversationResultsComponent } from './conversation-results/conversation-results.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { DateService } from './services/date.service';
import { MergeFieldPopoverComponent } from './components/merge-field-popover/merge-field-popover.component';
import { ContactDisplayComponent } from './components/contact-display/contact-display.component';
import { BlockedSenderManagerComponent } from './components/blocked-sender-manager/blocked-sender-manager.component';
import { SenderBlockerComponent } from './components/sender-blocker/sender-blocker.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BillingComponent } from './views/billing/billing.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ConversationsComponent,
    ContactsComponent,
    ProfileComponent,
    LoginComponent,
    MessageComposerComponent,
    CannedResponsesComponent,
    BlastsComponent,
    UsersComponent,
    UserDetailsComponent,
    StatisticsComponent,
    ConfirmAccountComponent,
    NumberSwitcherComponent,
    DripCampaignComponent,
    StatusMessageComponent,
    RemindersComponent,
    AddressBookComponent,
    OptedOutBannerComponent,
    ConsentMessageBannerComponent,
    ContactListComponent,
    EllipsisPipe,
    UserGuidesComponent,
    PlanSelectorComponent,
    MmsViewerComponent,
    UserMultiSelectorComponent,
    DebugDataViewerComponent,
    SubscriptionStatusMessageComponent,
    ProviderEditorComponent,
    NativeAddNewUserModalComponent,
    ServiceAccountsComponent,
    OrganizationEditorComponent,
    AdminToolsComponent,
    AdminToolComponent,
    ManageSsoComponent,
    MmsUploaderComponent,
    TzDateTimePickerComponent,
    LoadingModalComponent,
    IntegrationMessageBannerComponent,
    IntegrationSettingsPageComponent,
    IntegrationSettingsComponent,
    IntegrationInstallationFormControlComponent,
    UserNotificationComponent,
    DidInputComponent,
    MessageViewComponent,
    KeywordNoticeComponent,
    BackgroundOperationStatusComponent,
    ContactImportComponent,
    PhoneNumberInputComponent,
    ReminderImportComponent,
    ConversationResultsComponent,
    NavigationMenuComponent,
    MergeFieldPopoverComponent,
    ContactDisplayComponent,
    BlockedSenderManagerComponent,
    SenderBlockerComponent,
    BillingComponent
  ],
  imports: [ 
    NzSwitchModule,
    NzTagModule,
    NzInputNumberModule,
    NzCheckboxModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzCalendarModule,
    NzLayoutModule,
    NzPopoverModule,
    NzAvatarModule,
    NzDividerModule,
    NzPaginationModule,
    NzTabsModule,
    NzCollapseModule,
    NzListModule,
    NzStatisticModule,
    CommonModule,
    NzCardModule,
    NzResultModule,
    NzDescriptionsModule,
    NzDrawerModule,
    NzMessageModule,
    NzBadgeModule,
    NzDropDownModule,
    NzAlertModule,
    NzIconModule,
    ScrollingModule,
    NzStepsModule,
    NzSelectModule,
    NzTableModule,
    NzFormModule,
    NzButtonModule,
    NzSpinModule,
    CodeEditorModule.forRoot({
      baseUrl: 'assets/monaco', // Obtain editor files locally instead of from CDN
      typingsWorkerUrl: 'assets/workers/typings-worker.js'
    }),
    NzDatePickerModule,
    NzInputModule,
    NzTimelineModule,
    NzModalModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, //.enablePersistence(), took out because of realtime data issues
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    TextMaskModule,
    LinkyModule,
    MarkdownModule.forRoot(),
    QRCodeModule,
    NzToolTipModule,
    NzProgressModule,
    PickerModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    ControlMessagingService,
    TextableService,
    TitleCasePipe,
    NzConfigService,
    NzNotificationService,
    {provide: Document, useExisting: DOCUMENT}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    /**
     * Include DateService here so Dependency Injection picks it up and instantiates it
     * Since it's not used anywhere directly, we just need *somewhere* to put it.
     */
    dateService: DateService
  ) {
    
  }
}
