import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NzConfigService } from 'ng-zorro-antd/core/config'
import { NzNotificationService } from "ng-zorro-antd/notification"
import { NzMessageService } from "ng-zorro-antd/message"
import { TitleCasePipe } from '@angular/common';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ApplicationStates } from '../core/auth-nz/interfaces';
import { AuthNZService } from '../core/authnz.service';
import { ObservableContexUpdateSignal, SubscriptionManagerService } from '../core/subscription-manager.service';
import { getLogger } from 'src/shared/logging';
import { BackendService } from './backend.service';
import "firebase/compat/auth";
import { ApplicationContextService, TextableProviderConfiguration } from './application-context.service';
import { NavigationService } from './navigation.service';
import { TextableOrganization } from '../backported_types/users';
import { TextableContact, TextableContactList } from '../backported_types/contact';
import { mapToTextableBaseFirestoreDocument } from '../backported_types/base';
import { TrimObject } from '../functions';
const log = getLogger("TextableService");

interface txtMessageConfig {
    title: string;
    subtitle:string;
}

@Injectable({
  providedIn: 'root'
})
export class TextableService {

  isEmailVerified: boolean = true;

  /** 
   * Observable of contact lists for the activeNumber
   * TODO: Set the type of this to be TextableContactList[] when TXBDEV-93 is done
   */
  contactLists$: Observable<TextableContactList[]>;

  /**
   * Observable of CannedResponses for the activeNumber
   */
  cannedResponses$: Observable<any>;

  /**
   * Observable of DripCampaigns for the activeNumber
   */
  dripCampaigns$: Observable<any>;

  /**
   * Observable of Blasts for the activeNumber
   */
  blasts$: Observable<any>;
  

  /**
   * Observable of Blasts for the activeNumber
   */
  reminders$: Observable<any>
      


  constructor(
    private afa: AngularFireAuth,
    private afs: AngularFirestore,
    private authnz: AuthNZService,
    private http: HttpClient,
    private notification: NzNotificationService,
    private titleCase: TitleCasePipe,
    private fns: AngularFireFunctions,
    private msg: NzMessageService,
    private submanager: SubscriptionManagerService,
    private backendService: BackendService,
    private configService: NzConfigService,
    private applicationContextService: ApplicationContextService,
    private navigation: NavigationService
  ) {

    this.configService.set("notification", {
      nzPlacement: 'bottomLeft'
    });

    authnz.authChanged.subscribe( (state) => {
      this.navigation.refreshNavigation();
      if (state == ApplicationStates.LoggedIn) {
        this.isEmailVerified = authnz.currentFireauthUser.emailVerified;
      }
    });

    //when the signed in user changes... (or initializes on page load...)
    authnz.contextChanged.subscribe(()=>{
      this.navigation.refreshNavigation();
    });
   
    this.preloadCannedResponses();
    this.preloadContactLists();
    this.preloadDripCampaigns();
    this.preloadBlasts();
    this.preloadReminders();
   
  }



  /**
   * Gets the provider object from environment reference
   * @param providerName string corresponding to the id (or any alternateIds if present) of the desired provider
   * @returns 
   */
  getProvider(providerName:string): TextableProviderConfiguration {
    const provider = this.applicationContextService.applicationContext.providers.find((p)=>p.id == providerName || p.alternateIds?.includes(providerName) );
    if (!provider) {
      return;
    }
    if (!provider.fields) {
      log.error ("Provider has no fields: " + provider.id);
    }
    return provider;
  }

  /**
   * Gets the names of all provider fields
   * 
   * Used to reset the form state
   * 
   */
  getAllCredentialFields(): string[] {
    let fields: string[] = [];
    this.applicationContextService.applicationContext.providers.filter((p)=>p.fields).forEach(provider => {
      Object.keys(provider.fields).forEach(field => {
        fields.push(field)
      });
    })
    return fields;
  }
    


  ////////////////////////////////////////////////////////////////////////////////////
  // Generic afs.doc & afs.collection
  ////////////////////////////////////////////////////////////////////////////////////

  create(document, data, customMessage?:txtMessageConfig) {
    return new Promise((resolve, reject) => {
      this.afs
      .collection(document)
      .add(data)
        .then(() => {
          if (customMessage) {
            this.showCustomNotifications('success', customMessage)
          } else {
            this.showNotifications('success', 'create', document);
          }
        resolve(true);
      })
        .catch(err => {
          this.showNotifications('error', 'create', document);
          console.log(err);
          reject(err);
        });
    })

  }

  async update(document, data , id , customMessage?:txtMessageConfig) {
    TrimObject(data);
    try {
      await this.afs.doc(document + '/' + id).update(data)
      if (customMessage) {
        this.showCustomNotifications('success', customMessage)
      } else {
        this.showNotifications('success', 'update', document);
      }
    }
    catch(err) {
      this.showNotifications('error', 'update', document);
      throw err
    }
  }

  delete(document, id, customMessage?: txtMessageConfig) {
    return new Promise((resolve, reject) => {
      this.afs
      .doc(document + '/' + id)
      .delete()
        .then(() => {
        if (customMessage) {
          this.showCustomNotifications('success', customMessage)
        } else {
          this.showNotifications('success', 'delete', document);
        }
        resolve(true);
      })
        .catch(err => {
          this.showNotifications('error', 'delete', document);
          reject(err);
        });
    })
  }

  //////////////////////////
  // Callable Cloud Functions
  //////////////////////////
  baseDeleteAll(type) {
    // TYPE is the name of collection to delete
    let callable = this.fns.httpsCallable('baseDeleteAll');
    callable({ type: type }).forEach(res => {
    }).catch(e => {
      console.log(e)
    })
  }

  provisionRetailUser(data) {
    let callable = this.fns.httpsCallable('provisionRetailUser');
    return callable(data)
  }

  deprovisionRetailTeamUser(data) {
    let callable = this.fns.httpsCallable('deprovisionRetailTeamUser');
    return callable(data)
  }

  isNumberInUse(data) {
    let callable = this.fns.httpsCallable('isNumberInUse');
    return callable(data);
  }

  sendPasswordResetEmail(data) {
    let callable = this.fns.httpsCallable('sendPasswordResetEmail');
    return callable(data);
  } 

  sendVerificationEmail(data) {
    let callable = this.fns.httpsCallable('sendVerificationEmail');
    return callable(data);
  } 

  ///////////////////////
  // Notification Services
  //////////////////////
  formatDocumentName(document) {
    let word = this.titleCase.transform((document.replace('-', " ")));
    return word;
  }

  showNotifications(success,type,name) {
    if (success == 'success') {
      this.notification.create(
        'success',
        'Success',
        this.formatDocumentName(name) + ' has been ' + type + 'd.'
      );
    } else {
      this.notification.create(
        'error',
        'Error',
        this.formatDocumentName(name) + ' has an error on ' + type + '.'
      );
    }
  }
  /**
   * @deprecated Use the notification service directly
   * @param success 
   * @param msg 
   */
  showCustomNotifications(success, msg:txtMessageConfig) {
    if (success == 'success') {
      this.notification.create(
        'success',
        msg.title,
        msg.subtitle
      );
    } else {
      this.notification.create(
        'error',
        'Error',
        msg.subtitle
      );
    }
  }

  /////////////////////////
  // Contact List Methods
  /////////////////////////
  
  preloadContactLists() {
    this.contactLists$ = this.submanager.observe(
      "activenumber-lists", 
      () => {
        return this.afs
          .collection('contact-lists', ref =>
            ref.where('uid', '==', this.authnz.activeNumberUID)).snapshotChanges()
        },
      ObservableContexUpdateSignal.ActiveNumberChanged).pipe(
      map((contacts: any) => contacts.map(a => {
        const c: any = a.payload.doc.data();
        c.id = a.payload.doc.id;
        return c;
      }))
    );
  }

  getContactsInList(listID): Observable<TextableContact[]> {
    this.submanager.destroyObservable("activenumber-contacts-in-list");
    return this.submanager.observe(
      "activenumber-contacts-in-list", 
      () => this.afs.collection('contacts', ref =>
          ref.where('uid', '==', this.authnz.activeNumberUID).where('lists', 'array-contains', listID))
          .snapshotChanges(),
      ObservableContexUpdateSignal.Immediate
      )
      .pipe(
        map((contacts) => contacts.map(c=>mapToTextableBaseFirestoreDocument<TextableContact>(c.payload.doc)) )
      )
    }

  bulkArchiveConversations(data) {
    let postData = {
      contactsArray: data
    };
    this.backendService.backendPost('bulkArchiveConversations', {},postData)
      .then((result: any) => {
        this.notification.create(
          'success',
          'Success',
          'Selected Conversations have been archived.'
        );
      }, (error) => {
          console.error(error)
          this.notification.create(
            'error',
            'Error',
            'Failed to archive all contacts.'
          );
      });
  }

  bulkMarkUnread(data) {
    let postData = {
      contactsArray: data
    };
    this.backendService.backendPost('api/v2/bulk/unread', {},postData)
      .then((result: any) => {
        this.notification.create(
          'success',
          'Success',
          'Selected Conversations have been marked unread.'
        );
      }, (error) => {
          console.error(error)
          this.notification.create(
            'error',
            'Error',
            'Failed to mark all contacts as unread.'
          );
      });
  }

  preloadCannedResponses() {
    this.cannedResponses$ = this.submanager.observe(
      "activenumber-cannedresponses", 
      () => {
        return this.afs
        .collection('canned-responses', ref => 
          ref.where('uid', '==', this.authnz.activeNumberUID)
          .orderBy('name', 'asc')
        ).snapshotChanges()
      },
      ObservableContexUpdateSignal.ActiveNumberChanged
      ).pipe(
        map(response => response.map(a => {
          const r:any = a.payload.doc.data();
          r.id = a.payload.doc.id;
          return r;
        }))
      );
  }

  preloadDripCampaigns(){
    this.dripCampaigns$ = this.submanager.observe(
      "activenumber-dripcampaigns",
      () => this.afs
        .collection('drip-campaign', ref => 
          ref.where('uid', '==', this.authnz.activeNumberUID)
          .orderBy('created', 'desc')
        ).snapshotChanges(),
      ObservableContexUpdateSignal.ActiveNumberChanged
      )
      .pipe(
      map(response => response.map(a => {
        const r:any = a.payload.doc.data();
        r.id = a.payload.doc.id;
        return r;
      }))
    );
  }

  preloadBlasts(){
    this.blasts$ = this.submanager.observe(
      "activenumber-blasts",
      () => this.afs.collection('blasts', ref => 
          ref.where('uid', '==', this.authnz.activeNumberUID) //.where('status', '==', type)
          .orderBy('lastModifiedDate', 'desc')
          ).snapshotChanges(),
      ObservableContexUpdateSignal.ActiveNumberChanged
      )
      .pipe(
      map(blasts => blasts.map(a => {
        const b:any = a.payload.doc.data();
        b.id = a.payload.doc.id;
        b.scheduleTime = typeof b.scheduleTime == "number" ? new Date(b.scheduleTime) : null;
        return b;
      }))
    );
  }

  getTags() {
    return this.afs
        .collection('tags', ref => 
        ref.where('uid', '==', this.authnz.activeNumberUID)).snapshotChanges().pipe(
          map((contacts:any) => contacts.map(a => {
            const c:any = a.payload.doc.data();
            c.id = a.payload.doc.id;
            return c;
          }))
        );
  }

  getAccessTokens(user){
    return this.submanager.observe(
      "currentuser-accesstokens", 
      ()=>this.afs .collection('users/'+user.uid+'/api').snapshotChanges(),
      ObservableContexUpdateSignal.Immediate
    ).pipe(
      map(tokens => tokens.map(a => {
        const b:any = a.payload.doc.data();
        b.id = a.payload.doc.id;
        return b;
      }))
    );
  }

  createAccessToken(user){
    return  this.backendService.backendPost('frontend/users/'+user.uid+'/token',{},{});
  }


  preloadReminders() {
    this.reminders$ = this.submanager.observe(
      "activenumber-reminders", 
      ()=>this.afs
        .collection('messages', ref => 
          ref.where('uid', '==', this.authnz.activeNumberUID)
            .orderBy('date', 'desc')
            .where('reminder', '==', true)
        ).snapshotChanges(),
      ObservableContexUpdateSignal.ActiveNumberChanged
      ).pipe(
      map(response => response.map(a => {
        const r:any = a.payload.doc.data(); // TODO: TXBDEV-1621 - this required a tsconfig.json change to see the id property on a QueryDocumentSnapshot
        r.id = a.payload.doc.id;
        return r;
      }))
    );
  }

  updateOrganization(org){
    if(!org.organizationName){
      return Promise.reject("Invalid organization name provided.");
    }
    return this.afs.doc('organizations/'+org.id).update(org);
  }

  getOrganizations(): Observable<TextableOrganization[]> {
    return this.submanager.observe(
      "currentuser-organizations", 
      () => {
        return this.afs.collection('organizations', (ref) => {
          let query: Query = ref;      
          query = this.authnz.currentSecurityProvider.trimFirestoreOrgQuery(query).orderBy('organizationName', 'asc');
          return query;
        }).snapshotChanges()
      },
      ObservableContexUpdateSignal.Immediate
    ).pipe(
      map(
        orgs => orgs.map(a => {
            const data = a.payload.doc.data(); // TODO: TXBDEV-1621 - this required a tsconfig.json change to see the id property on a QueryDocumentSnapshot
            const id = a.payload.doc.id;
            return { id, ...data as object } as TextableOrganization;
        }).sort((a:any,b:any)=>{
          return a.organizationName.toLowerCase().localeCompare(b.organizationName.toLowerCase());
        })
      )
    );
  }

  getOrganization(id){
    return this.afs.doc('organizations/'+id).get().pipe(
      map(a => {
        const d:any = a.data();
        d.id = a.id;
        return d;
      })
    );
  }

  sendMessage(msg){

    if(!msg){
      return Promise.reject('Missing message.');
    }

    if(!msg.uid){
      msg.uid = this.authnz.activeNumberUID;
    }

    return this.afs.collection('messages').add(msg);

  }

  convertIntervalToSeconds(drip){
    let multiple = 0;
    switch(drip.intervalType){
      case "minutes":
          multiple = 60;
        break;
      case "days":
          multiple = 60 * 60 * 24;
        break;
      case "hours":
          multiple = 60 * 60;
        break;
    }
    return drip.interval * multiple;
  }

  async exportMessages(data) {
    delete data.range;
    return this.backendService.backendPost('frontend/user-export', {}, data, {responseType: "blob"});
  }

  async setAutoReplyMessage(val){
    let replyMessage;
    if (val == null) {
      replyMessage = { uid: this.authnz.activeNumberUID }
    } else {
      replyMessage = val
    }

    return this.backendService.backendPost('setAutoReplyMessage', {}, {autoReplyMessage: replyMessage})
    
  }


  addCountryCode(phoneNumber,phonePrefix) {
    return phonePrefix + phoneNumber.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").replaceAll(phonePrefix, "")
  }
 
}