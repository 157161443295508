import { Component, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { getLogger, levels } from '@shared/logging';
import { CountryCodes, PhoneNumber } from '@shared/PhoneNumber';

const log = getLogger("PhoneNumberInput")
// log.setLevel(levels.INFO);

@Component({
  selector: 'phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: forwardRef(() => PhoneNumberInputComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => PhoneNumberInputComponent)
    }
  ]
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor, Validator {
  // #region Properties (6)

  //ensures the first digit is not +, 0, or 1. This allows the user to paste '+1##########' formatted numbers.

  /**
   * Country Codes
   * 
   * TODO: Deduplicate this to a single location across PF
   */
  public countryCodes = CountryCodes;
  /**
   * Controls whether the component is disabled.
   */
  public disabled = false;
  /**
   * Call this method when the component's value changes.
   */
  public onChange: (_: any) => void = ()=>{}

  /**
   * Call this method when the component is touched.
   */
  public onTouch: (_: any) => void = ()=>{}

  /**
   * Input mask for phone numbers
   * 
   * ensures the first digit is not +, 0, or 1. This allows the user to paste '+1##########' formatted numbers.
   * 
  */
  public phoneMask = [/[2-9]/, /\d/, /\d/,'-', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  /**
   * Remove these characters from the text input controlled by phoneMask
   */
  private stripCharacters = /[\(\)\s-_]/ig
  public phoneNumberFormGroup: FormGroup;

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(private fb: FormBuilder) {
    this.phoneNumberFormGroup = this.fb.group({
      "phoneNumber": [null,[Validators.required, this]],
      "countryCode": ["+1",[Validators.required]]
    });
    this.phoneNumberFormGroup.valueChanges.subscribe(c=>{
      const fullNumber = `${c.countryCode}${c.phoneNumber.replace(this.stripCharacters,"")}`;
      try {
        const tn = new PhoneNumber(fullNumber);
        this.onChange(tn.ToE164());
      }
      catch (err) {
        this.onChange(fullNumber);
      }
    })
  }

  // #endregion Constructors (1)

  // #region Public Methods (6)

  public ngOnInit(): void {
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public validate(control: AbstractControl): ValidationErrors {
    try {
      const tn = new PhoneNumber(control.value)
      log.debug(`Validated number ${tn.ToE164()}`)
    }
    catch (err) {
      log.debug(`Failed to validate number ${control.value}`)
      return {
        phoneNumber: err.message
      }
    }
  }

  public writeValue(obj: any): void {

    if (obj === undefined || obj === null || obj == "") {
      this.phoneNumberFormGroup.patchValue({
        phoneNumber:""
      });
      this.phoneNumberFormGroup.reset({
        phoneNumber:"",
        countryCode:"+1"
      })
      return;
    };

    try {
      const tn = new PhoneNumber(obj)
      log.debug("Received number", obj)
      this.onChange(tn.ToE164());
      this.phoneNumberFormGroup.patchValue({
        phoneNumber:tn.Format("{areaCode}{exchangeCode}{subscriberNumber}"),
      });
    }
    catch (err){
      log.warn("Invalid number supplied", obj)
    }
    
  }

  // #endregion Public Methods (6)
}
