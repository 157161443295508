<ul
  nz-menu
  [nzTheme]="'dark'"
  [nzMode]="'inline'"
>
  <ng-container *ngFor="let li of navigation.navigation$ | async">
    <li
      nz-menu-item
      *ngIf="li.children.length == 0; else tplNavSubmenu"
      [routerLink]="[li.route]"
      routerLinkActive="router-link-active"
    >
      <span>
        <i [class]="li.icon"></i>
          <span class="nav-text">{{ li.title }}</span>
      </span>
      <nz-tag *ngIf="li.badge" style="margin-left: 15px;" [nzColor]="li.badge?.color">{{li.badge?.text}}</nz-tag>
    </li>
    <ng-template #tplNavSubmenu>
      <li
        nz-submenu
      >
        <span title><i [class]="li.icon"></i>{{ li.title }}</span>
        <ul>
          <li
            nz-menu-item
            *ngIf="li.route"
            [routerLink]="[li.route]"
            routerLinkActive="router-link-active"
          >
            <span
              ><span class="nav-text">{{ li.title }}</span></span
            >
          </li>
          <li
            nz-menu-item
            *ngFor="let lic of li.children"
            [routerLink]="[lic.route]"
            routerLinkActive="router-link-active"
          >
            <span
              ><span class="nav-text">{{ lic.title }}</span></span
            >
          </li>
        </ul>
      </li>
    </ng-template>
  </ng-container>


</ul>