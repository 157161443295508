import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { OperationStatusUpdate } from 'src/app/background-operation-status.service';
import { WithRequired } from 'src/app/interfaces';

type BackgroundOperationTemplateProps = WithRequired<OperationStatusUpdate, "icon"> & { 
  showClose: boolean
  showProgress: boolean
}

@Component({
  selector: 'background-operation-status',
  templateUrl: './background-operation-status.component.html',
  styleUrls: ['./background-operation-status.component.scss']
})
export class BackgroundOperationStatusComponent implements OnInit {

  /**
   * Reference to the template for a new message popup
   * 
   */
  @ViewChild('tplStatusUpdate') template: TemplateRef<{BackgroundOperationTemplateProps}>;
  /**
   * There doesn't seem to be a proper way of hiding the close icon 
   * in the notification popup, but passing an empty TemplateRef
   * to the `nzCloseIcon` property seems to do the trick
   */
  @ViewChild('tplCloseIcon') closeIconTemplate: TemplateRef<void>;
 
  private operations: Record<string,{
    /**
     * A reference to the notification created by NzNotificationService
     */
    reference: NzNotificationRef,
    /**
     * Store the icon so that subsequent calls to updateStatus may omit the icon
     * without losing it.  Allow updates to the icon.
     */
    icon: string
  }> = {};

  constructor(
    public notify: NzNotificationService,
  ) {}

  ngOnInit(): void {
  }

  public updateStatus(status: OperationStatusUpdate) {
    const key = status.operation.replace(/\s/g,"")

    if (!this.operations.hasOwnProperty(key)) {
      this.operations[key] = {
        icon: null,
        reference: null
      };
    }

    if (status.icon) {
      this.operations[key].icon = status.icon
    }

    const templateProps: BackgroundOperationTemplateProps = {
      ... status,
      icon: this.operations[key].icon || "loading",
      showClose: typeof status.finished == "boolean" ?  status.finished : status.percentComplete == 100,
      showProgress: typeof status.percentComplete == "number"
    }   
    
    if (status.finished) {
      setTimeout(()=>{
        const idToRemove = this.operations[key].reference?.messageId
        this.notify.remove(idToRemove)
        delete this.operations[key];
      },
      1500)
    }
    const ref = this.notify.template(this.template, {
      nzData: {
        ...templateProps,
        percentComplete: Math.ceil(status.percentComplete)
      },
      nzDuration: 0,
      /**
       * This is weird.  
       * 
       * Setting `nzCloseIcon` to null SHOWS the icon,
       * but setting it to a TemplateRef that contains no content HIDES the icon
       */
      nzCloseIcon:  templateProps.showClose ? null : this.closeIconTemplate,
      nzKey: key
    });

    if (!this.operations[key].reference) {
      this.operations[key].reference = ref;
    }

  }
}
