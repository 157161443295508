<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div class="pad-main-content">
    <p class="text-left">Create reminders to send scheduled messages to individual contacts.</p>
    <nz-row [nzGutter]="8">
      <nz-col nzSpan="10" [ngStyle]="{ border: '1px solid #d9d9d9', borderRadius: '4px' }">
        <nz-calendar 
          [nzFullscreen]="false"
          [nzDateFullCell]="dateCellTpl">
        </nz-calendar>
        <ng-template #dateCellTpl let-date >
          <nz-badge class="reminder-dot" [nzDot]="getCountForDate(date) > 0">{{date | date:'d'}}</nz-badge>
        </ng-template>
      </nz-col>
      <nz-col nzSpan="14">
        <nz-table 
          
          class="txb-list" 
          #basicTable
          [nzTitle]="tableTitle"
          [nzBordered]="true"
          [nzNoResult]="empty" 
          [nzVirtualItemSize]="55"
          [nzData]="selectedDateMessages$ | async"
          [nzVirtualForTrackBy]="trackByDocumentId"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzVirtualMinBufferPx]="900"
          [nzScroll]="{ x: '800px', y: '70vh' }"
          >
          <thead>
            <tr>
              <th nzWidth="20%"></th>
              <th nzWidth="30%">Contact Name</th>
              <th nzWidth="25%">Phone Number</th>
              <th nzWidth="25%">Scheduled</th>
            </tr>
          </thead>
          <tbody>
            <ng-template nz-virtual-scroll let-data let-index="index">
              <tr (click)="createMessageModal(tplTitle,tplContent ,tplFooter,data)">
                <td>
                  <div *ngIf="data.send_status == 'sent'">
                    <i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></i> Sent
                  </div>
                  <div *ngIf="data.send_status == 'scheduled'">
                    <i nz-icon [nzType]="'info-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#faad13'"></i> Scheduled
                  </div>
                </td>
                <td>{{ data.Recipient }}</td>
                <td>{{data.to}}</td>
                <td>
                  {{ formatTimeZone(data.date,data.scheduleTimezone) }}
                </td>
              </tr>
            </ng-template>
          </tbody>
        </nz-table>
      </nz-col>
    </nz-row>
  </div>
</nz-card>

<ng-template #empty>
</ng-template>

<ng-template #tableTitle>
  <h3>Reminders for {{formDate | date: 'longDate'}}</h3>
</ng-template>

<ng-template #extraTemplate>
  <button nz-button nzType="primary" (click)="createMessageModal(tplTitle,tplContent ,tplFooter)"><i
      class="far fa-bell"></i>Create Reminder
    </button>

    <button nz-button nzType="primary" (click)="drwImportReminders = !drwImportReminders"><i
      class="far fa-file-import"></i>Import Bulk Reminders
    </button>
</ng-template>


<nz-drawer [nzClosable]="true" [nzVisible]="drwImportReminders" nzPlacement="right" nzTitle="Import Reminders"
  (nzOnClose)="drwImportReminders=false" [nzWidth]="800">
  <ng-container *nzDrawerContent>
    <app-reminder-import 
    *ngIf="drwImportReminders"       
    (ReminderImportQueued) = "drwImportReminders=false"
    ></app-reminder-import>
  </ng-container>
</nz-drawer>


<!-- MODAL HTML -->

<ng-template #tplTitle>
  <span *ngIf="editBool">Update Reminder</span>
  <span *ngIf="!editBool">Create Reminder</span>
</ng-template>

<ng-template #tplContent>
  
  <div style="text-align: center;font-size: 24px;" *ngIf="isSelectedMessageSent() && editBool"
    class="text-left"><i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></i> Sent
  </div>

  <form nz-form nzLayout="vertical" [formGroup]="messageForm" autocomplete="off">

    <nz-form-item>
      <nz-form-label nzFor="Recipient">Select Recipient</nz-form-label>
      <nz-form-control>
        <app-address-book [activated]="messageModal" [addressBookMode]="'reminders'" [perPage]="5" [clearOnSelect]="true"
          *ngIf="!messageForm.value.Recipient;else activeTag" (contactSelected)="chooseRecipient($event)">
        </app-address-book>
        <ng-template #activeTag>
          <nz-tag class="contactsTag tagDisabled"
            *ngIf="isSelectedMessageSent() || editBool;else editTag">
            {{messageForm.value.Recipient}}
          </nz-tag>
          <ng-template #editTag>
            <nz-tag class="contactsTag tagEnabled" (click)="clearRecipient()">
              {{messageForm.value.Recipient}}
            </nz-tag>
          </ng-template>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <ng-container *ngIf="messageForm.value.Recipient">
      <nz-form-item>
        <nz-form-label>Time</nz-form-label>
        <nz-form-control>
            <tz-date-time-picker [disabled]="isSelectedMessageSent() && editBool" [formGroup]="messageForm" timeZoneFieldName="scheduleTimezone" dateFieldName="date">
            </tz-date-time-picker>
            <span *ngIf="messageForm.errors?.dateError && !isSelectedMessageSent()" class="time-error">Please select a date in the future.</span>
        </nz-form-control>
      </nz-form-item>

      <ng-container *ngIf="cannedResponses | async as crs;else loadingCannedResponses">
        <nz-form-item class="inset-panel" *ngIf="crs.length > 0">
          <nz-form-label>Start from a Canned Response</nz-form-label>
          <nz-select nzPlaceHolder="Select a Canned Response" (ngModelChange)="selectCannedResponse($event)" formControlName="selectedCannedResponse">
            <nz-option nzCustomContent [nzLabel]="cr.name" [nzValue]="cr.body" *ngFor="let cr of crs">
              <h4 style="margin-bottom:0;">{{cr.name}}</h4>
              <small>{{cr.body}}</small>
            </nz-option>
          </nz-select>
        </nz-form-item>
      </ng-container>
      <ng-template #loadingCannedResponses>
        <div class="inset-panel">Loading...</div>
      </ng-template>

      <nz-form-item>
        <nz-form-label nzFor="body">Message</nz-form-label>
        <nz-form-control>
          <div *ngIf="isSelectedMessageSent() && editBool; else ableMessageBlock">
            <textarea disabled class="disabled-textarea" formControlName="body" rows="4">
            </textarea>
          </div>
          <ng-template #ableMessageBlock>
            <textarea formControlName="body" id="body" placeholder="Message..." rows="4" nz-input></textarea>
          </ng-template>
          <nz-form-extra *ngIf="messageForm.value.attachments.length > 0">
            <i nz-icon nzType="paper-clip"></i>&nbsp;
            <a (click)="mms.openPhoto(messageForm.value.attachments[0])">{{ messageForm.value.attachments[0]?.filename }}</a>&nbsp;
            <i *ngIf="!isSelectedMessageSent()" class="far fa-trash" nz-popconfirm nzPopconfirmTitle="Are you sure you want to remove this attachment?"
            (nzOnConfirm)="removeAttachment()"></i>
          </nz-form-extra>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  </form>

  <app-mms-uploader [isVisible]="showMMSModal" [mode]="mmsUploaderMode" (closeMMSUploaderEvent)="closeMMSUploader($event)">
  </app-mms-uploader>

  <ng-container *ngIf="messageForm.value.Recipient">
    <button *ngIf="this.authnz.currentSecurityProvider.canAccessBulkMMS() && !isSelectedMessageSent()" nz-button (click)="showMMSModal=true">
      {{messageForm.value.attachments.length > 0 ? 'Change Attachment' : 'Upload Attachment'}}
    </button>
    <button *ngIf="!isSelectedMessageSent()" style="margin-bottom: 12px" nz-button nz-popover
      [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate">
      View Merge Fields
    </button>
    <ng-template #titleTemplate>
      <i nz-icon nzType="close"></i> Merge Fields
    </ng-template>
    <ng-template #contentTemplate>
      <app-merge-field-popover></app-merge-field-popover>
    </ng-template>
  </ng-container>

</ng-template>

<ng-template #tplFooter>
  <button *ngIf="editBool" nz-popconfirm nzPopconfirmTitle="Delete Message?" (nzOnConfirm)="deleteReminder()" nz-button
    class="q-btn-default" style="float: left" nzType="danger"
    [disabled]="!messageForm.valid || isSelectedMessageSent()">
    Delete
  </button>
  <button *ngIf="editBool" [nzLoading]="loading" nz-button class="q-btn-primary" nzType="primary"
    (click)="updateReminder()" [disabled]="!messageForm.valid || isSelectedMessageSent()">
    Update
  </button>
  <button *ngIf="!editBool" [nzLoading]="loading" nz-button class="q-btn-primary" nzType="primary"
    (click)="createReminder()" [disabled]="!messageForm.valid">
    Save
  </button>
</ng-template>

<ng-template #pageTitleTemplate>
  Reminders <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Reminders'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>