<ng-container *ngFor="let formField of _formDefinition">
  <ng-container *ngIf="formField.type == 'button'">
    <button (click)="buttonClick(formField)" nz-button nzType="primary" nzBlock>{{formField.label}}</button>
  </ng-container>
  
  <ng-container *ngIf="formField.type == 'text'"> 
    <nz-form-item>
      <nz-form-label [ngClass]="extraClassDefinitions?.label?.join(' ')">{{formField.label}}</nz-form-label>
      <nz-form-control 
        nzHasFeedback
        [nzExtra]="formField.explain"
        >
        <!-- 
          NOTICE! The order of the attributes on this input tag MATTERS.  
          ngModelChange MUST occur after ngModel for the downstream event handlers to function properly
          https://github.com/angular/angular/issues/28107
        -->
        <input 
          nz-input 
          [(ngModel)]="values[formField.name]" 
          (ngModelChange)="inputChanged($event)" 
          type="text"
          [disabled]= "formField.disabled"
        />
      </nz-form-control>
    </nz-form-item>
  </ng-container>

  <ng-container *ngIf="formField.type == 'textarea'"> 
    <nz-form-item>
      <nz-form-label [ngClass]="extraClassDefinitions?.label?.join(' ')">{{formField.label}}</nz-form-label>
      <nz-form-control 
        nzHasFeedback
        [nzExtra]="formField.explain"
        >
        <!-- 
          NOTICE! The order of the attributes on this input tag MATTERS.  
          ngModelChange MUST occur after ngModel for the downstream event handlers to function properly
          https://github.com/angular/angular/issues/28107
        -->
        <textarea 
          nz-input 
          [(ngModel)]="values[formField.name]" 
          (ngModelChange)="inputChanged($event)" 
          type="text"
          [disabled]= "formField.disabled"
          [rows]="formField.rows"
        >
        </textarea>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
    
    
  <ng-container *ngIf="formField.type == 'password'">
    <nz-form-item>
      <nz-form-label [ngClass]="extraClassDefinitions?.label?.join(' ')">{{formField.label}}</nz-form-label>
      <nz-form-control 
        nzHasFeedback
        [nzExtra]="formField.explain"
        >
        <!-- 
          NOTICE! The order of the attributes on this input tag MATTERS.  
          ngModelChange MUST occur after ngModel for the downstream event handlers to function properly
          https://github.com/angular/angular/issues/28107
        -->
        <input 
          nz-input 
          [(ngModel)]="values[formField.name]" 
          (ngModelChange)="inputChanged($event)" 
          type="password"
          [disabled]= "formField.disabled"
        />
      </nz-form-control>
    </nz-form-item>
  </ng-container>
  
    
  <ng-container *ngIf="formField.type == 'select'">
    <nz-form-item>
      <nz-form-label [ngClass]="extraClassDefinitions?.label?.join(' ')">{{formField.label}}</nz-form-label>
      <nz-form-control 
        nzHasFeedback
        [nzExtra]="formField.explain"
        >
        <!-- 
          NOTICE! The order of the attributes on this input tag MATTERS.  
          ngModelChange MUST occur after ngModel for the downstream event handlers to function properly
          https://github.com/angular/angular/issues/28107
        -->
        <nz-select 
          nz-input 
          [(ngModel)]="values[formField.name]" 
          (ngModelChange)="inputChanged($event)" 
          [disabled]= "formField.disabled"
          [nzMode]="formField.selectLimit != 1 ? 'multiple' : 'default'"
        >
          <nz-option
            *ngFor="let choice of formField.choices"
            nzValue="{{ choice.value }}" 
            nzLabel="{{ choice.label }}"
            ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</ng-container>