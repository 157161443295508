<ng-container>
  <nz-card  
    [nzActions]="mode == 'Available' ? [actionInstall, actionInfo] : [actionSetting,actionUninstall,actionInfo]"
    [nzTitle]="CardTitle"
    [nzExtra]="CardSubTitle"
    class="integration-card"
    >
    <p>{{integrationDefinition.description}}</p>
  </nz-card>
  <ng-template #actionInstall>
    <i nz-icon nzType="api" nz-tooltip nzTooltipTitle="Install" (click)="toggleModal()"></i>
  </ng-template>
  <ng-template #actionInfo>
    <i nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Help" (click)="guideVisibleBool = !guideVisibleBool"></i>
  </ng-template>
  <ng-template #actionSetting>
    <i nz-icon nzType="setting" nz-tooltip nzTooltipTitle="Settings" (click)="toggleModal()"></i>
  </ng-template>
  <ng-template #actionUninstall>
    <i nz-icon nzType="disconnect" nz-tooltip nzTooltipTitle="Uninstall" nz-popconfirm nzPopconfirmTitle="Are you sure you want to uninstall this integration?" (nzOnConfirm)="uninstall()"></i>
  </ng-template>
  <nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
    <ng-container *nzModalContent>
      <app-user-guides [selectedGuide]="'Integration - ' + this.integrationDefinition.name"></app-user-guides>
    </ng-container>
  </nz-modal>
  <ng-template #guideFooter>
  
  </ng-template>
  <ng-template #CardTitle>
    {{IntegrationName}}<br/>
    <small *ngIf="integrationInstallation">{{ this.integrationDefinition.name }}</small>
  </ng-template>
  <ng-template #CardSubTitle>
    <img 
      style="height: 40px; width: 40px;"
      [src]="'assets/integration_icons/' + this.integrationDefinition.id + '.png'"/>
  </ng-template>
</ng-container>


<ng-container *ngIf="view=='Install'">
  <nz-modal  
    [nzVisible]="true" 
    [nzStyle]="{ top: '30px' }" 
    [nzTitle]="'Install ' + IntegrationName " 
    (nzOnCancel)="toggleModal()" 
    nzWidth="60%"
    [nzOkText]="Install"
    (nzOnOk)="handleInstallationOk()"
    [nzOkLoading]="isOkLoading">
    <ng-container *nzModalContent>
      <ng-container *ngIf="!installationResult">
        <form [formGroup]="installationOptionForm">
          
          <nz-form-item>
            <nz-form-label>Select an installation option</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              [nzExtra]="selectedInstallationType?.summary || ''"
              >
              <nz-select 
                formControlName="installationType"
                (ngModelChange)="changeSelectedForm()"
                >
                <ng-container *ngFor="let installOption of integrationDefinition.installationTypes">
                  <nz-option [nzValue]="installOption.name" [nzLabel] = "installOption.name"></nz-option>
                </ng-container>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <ng-container *ngIf="selectedInstallationType">
            <nz-form-item *ngIf="selectedInstallationType.allowUserDefinedName">
              <nz-form-label ngClass="integration-form-label">Name</nz-form-label>
                <nz-form-control 
                  nzHasFeedback
                  nzExtra="A name to identify this installation"
                  >
                  <input
                    nz-input
                    type="text"
                    formControlName="InstallationName"
                    />
                </nz-form-control>
            </nz-form-item>
            <integration-installation-form
              [baseFormGroup] = "installationOptionForm"
              [formDefinition] = "selectedInstallationType.fields"
              lifecycleState = "new"
              [extraClassDefinitions]="{label: ['integration-form-label'] }"
              >
            </integration-installation-form>
          </ng-container>
        </form>
      </ng-container>
      <ng-container *ngIf="installationResult">
        <span [style.color]="installationResult.color">{{installationResult.message}}</span>
      </ng-container>
    </ng-container>
  </nz-modal>
</ng-container>


<ng-container *ngIf="view=='Settings'">
  <nz-modal 
    [nzVisible]="true" 
    [nzStyle]="{ top: '30px' }" 
    [nzTitle]="'Settings for ' + IntegrationName  + ' (' + integrationDefinition.name + ')'"
    (nzOnCancel)="toggleModal()" 
    nzWidth="60%"
    (nzOnOk)="handleSettingsOk()"
    [nzOkLoading]="isOkLoading"
    >
    <ng-container *nzModalContent>
      <form [formGroup]="installationOptionForm">
        <nz-form-item>
          <nz-form-label>Installation Type: {{integrationInstallation.InstallationBaseType}}</nz-form-label>
        </nz-form-item>
        <nz-form-item *ngIf="selectedInstallationType.allowUserDefinedName">
          <nz-form-label>Name</nz-form-label>
          <input
            nz-input
            type="text"
            formControlName="InstallationName"
            />
        </nz-form-item>
        <integration-installation-form
          [baseFormGroup] = "installationOptionForm"
          [sourceDocment] ="integrationInstallation.preferences"
          [formDefinition] = "selectedInstallationType.fields"
          lifecycleState = "edit"
          >
        </integration-installation-form>
      </form>
    </ng-container>
  </nz-modal>
</ng-container>


