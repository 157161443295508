<nz-card [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div>
    <nz-tabset [nzSelectedIndex]="selectedTabIndex">
      <nz-tab nzTitle="Available Integrations">
        <div class="backdropcolor-background" style="padding:30px;">
          <div nz-row [nzGutter]="8">
            <ng-container *ngFor="let integration of availableIntegrations">
              <div nz-col [nzSpan]="8">
                <app-integration-settings
                  mode="Available"
                  [integration]="integration"
                  (integrationChangedEvent)="reloadIntegrations($event)"
                  >
                </app-integration-settings>
              </div>
            </ng-container>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Installed Integrations">
        <div class="backdropcolor-background" style="padding:30px;">
          <div nz-row [nzGutter]="8">
            <ng-container *ngFor="let integration of installedIntegrations">
              <div nz-col [nzSpan]="8">
                <app-integration-settings
                  mode="Installed"
                  [integration]="integration"
                  (integrationChangedEvent)="reloadIntegrations($event)"
                  >
                </app-integration-settings>
              </div>
            </ng-container>
          </div>
        </div>
      </nz-tab>
      <!-- coming soon <nz-tab nzTitle="Admin Defined Integrations" *ngIf="this.authnz.currentSecurityProvider.canEditAdminIntegrations()">
        <div class="backdropcolor-background" style="padding:30px;">
          <div nz-row [nzGutter]="8">
            <ng-container *ngFor="let integration of installedIntegrations">
              <div nz-col [nzSpan]="8">
                <app-integration-settings
                  mode="Installed"
                  [integration]="integration"
                  (integrationChangedEvent)="reloadIntegrations($event)"
                  >
                </app-integration-settings>
              </div>
            </ng-container>
          </div>
        </div>
      </nz-tab>-->
    </nz-tabset>
  </div>
</nz-card>

<ng-template #empty>
</ng-template>

<ng-template #loadingintegrations>
  <p class="text-center">Loading Integrations...</p>
</ng-template>

<ng-template #pageTitleTemplate>
 Integrations <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Integrations'"></app-user-guides>
  </ng-container>
</nz-modal>

<ng-template #guideFooter>

</ng-template>