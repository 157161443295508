<ng-template let-operationStatus="data" #tplStatusUpdate>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <i nz-icon [nzType]="operationStatus.icon" style="color: var(--ant-primary-color)"></i>
      </span>
      <div class="ant-notification-notice-message">{{ operationStatus.operation }}</div>
      <div class="ant-notification-notice-description">
        {{ operationStatus.statusText }}
        <nz-progress *ngIf="operationStatus.showProgress" [nzPercent]="operationStatus.percentComplete" nzSize="small"></nz-progress>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tplCloseIcon></ng-template>