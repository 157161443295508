<p>Relay is a feature that enables the synchronization of messages that are sent or received to a user's phone number with external systems like CRMs, Support Desk software, and more.</p>

<nz-divider nzText="Webhook Event Data" nzOrientation="left"></nz-divider>
<p>Relay sends a POST to the Relay Webhook URL when a message is sent or received. The POST body is formatted as such:</p>
<code>
  {{
    '{'
  }}
  <br>
  MessageID: 'abc123xyz', // the unique message ID<br>
  ConversationID: "xyz321cba", //the unique contact ID<br>
  ToNumber: "+15555555555", //the number the message was sent to<br>
  FromNumber: "+13333333333", //the number the message was sent from<br>
  MessageBody: "Hello, world!", //the message body<br>
  MessageDirection: "in", //the message direction (either "in" or "out")<br>
  TextableUserID: "lmnopqrstuv", //the user id of the account that sent or received the message,<br>
  ContactName: "John Doe", //the Full Name property of the contact,<br>
  ContactEmail: "john.doe@gmail.com", //the e-mail address of the contact (if available),<br>
  Media: ["https://sampleUrl.com/URL1","https://sampleUrl.com/URL2"], //all MMS attachment urls associated with the message (if any)
  <br>
  {{
    '}'
  }}
</code>
<style>
  code {
    font-family: Consolas,"courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
  }
</style>