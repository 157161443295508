<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div>
    <div class="pad-main-content">
      <p class="text-center">Canned Responses are used for common replies to inquiries such as hours, frequently asked
        questions, and more.</p>
    </div>
    <ul class="txb-list">
      <li *ngFor="let resp of canned$ | async" class="list-item list-item-single-select" (click)="editResponse(resp)">
        <div nz-row>
          <div nz-col nzSpan="22">
            <p>
              <b>{{resp.name}}</b> 
              <ng-container *ngIf="resp.isAwayMessageOption">
                &nbsp;<i class="far fa-sms"></i>
              </ng-container>
              <br>
              {{resp.body}}<br>
              <small *ngIf="resp.auto_keyword"><b>Auto-Reply Keyword: </b> {{resp.auto_keyword}}</small>
            </p>
          </div>
          <div nz-col nzSpan="2">
            <button class="qr-code-button" *ngIf="resp.auto_keyword" nz-button nzType="primary" nzShape="circle" nz-popover
              [nzPopoverTrigger]="'hover'" [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate"><i
                nz-icon nzType="qrcode"></i></button>
            <ng-template #titleTemplate>Shareable QR Code</ng-template>
            <ng-template #contentTemplate>
              <div style="width:300px;text-align:center;">
                <qrcode #contactQR [qrdata]="'smsto:'+authnz.activeNumberProfile?.phone_number+':'+resp.auto_keyword"
                  [width]="256" [errorCorrectionLevel]="'Q'" elementType="img"></qrcode>
                <ng-container *ngIf="clipboardAPISupported">
                  <br />
                  <a (click)="CopyQRCodeImageToClipboard()"><i nz-icon nzType="copy" nzTheme="outline"></i>Copy to
                    Clipboard</a>
                  <br />
                  <span id="copiedToClipboard">{{copiedToClipboard}}</span>
                  <br />
                </ng-container>
                <small>Share this code on flyers, social media and other marketing materials to promote keyword auto
                  replies.</small>
              </div>
            </ng-template>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nz-card>

<ng-template #extraTemplate>
  <button (click)="addResponse()" nz-button nzType="primary"><i class="far far-add"></i> Add New Response</button>
</ng-template>

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="drwEditResponse"
  nzPlacement="right"
  [nzTitle]="(activeResponse ? 'Edit' : 'Add') + ' Canned Response'"
  (nzOnClose)="closeDrwEditResponse()"
  [nzWidth]="400"
>
  <ng-container *nzDrawerContent>
    <form nz-form nzLayout="vertical" [formGroup]="frmEditResponse" (ngSubmit)="updateResponse()">
      <nz-form-item>
        <nz-form-label nzRequired>Friendly Name</nz-form-label>
        <nz-form-control 
          nzHasFeedback
          nzErrorTip="Please enter a friendly name for this response"
          >
          <input nz-input formControlName="name" placeholder="e.g. How Can We Help You?" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Auto-Reply to Keyword: (optional)</nz-form-label>
        <nz-form-control 
          nzHasFeedback
          nzErrorTip="Keywords must be at least 4 characters">
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input nz-input formControlName="auto_keyword" placeholder="e.g. SUPPORT or TACOS" type="text"/>
          </nz-input-group>
          <ng-template #suffixTemplate><i nz-icon nz-tooltip nzTooltipTitle="(If this keyword is received exactly by your phone number, it will trigger this auto-reply. Leave blank to disable.)" nzType="info-circle"></i></ng-template>
        </nz-form-control>
        <app-keyword-notice></app-keyword-notice>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>Message Body</nz-form-label>
        <nz-form-control 
          nzHasFeedback
          nzErrorTip="Message body must be at least 3 characters"
          >
          <textarea nz-input formControlName="body" placeholder="e.g. Thanks for contact us today, how can we help you?" rows="6"></textarea>
          <nz-form-extra *ngIf="frmEditResponse.value.attachments?.length > 0">
            <i nz-icon nzType="paper-clip"></i>&nbsp;
            <a (click)="mms.openPhoto(frmEditResponse.value.attachments[0])">{{ frmEditResponse.value.attachments[0]?.filename }}</a>&nbsp;
            <i class="far fa-trash" nz-popconfirm nzPopconfirmTitle="Are you sure you want to remove this attachment?"
            (nzOnConfirm)="removeAttachment()"></i>
          </nz-form-extra>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>Is Away Message?</nz-form-label>
        <nz-form-control>
          <nz-switch formControlName="isAwayMessageOption"></nz-switch>
        </nz-form-control>
      </nz-form-item>
    </form>

    <app-mms-uploader [isVisible]="showMMSModal" [mode]="mmsUploaderMode" (closeMMSUploaderEvent)="closeMMSUploader($event)">
    </app-mms-uploader>


    <button *ngIf="this.authnz.currentSecurityProvider.canAccessBulkMMS()" nz-button class="drawer-button" (click)="showMMSModal=true">
      {{frmEditResponse.value.attachments?.length > 0 ? 'Change Attachment' :  'Upload Attachment'}}
    </button>
    <button class="drawer-button" nz-button nz-popover [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate">
      View Merge Fields
      </button>
      <ng-template #titleTemplate>
        <i nz-icon nzType="close"></i> Merge Fields
      </ng-template>
      <ng-template #contentTemplate>
        <app-merge-field-popover></app-merge-field-popover>
      </ng-template>
      
    <form nz-form nzLayout="vertical" [formGroup]="frmEditResponse" (ngSubmit)="updateResponse()">
      <button [disabled]="!frmEditResponse.valid" nz-button nzType="primary" nzBlock [nzLoading]="isUpdating">{{(activeResponse ? 'Edit' : 'Add') + ' Canned Response'}}</button><br/><br/>
      <button nz-button nzType="danger" nzBlock [nzLoading]="isDeleting" *ngIf="activeResponse"  nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this response?" (nzOnConfirm)="deleteResponse()">Delete Response</button>
    </form>
  </ng-container>
</nz-drawer>

<ng-template #pageTitleTemplate>
  Canned Responses <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Canned Responses'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>