type IntervalTimer = ReturnType<typeof setInterval>;

export class PausableCountdown {
  // #region Properties (1)

  private intervalObject: IntervalTimer;
  private elapsedMS: number;
  private finished: boolean = false;

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(
    private duration: number,
    private tickInterval: number,
    private onTick: (elapsed: number, duration: number) => void,
    private onFinish: (cause: "completed" | "cancelled") => void
  ) {
    this.elapsedMS = 0;
    this.resume();
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)

  public cancel() {
    clearInterval(this.intervalObject);
    delete this.intervalObject
    this.preFinish("cancelled");
  }

  public pause() {
    clearInterval(this.intervalObject);
    delete this.intervalObject
  }

  public reset() {
    this.elapsedMS = 0;
    this.tick();
  }

  public resume() {
    if (this.intervalObject) {
      // we already have an interval object, so there's nothing to do
      return
    }
    this.tick();
    this.intervalObject = setInterval(() => {
      this.tick();
    }, this.tickInterval);
  }

  // #endregion Public Methods (3)

  // #region Private Methods (1)

  private tick() {
    this.elapsedMS += this.tickInterval;
    if (this.elapsedMS >= this.duration) {
      clearInterval(this.intervalObject);
      this.preFinish("completed");
    }
    else {
      this.onTick(this.elapsedMS,this.duration)
    }
  }

  private preFinish(cause: "completed" | "cancelled") {
    if (this.finished) {
      // already finished; don't finish again
      return;
    }
    this.finished = true;
    this.onFinish(cause)
  }

  // #endregion Private Methods (1)
}
