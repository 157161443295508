import { Component, ViewChild, TemplateRef, Inject, ViewContainerRef } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthNZService } from './core/authnz.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ControlMessagingService } from './services/control-messaging.service';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import 'firebase/compat/messaging';
import { TextableService } from './services/textable.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApplicationStates } from './core/auth-nz/interfaces';
import { ObservableContexUpdateSignal, SubscriptionManagerService } from './core/subscription-manager.service';
import { DOCUMENT } from '@angular/common';
import { vars } from 'src/app/app.constants';
import { UserNotificationService } from './services/user-notification.service';
import { ApplicationContextService } from './services/application-context.service';
import { ThemeService } from './services/theme.service';
import { BackgroundOperationStatusService } from './background-operation-status.service';
import { SessionRevocationService } from './services/session-revocation.service';
import { TimezoneService } from './services/timezone.service';
import { TextableDate } from 'src/classes/TextableDate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  /**
   * This environment reference is necessary to access the environment 
   * in the HTML template
   */
  environment = environment;

  /**
   * Stores the current route
   */
  private currentRoute: ActivatedRouteSnapshot;

  /**
   * Drives the template to render from the HTML template.
   */
  public currentTemplate:string =  "tplLogin";

  constructor(
    public router: Router, 
    public authnz: AuthNZService,
    public textable: TextableService,
    private userNotifications: UserNotificationService,
    private backgroundOperationStatus: BackgroundOperationStatusService,
    public applicationContext: ApplicationContextService,
    public themeService: ThemeService,
    @Inject(ViewContainerRef) viewContainerRef,
    private document: Document,
    private timezoneService: TimezoneService
  ){
    this.timezoneService.buildAvailableTimezones();
    this.userNotifications.setContainer(viewContainerRef);
    this.backgroundOperationStatus.setContainer(viewContainerRef);
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        if(route){
          this.currentRoute = route;
        }
      }
    });
  }

  
  ngOnInit(){
    this.authnz.authChanged.subscribe((state) => {
      if (this.currentRoute.routeConfig.path === "confirm-account/:uid/:hash") { 
        this.currentTemplate = "tplLogin"
        return;
      }
      else if (state === ApplicationStates.LoggedOut) {
        this.currentTemplate = "tplLogin"
        // Only redirect if we're not already on this route.
        // We don't want to lose URL Query paramters on 
        // the login route here if we were to indiscriminately redirect
        if (this.currentRoute.routeConfig.path !== "login") {
          this.router.navigate(['login']);
        }
      }
      else if(state == ApplicationStates.PendingEmailVerification) {
        this.currentTemplate = "tplLogin"
        if (this.currentRoute.routeConfig.path !== "login") {
          this.router.navigate(['login']);
        }
      }
      else if( state == ApplicationStates.Error) {
        this.currentTemplate = "tplError"
      }
      else {
        this.currentTemplate = "tplFullApp"
      }
    });
  }
}
