import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getLogger } from '@shared/logging';
import { PhoneNumber } from '@shared/PhoneNumber';
import { isTextableContact, TextableContact } from 'src/app/backported_types/contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { ContactDTO } from 'src/classes/contactDTO';
const log = getLogger("ContactDisplayComponent");

@Component({
  selector: 'inline-contact',
  templateUrl: './contact-display.component.html',
  styleUrls: ['./contact-display.component.scss']
})
export class ContactDisplayComponent implements OnInit, AfterViewInit {

  /**
   * The current ContactDTO;
   * public because we read it in the HTML template
   */
  public _contact: string | PhoneNumber | ContactDTO;
  /**
   * The render mode of the current contact;
   * public because we read it in the HTML template
   */
  public viewMode: "raw" | "PhoneNumber" | "KnownContact"

  public frmContactDetails: FormGroup;
  public isSavingContact: boolean = false;


  
  @ViewChild('newContactCard') 
  private newContactCard: TemplateRef<void>;

  @ViewChild('knownContactCard') 
  private knownContactCard: TemplateRef<void>;

  public contactPopover: TemplateRef<void>;
  private hasRendered: ()=>void;
  private renderedPromise = new Promise<void>((resolve,reject)=>{this.hasRendered = resolve});

  /**
   * Supply the contact to display;
   * Can be any of:
   *  * Contact ID
   *  * Phone Number
   *  * Contact Object
   */
  @Input() 
    set contact(value: string | PhoneNumber | TextableContact ) {
      this.setContact(value);
    }

    

  constructor(
    private contactService: ContactsService
  ) { }
  ngAfterViewInit(): void {
    this.hasRendered();
  }

  ngOnInit(): void {
    this.frmContactDetails = new FormGroup({
      "full_name": new FormControl(null,[
        Validators.required,
        Validators.minLength(2),
      ])
    });
  }

  public async updateContactDetails() {
    const tempContact =  {
      full_name: this.frmContactDetails.value.full_name,
      phone_number: (this._contact as PhoneNumber).ToE164()
    }
    this.isSavingContact = true;
    this.frmContactDetails.reset();
    const newC = await this.contactService.createContact(tempContact)
    this.setContact(newC);
    this.isSavingContact = false
  }

  /**
   * Parses the input to this component, and tries to derive a contact.
   * 
   * Updates the render mode of the component as it makes progress.
   */
  private async setContact(c: string | PhoneNumber |  TextableContact | ContactDTO) {
    if (!c) {
      return;
    }
    await this.renderedPromise;
    if (typeof c == "string") {
      this.viewMode = "raw";
      this._contact = c;
      this.contactPopover = null;
      try {
        const tn = new PhoneNumber(c);
        this.viewMode = "PhoneNumber";
        this._contact = tn;
        this.setContact(tn);
      }
      catch (err) {
        log.warn(`Could not parse ${c} as PhoneNumber`, err)
      }
    }
    else if (isTextableContact(c)) {
      this.setContact(c.phone_number);
    }
    else if (c instanceof PhoneNumber) {
      this.viewMode = "PhoneNumber";
      this._contact = c;
      this.contactPopover = this.newContactCard;
      this.contactService.getContactByPhoneNumber(c).then(c=>{
        this.setContact(c);
      })
    }
    else if (c instanceof ContactDTO) {
      this._contact = c;
      this.viewMode = "KnownContact"
      this.contactPopover = this.knownContactCard
    }
  }

}
