import { parse, ParseError, ParseResult }  from "papaparse"
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map, scan } from "rxjs/operators";
import { pickBestFromList } from 'src/app/functions';

import { getLogger } from 'src/shared/logging';
const log = getLogger("CSVParser")


/**
 * Stores the mapping of ParsedImportRow to ImportableContact fields
 * either as column indexes if no header is provided
 * or as parsed object keys if a header was provided.
 */
export type ImportColumnMapping<T> = {
  [importField in keyof T]: number | string;
};


/**
 * PapaParse will return either objects 
 * with keys defined by the header row of the CSV
 * or arrays with imported values in the index of the source column.
 */
export type ParsedImportRow = Record<string,string> | Array<string>;

export type CSVParseResult<T> = {
  data: T[]
  errors: ParseError[]
  columnMapping: Partial<ImportColumnMapping<T>>
}

export type ParseStatus<T> = {
  rowsParsed: number
  percentComplete: number
  result?: CSVParseResult<T>
};

export type ColumnDataFormatHint = (val:string)=>boolean


export class CSVParser<T> {

  constructor(
    /**
     * Really, this should be a string array of all the keys of T
     * not sure how to type it
     */
    private desiredObjectHeaders: string[], 
    private columnDataFormatHints: Record<keyof T, ColumnDataFormatHint>
  ){}

  private importColumnMapping: Partial<ImportColumnMapping<T>>;

  /**
   * Given a File input and whether or not that file has a header row, parse the CSV
   * out to JSON objects.
   * 
   * As the parse progresses, emits status updates.
   * 
   * Completes the observable when the parse has completed.
   * 
   * @param f 
   * @param hasHeaderRow 
   * @returns 
   */
  public ParseCSV(f: File, hasHeaderRow: boolean): Observable<ParseStatus<T>> {

    const parseStatusObservable = new BehaviorSubject<ParseResult<ParsedImportRow>>(null);
    this.importColumnMapping = {};

    parse<ParsedImportRow>(f, {
      worker: true,
      skipEmptyLines: "greedy",
      header: hasHeaderRow,
      chunkSize: Math.max(f.size/100,10000),
      chunk(parseResults, parser) {
        parseStatusObservable.next(parseResults);
      },
      complete(parseResults, file) {
        parseStatusObservable.next(parseResults);
        parseStatusObservable.complete();
      }
    })

    const mappedObservable = parseStatusObservable.pipe(
      filter(x=> !!x),
      map(parseResults=>{
        
        if (Object.keys(this.importColumnMapping).length == 0) {
          this.importColumnMapping = this.getColumnMapping(hasHeaderRow, parseResults)
          log.debug("Determined column mappings", this.importColumnMapping)
        }

        /**
         * This callback is invoked every so many bytes
         * so we can coalesce the imported data into our format;
         * as well as update the progress bar.
         */
        return { 
          parseResults: parseResults, 
          objects: parseResults.data.map((r)=>{
            return this.transformRowToObject(r)
          })
        }
      }),
      scan( 
        (ps, {parseResults,objects}) => ({
          percentComplete: Math.ceil((parseResults.meta.cursor / f.size)*100),
          rowsParsed: [...ps.result.data, ...objects].length,
          result: {
            data: [...ps.result.data, ...objects],
            errors: parseResults.errors,
            columnMapping: this.importColumnMapping
          }
        }), 
        {
          percentComplete: 0,
          rowsParsed: 0,
          result: {
            data: [],
            errors: [],
            columnMapping:  null
          }
        }
      )
    )

    return mappedObservable;
  }

  private getColumnMapping(hasHeaderRow: boolean, parseResults: ParseResult<ParsedImportRow>): Partial<ImportColumnMapping<T>> {
    if (hasHeaderRow) {
      return this.guessColumnMappingWithHeader(parseResults);
    }
    else {
      return this.guessColumnMappingWithoutHeader(parseResults);
    }
  }

  /**
   * Attempt to map a ParsedImportRow to a ImportableContact through either the header mapping
   * or guessing at the columns.
   * 
   * TODO: Add a user-definable column mapping
   * 
   * @param parseResults 
   * @param hasHeaderRow 
   */
  private transformRowToObject(rowData: ParsedImportRow) {
    let c = {}
    for (let k of this.desiredObjectHeaders ){
      if (typeof rowData[this.importColumnMapping[k]] !== "undefined") { 
        c[k] = rowData[this.importColumnMapping[k]]
      }
    }
    return c as T;
  }

  private guessColumnMappingWithHeader(parseResults: ParseResult<ParsedImportRow>):  Partial<ImportColumnMapping<T>> {
    let cm:  Partial<ImportColumnMapping<T>> = {};
    log.debug("Mapping CSV header column to TextableContact types")
    for (let field of parseResults.meta.fields){
      if (Object.values(cm).includes(field)) {
        // Don't look at columns which are already mapped
        continue;
      }
      const best = pickBestFromList(field, this.desiredObjectHeaders);
      cm[best] = field
      log.debug("Mapped import field name '" + best + "' to '" + field + "'")
    }
    return cm;  
  }

  private guessColumnMappingWithoutHeader(parseResults: ParseResult<ParsedImportRow>): Partial<ImportColumnMapping<T>> {
    let cm:  Partial<ImportColumnMapping<T>> = {};
    /**
     * Look at the values for each column from this many rows of data
     * before guessing what the column contains
     */
    const ROWS_TO_CONSIDER_FOR_GUESS = Math.min(parseResults.data.length, 10);
    /**
     * Require at least this percentage of cells to contain values
     * that look like the desired column
     */
    const ROWS_PERCENT_MATCH_REQUIRED = .5
    log.debug("Guessing at mapping for CSV column to TextableContact types")
    const resultSamples = parseResults.data.slice(0,ROWS_TO_CONSIDER_FOR_GUESS);
    const sampleColumnCounts = resultSamples.map(s=>s.length as number)
    log.debug("Sample Column Counts", sampleColumnCounts)
    const maxCols = Math.max(...sampleColumnCounts);
    const minCols = Math.min(...sampleColumnCounts);
    const colsToEvaluate =  Math.min(minCols, maxCols);
    log.debug("Looking at first "+ROWS_TO_CONSIDER_FOR_GUESS+" imported rows to determine field mappings.  Min cols: " +  minCols +". Max Cols: " + maxCols)
    if (minCols !== maxCols) {
      log.warn("some rows have more columns than others.  Only evaluating " + colsToEvaluate + " columns")
    }
    
    for (let columnIterator = 0; columnIterator<colsToEvaluate;columnIterator++) {
      if (Object.values(cm).includes(columnIterator)) {
        // Don't look at columns which are already mapped
        continue;
      }

      for (let fieldIterator = 0; fieldIterator < Object.keys(this.columnDataFormatHints).length; fieldIterator++) {
        const field = this.desiredObjectHeaders[fieldIterator];
        const isColMatchTotal = (resultSamples.map(sample=>sample[columnIterator]).filter(testValue => {
            const testResult = this.columnDataFormatHints[field](testValue)
            return testResult
          })).length 
        const isColMatchPercent = isColMatchTotal / ROWS_TO_CONSIDER_FOR_GUESS
        const isColumnDesiredField = isColMatchPercent > ROWS_PERCENT_MATCH_REQUIRED
        const matchPercentString = (isColMatchPercent*100).toLocaleString(undefined,{maximumFractionDigits: 2}) +"%"
        if (isColumnDesiredField) {
          cm[field] = columnIterator
          log.debug("Mapped import column '" + cm[field] + "' to '" + field + "' with match percent " + matchPercentString) 
          break;
        }
      }
    }
    return cm;
  }
  
}
