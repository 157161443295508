import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { SelectOptionList } from 'src/app/interfaces'
import { testPropertyMatchesQuery } from 'src/app/functions'
import { UserManagementService } from 'src/app/services/user-management.service';
import { TextableUser } from 'src/app/backported_types/users';

@Component({
  selector: 'app-user-multi-selector',
  templateUrl: './user-multi-selector.component.html',
  styleUrls: ['./user-multi-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UserMultiSelectorComponent
    }
  ]
})

export class UserMultiSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() placeholderText: string;
  searchChange$ = new ReplaySubject();
  filterStatus = null;
  optionList: SelectOptionList[] = [];
  selectedUsers: any[];
  onChange: any;
  onTouched: any;
  availableUsers$: Observable<TextableUser[]>;

  names = new ReplaySubject();


  constructor(
    private userService: UserManagementService
  ) {
    this.availableUsers$ = this.userService.getUsers();
   }

  /**
   * Hook into the lifecycle function 
   * provided by https://angular.io/api/forms/ControlValueAccessor
   * 
   * This method is called by the forms API to write to the view
   * when programmatic changes from model to view are requested. 
   * 
   * This iterates over the incoming form data (which should be an array of UIDs as strings) 
   * and queries firestore to get the actual user documents 
   * so we can render a "prettified" representation of the user.
   * 
   * The firestore documents are converted to a label/value object and placed into
   * an instance variable for the optionList of the select box
   * 
   * @param obj array of selected UIDs as strings
   * @returns 
   */
  writeValue(obj: any): void {
    if (!obj) {
      this.selectedUsers = []
      return;
    }
    this.optionList = [];
    Promise.all(obj.map(async (uid: string) => {
      try{
        const tUser = await this.userService.getUser(uid);
        this.optionList.push({
          value: tUser.id,
          label: this.formatUserString(tUser)
        });
        return uid;
      }
      catch (err) {
        console.warn("No user data for: " + uid, err);
      }
    })).then((data) => {
      let filteredData = data.filter(item => item !== undefined);
      this.selectedUsers = filteredData;
    });
   
  }

  onSelectChange(data: any) {
    this.onChange(this.selectedUsers)
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  formatUserString(user: any) { //TODO: Refactor this to use TextableUser from TextableCommon TXBDEV-193
    return `${user.full_name} (${user.phone_number})`
  }



  /**
   * Searches the supplied user object against the supplied query
   * 
   * 
   * @param user 
   * @param query 
   * @returns Boolean whether the user matches
   */
  testUserMatchesSearchString(user: TextableUser,query: string): boolean {
    query=query.toLowerCase();

    return testPropertyMatchesQuery(user,"full_name",query) ||
      testPropertyMatchesQuery(user,"phone_number",query) ||
      testPropertyMatchesQuery(user,"email",query)
      
  }

  filterMatchingUsers = (name: string): Observable<SelectOptionList[]> => {
    return this.availableUsers$
      .pipe(
        catchError(() => of({ results: [] })),
      )
      .pipe(
        map(
          (list: TextableUser[]) => 
            list
              .filter( (user)=>{
                const matched =  this.testUserMatchesSearchString(user,name);
                //log.debug("User matched: " + matched , user);
                return matched;
              })
              .map((item: TextableUser) => ({
                value: item.id,
                label: this.formatUserString(item)
              })
          )
        )
      )
    }

  ngOnInit() {
    const optionList$: Observable<SelectOptionList[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(this.filterMatchingUsers));


    optionList$.subscribe(data => {
      this.optionList = data;
      this.filterStatus = null;
    });
  }

  onSearch(value: string): void {
    const minSearchChars = 1
    this.filterStatus = {};
    if (value.length > minSearchChars) {
      this.filterStatus.message  = "Loading";
      this.filterStatus.iconType ="loading";
      this.searchChange$.next(value);
    }
    else if (value.length >  0) {
      this.filterStatus.message = "Type at least " + (minSearchChars+1-value.length) + " more characters to search";
      this.filterStatus.iconType ="exclamation-circle";
    }
  }

}
