import { Injectable } from '@angular/core';
import 'firebase/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'
import { getLogger } from "src/shared/logging";
import { AuthNZService } from '../core/authnz.service';
import { BackendService } from './backend.service';
import { ApplicationStates } from '../core/auth-nz/interfaces';
import { environment } from 'src/environments/environment';
import { UserManagementService } from './user-management.service';
import { UserNotificationService } from './user-notification.service';
import { DOMCoordinatedMessageChannel } from '@shared/ServiceWorkerHelpers/DOMCoordinatedMessageChannell';
import { filter, first } from 'rxjs/operators';
import { DraftCoordinatedControlMessage, DraftCoordinatedDataMessage } from '@shared/ServiceWorkerHelpers/BaseCoordinatedMessageChannel';
import { TXB_FCM_UserMessageReceived } from 'src/sw/txb-messaging';

const log = getLogger("ControlMessagingService");


const SERVICE_WORKER_RELATIVE_PATH = "/sw.js"
@Injectable({
  providedIn: 'root',
})
export class ControlMessagingService {

  public serviceWorkerMessageChannel: DOMCoordinatedMessageChannel;
  private serviceWorkerRegistration: ServiceWorkerRegistration
  private currentToken: string;
  messaging: firebase.messaging.Messaging
  currentMessage = new BehaviorSubject(null);

  constructor(
    private authnz: AuthNZService,
    public backendService: BackendService,
    private userService: UserManagementService,
    private userNS: UserNotificationService
    ) {
    if (! ("serviceWorker" in navigator)) {
      throw new Error('Service workers are not supported.');
    }
    this.messaging = firebase.messaging();
    this.authnz.authChanged.subscribe(async (state)=>{
      if (state == ApplicationStates.PreLogout) {
        this.removeExistingRegistrations();
      }
      if (state == ApplicationStates.LoggedIn) {
        this.SetupNotifications();
      }
    })
  }

  public async reRegisterServiceWorker() {
    await this.removeExistingRegistrations();
    await this.SetupNotifications();
  }

  public async SetupNotifications(shouldRequest = false) {
    if (! shouldRequest && Notification.permission != 'granted') {
      log.debug("Notification permission has not been granted, and we can't ask for it in this context.")
      this.userNS.requestPermission();
      return;
    }
    try {
      await Notification.requestPermission();
    }
    catch (err) {
      log.warn('Unable to get permission to notify.', err);
    }
    try {
      await this.registerServiceWorker();
    }
    catch(err) {
      log.warn("Failed to register service worker", err)
      return;
    }
    try {
      this.currentToken = await this.messaging.getToken({
        serviceWorkerRegistration: this.serviceWorkerRegistration
      });
      log.debug("FCM Token: " + this.currentToken)
      this.userService.addUserDeviceToken(this.authnz.currentFireauthUser.uid, this.currentToken); 
    }
    catch (err) {
      log.warn("Unable to get FCM token", err)
    }
  }


  /**
   * Registeres the service worker
   * 
   * DOES NOT CATCH EXCEPTIONS so they'll bubble up.
   * 
   * @returns 
   */
  private async registerServiceWorker() {

    const regs = await navigator.serviceWorker.getRegistrations();
    log.debug("regs", regs)
    regs.filter(r=>r.scope.endsWith("/firebase-cloud-messaging-push-scope")).forEach(r=>{
      log.debug("Unregistering old service worker ", r)
      r.unregister()
    })
    this.serviceWorkerRegistration = regs.find((r)=>r.active?.scriptURL.endsWith(SERVICE_WORKER_RELATIVE_PATH))
    if (!this.serviceWorkerRegistration) {
      this.serviceWorkerRegistration = await navigator.serviceWorker.register(SERVICE_WORKER_RELATIVE_PATH, { scope: "/", updateViaCache: 'none' })
      log.debug("Registered New Service Worker", this.serviceWorkerRegistration)
    }
    else {
      
      log.debug("Using Existing Service Worker", this.serviceWorkerRegistration)
    } 
    this.serviceWorkerMessageChannel = new DOMCoordinatedMessageChannel(navigator.serviceWorker)
    this.serviceWorkerMessageChannel.dataMessages.subscribe(async (m)=>{
      
      if (m.command == "requestFirebaseInit") {
        const message: DraftCoordinatedDataMessage = {
          command: "firebaseInit",
          data: {
            ...environment.firebase
          }
        }
        log.debug("Sending Firebase Config to service worker");
        this.serviceWorkerMessageChannel.sendDataMessage(message);
      }

      if (m.command == "newBackgroundMessage") {
        const message  = m.data as TXB_FCM_UserMessageReceived
        let subTitle: string = "";
        if (message.direction == "in") {
          subTitle = `Sent to ${message.phone_number}`;
        }
        else if (message.direction == "to") {
          const user = await this.userService.getUser(message.sentBy);
          subTitle = `Sent by ${user?.full_name || "Unknown"}`
        }
        else {
          const user = await this.userService.getUser(message.sentBy);
          subTitle = `By ${user?.full_name || "Unknown"}`
        }
        this.userNS.showNotification({
          title: message.title,
          body: message.body,
          from: message.full_name,
          to: message.phone_number,
          uid: message.activeUserID,
          contactId: message.contactID,
          subTitle: subTitle
        })
      }
    })

  
  }

  /**
   * Sometimes it's necessary to unregister and re-register the service worker;  this cleans up 
   * all service workers in this page's scope
   */
  private async removeExistingRegistrations() { 
    this.userService.removeUserDeviceToken(this.authnz.currentFireauthUser.uid, this.currentToken);
    const sws = await navigator.serviceWorker.getRegistrations();
    if (sws.length > 0) {
      log.debug("Existing registrations", sws)
      for (let s of sws) {
        try {
          await s.unregister();
          log.debug("removed worker", s)
        }
        catch (err) {
          log.warn("Failed to removed worker", s)
        }
      }
    }

  }

  public TestPushNotification(token?: string) {
    this.backendService.backendPost("api/v2/users/"+this.authnz.currentFireauthUser.uid+"/testNotifitactions",{},{token:token})
  }
}
