import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from "ng-zorro-antd/modal"
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TextableService } from '../../services/textable.service';
import { RelayDocComponent } from 'src/app/components/modals/relay-doc/relay-doc.component';
import { vars } from 'src/app/app.constants';
import { AuthNZService } from 'src/app/core/authnz.service';
import log from 'loglevel';
import { ColumnItem, UserManagementService } from '../../services/user-management.service';
import { BackendService } from '../../services/backend.service';
import { NzFilterItem } from '../../../app/interfaces';
import { testPropertyMatchesQuery, trackByDocumentId } from 'src/app/functions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { KeypressserviceService } from 'src/app/services/keypressservice.service';
import { NzTabComponent } from 'ng-zorro-antd/tabs';
import { ApplicationContextService } from '../../services/application-context.service';
import { RestrictList } from './user-constants';
import { TextableOrganization, TextableUser } from 'src/app/backported_types/users';

type DisplayTextableUser = TextableUser & {
  organizationName?: string
}
import { MatchesField } from 'src/app/form_validators/MatchesField';
import { ValidateField } from 'src/app/form_validators/ValidateField';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class UsersComponent implements OnInit {
  public trackByDocumentId = trackByDocumentId;
  isLoadingUsers: boolean = true;
  activeUser: any = null;
  activeOrg: any = null;
  isSavingUser: boolean = false;
  isUpdatingPassword: boolean = false;
  isAddingSharedWith: boolean = false;
  isLoadingOrgs: boolean = false;
  /**
   * Boolean to define whether or not to show the add new user modal
   */
  showAddUserModal: boolean = false;
  changePasswordError: string = null;
  frmEditUser: FormGroup;
  frmEditPassword: FormGroup;
  frmAddSharedWith: FormGroup;
  frmRelayWebhook: FormGroup;
  frmAPIToken: FormGroup;
  organizations: Array<any>;
  environment = environment;
  public phoneMask = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; //ensures the first digit is not +, 0, or 1. This allows the user to paste '+1##########' formatted numbers.
  activeUserNumbers$: Observable<any>;
  activeUserSharing = [];
  frmRestrictions: FormGroup;
  activeTab: NzTabComponent;
  userSearchValue = '';
  orgSearchValue = '';
  /**
   * used to track the selected tab inside the Edit User Details Drawer
   */
  editUserTabNumber: number = 0;
  showOrgEditorModal: boolean = false;
  organizationEditorModalMode: "new"|"edit";
  /**
   * All organizations to which the currently logged-in user has "view" (or higher) access.
   */
  AllOrganizations: TextableOrganization[] = [];
  /**
   * Filter values for the Organization filter in the User display grid
   */
  listOfOrgIDs: NzFilterItem[] = [];
  visibleOrganizations: TextableOrganization[]
  activeUserTokens = [];
  activeOrgUsers: any[];
  restrictList = RestrictList
  isUpdatingRestrictions: boolean;
  guideVisibleBool = false;
  samlDef: boolean;
  /**
   * Keeps track of the status of the sendInvite function
   */
  userSendingInvite: any;
  //phonePrefix should change depending on location in the future.
  phonePrefix: string = '+1';
  countryCodes = vars.countryCodes;

  //TABLE SORT VARS
  /**
   * All users to which the currently logged-in user has "view" (or higher) access.
   */
  AllUsers:any[] = [];
  /**
   * Users to be displayed in the "Users" UI grid view (filtered by modifications to criteria)
   * 
   * TODO: TXBDEV-193 replace this with TextableUser when we can use TextableCommon Types after Angular/TypeScript upgrade
   */
  listOfDisplayUsers: DisplayTextableUser[] = [];
  sortName: string | null = null;
  sortValue: string | null = null;
  listOfSearchName: string[] = [];

  /**
   * When deleting a user, we can either soft or hard delete.
   * This state is determined at the time the delete is triggered, so we 
   * need to store it for displaying and calling the api
   */
  public permanentDelete: boolean = false;

  /**
   * Toggles whether deleted users should be included when
   * updateGrid is called for users or organizations.
   * 
   * This is modified when the selected tab changes between
   * "Users" and "Deleted Users" and between
   * "Organizations" and "Deleted Organizations"
   */
  private showDeleted = false;


  /**
   * List of Columns and their props/funcs for Orgs
   */
  listOfOrgColumns: ColumnItem<TextableOrganization>[] = [
    {
      name: 'Organization Name',
      sortOrder: null,
      sortFn: (a,b)=> a.organizationName.localeCompare(b.organizationName),
      listOfFilter: null,
      filterFn: null
    },
    {
      name: 'User Count',
      sortOrder: null,
      sortFn: (a, b) => a.userCount - b.userCount,
      listOfFilter: null,
      filterFn: null
    }
  ];
  /**
   * List of Columns and their props/funcs for users
   */
  listOfUserColumns: ColumnItem<DisplayTextableUser>[] = [
    {
      name: 'Organization',
      sortOrder: null,
      sortFn: (a, b) => a.organizationName.localeCompare(b.organizationName),
      listOfFilter: null,
      filterFn: (orgIdList: string[], item) => orgIdList.some(id => item.organizationId?.indexOf(id) !== -1)
    },
    {
      name: 'Name',
      sortOrder: null,
      sortFn: (a, b) => a.full_name.localeCompare(b.full_name),
      listOfFilter: null,
      filterFn: null
    },
    {
      name: 'Email',
      sortOrder: null,
      sortFn: (a, b) => a.email.localeCompare(b.email),
      listOfFilter: null,
      filterFn: null
    },
    {
      name: 'DID',
      sortOrder: null,
      sortFn: (a, b) => a.phone_number.localeCompare(b.phone_number),
      listOfFilter: null,
      filterFn: null
    }
  ];

  constructor(
    private auth: AngularFireAuth,
    public authnz: AuthNZService,
    private afs: AngularFirestore,
    private msg: NzMessageService,
    private http: HttpClient,
    public textable: TextableService,
    private modalService: NzModalService,
    private userManagementService: UserManagementService,
    private backendService: BackendService,
    private notification: NzNotificationService,
    public keypress: KeypressserviceService,
    private userService: UserManagementService,
    public applicationContextService: ApplicationContextService
  ) { 

  }

  ngOnInit() {
    this.initializeForms();
    this.syncRestrictionsWithEnvironmentFeatures();
    // Initialize activeTab to "users" so that the logic for buttons to show works at page load.
    (this.activeTab as Partial<NzTabComponent>) = {
      nzTitle: "Users"
    }
    // Get a subscription to all users, including deleted, since we'll be filtering out deleted users internally
    this.userService.getUsers(true).subscribe(
      (users)=>{
        this.AllUsers = users;
        this.updateDisplayUsers(users);
        this.isLoadingUsers = false;
        this.UpdateGrid('users');
      },
      (error) => {
        log.error("Error getting users: ", error);
      }
    );


    this.textable.getOrganizations()
      .subscribe(
        (orgs)=>{
          this.AllOrganizations = orgs
          this.isLoadingOrgs = false;
          this.UpdateGrid('orgs');
          this.updateDisplayUsers(this.listOfDisplayUsers)
        },
        (error) => {
          log.error("Error getting organizations: ", error);
        });

    this.authnz.getLoginProviders().then(data => this.samlDef = data.length > 0)

  }

  private async updateDisplayUsers(users: TextableUser[]){

    this.listOfDisplayUsers = users.map(u=>({
      ...u,
      providerName: this.getUserProvider(u),
      organizationName: this.getOrgByID(u.organizationId)?.organizationName || ""
    }))
  }
  /////////////////////////
  // TABLE FUNCTIONS
  ////////////////////////
  filterData(data: any): any {
    for (let i = 0; i < data.length; i++) {
      data[i]["billingPlan"] = data[i]["billing"] === null || data[i]["billing"] === undefined ? "" : (data[i]["billing"].plan === undefined ? "" : data[i]["billing"].plan)
      if (data[i][this.sortName] === null || data[i][this.sortName] === undefined) {
        data[i][this.sortName] = ""
      }
    }

    return (
      data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!].toString().toLowerCase() > b[this.sortName!].toString().toLowerCase()
            ? 1
            : -1
          : b[this.sortName!].toString().toLowerCase() > a[this.sortName!].toString().toLowerCase()
            ? 1
            : -1
      )
    )
  }

  UpdateGrid(grid: string): void {
    const deletedFilterFunc = (deleteable: {deleted?: number}) => {
      const show =  this.showDeleted ?
        ( deleteable.hasOwnProperty("deleted") && deleteable.deleted != 0) // include objects which both have a deleted property, and the deleted property is not zero
        : 
        (! deleteable.hasOwnProperty("deleted")  || deleteable.deleted == 0) // include objects which either have no deleted property, or the deleted property is zero
      return show;
    }
    /** filter data **/
    const filterFunc = (item) => {
      return (
        this.listOfSearchName.length ? 
          this.listOfSearchName.some(organizationId => item.organizationId?.indexOf(organizationId) !== -1) :
          true
      )
    }
    if (grid == "users") {
      const data = this.AllUsers
        .filter(item => filterFunc(item))
        .filter(deletedFilterFunc)
      /** sort data **/
      if (this.sortName && this.sortValue) {
        this.updateDisplayUsers(this.filterData(data))
      } else {
        this.updateDisplayUsers(data);
      }
    } else {
      const data = this.AllOrganizations
        .filter(item => filterFunc(item))
        /**
         * 
         * TODO: TXBDEV-193 - once we have real types here, the TextableOrganization property should have a `deleted` attribute,
         * and the TSC compiler should be happy here.
         * 
         * @ts-ignore */
        .filter(deletedFilterFunc);
      this.listOfOrgIDs = data.map((x) => {
        return {
          value: x.id, text: x.organizationName
        }
      });
      this.listOfUserColumns[0].listOfFilter = this.listOfOrgIDs

      /** sort data **/
      if (this.sortName && this.sortValue) {
        this.visibleOrganizations = this.filterData(data)
      } else {
        this.visibleOrganizations = data;
      }
    }
  }

  getOrgByID(orgID){
    if(!this.AllOrganizations){
      return;
    }
    let org = this.AllOrganizations.filter(org => org.id == orgID)[0];
    return org;
  }

  initializeForms() {
    this.frmEditPassword = new FormGroup({
      "password": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.password),
        ValidateField("cPassword")
      ]),
      "cPassword": new FormControl(null, [
        Validators.required,
        MatchesField("password")
      ])
    })

    this.frmEditUser = new FormGroup({
      "full_name": new FormControl(null, [
        Validators.required
      ]),
      "external_id": new FormControl(null, []),
      "email": new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      "phone_number": new FormControl({ value: '', disabled: !this.authnz.currentSecurityProvider.canEditUserPhoneNumber() }, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.phone),// Require length of 12(on read) then 14 (on write)
      ]),
      "provider": new FormControl({ value: '', disabled: !this.authnz.currentSecurityProvider.canEditUserFullName() }, [
        Validators.required
      ]),
      "is_disabled": new FormControl({ value: '', disabled: !this.authnz.currentSecurityProvider.canEditUserDisabled() }, []),
      "isAdmin": new FormControl({ value: '', disabled: !this.authnz.currentSecurityProvider.canEditUserIsAdmin() }, []),
      "organizationId": new FormControl({ value: '', disabled: !this.authnz.currentSecurityProvider.canEditUserOrganization() }, [
        Validators.required
      ]),
      "cell": new FormControl({ value: '', disabled: !this.authnz.currentSecurityProvider.canEditUserPhoneNumber() }) //this is being used as a placeholder for now untill the fix is deployed for provisionRetailTeamUsers
    });



    this.frmAddSharedWith = new FormGroup({
      "shareWithID": new FormControl({ value: null, disabled: !this.canAccessUserNumberSharingTab() }, [
        Validators.required
      ])
    });

    this.frmRelayWebhook = new FormGroup({
      "relayWebhook": new FormControl(null)
    });

    this.frmAPIToken = new FormGroup({
      "tokenName": new FormControl(null, [
        Validators.required
      ]),
      "accessToken": new FormControl(null, [
        Validators.required
      ])
    });

    this.frmRestrictions = new FormGroup({
      "restrictionList": new FormControl()
    });

  }

  clearActiveUser() {
    this.activeUser = null;
    this.activeUserSharing = [];
    this.activeOrgUsers = [];
    this.frmRestrictions.reset();
    this.frmEditPassword.reset();
    this.frmEditUser.reset();
  }

  updateRelayWebhook() {
    this.afs.doc('users/' + this.activeUser.id).update(this.frmRelayWebhook.value)
      .then((status) => {
        this.isSavingUser = false;
        this.msg.create('success', 'Relay webhook has been updated.');
      })
      .catch((err) => {
        this.isSavingUser = false;
        this.msg.create('danger', 'Relay webhook could not be updated.');
      });
  }

  async updateUser() {
    this.isSavingUser = true

    const updateRequest = this.frmEditUser.value

    if (this.authnz.currentSecurityProvider.canEditUserPhoneNumber()) {
      updateRequest.phone_number = this.textable.addCountryCode(updateRequest.phone_number, this.phonePrefix);
    }
    else {
      delete updateRequest.phone_number;
    }

    try {
      const response = await this.backendService.backendPost("api/v2/users/" + this.activeUser.id,
        {},
        updateRequest,
        {
          method: "patch"
        }
      );
      log.debug("user edit response", response.data)

      this.isSavingUser = false;
      this.msg.create('success', this.frmEditUser.get('full_name').value + '&rsquo;s details have been updated.');


    }
    catch (error) {
      this.isSavingUser = false;
      log.debug("user edit error", error)
      this.msg.create('danger',
        this.frmEditUser.get('full_name').value + '&rsquo;s details could not be updated. Error: ' + error.error.error
      );
    }
  }

  /**
   * Evaluates the UI form elements against the environments features
   * so that only environment-enabled features are elgible for disabling 
   * from the manage users page.
   */
  syncRestrictionsWithEnvironmentFeatures() {
    const syncedRestrictions = this.restrictList.filter(feature => { 
      //for features that are not gated by the environment
      if (feature.ignoreEnvFeatures == true) {
        return true;
      }
      return this.environment.features[feature.value]; 
    });
    this.restrictList = syncedRestrictions;
  }

  updateRestrictions() {

    this.isUpdatingRestrictions = true;

    let restrictions: Array<any> = this.frmRestrictions.get('restrictionList').value.map(p => p.checked ? null : p.value).filter(p => p != null);

    this.afs.doc('users/' + this.activeUser.id).update({ restrictions: restrictions })
      .then(
        () => {
          this.isUpdatingRestrictions = false;
          this.msg.create('success', 'Permissions have been updated successfully.');
        }
      )
      .catch(
        (err) => {
          this.isUpdatingRestrictions = false;
          this.msg.create('danger', 'Permissions could not be updated. Error: ' + err);

        }
      );

  }

  btnAddUser() {
    this.showAddUserModal = true;
  }

  organizationEditorModalDismissed() {
    log.debug("Organization editor dismissed");
    this.showOrgEditorModal = false;
  }

  btnEditUser(user) {
    log.debug("Opening User editor for: ", user);
    //reset the edit form
    this.frmRestrictions.reset();
    this.frmEditPassword.reset();
    this.frmEditUser.reset();
    //set the provider to user.provider
    this.activeUser = user;
    this.activeUserNumbers$ = this.userManagementService.getUsersNumbers(user.id);
    this.activeOrgUsers = this.AllUsers.filter((u)=>{
      return (u.organizationId == user.organizationId) && !(u.deleted > 0);
    });

    this.refreshSharedWith();

    if (this.authnz.currentSecurityProvider.canModifyUserWebhookRelay()) {
      // Setup an observable to watch the user's API relay tokens
      // BUT only if the current user is authorized to do so
      // otherwise, we'd get console errors from Firebase authorization failures.
      this.textable.getAccessTokens(user).subscribe((tokens: any) => {
        this.activeUserTokens = tokens;
      })
    }

    //patch value
    this.frmEditUser.patchValue(user);
    this.frmRelayWebhook.patchValue(user);


    /**
       * Object deep-copy is necessary within map operations for `restrictList` since changes to the form state would
       * otherwise propagate to the base `restrictList` when the form state is manipulated by the end user.
       * 
       * Without a deep copy here, changes to one user who does not have a restrictions array would appear to be present for
       * all other users who also do not have a restrictions array (i.e. TXBDEV-1587)
       * 
       * Custom iteration of the array is more efficient than a JSON encode/decode cycle.
       */
    if (user.restrictions) {
      let tempRestrictions = this.restrictList.map(o=>({
        ...o,
        checked: !user.restrictions.includes(o.value) && !o.disabled, //Override the copied `checked` attribute based on whether the current user's restrictions includes this value
       })
      )
      this.frmRestrictions.patchValue({ restrictionList: tempRestrictions });
    } else {
      this.frmRestrictions.patchValue({ restrictionList: this.restrictList.map(o=>({...o}))} ); 
    }
    this.editUserTabNumber = 0;
  }

  createAccessToken() {
    this.backendService.backendPost('frontend/users/' + this.activeUser.uid + '/token', {}, {})
      .then((response: any) => {
        this.msg.create('success', 'Access token has been created.');
      }, (err) => {
        this.msg.create('error', 'There was a problem creating an access token. Please try again.');
      });
  }

  /**
   * 
   * @deprecated this seems unused?
   * @param data 
   * @param event 
   */
  updateAccessToken(data, event) {
    this.backendService.backendPost('frontend/users/' + this.activeUser.uid + '/token/' + data.id, {}, { isActive: event })
      .then((response: any) => {
        this.msg.create('success', 'Access token has been ' + (data ? 'activated' : 'deactivated') + '.');
      }, (err) => {
        this.msg.create('error', 'There was a problem updating the access token. Please try again.');
      });
  }

  confirmTokenDelete(data) {
    this.backendService.backendPost('frontend/users/' + this.activeUser.uid + '/deleteToken/' + data.id, {}, {})
      .then((response: any) => {
        this.msg.create('success', 'Access token has been deleted.');
      }, (err) => {
        this.msg.create('error', 'There was a problem deleting the access token. Please try again.');
      });
  }

  /**
   * Called when the confirmUserDelete popup is opened.
   * @param event 
   */
  deleteShow(event) {
    if (event == true) { // only update this.permanentDelete when the popup is opening; ignore close operations for the popup.
      this.permanentDelete = this.keypress.isShiftKeyDown() || this.showDeleted
    }
  }

  /**
   * Prompts for confirmation to delete the user, and then calls UserManagementService to finish the deletion
   * 
   * @param user - The user to delete TODO: TXBDEV-193 replace this with TextableUser when we can use TextableCommon Types after Angular/TypeScript upgrade
   */
  confirmUserDelete(user: TextableUser, force: boolean) {
    this.isLoadingUsers = true;
    if (confirm("Are you sure you want to delete this user? This CANNOT be undone and will delete user's conversations, contacts, etc. Users who this number is shared with will not be able to access it.")) {
      this.userManagementService.deleteUser(user, force).then((r) => {
        this.clearActiveUser();
        this.notification.create(r.status, r.title, r.message)
        this.isLoadingUsers = false;
      })
    }
  }

   /**
   * Prompts for confirmation to un-delete the user, and then calls UserManagementService to finish the undeletion
   * 
   * @param user - The user to undelete TODO: TXBDEV-193 replace this with TextableUser when we can use TextableCommon Types after Angular/TypeScript upgrade
   */
  confirmUserUndelete(user: TextableUser) {
    this.isLoadingUsers = true;
    if (confirm("Are you sure you want to undelete this user?  This will also re-enable the user for login and sending messages")) {
      this.userManagementService.undeleteUser(user).then((r) => {
        this.clearActiveUser();
        this.notification.create(r.status, r.title, r.message)
        this.isLoadingUsers = false;
      })
    }
  }

  updateUserPassword() {
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    if (strongRegex.test(this.frmEditPassword.get('password').value)) {
      if (this.frmEditPassword.get('password').value !== this.frmEditPassword.get('cPassword').value) {

        this.changePasswordError = "Please make sure the password field and confirm password field match.";
      } else {
        this.userManagementService.changePassword(this.frmEditPassword.get('password').value, {
          firebase_document_id: this.activeUser.id,
          ...this.activeUser
        })
      }
    } else {
      this.changePasswordError = "Passwords must be at least six characters and \
        must contain one number, an uppercase and a lowercase letter.";
      this.msg.create('error', 'Passwords must be at least six characters and \
        must contain one number, an uppercase and a lowercase letter.');
    }
  }

  cancelNewUserModal() {
    this.showAddUserModal = false;
  }

  addShare() {
    this.isAddingSharedWith = true;
    if (!this.frmAddSharedWith.valid) {
      this.msg.create('error', 'Please select a user to share this number with.');
      return;
    }

    if (!this.activeUser.sharedWith) {
      this.activeUser.sharedWith = [];
    }

    this.activeUser.sharedWith.push(this.frmAddSharedWith.get('shareWithID').value);
    let sharedWith = this.activeUser.sharedWith;

    this.userManagementService.updateUserSharedWith(this.activeUser.id, sharedWith)
      .then((response) => {
        this.activeUser.sharedWith = sharedWith;
        this.refreshSharedWith();
        this.frmAddSharedWith.reset();
        this.msg.create('success', "Number sharing settings has been updated for user.");
        this.isAddingSharedWith = false;
      })
      .catch((err) => {
        this.msg.create('error', err);
        this.isAddingSharedWith = false;
      });
  }

  removeShare(id) {
    let sharedWith = this.activeUser.sharedWith.filter((x) => { return x != id });

    this.userManagementService.updateUserSharedWith(this.activeUser.id, sharedWith)
      .then((response) => {
        this.activeUser.sharedWith = sharedWith;
        this.refreshSharedWith();
        this.msg.create('success', "Number sharing settings has been updated for user.");
      })
      .catch((err) => {
        this.msg.create('error', err);
      });
  }

  refreshSharedWith() {
    if (!this.canAccessUserNumberSharingTab()) {
      return;
    }
    this.userManagementService.getSharedUsers(this.activeUser).then((users: any) => {
      //console.log("Users shared with:"+JSON.stringify(users));
      this.activeUserSharing = users;
    })
      .catch((err) => {
        log.error("Unable to update shared with users: ", err);
      });

  }

  isAlreadyShared(id) {
    if (!this.canAccessUserNumberSharingTab()) {
      return;
    }

    let isAlreadyShared = this.activeUserSharing.filter((sharedWith) => {
      return sharedWith.id == id;
    }).length;

    return isAlreadyShared > 0 ? true : false;
  }

  showRelayDocModal(): void {
    const modal = this.modalService.create({
      nzTitle: 'Relay Documentation',
      nzContent: RelayDocComponent,
      nzComponentParams: {
      },
      nzFooter: [{
        label: 'Close',
        onClick: (instance) => {
          this.modalService.closeAll();
        }
      }
      ],
      nzWidth: '50%'
    });

    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));

    // Return a result when closed
    modal.afterClose.subscribe(result => console.log('[afterClose] The result is:', result));

    // delay until modal instance created
    setTimeout(() => {
      const instance = modal.getContentComponent();
    }, 2000);

  }

  showAPIDocs(): void {
    (window as any).showDocs();
  }

  btnAddOrg() {
    this.organizationEditorModalMode = "new";
    this.showOrgEditorModal = true;

    this.activeOrg = {};
  }

  btnEditOrg(org) {
    this.organizationEditorModalMode = "edit";
    this.showOrgEditorModal = true;
    this.activeOrg = org;
  }



  canDeleteOrganization(org: TextableOrganization) {
    return this.authnz.currentSecurityProvider.canDisableOrganizations();
  }

  canUndeleteOrganization(org: TextableOrganization) {
    return org.deleted
  }

  confirmOrganizationDelete(id, force) {
    this.userService.deleteOrganization(id, force)
      .then((result) => {
        this.msg.create('success', 'Organization deleted successfully.');
      })
      .catch((err) => {
        this.msg.create('error', err);
      })
      .finally(() => {
        //this.isOrgSaving = false;
      });
  }

  confirmOrgUndelete(org: TextableOrganization) {
    this.isLoadingOrgs = true;
    if (confirm("Are you sure you want to undelete this organization?  This will also re-enable member users for login and sending messages")) {
      this.userManagementService.undeleteOrg(org).then((r) => {
        this.notification.create(r.status, r.title, r.message)
        this.isLoadingOrgs = false;
      })
    }
  }

  

  tabChanged(event: {index: number, tab: NzTabComponent}) {
    const {index,tab} = event;
    this.activeTab = tab;
    if (tab.nzTitle.toString().includes("Deleted")){
      this.showDeleted = true;
    }
    else {
      this.showDeleted = false;
    }
    this.UpdateGrid(tab.nzTitle.toString().includes("Users") ? "users":"organizations");
    
  }

  cancelAddOrg() {
    this.showOrgEditorModal = false;
    this.activeOrg = null;
  }

  resendEmail(user: TextableUser) {
    this.userSendingInvite = user.id;
    this.userManagementService.resendTeamEmail(user.id);
  }

  shouldShowAddUserButton() {
    return this.activeTab && this.activeTab.nzTitle == "Users" && this.authnz.currentSecurityProvider.canAddUsers();
  }

  shouldShowAddOrgsButton() {
    return this.activeTab && this.activeTab.nzTitle == "Organizations" && this.authnz.currentSecurityProvider.canAddOrgs();
  }

  shouldShowOrganizationsTab() {
    return this.authnz.currentSecurityProvider.canAddOrgs();
  }
  /**
   * Checks whether the current user may delete a specific user
   * 
   * TODO: TXBDEV-193 Refactor this to use TextableUser interface when TextableCommon become available in PF
   * 
   * @param data The TextableUser to be considered for deletion
   * @returns False if the supplied user is the current user, or is a superAdmin. 
   *    False if the current user is unauthorized to delete users by the current security provider.
   *    True if the user may be deleted
   */
  canDeleteUser(data) {
    return (
      this.authnz.currentSecurityProvider.canDeleteUsers() && // the current user needs to be able to delete users.
      data.id !== this.authnz.currentFireauthUser.uid && // user cannot delete self
      ! data.isSuperAdmin && // super admins may not be deleted
      data.managedBy != data.id // team admins may not be deleted
    );
  }

  canUndeleteUser(data) {
    return data.deleted
  }

  canEditFullName() {
    return false;
  }

  canAccessUserPermissionsTab() {
    return this.authnz.currentSecurityProvider.canModifyUserPermissions();
  }
  canAccessUserNumberSharingTab() {
    return this.authnz.currentSecurityProvider.canModifyUserNumberSharing();
  }

  /**
   * Called by the UI to make the backend call to change the organization's disabled status
   * 
   * @param id organization Id
   * @param isDisabled true / fals if the org should be disabled
   */
  async setOrganizationIsDisabled(id: string, isDisabled: boolean) {
    const messageVerb = isDisabled  ? "Disabled" : "Enabled"
    try {
      await this.backendService.backendPost(
        "api/v2/organizations/"+id,
        {},
        {
          is_disabled: isDisabled
        },
        {
          method: "patch"
        })
      this.msg.create('success', "Organization " + messageVerb + " successfully.");
    } catch (err) {
      this.msg.create('error', "Organization could not be " + messageVerb + ". Please try again.");
    }
  }

  /** Attempts to resolve the provdier which would be used for a supplied user
   * 
   * @param data: the current data row from the table; should be a TextableUser
   */
  private getUserProvider(data) {
    if (data.provider) {
      try {
        return this.textable.getProvider(data.provider).label
      }
      catch (err) {
        log.warn("error getting provider '" + data.provider +"' for user '" + data.id +"'" )
        return "Invalid"
      }
    }
    return "Unknown";
  }
  
  public targetOrgIsDisabled(orgId: string) {
    const to = this.AllOrganizations?.find(vo=>vo.id == orgId)
    return to?.is_disabled || to?.deleted >0;
  }

  userSearch(): void {
    this.isLoadingUsers = true;
    this.resetSort()

    this.updateDisplayUsers(this.AllUsers.filter(user => {
      const show =  this.showDeleted ?
      ( user.hasOwnProperty("deleted") && user.deleted != 0)
      : 
      (! user.hasOwnProperty("deleted")  || user.deleted == 0)
    return show;
    }));

    if(this.listOfDisplayUsers.length === 0){
      this.isLoadingUsers = false;
      return;
    }

    this.updateDisplayUsers(this.listOfDisplayUsers.filter((user) => {
      if ( 
        testPropertyMatchesQuery(user,"full_name",this.userSearchValue)
        ||
        testPropertyMatchesQuery(user,"email",this.userSearchValue)
        ||
        testPropertyMatchesQuery(user,"id",this.userSearchValue)
        ||
        testPropertyMatchesQuery(user,"phone_number",this.userSearchValue)
      ) {
        this.isLoadingUsers = false;
        return true;
      } else {
        this.isLoadingUsers = false;
        return false;
      }
    }));
  }

  orgSearch(): void {
    this.isLoadingOrgs = true
    this.resetSort()
    this.visibleOrganizations = this.AllOrganizations.filter(org => {
      const show =  this.showDeleted ?
        ( org.hasOwnProperty("deleted") && org.deleted != 0)
        : 
        (! org.hasOwnProperty("deleted")  || org.deleted == 0)
      return show;
    })

    if(this.visibleOrganizations.length === 0){
      this.isLoadingOrgs = false;
      return;
    }

    this.visibleOrganizations = this.visibleOrganizations.filter((org) => {
      if (
        testPropertyMatchesQuery(org,"organizationName",this.orgSearchValue)
        ||
        testPropertyMatchesQuery(org,"managedBy",this.orgSearchValue)
        ||
        testPropertyMatchesQuery(org,"id",this.orgSearchValue)
      ) {
        this.isLoadingOrgs = false
        return true;
      } else {
        this.isLoadingOrgs = false
        return false;
      }
    });
  }

  resetSort(){
    this.listOfOrgColumns.forEach(item => {
      item.sortOrder = null;
    });
    this.listOfUserColumns.forEach(item => {
      item.sortOrder = null;
    });
  }

  /**
   * Checks if the org has a valid plan type, returns 'Invalid Plan Type' if not valid
   * @param planId org's billing plan type
   * @returns org's billing plan type or 'Invalid Plan Type'
   */
  getPlanDisplayName(planId: string): string {
    const plan = this.authnz.currentSecurityProvider.getPlanSelectionDetails().choices.find(x => x.id == planId);

    if (!plan) {
      return 'Invalid Plan Type'
    }

    return plan.displayName

  }
}
