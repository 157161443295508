import { Injectable } from '@angular/core';
import { getLogger } from '@shared/logging';

const log = getLogger("UserPreferencesService");

type LocalPreference = "preferredActiveNumber" | "localSignatures";
type StorageType =  'localStorage' | 'sessionStorage' 

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {  

  private storageAvailable: boolean;
  constructor() { 
    this.storageAvailable = this.checkStorageAvailable("localStorage");
    if (!this.storageAvailable) {
      log.warn("LocalStorage not supported, some features will not be available")
    }
    this.scavengeLocalStorageKeys();
  }

  /**
   * Detects if LocalStorage is supported and available
   * 
   * Courtesy of https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
   * 
   * @param type 
   * @returns 
   */
  private checkStorageAvailable(type: StorageType) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
    }
}


  public getLocalPreference(preferenceName: LocalPreference) {
    if (!this.storageAvailable) {
      log.debug("LocalStorage not available; unable to read preference  '"+preferenceName +"'")
      return null;
    }
    const value = localStorage.getItem(preferenceName);
    if (value == "null" || value == null) {
      log.debug("Stored value of '"+preferenceName +" was null; removing")
      localStorage.removeItem(preferenceName);
      return null
    }
    else {
      log.debug("Read preference '"+preferenceName +": '"+value+"'")
      return value;
    }
  }

  public setLocalPreference(preferenceName: LocalPreference, value: string) {
    if (!this.storageAvailable) {
      log.debug("LocalStorage not available; unable to store preference  '"+preferenceName +"'")
      return null;
    }
    if (value == null) {
      log.debug("Removing '"+preferenceName)
      localStorage.removeItem(preferenceName);
    }
    else {
      log.debug("Storing '"+preferenceName+": " + value)
      localStorage.setItem(preferenceName,value);
    }
  }

  private scavengeLocalStorageKeys() {
    if (!this.storageAvailable) {
      return null;
    }
    /**
     * LocalStorage keys starting with any string in this
     * array will be removed.
     */
    const deprecatedPrefixes = ["logleve"]
    const keys = Object.keys(localStorage)
    keys.filter(k=>deprecatedPrefixes.find(dp=> k.startsWith(dp))).forEach(k=>{
      localStorage.removeItem(k);
      log.debug("Removed deprecated LocalStorage key '"+k+"'")
    })

  }

}
