import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { FronendNumberBlock } from "../../services/contacts.service.UserContactWatcher";
import { ContactsService } from "../../services/contacts.service";
import { getLogger } from "@shared/logging";
import { map } from "rxjs/operators";
const log = getLogger("ProfileComponent");

@Component({
  selector: "app-blocked-sender-manager",
  templateUrl: "./blocked-sender-manager.component.html",
  styleUrls: ["./blocked-sender-manager.component.scss"],
})
export class BlockedSenderManagerComponent implements OnInit{
  public frmBlockedNumbers: FormGroup;
  public BlockedNumbers: Observable<FronendNumberBlock[]>;
  private _userId: string;

  @Input()
  public set UserId(value: string) {
    if (value != this._userId) {
      this._userId = value;
      this.refreshBlockedNumbers();
    }
  }
  public get UserId(): string { 
    return this._userId; 
  }

  constructor(private contactService: ContactsService) {
    this.frmBlockedNumbers = new FormGroup({
      newPhoneNumberToBlock: new FormControl(""),
    });
  }

  ngOnInit(): void {}

  private refreshBlockedNumbers() {
    this.BlockedNumbers = this.contactService
      .getContactWatcher(this.UserId)
      .BlockedSenders
      .pipe(
        map((blockedNumbers) => {
          return blockedNumbers.sort((a, b) => a.phoneNumber.localeCompare(b.phoneNumber));
        })
      )
  }
}
