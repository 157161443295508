<ng-container 
  *ngTemplateOutlet="currentTemplate">
</ng-container>

<ng-template #tplButton>
  <button  
    nz-button nzType="default" 
    nz-tooltip [nzTooltipTitle]="nzTooltipTitle | async | titlecase" 
    nz-popover nzPopoverTrigger="click" [nzPopoverContent]="blockOptionsPopover"
    [(nzPopoverVisible)]="blockOptionsPopoverVisible"
    [disabled]="disabled || ((_sender| async).length == 0)">
      <i class="fas fa-ban" *ngIf="displayStyle == 'icon' || displayStyle == 'both'"></i>
      <span *ngIf="displayStyle == 'text' || displayStyle == 'both'">{{nzTooltipTitle | async | titlecase}}</span>
  </button>
</ng-template>

<ng-template #tplHref>
  <a 
    nz-popover nzPopoverTrigger="click" [nzPopoverContent]="blockOptionsPopover"
    [(nzPopoverVisible)]="blockOptionsPopoverVisible">
    <i class="fas fa-ban" *ngIf="displayStyle == 'icon' || displayStyle == 'both'"></i>
    <span *ngIf="displayStyle == 'text' || displayStyle == 'both'">{{nzTooltipTitle | async | titlecase}}</span>
  </a>
</ng-template>

<ng-template #blockOptionsPopover>
  <div class="blockOptionsPopover">
    <span *ngIf="(mode|async) == 'unblock'">{{ confirmationMessage | async }}</span>
    <div *ngIf="(mode|async) == 'block'">
      <nz-alert
        nzType="warning"
        nzMessage="Warning"
        nzDescription="Blocking a contact will prevent them from sending you messages, but you may still be charged by your provider for messages received."
        nzShowIcon
      ></nz-alert>
      <form nz-form nzLayout="vertical" [formGroup]="blockOptionsForm">
        <nz-form-item>
          <label nz-checkbox formControlName="delete">Delete contact and messages</label>
        </nz-form-item>
        <!--<nz-form-item>
          TODO: TXBDEV-2569 - Report blocked conversations externally as spam / junk
          <label nz-checkbox formControlName="reportSpam">Report as spam</label>
        </nz-form-item>-->
        <nz-form-item>
          <label nz-checkbox formControlName="sendStop">Send STOP message</label>
        </nz-form-item>
        </form>
    </div>
    <button nz-button nzType="primary" (click)="execute()">{{  mode | async |  titlecase }}</button>
  </div>
</ng-template>