<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div>
    <div class="pad-main-content">
      <p class="text-center">Blast a message out to multiple phone numbers at once. </p>
    </div>
    <nz-tabset [nzSelectedIndex]="'0'">
      <nz-tab nzTitle="Drafts">
        <nz-table class="txb-list" [nzNoResult]="empty" #tblDrafts>
          <thead>
            <tr>
              <th nzWidth="70%">Blast</th>
              <th>Recipients</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of draftBlasts$ | async"
              (click)="editBlast(data)">
              <td>{{ data.blastName }}<br><small>{{ data.blastMessage }}</small></td>
              <td>{{ formatBlastRecipients(data) }}</td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="Scheduled">
        <nz-table class="txb-list" [nzNoResult]="empty" #tblScheduled>
          <thead>
            <tr>
              <th nzWidth="60%">Blast</th>
              <th nzWidth="20%">Recipients</th>
              <th nzWidth="20%">Scheduled Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of scheduledBlasts$ | async"
              (click)="editBlast(data)">
              <td>{{ data.blastName }}<br><small>{{ data.blastMessage }}</small></td>
              <td >{{ formatBlastRecipients(data) }}</td>
              <td>{{ formatTimeZone(data.scheduleTime, data.scheduleTimezone ) }}</td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="In Progress">
        <nz-table class="txb-list" [nzNoResult]="empty" #tblInProgress>
          <thead>
            <tr>
              <th nzWidth="60%">Blast</th>
              <th nzWidth="20%">Recipients</th>
              <th nzWidth="20%">Start Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of inProgressBlasts$ | async">
              <td>{{ data.blastName }}<br><small>{{ data.blastMessage }}</small></td>
              <td>{{ formatBlastRecipients(data) }}</td>
              <td>{{ data.sendDate | date: "short" }}</td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
      <nz-tab nzTitle="Sent">
        <nz-table class="txb-list" [nzNoResult]="empty" #tblSent>
          <thead>
            <tr>
              <th nzWidth="55%">Blast</th>
              <th nzWidth="15%">Recipients</th>
              <th nzWidth="15%">Sent Date</th>
              <th nzWidth="15%">End Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of sentBlasts$ | async">
              <td>{{ data.blastName }}<br><small>{{ data.blastMessage }}</small></td>
              <td>{{formatBlastRecipients(data) }}</td>
              <td>{{ data.sendDate | date: "short" }}</td>
              <td>{{ data.completionDate | date: "short" }}</td>
            </tr>
          </tbody>
        </nz-table>
      </nz-tab>
    </nz-tabset>
  </div>
</nz-card>
<ng-template #empty></ng-template>

<ng-template #extraTemplate>
  <nz-button-group>
    <button nz-button nzType="primary" (click)="openDrwNewBlast()"><i class="far fa-rocket"></i>Send New Blast</button>
    <button nz-button nzType="primary" (click)="openMdlFooter()"><i class="fas fa-cog"></i></button>
  </nz-button-group>
</ng-template>

<nz-drawer [nzClosable]="true" [nzVisible]="drwNewBlast" nzPlacement="right"
  [nzTitle]="!activeBlast ? 'Send New Blast' : 'Edit Draft Blast'" (nzOnClose)="closeDrawer();addressBook.resetValues()" nzWidth="50%">
  <ng-container *nzDrawerContent>
    <div class="pad-15">
      <nz-spin [nzTip]="(isBlastSending ? 'Sending...' : 'Deleting...')" [nzSpinning]="isBlastSending || isBlastDeleting">

        <form nz-form nzLayout="vertical" [formGroup]="frmSendNewBlast">
          <nz-form-item>
            <nz-form-label nzRequired>Blast Name</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please enter a blast name. (Min. 5 chars.)"
              >
              <input nz-input formControlName="blastName" placeholder="e.g. Thanksgiving Special Offer." type="text" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzRequired>Blast Status</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please select a blast status."
              >
              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a status" formControlName="status">
                <nz-option nzLabel="Draft" nzValue="draft"></nz-option>
                <nz-option nzLabel="Send Now" nzValue="send"></nz-option>
                <nz-option nzLabel="Schedule" nzValue="scheduled"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

        <ng-container *ngIf="frmSendNewBlast.get('status').value=='scheduled'">
          <nz-form-item>
            <nz-form-label>Time</nz-form-label>
            <nz-form-control>
                <tz-date-time-picker
                [formGroup]="frmSendNewBlast"
                timeZoneFieldName="scheduleTimezone"
                dateFieldName="scheduleTime"
                ></tz-date-time-picker>
              <span *ngIf="frmSendNewBlast.errors?.dateError" style="color:red;margin-top:10px;">Please select a date in the future.</span>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

          <nz-form-item>
            <nz-form-label nzRequired>Message</nz-form-label>
            <nz-form-control 
              nzHasFeedback
              nzErrorTip="Please enter a message. (Min. 5 chars.)"
              >
              <textarea nz-input formControlName="blastMessage"
                placeholder="e.g. Use code TURKEY for 10% Off your next order." rows="5"></textarea>
                <nz-form-extra *ngIf="frmSendNewBlast.value.attachments.length > 0">
                  <i nz-icon nzType="paper-clip"></i>&nbsp;
                  <a (click)="mms.openPhoto(frmSendNewBlast.value.attachments[0])">{{ frmSendNewBlast.value.attachments[0]?.filename }}</a>&nbsp;
                  <i class="far fa-trash" nz-popconfirm nzPopconfirmTitle="Are you sure you want to remove this attachment?"
                    (nzOnConfirm)="removeAttachment()"></i>
                </nz-form-extra>
            </nz-form-control>
          </nz-form-item>

          
          <app-mms-uploader [isVisible]="showMMSModal" [mode]="mmsUploaderMode" (closeMMSUploaderEvent)="closeMMSUploader($event)">
          </app-mms-uploader>
  
          <button *ngIf="this.authnz.currentSecurityProvider.canAccessBulkMMS()" nz-button class="drawer-button" (click)="showMMSModal=true">
            {{frmSendNewBlast.value.attachments.length > 0 ? 'Change Attachment' : 'Upload Attachment' }}
          </button>
          <button class="drawer-button" nz-button nz-popover [nzPopoverTitle]="titleTemplate"
          [nzPopoverContent]="contentTemplate">
            View Merge Fields
          </button>
          <ng-template #titleTemplate>
            <i nz-icon nzType="close"></i> Merge Fields
          </ng-template>
          <ng-template #contentTemplate>
            <app-merge-field-popover></app-merge-field-popover>
          </ng-template>
          <nz-alert nzType="warning"
            nzMessage="Compliance Footer will be automatically appended. Contacts will be automatically opted out of automated messages when they reply with: STOP, END, CANCEL, QUIT, UNSUBSCRIBE, OPTOUT" >
          </nz-alert>

          <nz-form-item>
            <nz-form-label>Current Footer</nz-form-label>
            <nz-form-control
              nzExtra="Message Length (including Footer): {{getMessageCount()}}">
              <textarea nz-input formControlName="blastComplianceFooter" rows="3"></textarea>
            </nz-form-control>
          </nz-form-item>

          <div *ngIf="this.authnz.currentSecurityProvider.canAccessContactLists()">
            <nz-form-label nzRequired>Select Contacts</nz-form-label>
            <nz-tabset [nzSelectedIndex]="setSelectedTabIndex()" >
              <nz-tab nzTitle="Select List" (nzClick)="changeRecipientListSource('select')" (nzSelect)="changeRecipientListSource('select')">
                <nz-select *ngIf="availableContactLists?.length > 0" style="margin-bottom:530px" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedContactList"
                  (ngModelChange)="changeList($event)" nzPlaceHolder="Select a List">
    
                  <ng-container *ngFor="let list of availableContactLists">
                      <nz-option *ngIf="!list.isDeleted;else deletedListBlock" nzValue="{{list.id}}" nzLabel="{{list.listName + ' (' + (list?.count >= 0 ? list.count : '0') + ')' }}" >
                      </nz-option>
                      <ng-template #deletedListBlock>
    
                          <nz-option *ngIf="blast?.recipientList?.listName == list.listName" [nzDisabled]="true" nzValue="{{list.id}}" nzLabel="{{list.listName  + ' (Deleted)'}}" >
                          </nz-option>
                      </ng-template>
                  </ng-container>
    
                </nz-select>
                <p *ngIf="availableContactLists?.length == 0" style="margin-bottom:530px">
                  No contact lists have been created. <a [routerLink]="['/contact-list']" routerLinkActive="router-link-active">Create a List.</a>
                </p>
              </nz-tab>
              <nz-tab nzTitle="Custom List" (nzClick)="changeRecipientListSource('custom')" (nzSelect)="changeRecipientListSource('custom')">
                  <app-address-book 
                    #addressBook 
                    [activated]="frmSendNewBlast.value.recipientListSource == 'custom'" 
                    (selectedListChanged)="selectedListChanged($event)" 
                    [selectedContacts]="frmSendNewBlast.value.recipientList"
                    [clearOnSelect]="true" 
                    [addressBookMode]="'blast'"
                    [perPage]="10">
                  </app-address-book>
                  <br />
                  <span>* Contact has opted out from automated messages (like blasts, drip campaigns, etc.)</span>
                  <br />
                  <br />
                  <span *ngIf="frmSendNewBlast?.errors?.invalidRecipientListSelection" style="color:red;margin-top:10px;">Please select at least one contact to receive the message.</span>
                </nz-tab>
            </nz-tabset>
          </div>

  

          <button nz-button nzType="primary" nzBlock [nzLoading]="isBlastSending" [disabled]="!frmSendNewBlast.valid"
            *ngIf="!isBlastDeleting" (click)="sendNewBlast();addressBook.resetValues()">
            {{ !activeBlast 
              ? (frmSendNewBlast.get('status').value == 'draft' 
                ? 'Save' 
                : (frmSendNewBlast.get('status').value == 'scheduled' ? 'Schedule' : 'Send')) + ' Blast' 
              : 'Edit Blast'}}</button><br /><br />
          <a nz-button nzType="danger" nzBlock [nzLoading]="isBlastDeleting" *ngIf="activeBlast" nz-popconfirm
            nzPopconfirmTitle="Are you sure you want to delete this blast?" nzPopconfirmPlacement="top"
            (nzOnConfirm)="deleteBlast();addressBook.resetValues()">Delete Blast</a>

        </form>
      </nz-spin>
    </div>
  </ng-container>
</nz-drawer>

<nz-modal [nzVisible]="mdlFooter" nzTitle="Settings" [nzFooter]="modalFooter" (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <form nz-form nzLayout="vertical" [formGroup]="frmSettings">
      <nz-form-item>
        <nz-form-label>Compliance Footer Default</nz-form-label>
        <nz-form-control
          nzExtra="Contacts will be automatically opted out of automated messages when they reply with: STOP, END,
          CANCEL, QUIT, UNSUBSCRIBE, OPTOUT">
          <textarea nz-input formControlName="blastComplianceFooter" placeholder="Message..." rows="3"></textarea>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="saveFooter()">Save &amp; close</button>
  </ng-template>
</nz-modal>

<ng-template #pageTitleTemplate>
  Blasts <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Blasts'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>