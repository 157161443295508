import { TimeSync } from "@crossan007/timesync";
import { getLogger } from "@shared/logging";
import moment from "moment";
const log = getLogger("TextableDate");

export class TextableDate extends Date {

  public static timeSync: TimeSync;
  private originalValue : number | string | Date | undefined;

  constructor(value?: number | string | Date) {
    if (value) {
      super(value);
      this.originalValue = value;
    }
    else {
      super(TextableDate.now());
    }
  }

  public static now(): number {
    if (!this.timeSync) {
      log.warn("Date requested which has not been confirmed by server");
      return Date.now();

    }
    return this.timeSync.now();
  }
  /**
   * Returns a moment object using timesync.
   */
  public static getMomentObject(time?:any){
    let now;
    if (!this.timeSync) {
      log.warn("Date requested which has not been confirmed by server");
      now = Date.now();
    }else{
      now = this.timeSync.now()
    }
    return time ? moment(time) : moment(now)
  }
  
  public format(fmtString: string): string {
    return moment(this.getTime()).format(fmtString);
  }

  public isDST() {
    return moment(this.originalValue).isDST();
  }

  public humanize(to: TextableDate = new TextableDate()) {
    const timeDiff = moment.duration(to.getTime() - this.getTime());
    return timeDiff.humanize();
  }

  public startOfDayTimestamp(): number {
    return moment(this.getTime()).startOf('day').valueOf();
  }

  // #endregion Public Static Methods (1)
}


/**
 * Creates a String with a tz representation from separate Date and Timezone objects
 * @param time 
 * @param zone 
 * @returns 
 */
export function formatTimeZone(time: Date | number, zone: string) {
  if(!time || !zone){
    return '-'
  }
  let newDate = moment(time);
  return newDate.tz(zone).format('MM/DD/YY h:mm a z')
}


/**
 * Gets the unix timestamp in miliseconds from separate Date and Timezone objects
 * 
 * 
 * 
 * @param fullDate 
 * @param timezone 
 * @returns 
 */
export function  DateWithTimezoneToDate(fullDate: Date, timezone: string){
  let date = moment(fullDate);
  let ndate = date.format("YYYY-MM-DD HH:mm");
  return moment.tz(ndate, timezone).valueOf();
}