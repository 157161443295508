<nz-form-control nzHasFeedback>
  <nz-select 
  nzMode="multiple" 
  [nzTokenSeparators]="[',']" 
  [nzPlaceHolder]="this.placeholderText" 
  style="width: 100%"
  [(ngModel)]="selectedUsers"
  (nzOnSearch)="onSearch($event)"
  nzShowSearch
  nzServerSearch
  (ngModelChange)="onSelectChange($event)"
  >
  <ng-container *ngFor="let o of optionList">
    <nz-option *ngIf="!filterStatus" [nzValue]="o.value" [nzLabel]="o.label"></nz-option>
  </ng-container>
  <nz-option *ngIf="filterStatus" nzDisabled nzCustomContent>
    <i nz-icon nzType="{{filterStatus.iconType}}" ></i>
    {{filterStatus.message}}
  </nz-option>
</nz-select>
</nz-form-control>
