import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthNZService } from 'src/app/core/authnz.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';;
import { TextableService } from 'src/app/services/textable.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { vars } from 'src/app/app.constants';
import { ApplicationStates, LoginProvider } from '../../core/auth-nz/interfaces'
import * as log from 'loglevel';
import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
import { UserManagementService } from 'src/app/services/user-management.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatchesField } from 'src/app/form_validators/MatchesField';
import { ValidateField } from 'src/app/form_validators/ValidateField';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  environment;
  user = null;
  isReverseArrow = false;
  width: string | number = '200px';
  frmLogin: FormGroup;
  frmSignUp: FormGroup;
  frmResetPassword: FormGroup;
  loginError = false;
  /**
   * Error message to be displayed when  a successful IdP authentication has occurred, but we were unable to 
   * handle the incoming claims.
   * 
   * This may occur when an unprovisioned user attempts to sign in
   */
  ssoErrorMessage = '';
  errorMessage = null;
  successMessage = null;
  isRequestingPassword = false;
  resetError = false;
  userProfile: any = {};
  modalForgotPassword: boolean = false;
  signupError: boolean;
  signupErrorMessage: any;
  frmUpdateEmail: FormGroup;
  isLoading: boolean = false;
  pageToShow = "Loading";
  private authProviderPromise: Promise<any>;
  /**
   * All enabled login providers
   */
  authProviders: LoginProvider[];
  /**
   * Login providers for which login buttons should be shown.
   * 
   * Having an enabled login provider with no login button suggests that users will
   * initiated the SSO workflow via the ?ProviderName URL querystring parameter
   * 
   */
  authProviderLoginButtons: LoginProvider[]

  /**
   * In the event of a URL-initiated SSO login, we may encounter a blocked popup.
   * 
   * If the browser does block the popup, we should still give the user a sign-in button to continue SSO
   */
  BlockedPopupFunction: ()=>void = null

  constructor(
    public router: Router, 
    private route: ActivatedRoute,
    public authnz: AuthNZService,
    private db: AngularFirestore,
    private afa: AngularFireAuth,
    private userManagementService: UserManagementService,
    private notify: NzNotificationService,
    private textable: TextableService,
    private fns: AngularFireFunctions){
      
    this.environment = environment;


    this.frmLogin = new FormGroup({
      'emailAddress': new FormControl(null,[ 
        Validators.email,
        Validators.required
      ]),
      'password': new FormControl(null, [
        Validators.required
      ])
    });

    this.frmSignUp = new FormGroup({
      'email': new FormControl(null, [
        Validators.email,
        Validators.required
      ]),
      'password': new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.password),
        ValidateField("cPassword")
      ]),
      'cPassword': new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.password),
        MatchesField("password")
      ])
    });

    this.frmResetPassword = new FormGroup({
      'emailAddress': new FormControl(null, [
        Validators.email,
        Validators.required
      ])
    });
    
    this.frmUpdateEmail = new FormGroup({
      'emailAddress': new FormControl(null, [
        Validators.email,
        Validators.required
      ])
    });
  }

  ngOnInit() {
    this.authProviders = []
    this.authProviderLoginButtons = []
    this.authProviderPromise = this.authnz.getLoginProviders().then((providers) => {
      this.authProviders = providers;
      this.authProviderLoginButtons = providers.filter(p=>p.showLoginButton);
    });
    this.authnz.authChanged.pipe(
      distinctUntilChanged()
    ).subscribe((state)=> {
      if(state === ApplicationStates.LoggedIn){
        if(environment.production){
          if (this.authnz.currentSecurityProvider.canAccessConversations()) {
            this.router.navigate(['/conversations']);
          }
          else if (this.authnz.currentSecurityProvider.canAccessManageUsers()){
            this.router.navigate(['/users']);
          }
          else {
            this.router.navigate(['/profile']);
          }
        }else{
          if(this.route.snapshot.paramMap.get('redirectUrl')){
            this.router.navigate([this.route.snapshot.paramMap.get('redirectUrl')]);
          }else{
            this.router.navigate(['/conversations']);
          }
        }
      }
      else if (state == ApplicationStates.PendingEmailVerification ) {
        this.user = this.authnz.currentFireauthUser
        this.pageToShow = "EmailVerification";
      }
      else if(state == ApplicationStates.LoggedOut) {
        this.pageToShow = "Login"
        this.HandleSSOTriggerParams();
      }
      else {
        this.pageToShow = "Loading"
      }

    }); 

    

  }

  async HandleSSOTriggerParams() {
    const params = this.route.snapshot.queryParams

    log.debug("Route query params: ", params);
    if (params['ProviderName']) {
      const providerName = params['ProviderName'];
      log.debug("Starting IdP SSO for " + providerName)
      
      this.router.navigate(['login'], {queryParams: {}, queryParamsHandling: ''}); // Remove the ProviderName query param so the next time the login page loads, we don't try to re-load this SSO trigger
      // make sure the promise to get auth providers has resolved before we try logging in with one.
      await this.authProviderPromise
      const selectedProvider = this.authProviders.filter((p)=> p.name == providerName);
      if (selectedProvider.length !== 1) {
        log.error("Not exactly one provider matched by query string ", selectedProvider);
      }
      const result = await this.authnz.providerLogin(
        selectedProvider[0],
        (message)=> {
          log.debug("handled error");
          this.ssoErrorMessage = "An error occurred while processing your login.  Please contact your administrator";
        },
        );
      if (!result) {
        this.BlockedPopupFunction = () => this.authnz.providerLogin(
          selectedProvider[0],
          (message)=> {
            log.debug("handled error");
            this.ssoErrorMessage = "An error occurred while processing your login.  Please contact your administrator";
          },
          );
      }
    }
  }

  PostToken(token: string) { 
    log.debug("PostToken: ", token)
  }

  clearError(){
    this.resetError = false;
    this.loginError = false;
    this.errorMessage = '';
    this.isRequestingPassword = false;
  }

  resetPassword(){
    this.clearError();
    this.successMessage = null;
    this.isRequestingPassword = true;

    this.textable.sendPasswordResetEmail({email: this.frmResetPassword.get('emailAddress').value}).toPromise()
      .then(()=>{
        this.clearError();
        this.successMessage = 'We have sent an e-mail with instructions on how to reset your password.';
      })
      .catch((err)=>{
        this.isRequestingPassword = false;
        this.resetError = true;
        this.errorMessage = err.message;
      });

  }

  login() {
    this.isLoading = true;
    if(this.frmLogin.valid){
      this.authnz.emailLogin(this.frmLogin.get('emailAddress').value, this.frmLogin.get('password').value)
        .then(() => {
      })
      .catch((err) => {
        this.isLoading = false;
        this.loginError = true;
        if (err["code"] == "auth/too-many-requests") {
          this.errorMessage = 'Too many incorrect attempts. Please wait before trying again.'
          return;
        }
        this.errorMessage = 'Invalid e-mail address or password.';
      });
    }else{
      this.isLoading = false;
      this.loginError = true;
      this.errorMessage = 'Please enter a valid e-mail address and password.';
    }
  }

  updateEmailAddress(){

    if(this.frmUpdateEmail.valid){
      let email = this.frmUpdateEmail.get('emailAddress').value;
      if(email != firebase.auth().currentUser.email){
        firebase.auth().currentUser.updateEmail(email)  
          .then(()=>{

            this.userManagementService.editUserProfile({
                  email: email
                }).then((response)=>{
                  response.subscribe(
                    (response)=>{
                      this.sendVerificationEmail();
                    },
                    (response)=>{
                    },
                    ()=>{
                    }
                  )
              });
            

            this.notify.create(
              'success', 'Your e-mail address has been updated.','Please check your e-mail for a verification link.'
            )
            
           

          })
          .catch((err)=>{
            this.notify.create(
              'error', 'There was a problem updating your e-mail address.', err
            )
          })
      }else{
        this.notify.create(
          'error', 'There was a problem updating your e-mail address.', 'Please enter an e-mail address different from your current e-mail.'
        )
      }

    }

  }

  async sendVerificationEmail(){
    const email = firebase.auth().currentUser.email
    this.textable.sendVerificationEmail({email: email}).toPromise().then(() => {
      this.notify.create(
        'success', 'A verification e-mail has been sent to your e-mail address.',''
      )
    }).catch((err)=>{
      console.log(err);
      this.notify.create(
        'error', 'There was a problem sending a verification e-mail. Please try again. If this problem persists, please contact support@textable.co.',''
      )
    });
  }

  signup() {
    this.isLoading = true;
    for (const i in this.frmSignUp.controls) {
      this.frmSignUp.controls[i].markAsDirty();
      this.frmSignUp.controls[i].updateValueAndValidity();
    }
    if (this.frmSignUp.valid) {

      this.textable.provisionRetailUser({
        email: this.frmSignUp.get('email').value,
        password: this.frmSignUp.get('password').value
      }).toPromise().then(async (result)=>{
          const user = await this.afa.signInWithCustomToken(result);
        this.user = user; //TODO: should this be user.user, or just user?  I think this is right, but need to confirm
        this.isLoading = false;
        },(err)=>{
          this.signupError = true;
          this.signupErrorMessage = err.message;
          this.isLoading = false;
          console.log(err);
        })
    } else {
      this.isLoading = false;
      this.signupError = true;
      this.signupErrorMessage = 'Please complete all fields.';
    }
  }

  reloadPage(){
    location.reload();
  }

  logout(){
    this.authnz.logout();
  }

  providerLogin(provider: LoginProvider) {
    this.ssoErrorMessage = ''
    this.authnz.providerLogin(
      provider,
      (message)=> {
        log.debug("handled error");
        this.ssoErrorMessage = "An error occurred while processing your login.  Please contact your administrator";
      }
    );
  }
}