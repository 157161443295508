import { AngularFirestore } from "@angular/fire/compat/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { getLogger } from "src/shared/logging";
import firebase from "firebase/compat";

const log = getLogger("AFSBatcher")

const FIRESTORE_BATCH_SIZE = 500;

type BatchStatus = {
  totalItems: number
  totalBatches: number
  doneItems: number
  doneBatches: number
}

export const AFSBatcher = <T>(
  afs: AngularFirestore,
  docs: T[],
  operation: (batch: firebase.firestore.WriteBatch, item: T)=>void
): Observable<BatchStatus> => {

  const progress = {
    doneBatches: 0,
    doneItems: 0,
    totalBatches:  Math.ceil(docs.length / FIRESTORE_BATCH_SIZE),
    totalItems: docs.length
  }

  const status$ = new BehaviorSubject<BatchStatus>(progress);

  new Promise<void>(async (resolve,reject) => {
    status$.next(progress)
    for (let batchNum = 0; batchNum < progress.totalBatches; batchNum++) {
      try {
        log.debug("Starting batch " + batchNum);
        const batch = afs.firestore.batch();
        const start = batchNum * FIRESTORE_BATCH_SIZE;
        const end = Math.min((batchNum + 1) * FIRESTORE_BATCH_SIZE, docs.length) - 1;
        for (let batchIndex = start; batchIndex <= end; batchIndex++) {
          operation(batch, docs[batchIndex]);
        }
        // TODO: do we really need to await this?  Since we're generating unique batches, maybe we can queue them all up at onces?
        await batch.commit();
        progress.doneItems = end + 1 ; // TODO is this necessary? I think so
        progress.doneBatches = batchNum;
        status$.next(progress)
        log.debug("Completed batch " + batchNum);
      }
      catch (err) {
        log.debug("Failed processing batch at " + batchNum, progress);
        status$.error("Failed processing batch at " + batchNum);
        return resolve();
      }
    }
    log.debug("Completed all batches");
    status$.complete();
    resolve();
  });

  return status$;

}