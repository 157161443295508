import { Component, OnInit } from '@angular/core';
import { AuthNZService } from 'src/app/core/authnz.service';
import { IntegrationDefinition, IntegrationInstallation, IntegrationService } from 'src/app/services/integration.service';

@Component({
  selector: 'app-integration-settings-page',
  templateUrl: './integration-settings-page.component.html',
  styleUrls: ['./integration-settings-page.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class IntegrationSettingsPageComponent implements OnInit {
  
  public availableIntegrations: IntegrationDefinition[] = [];
  public installedIntegrations: IntegrationInstallation[] = [];
  public selectedTabIndex = 0;
  public guideVisibleBool = false;
  constructor(
    public integrationService: IntegrationService,
    public authnz: AuthNZService
  ) { }

  ngOnInit() {
    this.reloadIntegrations();
  }

  reloadIntegrations() {
    this.integrationService.GetAvailableIntegrations().then(I=> {
      this.availableIntegrations = I.filter(i=>i.installationTypes.length > 0)
    })

    this.integrationService.GetInstalledIntegrations().then(I=> {
      this.installedIntegrations = I
      if (this.installedIntegrations.length > 0 ) {
        this.selectedTabIndex = 1;
      }
      else { 
        this.selectedTabIndex = 0;
      }
    })
  }
}
