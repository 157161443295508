
<nz-steps [(nzCurrent)]="currentStep" nzSize="small">
  <nz-step nzTitle="Select"></nz-step>
  <nz-step nzTitle="Review"></nz-step>
  <nz-step nzTitle="Finalize"></nz-step>
</nz-steps>

<ng-container *ngTemplateOutlet="([select,review,confirm])[currentStep]"></ng-container>

<ng-template #select>
  Select a CSV file or paste a list
  <form nz-form nzLayout="vertical" [formGroup]="frmImportContacts">
    <nz-collapse nzAccordion>
      <nz-collapse-panel
        nzHeader="Import from file"
        [nzActive]="true"
        >
        <nz-form-item>
          <nz-form-label>
            Does the file have a header row?
          </nz-form-label>
          <nz-form-control>
              <nz-radio-group formControlName="skipFirstRowFile">
                <label nz-radio-button [nzValue]="true">Yes</label>
                <label nz-radio-button [nzValue]="false">No</label>
              </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>Select File (<a
              href="assets/mock_data/contacts/SampleImport.csv"
              download>Download Sample</a>)</nz-form-label>
          <nz-form-control>
            <input type="file" nz-input (change)="importCSV($event.target.files,frmImportContacts.get('skipFirstRowFile').value)" formControlName="importFile">
          </nz-form-control>
          
        </nz-form-item>
      </nz-collapse-panel>
      <nz-collapse-panel
        nzHeader="Import pasted CSV data"
        >
        <nz-form-item>
          <nz-form-label>
            Does the pasted data have a header row?
          </nz-form-label>
          <nz-form-control>
              <nz-radio-group formControlName="skipFirstRowPaste">
                <label nz-radio-button [nzValue]="true">Yes</label>
                <label nz-radio-button [nzValue]="false">No</label>
              </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>
            Paste import 
          </nz-form-label>
          <nz-form-control 
            nzHasFeedback
            nzErrorTip="Please paste your contact data"
            >
            <small>(Format: Full Name, Phone Number, E-mail, Company Name, Title, Notes)</small>
            <textarea formControlName="pastedImportData" nz-input rows="10"></textarea>
          </nz-form-control>
        </nz-form-item>
        
      </nz-collapse-panel>
    </nz-collapse>
  </form>
  
  <div class="bottom">
    <nz-card nzTitle="Parse Status" nzSize="small" style="margin-top:5px;">
      {{ parseStatus?.operation }}
      <nz-progress *ngIf="parseStatus?.percentComplete" [nzPercent]="parseStatus?.percentComplete" nzSize="small"></nz-progress>
      {{ parseStatus?.statusText }}
    </nz-card>
    <nz-divider></nz-divider>
    <button nz-button nzBlock nzType="primary" nzBlock [disabled]="!(importedData?.length >0 && busyParsing == false)" (click)="currentStep=1">Review Data and Alerts</button>
  </div>
</ng-template>

<ng-template #review>
  Review the import and choose a target list
  <form nz-form nzLayout="vertical" [formGroup]="frmImportContacts">
    <nz-collapse nzAccordion>
      <nz-collapse-panel
        nzHeader="Review Imported Contacts"
        [nzActive]="true"
        >
        Please review this subset of contacts from your import to ensure the data in each column is appropriate.
        <nz-table 
          #contactReviewTable 
          [nzData]="importPreviewData" 
          [nzLoading]="!importPreviewData" 
          [nzScroll]="{ y: '240px' }"
          [nzHideOnSinglePage]="true"
          >
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Company</th>
              <th>Title</th>
              <th>Notes</th>

            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of contactReviewTable.data">
            <td>{{data?.full_name}}</td>
            <td>{{data?.phone_number}}</td>
            <td>{{data?.email}}</td>
            <td>{{data?.companyName}}</td>
            <td>{{data?.title}}</td>
            <td>{{data?.notes}}</td>

          </tr>
        </tbody>
        </nz-table>
      </nz-collapse-panel>
      <nz-collapse-panel
        nzHeader="Review Alerts ({{importAlerts.length}})"
        [nzDisabled] = "importAlerts.length == 0"
        >
        Please review the following alerts and resolve if necessary
        <nz-table 
          #contactProblemTable
          [nzData]="importAlerts" 
          [nzLoading]="!importAlerts" 
          [nzScroll]="{ y: '240px' }"
          [nzHideOnSinglePage]="true"
          >
          <thead>
            <tr>
              <th>Problem</th>
              <th>Context</th>
              <th>Resolution</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of contactProblemTable.data" [ngClass]="{'blocksImport':data?.blocksImport}">
            <td>{{data?.description}}</td>
            <td>{{data?.context}}</td>
            <td>{{data?.resolution}}</td>
          </tr>
        </tbody>
        </nz-table>
      </nz-collapse-panel>
      <nz-collapse-panel nzHeader="Select a List (Optional)" *ngIf="this.authnz.currentSecurityProvider.canAccessContactLists()">
        <ng-container *ngIf="contactList$ | async as contactLists">
          <nz-form-item>
            <nz-form-label>
              Import Contacts into Selected List:
            </nz-form-label>
            <nz-select nzPlaceHolder="Select a List" formControlName="importToList" [nzDropdownRender]="createNewContactListDuringImport">
              <nz-option *ngFor="let list of contactLists" [nzValue]="list.id" nzLabel="{{list.listName + ' (' + (list?.count >= 0 ? list.count : '0') + ')' }}">
              </nz-option>
            </nz-select>
          </nz-form-item>
          
          <ng-template #createNewContactListDuringImport>
            <nz-divider nzPlain nzText="Or Create a New List"></nz-divider>
            <div class="createNewContactList">
              <input type="text" nz-input #inputElement />
              <a class="add-item" (click)="createAndSelectContactList(inputElement)">
                <i nz-icon nzType="plus"></i>
                Create New List
              </a>
            </div>
          </ng-template>

        </ng-container>
        
      </nz-collapse-panel>
    </nz-collapse>
  </form>
  <div class="bottom">
    <button nz-button nzBlock nzType="primary" nzBlock [disabled]="blockingImportAlerts > 0" (click)="currentStep=2">Finalize Import</button>
    <button nz-button nzBlock nzType="default" nzBlock (click)="currentStep=0">Back</button>
  </div>

 
</ng-template>

<ng-template #confirm>
  <nz-divider nzPlain nzText="Confirm Source Details"></nz-divider>
  Count of Contacts from import file: {{ importedData?.length }}<br/>

  <nz-divider nzPlain nzText="Confirm Mapped Details"></nz-divider>
  Count of Contacts to Create: {{ ContactsToCreate?.length }}<br/>
  Count of Contacts to Update: {{ ContactsToUpdate?.length }}<br/>

  <nz-divider nzPlain nzText="Confirm Target Details"></nz-divider>
  Target List: {{ targetList?.listName || "None"}}<br/>
  Target Number: {{ authnz.activeNumberProfile.phone_number }}<br/>
  <div class="bottom">
    <button 
      nz-button 
      nzBlock
      nzType="primary" 
      nzBlock 
      (click)="finishImport()"
      >Finish Import
    </button>
    <button nz-button nzBlock nzType="default" nzBlock (click)="currentStep=1">Back</button>
  </div>
</ng-template>