import { FormGroup, ValidatorFn, ValidationErrors } from "@angular/forms";
import log from "loglevel";
import { DateWithTimezoneToDate, TextableDate } from "src/classes/TextableDate";

/**
 * Validates that a Date component on an Angular form 
 * is in the future
 * 
 * https://angular.io/guide/form-validation#defining-custom-validators
 * @returns 
 */
export function DateIsInFuture(dateControlName: string, tzControlName: string, skipLambda?:(group:FormGroup)=>boolean): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {
    if (typeof(skipLambda) == "function" && skipLambda(group)) {
      return null
    }
    if (!group.get(dateControlName).value || !group.get(tzControlName).value) {
      log.debug("No Date value selected")
      return {dateError: true}
    }
    const finalTime = DateWithTimezoneToDate(group.get(dateControlName).value, group.get(tzControlName).value)
    const IsInPast = finalTime < TextableDate.now();
    log.debug("final time: " + finalTime + (IsInPast ? " is in the past" : " is in the future"))
    if (IsInPast) {
      return {dateError: true }
    }
    return null
  };
}