<nz-card [nzTitle]="pageTitleTemplate" nzBorderless="true">
    <div>
        <div class="pad-main-content">
            <p class="text-center">
                Manage stripe billing by click through the link.
            </p>
        </div>
        <nz-row nzJustify="center">
            <nz-col>
                <button nzType="primary" nz-button (click)="openBillingPortal()">
                    Open Billing Portal</button>
            </nz-col>
        </nz-row>

    </div>
</nz-card>

<ng-template #pageTitleTemplate>
    Billing
</ng-template>