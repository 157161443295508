import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { environment } from 'src/environments/environment';
import { TextableBrandingDetails } from './theme.service.types';
import { ReplaySubject } from 'rxjs';
import { getLogger } from 'src/shared/logging';
const log = getLogger("ApplicationContextService")

export interface TextableSupportDetails {
    primaryContactEmail: string;
}

export interface TextableApplicationContext  {
  providers: TextableProviderConfiguration[]
  enabledProviders: TextableProviderConfiguration[],
  currentUserContext: {
    supportDetails: TextableSupportDetails,
    [key:string]: any
  },
  branding?:TextableBrandingDetails
}

export interface TextableProviderConfiguration {
  id: string,
  enabled: boolean,
  label: string,
  fields: {[key: string]: string},
  fieldsFromEnvironment?: string[],
  enforceDefinedCredentialFields: boolean,
  alternateIds: string[]
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationContextService {
  /**
   * 
   */
  public applicationContext:  TextableApplicationContext

  public applicationContextChanged: ReplaySubject<TextableApplicationContext> = new ReplaySubject<TextableApplicationContext>();

  /**
   * Toggles whether to show the debug data sider component.
   * 
   * Defaults to the opposite of `environment.production`
   * 
   * Can be enabled at runtime by running 
   * `enableDebugMode();` in the JavaScript console
   * 
   */
  public debugMode: boolean = !environment.production



  constructor(
    private backendService: BackendService
  ) { 
    this.applicationContext = {
      providers:[],
      enabledProviders: [],
      currentUserContext: {
        supportDetails: {
          primaryContactEmail: ""
        }
      }
    }
  }


  
  /**
   * Loads application context from backend
   */
  public async refreshContext() {
    const data = await this.backendService.backendGet("application_context")
      log.debug("Set application context:", data);
      this.applicationContext = data as TextableApplicationContext
      this.applicationContext.enabledProviders = this.applicationContext.providers.filter((p)=>p.enabled);
      this.applicationContextChanged.next(this.applicationContext);      
  }
}
