<nz-form-item>
  <nz-form-label nzRequired>Phone Number (DID)</nz-form-label>
  <nz-form-control nzHasFeedback>
      <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
          <ng-template #addOnBeforeTemplate>
            <nz-select [(ngModel)]="phonePrefix" style="width: 60px" [ngModelOptions]="{standalone: true}" [nzDropdownMatchSelectWidth]="false">
              <nz-option style="width: 200px" nzCustomContent *ngFor="let country of countryCodes" [nzLabel]="country.code" [nzValue]="country.code">{{country.name}}<span style="color: lightgray"> ({{country.code}})</span></nz-option>
            </nz-select>
          </ng-template>
          <input 
            nz-input 
            placeholder="(555) 555-5555" 
            type="text" 
            [(ngModel)]="phone_number_formatted" 
            type="tel"
            [textMask]="{mask: phoneMask}"
            (keyup)="onNumberChange()"

            >
        </nz-input-group>
      <!-- TODO : Figure out how to apply form validation to ControlValueAccessor components <nz-form-explain
      *ngIf="(control?.dirty && control?.errors) || control?.pending">
      <ng-container *ngIf="control?.hasError('required') || control?.hasError('minlength')">
        Please enter user's assigned phone number. 
      </ng-container>
      <ng-container *ngIf="control?.pending">
        Validating...
      </ng-container>
    </nz-form-explain>-->
    <nz-collapse nzAccordion *ngIf="shouldShowSuggestedPhoneNumbers">
      <nz-collapse-panel 
        [nzHeader]="tplSuggestedPhoneNumbersHeader" 
        [nzActive]="expandSuggestedPhoneNumbers"
        >
      <nz-table 
        #tableData 
        [nzData]="suggestedPhoneNumbers" 
        [nzLoading]="suggestedPhoneNumbers == null || suggestedPhoneNumbers == undefined"
        nzPageSize= 5
        >
        <thead>
          <td></td>
          <td>
            Action
          </td>
          <td>
            Phone Number
          </td>
          <td>
            Metadata
          </td>
        </thead>
        <tbody>
          <tr *ngFor="let data of tableData.data" nz-tooltip [nzTooltipTitle]="data.additionalInformation" > 
            <td>
              <i nz-icon [nzType]="data.additionalInformationIcon" [style.color] = "data.additionalInformationIconColor" ></i>
            </td>
            <td>
              <ng-container *ngIf="data.allowPick">
                <a (click)="selectPhoneNumber(data.phoneNumber)">Pick</a>
              </ng-container>
              <ng-container *ngIf="!data.allowPick">
                Unavailable
              </ng-container>
            </td>
            <td>{{ data.phoneNumber }}</td>
            <td>{{ data.metadata }}</td>
          </tr>
        </tbody>
      </nz-table>
      </nz-collapse-panel>
    </nz-collapse>

  </nz-form-control>
</nz-form-item>


<ng-template #tplSuggestedPhoneNumbersHeader>
      <p>
        Suggested Phone Numbers<span *ngIf="suggestedPhoneNumbers.length>0"> ({{suggestedPhoneNumbers.length}})</span>
        <br>
        <span *ngIf="DIDSuggestionHeader" [style.color]="DIDSuggestionHeader?.error ? 'red' : 'black'"
        [style.font-size]="'smaller'">{{DIDSuggestionHeader?.Message}}</span>
      </p>
  <nz-spin *ngIf="DIDSuggestionHeader?.loading" nzSimple></nz-spin>
</ng-template>