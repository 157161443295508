<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div>
    <div class="pad-main-content">
      <p class="text-center">Design drip campaigns that can be applied to contacts. Drip campaigns allow you to apply a scheduled set of messages to a contact based on intervals of your choosing. Any replies received from the contact will break any future drips.</p>
    </div>
    <nz-table class="txb-list" #table [nzLoading]="loading" [nzNoResult]="empty">
      <thead>
        <tr>
          <th nzWidth="55%">Campaign</th>
          <th nzWidth="15%" class="text-center">Drips</th>
          <th nzWidth="15%">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of drips$ | async">
          <td>{{ data.name }}</td>
          <td>{{ data.drips.length}}</td>
          <td>           
              <a (click)="viewDrip(data)">View</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this Campaign?" (nzOnConfirm)="deleteDrip(data)">Delete</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</nz-card>


  <ng-template #extraTemplate>
    <button nz-button nzType="primary" (click)="viewDrip()"><i class="far fa-plus"></i>Create New Campaign</button>
  </ng-template>
  
  <nz-drawer
    [nzClosable]="true"
    [nzVisible]="drwDrip"
    nzPlacement="right"
    [nzTitle]="!editBool ? 'Create Drip Campaign' : 'Edit Drip Campaign'"
    (nzOnClose)="closeDrawer()"
    nzWidth="50%"
  >
    <ng-container *nzDrawerContent>
      <div class="pad-15">
        <nz-spin [nzSpinning]="loading">
      
          <h2>Campaign Name:</h2>
          <input nz-input [(ngModel)]="selectedDrip.name" (ngModelChange)="checkSelectedDripValid()" type="text" />
          <small style="color: red" *ngIf="selectedDrip.name.length == 0"><i>Campaign Name Required!</i></small><br>
      
          <nz-row>
            <nz-col nzSpan="17">
              <label nz-checkbox [(ngModel)]="selectedDrip.disableDripCampaignDelete">Disable automatic deletion of drips when
                a reply is received.</label>
            </nz-col>
            <nz-col nzSpan="7">
              <small *ngIf="editBool"><i>Updated: {{selectedDrip.updated | date: "short"}}</i></small>
            </nz-col>
          </nz-row>
      
          <nz-row>
            <nz-col>
              <nz-alert [nzMessage]="dripTipTemplate">
              </nz-alert>
              <ng-template #dripTipTemplate>
                <small><b>Tip:</b> This is useful when attempting educational campaigns like onboarding new customers or
                  training
                  on new products.</small>
              </ng-template>
            </nz-col>
          </nz-row>
      
          <br><br><br>
          <div nz-row>
            <div nz-col nzSpan="3">
              <h2>Drips</h2>
            </div>
            <div nz-col nzSpan="15">
              <button nz-button nz-popover [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate">
                View Merge Fields
              </button>
              <ng-template #titleTemplate>
                <i nz-icon nzType="close"></i> Merge Fields
              </ng-template>
              <ng-template #contentTemplate>
                <app-merge-field-popover></app-merge-field-popover>
              </ng-template>
            </div>
            <div nz-col nzSpan="4">
              <button nz-button nzType="primary" (click)="addDrip()"><i class="far fa-plus"></i>Add Drips</button>
            </div>
          </div>
      
        </nz-spin>
      </div>
      
      <app-mms-uploader [isVisible]="showMMSModal" [mode]="mmsUploaderMode"
        (closeMMSUploaderEvent)="closeMMSUploader($event)">
      </app-mms-uploader>
      
      <div class="body">
        <nz-table class="noHighlight" #dripsTable [nzData]="selectedDrip.drips" [nzNoResult]="empty">
          <thead>
            <tr>
              <th nzWidth="55%">Drip</th>
              <th nzWidth="33%">Interval</th>
              <th nzWidth="22%">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of dripsTable.data">
              <td>
                <textarea nz-input (ngModelChange)="checkSelectedDripValid()" [(ngModel)]="data.message"
                  placeholder="Drip Message..." rows="3"></textarea>
                <small style="color: red" *ngIf="data.message.length <= 0"><i>Drip Message Required!</i></small>
      
                <small *ngIf="data.attachments?.length > 0">
                  <i nz-icon nzType="paper-clip"></i>&nbsp;
                  <a (click)="mms.openPhoto(data.attachments[0])">{{ data.attachments[0]?.filename }} </a>&nbsp;
                  <i class="far fa-trash" nz-popconfirm nzPopconfirmTitle="Are you sure you want to remove this attachment?"
                    (nzOnConfirm)="removeAttachment(data)"></i>
                </small>
                <!-- Button to open upload modal -->
                <button *ngIf="this.authnz.currentSecurityProvider.canAccessBulkMMS()" nz-button class="drawer-button"
                  (click)="openModalAndSelectDriplet(data)">
                  {{data.attachments?.length > 0 ? 'Change Attachment' : 'Upload Attachment'}}
                </button>
              </td>
      
              <td>
                <nz-input-number style="margin-bottom: 6px" [(ngModel)]="data.interval" [nzMin]="1"
                  (ngModelChange)="checkSelectedDripValid();"></nz-input-number>
      
                <nz-select style="width: 100px;" [(ngModel)]="data.intervalType">
                  <nz-option nzValue="minutes" nzLabel="Minutes"></nz-option>
                  <nz-option nzValue="hours" nzLabel="Hours"></nz-option>
                  <nz-option nzValue="days" nzLabel="Days"></nz-option>
                </nz-select>
              </td>
              <td>
                <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this drip?"
                  (nzOnConfirm)="deleteDripStep(data)">Remove</a>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <div class="footer">
        <div *ngIf="editBool;else create">
          <button *ngIf="selectedDripValid == true;else disable" type="button" (click)="updateDrip()"
            class="ant-btn ant-btn-primary"><span>Save</span></button>
        </div>
        <ng-template #create>
          <button *ngIf="selectedDripValid == true;else disable" type="button" (click)="createDrip()"
            class="ant-btn ant-btn-primary"><span>Save</span></button>
        </ng-template>
        <ng-template #disable>
          <button disabled type="button" class="ant-btn ant-btn-primary"><span>Save</span></button>
        </ng-template>
      </div>
    </ng-container>
  </nz-drawer>

  <ng-template #pageTitleTemplate>
    Drip Campaign <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
  </ng-template>

  <nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
    <ng-container *nzModalContent>
      <app-user-guides [selectedGuide]="'Drip Campaign'"></app-user-guides>
    </ng-container>
  </nz-modal>
  <ng-template #guideFooter>
  
  </ng-template>

  <ng-template #empty></ng-template>