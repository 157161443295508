import { Observable, Subject } from "rxjs";

declare module "rxjs" {
  interface Observable<T> {
    /**
   * TODO: When we upgrade to RxJS 7 convert this block to `await firstValueFrom...`
   */
    nThEmitAsPromise(skip?: number): Promise<T>;
  }
}


Observable.prototype.nThEmitAsPromise = function<T>(this: Subject<T>, skip: number = 1): Promise<T> {
  return new Promise((resolve,reject)=>{
    const subscription = this.subscribe((v:T)=>{
      skip --;
      if (skip < 1 ) {
        //subscription.unsubscribe()
        return resolve(v)
      }
    },(error)=>{
      reject(error)
    });
  })
}