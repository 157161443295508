<nz-modal 
  [(nzVisible)]="visible" 
  [nzTitle]="getModalTitle()" 
  (nzOnCancel)="cancelAddOrgModal()" 
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <form nz-form nzLayout="vertical" [formGroup]="frmAddOrg" (ngSubmit)="updateOrganization()">
      <nz-form-item>
        <nz-form-label nzRequired>Organization Name</nz-form-label>
        <nz-form-control 
          nzHasFeedback
          nzErrorTip="Please enter organization's name.">
          <input nz-input formControlName="organizationName" placeholder="ACME, Inc." type="text"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Description / Notes</nz-form-label>
        <nz-form-control nzHasFeedback>
          <textarea nz-input formControlName="description" placeholder="Notes..." rows="4"></textarea>
        </nz-form-control>
      </nz-form-item>
      <ng-container *ngIf="planSelectionDetails.granularity == 'organization'">
        <nz-form-label nzRequired>Plan Selection</nz-form-label>
        <nz-form-item>
          <nz-select 
            formControlName="organizationPlanSelection"
            [nzDisabled] = "currentlySelectedPlan?.restricted"
            >
            <nz-option
              *ngFor="let plan of planSelectionDetails.choices" 
              nzValue="{{ plan.id }}"
              nzLabel="{{ plan.displayName }}"
              [nzDisabled] = "plan.restricted"
              >
            </nz-option>
          </nz-select>
          <p>{{currentlySelectedPlan?.description}}</p>
        </nz-form-item>
      </ng-container>
      <nz-form-item>
        <nz-form-label>Organization Admins <span style="margin-left: .5em" nz-tooltip nzTooltipTitle="Org admins may edit names and emails and may change passwords of users within an organization. Org admins may not create or delete users or organizations. Org admins may not modify user provider settings (phone numbers, API keys, etc) "><i nz-icon nzType="info-circle" nzTheme="outline"></i></span> </nz-form-label>
        <app-user-multi-selector *ngIf="visible" placeholderText="Organization Admins" formControlName="organizationAdmins" ></app-user-multi-selector>
      </nz-form-item>
      <ng-container *ngIf="this.authnz.currentSecurityProvider.canEditOrgConsentMessages()">
        <nz-form-item>
          <nz-form-label>Recipient Consent</nz-form-label>
          <label nz-checkbox formControlName="requireContactConsent">Require consent message</label>
          <nz-form-control nzHasFeedback>
            <textarea nz-input formControlName="requireContactConsentMessage" placeholder="Enter a message which will be automatically sent to new contacts" rows="4"></textarea>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <button nz-button nzType="primary" nzBlock [nzLoading]="isOrgSaving" [disabled]="!frmAddOrg.valid">
        {{mode=="new" ? 'Add' : 'Update' }} Organization
      </button>
    </form>
  </ng-container>
</nz-modal>