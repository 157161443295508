<div 
  [ngSwitch]="viewMode" 
  nz-popover
  [nzPopoverTrigger]="viewMode !== 'raw' ? 'hover' : 'null'"
  [nzPopoverTitle] = "viewMode == 'PhoneNumber' ? 'Save new Contact': null"
  [nzPopoverContent]="contactPopover"
  nzPopoverOverlayClassName="inline-contact-popover"
  >
  <ng-container *ngSwitchCase="'raw'">
    <i nz-icon nzType="question" nzTheme="outline"></i>
    <span>{{ _contact }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'PhoneNumber'">
    <i nz-icon nzType="phone" nzTheme="outline"></i>
    <span>{{ _contact.ToE164() }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'KnownContact'">
    <i nz-icon nzType="user" nzTheme="outline"></i>
    <span>{{_contact.contact.full_name}}</span>
  </ng-container>
</div>

<ng-template #newContactCard>
  <form nz-form nzLayout="vertical" [formGroup]="frmContactDetails" (ngSubmit)="updateContactDetails()">
    <nz-form-item>
      <nz-form-label nzRequired>Full Name</nz-form-label>
      <nz-form-control 
        nzHasFeedback
        nzErrorTip="Please enter a contact name."
        >
        <input nz-input formControlName="full_name" placeholder="John Doe" />
      </nz-form-control>
    </nz-form-item>
    <button
      nz-button
      nzBlock 
      nzType="primary"
      [disabled]="!frmContactDetails.valid"
      [nzLoading]="isSavingContact">
      Save Contact
  </button>
  </form>
 
</ng-template>

<ng-template #knownContactCard>
  <ng-template #knownContactCardTitle>
    <nz-avatar nzIcon="user"></nz-avatar>
    {{_contact.contact.full_name}}
  </ng-template>

  <nz-descriptions [nzTitle]="knownContactCardTitle" class="knownContactDescription" [nzColumn]="1" nzBordered>
    <nz-descriptions-item nzTitle="Full Name">{{_contact.contact.full_name}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Phone Number">{{_contact.contact.phone_number}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Last Message">{{_contact.contact.last_message}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Notes">{{_contact.contact.notes}}</nz-descriptions-item>
  </nz-descriptions>
  <div class="actionButtons">
    <button
      nz-button
      nzBlock 
      nzType="primary" 
      [routerLink]="['/contacts', _contact.contact.firebase_document_id]" 
      routerLinkActive="router-link-active">
      Edit Contact
    </button>
    <button
      nz-button
      nzBlock 
      nzType="primary" 
      [routerLink]="['/conversations', _contact.contact.firebase_document_id]" 
      routerLinkActive="router-link-active">
      Go to Conversation
    </button>
  </div>
  

</ng-template>