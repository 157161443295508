import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthNZService } from 'src/app/core/authnz.service';
import { ControlMessagingService } from 'src/app/services/control-messaging.service';
import { FrontendUserNotification } from 'src/app/services/user-notification.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss']
})
export class UserNotificationComponent implements OnInit {

  /**
   * Reference to the template for a new message popup
   */
  @ViewChild('tplNewMessage') tplNewMessage: TemplateRef<{}>;

  /**
   * Reference to the template for a new message popup
   */
  @ViewChild('tplRequestPermission') tplRequestPermission: TemplateRef<{}>;

  private permissionRequestRef: NzNotificationRef;
  public message: any;
  public isRequestingPermission: boolean = false

  constructor(
    public authnz: AuthNZService,
    public notify: NzNotificationService,
    public router: Router,
    private cmService: ControlMessagingService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
  }

  public showMessage(msg: FrontendUserNotification){
    this.notify.template(this.tplNewMessage, {
      nzData: msg,
      nzDuration: 5000
    });
  }

  public showRequestPermission() {
    this.permissionRequestRef = this.notify.template(this.tplRequestPermission, {
      nzDuration: 0
    });
  }

  public async goToMessage(msg) {
    this.ngZone.run(async ()=> {
      await this.authnz.setActiveNumber(msg.uid);
      await this.router.navigate(['/conversations', msg.contactId]);
    });
  }

  public async doRequestPermission() {
    this.isRequestingPermission = true;
    await this.cmService.SetupNotifications(true);
    this.notify.remove(this.permissionRequestRef.messageId);
  }

}
