import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment-timezone';
import { TextableService } from 'src/app/services/textable.service';
import { AuthNZService } from 'src/app/core/authnz.service';
import { SubscriptionManagerService } from 'src/app/core/subscription-manager.service';
import { MMSUploaderMode } from 'src/app/components/mms-uploader/mms-uploader.component';
import { MmsService } from 'src/app/services/mms.service';
import { AddressBookComponent } from 'src/app/components/address-book/address-book.component';
import { Observable } from 'rxjs';
import { getLogger } from '@shared/logging';
import { TextableBlast } from 'src/app/backported_types/messaging';
import { TextableContactList } from 'src/app/backported_types/contact';
import { formatTimeZone, TextableDate } from 'src/classes/TextableDate';
import { DateIsInFuture } from 'src/app/form_validators/DateIsInFuture';

const log = getLogger("BlastsComponent")

function ListSelectionIsValid(recipientListFormFieldName: string, listSourceFormFieldName: string, composeModeFormFieldName: string): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {

    if (group.get(composeModeFormFieldName).value == "draft") {
      // Draft blasts may be created without specifying any recipients.
      return null;
    }
    const recipientList = group.get(recipientListFormFieldName).value
    if (!recipientList) {
      return {invalidRecipientListSelection: true};
    }
    if (group.get(listSourceFormFieldName).value == "select") {
      return recipientList.hasOwnProperty("id") && recipientList.id ? null : {invalidRecipientListSelection: true}
    }
    else if (group.get(listSourceFormFieldName).value == "custom") {
      return Array.isArray(recipientList) && recipientList.length >0 ? null : {invalidRecipientListSelection: true}
    }
    return {invalidRecipientListSelection: true}
  };
}



@Component({
  selector: 'app-blasts',
  templateUrl: './blasts.component.html',
  styleUrls: ['./blasts.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class BlastsComponent implements OnInit {

  /**
   * Obtains a reference to the address book component so 
   * we can call `resetValues()`
   */
  @ViewChild('addressBook') addressBook: AddressBookComponent;
  /**
   * Controls whether the MMS upload modal is visible
   */
  public showMMSModal: boolean = false;
  /**
   * Controls how the MMS uploader handles attachments
   */
  public mmsUploaderMode = MMSUploaderMode.COMPOSE
  /**
   * Controls whether the new blast drawer is visible
   */
  drwNewBlast: Boolean;
  /**
   * Controls whether the Blast Settings Modal is visible
   * 
   * This is where the `Compliance Footer Default` value is set
   */
  mdlFooter = false;
  /**
   * Form group for composing a new blast.
   */
  frmSendNewBlast: FormGroup;
  /**
   * this seems to be used to "reset" the state of frmSendNewBlast
   */
  baseFrmSendNewBlast: FormGroup;

  /**
   * Form group for blast settings 
   * e.g. `Compliance Footer Default`
   */
  frmSettings: FormGroup;
  /**
  * Controls display of a spinning indicator when a blast is sending 
  */
  isBlastSending: boolean = false;
  /**
  * Controls display of a spinning indicator when a blast is deleting 
  */
  isBlastDeleting: boolean = false;
    /**
   * Controls whether user guides are visible
   * 
   * TODO: TXBDEV-835 - refactor this to a service instead of individual modals.
   */
  guideVisibleBool = false;

  /**
   * Contains IDs of contacts selected in the "Custom List" tab of the blast composer
   * 
   * This is necessary to "preserve" the selection during editing in the 
   * event the user switches between contact list and custom list
   */
  customListValueHolder: string[] = []; 
  /**
   * Contains the ContactList object (id,count,listName) of the contact list
   * selected in the "Select List" tab of the blast compooser
   * 
   * This is necessary to "preserve" the selection during editing in the 
   * event the user switches between contact list and custom list
   */
  contactListValueHolder = {};

  /**
   * The blast object being viewed / edited
   */
  activeBlast: TextableBlast = null;

  /**
   * Two-way-bound variable to the standalone contact list select form element
   * 
   * Contains the id of the currently selected list.
   * 
   * TODO: Why is this a two way binding instead of a simple form element?
   */
  selectedContactList;

  /**
   * Observable of all blasts filtered to show only those in-progress
   */
  inProgressBlasts$: Observable<TextableBlast[]>;
  /**
   * Observable of all blasts filtered to show only scheduled
   */
  scheduledBlasts$: Observable<TextableBlast[]>;
  /**
   * Observable of all blasts filtered to show only sent
   */
  sentBlasts$: Observable<TextableBlast[]>;
  /**
   * Observable of all blasts filtered to show only drafts
   */
  draftBlasts$: Observable<TextableBlast[]>;
  /**
   * Array of all contact list objects.
   */
  availableContactLists: TextableContactList[] = [];
  /**
   * Bring the function into scope of this class so we can use it in 
   * the HTML template;  Wasteful and weird, but required
   */
  public formatTimeZone = formatTimeZone

  constructor(
    private afs: AngularFirestore, 
    public textable: TextableService,
    public authnz: AuthNZService,
    private submanager: SubscriptionManagerService,
    private mms: MmsService
  ) { }

  filterArrayOfBlastsByStatus(blastArray: any[], status: string) {
    return blastArray.filter(
      (i)=> {
        return i.status==status
      })
  }

  ngOnInit() {
    this.makeForms();

    this.textable.contactLists$.subscribe((lists) => {
      this.availableContactLists = lists
    })
    this.draftBlasts$ = this.textable.blasts$.pipe(map((e)=>this.filterArrayOfBlastsByStatus(e,"draft")));
    this.scheduledBlasts$ = this.textable.blasts$.pipe(map((e)=>this.filterArrayOfBlastsByStatus(e,"scheduled")));
    this.inProgressBlasts$ = this.textable.blasts$.pipe(map((e)=>this.filterArrayOfBlastsByStatus(e,'sending')));
    this.sentBlasts$ = this.textable.blasts$.pipe(map((e)=>this.filterArrayOfBlastsByStatus(e,'sent')));
  }

  makeForms() {
    this.frmSendNewBlast = new FormGroup({
      "blastName": new FormControl(null,[
        Validators.required,
        Validators.minLength(5)
      ]),
      "blastMessage": new FormControl("",[
        Validators.required,
        Validators.minLength(5)
      ]),
      "status": new FormControl('draft', [
        Validators.required
      ]),
      "scheduleTimezone": new FormControl(null),
      "scheduleTime": new FormControl(null),
      "blastComplianceFooter": new FormControl(""),
      "recipientListSource": new FormControl("select"),
      "attachments": new FormControl([]),
      "recipientList": new FormControl(null)
    }, {
      validators: [
        DateIsInFuture('scheduleTime','scheduleTimezone',(group)=> { return group.get("status").value != "scheduled"}),
        ListSelectionIsValid("recipientList", "recipientListSource","status")
      ],
      updateOn: 'change'
    });

    this.baseFrmSendNewBlast = this.frmSendNewBlast.value;

    this.frmSettings = new FormGroup({
      "blastComplianceFooter": new FormControl("Reply STOP to unsubscribe.")
    });
  }


  /////////////////////////////
  // Drawer Functions
  ////////////////////////////

  /**
   * Callback method for AddressBookComponent.refreshStatus
   * 
   * @param event string array of all currently selected contact ids
   */
  selectedListChanged(event: string[]) {
    if (!Array.isArray(event)) {
      log.warn("contact list is not a ")
    }
    this.frmSendNewBlast.get("recipientList").setValue(event);
    this.frmSendNewBlast.updateValueAndValidity();
    //IF DRAWER IS OPEN ; set holder values
    if (this.drwNewBlast) {
      this.customListValueHolder = event
    }
  }
  changeList(id = null) {
    if (id == null) {
      
    } else {
      this.afs.doc('contact-lists/' + id).valueChanges().forEach(res => {
        let data:any = res
        const list = {
          listName: data.listName,
          id: id,
          count: data.count || 0
        }
        this.frmSendNewBlast.get("recipientList").setValue(list);
        this.frmSendNewBlast.updateValueAndValidity();
        //IF DRAWER IS OPEN ; set holder values
        if (this.drwNewBlast) {
          this.contactListValueHolder = list
        }
      })
    }
  }
  changeRecipientListSource(source) {
    this.frmSendNewBlast.get('recipientListSource').setValue(source);
  }
  setSelectedTabIndex() {
    if (this.frmSendNewBlast.get('recipientListSource').value == "select") {
      this.frmSendNewBlast.get("recipientList").setValue( this.contactListValueHolder);
      this.frmSendNewBlast.updateValueAndValidity();
      return '0'
    }
    if (this.frmSendNewBlast.get('recipientListSource').value == "custom") {
      this.frmSendNewBlast.get("recipientList").setValue(  this.customListValueHolder);
      this.frmSendNewBlast.updateValueAndValidity();
      return '1'
    }
  }
  setSelectedContactList(data) {
    this.selectedContactList = data.recipientList.id
  }

  /////////////////////////////
  // Blast Send/Edit/Delete
  ////////////////////////////
  editBlast(blast) {

    this.drwNewBlast = true;
    this.activeBlast = blast;
    //log.debug("patching blast",blast)
    this.frmSendNewBlast.patchValue(blast);
    log.debug("Opening blast to edit", blast);
    if (blast.recipientListSource == "select") {
      this.contactListValueHolder = blast.recipientList
    } else {
      this.customListValueHolder = blast.recipientList
    }
  }

  sendNewBlast() {
    this.isBlastSending = true;

    for (const i in this.frmSendNewBlast.controls) {
      this.frmSendNewBlast.controls[i].markAsDirty();
      this.frmSendNewBlast.controls[i].updateValueAndValidity();
    }

    if (this.frmSendNewBlast.valid) {

      let blast = this.frmSendNewBlast.value;

      blast.lastModifiedDate = TextableDate.now();
      blast.uid = this.authnz.activeNumberUID;

      switch (this.frmSendNewBlast.get('status').value) {
        case "send":
          blast.scheduleTime = TextableDate.now();
          blast.scheduleTimezone = moment.tz.guess();
          blast.status = 'scheduled';
          break;
        case "draft":
          blast.scheduleTimezone = null;
          blast.scheduleTime = null;
          break;
        case "scheduled":
          break;
      }
      if (this.activeBlast) {
        this.afs.doc('blasts/' + this.activeBlast.id).update(blast)
          .then((result) => {
            this.isBlastSending = false;
            this.textable.showNotifications('success', 'update', 'blast');
          })
          .catch((err) => {
            console.error(err);
            this.textable.showNotifications('error', 'update', 'blast');
          });
      } else {
        this.afs.collection('blasts').add(blast)
          .then((result) => {
            this.isBlastSending = false;
            this.textable.showNotifications('success', 'create', 'blast');
          })
          .catch((err) => {
            console.error(err);
            this.textable.showNotifications('error', 'create', 'blast');
          });
      }
      this.closeDrawer();
    } else {
      this.isBlastSending = false;
    }

  }

  deleteBlast() {
    this.isBlastDeleting = true;
    this.afs.doc('blasts/' + this.activeBlast.id)
      .delete()
      .then(() => {
        this.closeDrawer();
      })
      .catch((err) => {
        console.error(err);
      })
  }

  formatBlastRecipients(data: TextableBlast) {
    if (data.recipientListSource == "custom") {
      return  "Custom (" + (data.recipientList?.length || 0) + ")"
    }
    else if (data.recipientListSource =="select") {
      if (Object.keys(data.recipientList || {}).length == 0) {
        return "None"
      }
      return data.recipientList.listName + " ("+data.recipientList.count+")"
    }
    else {
      return "None"
    }
  }
  /////////////////////////////
  // Modal functions
  ////////////////////////////

  openDrwNewBlast() {
    this.drwNewBlast = true;
    this.activeBlast = null;
    this.frmSendNewBlast.patchValue(this.baseFrmSendNewBlast)
    this.checkIfFooterSet();
  }
  openMdlFooter() {
    this.mdlFooter = true
    this.checkIfFooterSet();
  }

  closeDrawer() {
    this.activeBlast = null;
    this.frmSendNewBlast.patchValue(this.baseFrmSendNewBlast);
    this.isBlastSending = false;
    this.isBlastDeleting = false;
    this.drwNewBlast = false;
    let displayList = [];
    this.selectedContactList = null;
    this.contactListValueHolder = {};
    this.customListValueHolder = [];
  }

  close() {
    this.frmSettings.reset();
    this.mdlFooter = false;
  }
  /////////////////////////////
  // Compliance Footer Functions
  ////////////////////////////

  async saveFooter() {
    let footer = this.frmSettings.value;
    const activeNumberString = this.authnz.activeNumberProfile.full_name ? this.authnz.activeNumberProfile.full_name + " ("+this.authnz.activeNumberProfile.phone_number+")" : this.authnz.activeNumberProfile.phone_number;
    const showContext = this.authnz.activeNumberUID != this.authnz.currentFireauthUser.uid
    this.close();
    await this.textable.update('users', footer, this.authnz.activeNumberUID, { title: 'Success', subtitle: "Compliance Footer has been updated" + (showContext ? " for " + activeNumberString : ".")});
    
  }

  checkIfFooterSet() {
    if (this.authnz.activeNumberProfile.blastComplianceFooter) {
      this.frmSendNewBlast.get('blastComplianceFooter').setValue(this.authnz.activeNumberProfile.blastComplianceFooter)
      this.frmSettings.get('blastComplianceFooter').setValue(this.authnz.activeNumberProfile.blastComplianceFooter)
    } else {
      this.frmSendNewBlast.get('blastComplianceFooter').setValue("Reply STOP to unsubscribe.")
      this.frmSettings.get('blastComplianceFooter').setValue("Reply STOP to unsubscribe.")
    }
  }
  getMessageCount() {
    let footer = 0; let message = 0;

    if (this.frmSendNewBlast.value.blastComplianceFooter) {
      footer = this.frmSendNewBlast.value.blastComplianceFooter.length
    }
    if (this.frmSendNewBlast.value.blastMessage) {
      message = this.frmSendNewBlast.value.blastMessage.length
    }
    return footer + message
  }

  /////////////////////////////
  // MMS Related Functions
  ////////////////////////////
  public closeMMSUploader(event){
    this.attachUploadToMessage(event)
    this.showMMSModal = false
  }

  private attachUploadToMessage(event){
    if(event){
      this.frmSendNewBlast.get('attachments').setValue(event)
    }
  }

  public removeAttachment(){
    this.frmSendNewBlast.get('attachments').setValue([])
  }
}