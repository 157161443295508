import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TextableService } from 'src/app/services/textable.service';
import { environment } from 'src/environments/environment';
import { AuthNZService } from 'src/app/core/authnz.service';
import { MMSUploaderMode } from 'src/app/components/mms-uploader/mms-uploader.component';
import { MmsService } from 'src/app/services/mms.service';
import { getLogger } from 'src/shared/logging';
import { TextableDate } from 'src/classes/TextableDate';
const log = getLogger("DripCampaignComponent");

@Component({
  selector: 'app-drip-campaign',
  templateUrl: './drip-campaign.component.html',
  styleUrls: ['./drip-campaign.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class DripCampaignComponent implements OnInit {
  public showMMSModal:boolean = false;
  public mmsUploaderMode = MMSUploaderMode.COMPOSE
  
  drwDrip = false;
  loading = false;
  dripForm: FormGroup;

  selectedDripValid = true;
  selectedDrip = {
    name: '',
    drips: [],
    id: null,
    disableDripCampaignDelete: false
  }
  selectedDripletIndex:number;
  selectedStep;
  selectedIndex;
  editBool = false;
  guideVisibleBool = false;

  drips$;

  timezone: any = null;
  scheduleTime: any = null;
  currentDate = TextableDate.now();

  environment: any = environment;

  constructor(
    private textable: TextableService, 
    private authnz: AuthNZService,
    private mms: MmsService
  ) { }

  ngOnInit() {

    this.dripForm = new FormGroup({
      "name": new FormControl(null),
      "uid": new FormControl(null),
      "created": new FormControl(null),
      "updated": new FormControl(null),
      "drips": new FormControl(null),
      "disableDripCampaignDelete": new FormControl(false),
    });

    this.drips$ = this.textable.dripCampaigns$;
    
  }


  createDrip() {
    let time = TextableDate.now();
    this.loading = true;
    this.dripForm.patchValue(this.selectedDrip)
    this.dripForm.get('created').setValue(time);
    this.dripForm.get('updated').setValue(time)
    this.dripForm.get('disableDripCampaignDelete').setValue(this.selectedDrip.disableDripCampaignDelete)
    this.dripForm.get('uid').setValue(this.authnz.activeNumberUID);

    this.textable.create('drip-campaign', this.dripForm.value)
      .then(() => {
        this.refresh();
      })
      .catch(err => {
        this.loading = false;
      });

  }

  updateDrip() {
    let time = TextableDate.now();
    this.loading = true;

    this.dripForm.patchValue(this.selectedDrip)
    this.dripForm.get('updated').setValue(time)
    this.dripForm.get('uid').setValue(this.authnz.activeNumberUID);
    this.dripForm.get('disableDripCampaignDelete').setValue(this.selectedDrip.disableDripCampaignDelete)

    this.textable.update("drip-campaign", this.dripForm.value, this.selectedDrip.id)
      .then(() => {
        this.refresh();
      }).catch(err => {
        this.loading = false;
      })
  }

  deleteDrip(drip) {
    this.loading = true;
    this.textable.delete("drip-campaign", drip.id)
      .then(() => {
        this.refresh();
      }).catch(err => {
        this.loading = false;
      })

  }

  deleteDripStep(drip) {
    let placeHolderArray = this.selectedDrip.drips;
    placeHolderArray.splice(placeHolderArray.indexOf(drip), 1);
    this.selectedDrip.drips = [];
    for (let drip of placeHolderArray) {
      this.selectedDrip.drips.push(drip)
    }
    this.checkSelectedDripValid();
  }

  viewDrip(drip = null) {
    if (drip) {
      this.selectedDrip = JSON.parse(JSON.stringify(drip));
      this.sortDrips();
      this.editBool = true;
    } else {
      this.editBool = false;
      this.selectedDrip = {
        name: '',
        drips: [],
        id:null,
        disableDripCampaignDelete:false
      };
      this.checkSelectedDripValid();
      this.dripForm.reset()
    }
    this.drwDrip = true;
  }

  sortDrips() {
    let placeHolderArray = this.selectedDrip.drips;
    placeHolderArray.sort((a,b)=>{
      return this.textable.convertIntervalToSeconds(a) - this.textable.convertIntervalToSeconds(b);
    });
    this.selectedDrip.drips = [];
    for (let drip of placeHolderArray) {
      this.selectedDrip.drips.push(drip);
    }
  }
  
  addDrip() {
    let placeHolderArray = this.selectedDrip.drips;

    this.selectedDrip.drips = [{
      name: "",
      interval: 1,
      intervalType: "minutes",
      message: '',
      created: null
    }];
    for (let drip of placeHolderArray) {
      this.selectedDrip.drips.push(drip);
    }
    log.debug(this.selectedDrip.drips);
    this.sortDrips();
    this.checkSelectedDripValid();
  }

  closeDrawer() {
    this.drwDrip ? this.drwDrip = false : this.drwDrip = true;
    this.refresh();
  }

  refresh() {
    this.selectedDrip = {
      name: '',
      drips: [],
      id:null,
      disableDripCampaignDelete:false
    };
    this.loading = false;
    this.drwDrip = false;
    this.dripForm.reset();
  }

  checkSelectedDripValid() {
    //console.log(this.selectedDrip)
    let errors = [];
    if (this.selectedDrip.drips.length > 0) {
      this.selectedDrip.drips.forEach(drip => {
        if (drip.message == '' || drip.drip == '' || drip.interval == '') {
          
          errors.push('invalid drip / message');
          //console.log('False no Step')
        }
      });
    }else{
      errors.push('missing drips');
    }

    if (this.selectedDrip.name == '') {
      //console.log('False: ' + this.selectedDrip.name)
      errors.push('invalid drip campaign name');
    }
    

    this.selectedDripValid = errors.length < 1;

  }

  /////////////////////////////
  // MMS Related Functions
  ////////////////////////////
  public closeMMSUploader(event){
    this.attachUploadToMessage(event)
    this.showMMSModal = false
  }

  private attachUploadToMessage(event){
    if(event){
      this.selectedDrip.drips[this.selectedDripletIndex].attachments = event
    }
  }

  public removeAttachment(drip){
    this.selectedDripletIndex = this.selectedDrip.drips.indexOf(drip);
    this.selectedDrip.drips[this.selectedDripletIndex].attachments = []
  }

  public openModalAndSelectDriplet(drip){
    this.showMMSModal = true;
    this.selectedDripletIndex = this.selectedDrip.drips.indexOf(drip);
  }
}
