import { Theme } from "ng-zorro-antd/core/config"
import { CSSThemeProperties } from "./theme.service.themeables"

export interface TextableBrandingDetails  {
  displayName?: string
  siderLogoURL?: string
  logoutURL?: string
  theme?: TextableTheme
}

export type CSSThemePropertyValidationFunction = (property: string)=>boolean

export type CSSThemePropertyType = {
  typeName: "color",
  validator: CSSThemePropertyValidationFunction
}

export const CSSColorProperty: CSSThemePropertyType = {
  typeName: "color",
  validator: (value)=>
  {
    return value && value.charAt(0)=="#" &&  (value.length == 7 || value.length == 4)
  }
}

export type getPropertyFunction = (propertyName: string)=>string;
export type getPropertySourceFunction = (propertyName: string) => TextableThemePropertySource
type defaultValueFunction =  (props:{getPropertyValue: getPropertyFunction, getPropertySource: getPropertySourceFunction}) => string

export type CSSThemeProperty = {
  name: string,
  zorroThemeName?: keyof Theme,
  displayName: string,
  extra: string,
  cssType:CSSThemePropertyType
  legacyCustomName?: string,
  generateDebug?: boolean,
  /**
   * If no value is provided for this themeable property, then we should attempt to calculate it
   */
  defaultValue?: defaultValueFunction
}


/**
 * Intermediate type which extracts the `name` attributes from `cssThemeProperties` as a 
 * set of string choices.
 */
export type CSSPropertyNames = typeof CSSThemeProperties[number]['name']

export type TextableThemePropertySource = "legacyTheme" | "calculated" | "theme" | "debug"

/**
 * Full type definition for a theme
 */
export type TextableTheme = {
  properties: { [key in CSSPropertyNames] : string}
}