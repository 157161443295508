import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TextableService } from 'src/app/services/textable.service';
import { AuthNZService } from './authnz.service';
import { ApplicationStates } from './auth-nz/interfaces';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private authnz: AuthNZService
  ) {


  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (this.authnz.authState != ApplicationStates.LoggedIn) {
        this.router.navigate(['login',{redirectUrl: state.url}]);
        return false;
      }
      let navItem = this.navigation.mapRouteToFeature((next.routeConfig.path).split("/")[0]);
      let result = navItem.accessFunction(this.authnz.currentSecurityProvider, this.authnz);
      return result;
  }
  
}
