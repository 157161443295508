import { DocumentChange, DocumentSnapshot, QueryDocumentSnapshot } from "@angular/fire/compat/firestore"

export interface TextableBaseFirestoreDocument {
  /**
   * The actual ID of the document
   */
  firebase_document_id?: string
  /**
   * still used field that should be identical to `firebase_document_id`
   * @deprecated
   */
  id?: string
}

/**
 * Utility function to map RxJS observables from Angular Firestore
 * to TextableBaseFirestoreDocument
 * @param source U
 * @returns 
 */
export const mapToTextableBaseFirestoreDocument = <T extends TextableBaseFirestoreDocument>(source: QueryDocumentSnapshot<any>):T   => {
  const d: TextableBaseFirestoreDocument = source.data();
  d.id = source.id;
  d.firebase_document_id = source.id
  return d as T
}

export const isDocument = <T>(data: any, requiredFields: string[]): data is T => {
  for (let f of requiredFields) {
    if (! data.hasOwnProperty(f)) {
      return false
    }
  }
  return true;
}