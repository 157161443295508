import { Component, Input, OnInit } from '@angular/core';
import { TextableContact } from 'src/app/backported_types/contact';
import { ContactDTO, ContactConsentDTO, isContactDTO } from 'src/classes/contactDTO';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
  selector: 'app-consent-message-banner',
  templateUrl: './consent-message-banner.component.html',
  styleUrls: ['./consent-message-banner.component.scss']
})
export class ConsentMessageBannerComponent implements OnInit {

  @Input('contact') private contact: ContactDTO | TextableContact = null;
  @Input('page') page:any = null;

  public contactDTO: ContactDTO

  constructor(
    private contactService: ContactsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    /**
     * The app-address-book component passes a TextableContact to this component,
     * but app-conversations passes as ContactDTO, so we need to always have the ContactDTO
     */
    if (! isContactDTO(this.contact) ) {
      this.contactDTO = this.contactService.contacts$.getValue().contacts.find(c=>c.contact.id == (this.contact as TextableContact).id)
    }
    else { 
      this.contactDTO = this.contact as ContactDTO
    }
  }
}
