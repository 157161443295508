import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import log from 'loglevel';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PlanSelectionProperties, TextableSubscriptionPlan } from 'src/app/core/auth-nz/interfaces';
import { AuthNZService } from 'src/app/core/authnz.service';
import { BackendService } from 'src/app/services/backend.service';
import { TextableService } from 'src/app/services/textable.service';

@Component({
  selector: 'app-organization-editor',
  templateUrl: './organization-editor.component.html',
  styleUrls: ['./organization-editor.component.scss']
})
export class OrganizationEditorComponent implements OnInit, OnChanges{

  @Input() visible: boolean = false;
  @Input() selectedOrganization: any = {};
  @Input() mode: "new"|"edit";
  @Output() dismissed = new EventEmitter();

  frmAddOrg: FormGroup;
  isOrgSaving: boolean;
  public organizationPlanSelection: string;
  public selectedPlanDescription: string;
  public currentlySelectedPlan: TextableSubscriptionPlan;
  public planSelectionDetails: PlanSelectionProperties;
  
  constructor(
    private msg: NzMessageService,
    private textable: TextableService,
    public authnz: AuthNZService,
    private backendService: BackendService
  ) { }

  /**
 * Determine whether we're in "new" or "edit" mode and generate the title accordingly
 */
  getModalTitle() {
    return this.mode=="new" ? "Create Organization" : "Edit Organization";
  }

  ngOnInit() {

    this.planSelectionDetails = this.authnz.currentSecurityProvider.getPlanSelectionDetails();

    this.frmAddOrg = new FormGroup({
      "organizationName": new FormControl(null, [
        Validators.required
      ]),
      "description": new FormControl(null, [
      ]),
      "organizationPlanSelection": new FormControl(null, [
        (
          this.planSelectionDetails.granularity=='organization' ? 
          Validators.required : Validators.nullValidator
        )
      ]),
      "requireContactConsent": new FormControl(null), // TODO TXBDEV-193: Ensure this control name binds to the Types in textablecommon
      "requireContactConsentMessage":  new FormControl(null), // TODO TXBDEV-193: Ensure this control name binds to the Types in textablecommon
      "organizationAdmins":  new FormControl(null)
    });

    this.frmAddOrg.valueChanges.subscribe(()=>{
      this.currentlySelectedPlan =  this.planSelectionDetails.choices.find(p=>p.id == this.frmAddOrg.get('organizationPlanSelection').value)
    })
    

  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.frmAddOrg) {
      return;
    }

    if (this.mode == "new") {
      this.frmAddOrg.reset();
    }
    else if (changes.hasOwnProperty("selectedOrganization")) {
      this.frmAddOrg.reset();

      if(changes.selectedOrganization.currentValue.hasOwnProperty("billing")) {
        let {billing,...selectedOrg} = changes.selectedOrganization.currentValue; // This is _a little_ janky;  The "right way" would be to use separate Angular form groups and such, but for one field it's really not worthwhile
        selectedOrg.organizationPlanSelection = billing.plan;
        this.frmAddOrg.patchValue(selectedOrg);
      }     
      else {
        this.frmAddOrg.patchValue(changes.selectedOrganization.currentValue);
      }
    }
  }

  updateOrganization(){

    this.isOrgSaving = true;

    if(this.selectedOrganization.id){
      
      let {organizationPlanSelection, ...org} = this.frmAddOrg.value;
      org.billing = {
        plan: organizationPlanSelection // This is _a little_ janky;  The "right way" would be to use separate Angular form groups and such, but for one field it's really not worthwhile
      }

      this.backendService.backendPost("api/v2/organizations/"+this.selectedOrganization.id,
        {},
        org,
        {
          method: "patch"
        })
        .then((result)=>{
          this.msg.create('success', 'Organization updated successfully.');
          this.cancelAddOrgModal();
        })
        .catch((err)=>{
          console.log(err);
          this.msg.create('error', 'Organization could not be updated. ' + (err.error?.error ? err.error.error : ""));
        })
        .finally(()=>{
          this.isOrgSaving = false;
        });

    }else{
      
      //initialize the default user count
      this.frmAddOrg.value.userCount = 0;
      //ADD MANAGEDBY TO ORG DOCUMENT IF RETAIL
      if (this.authnz.currentSecurityProvider.storeManagedByAttributeOnUsers()) {
        this.frmAddOrg.value.managedBy = this.authnz.currentFireauthUser.uid;
      }
      let {organizationPlanSelection, ...org} = this.frmAddOrg.value;
      org.billing = {
        plan: organizationPlanSelection // This is _a little_ janky;  The "right way" would be to use separate Angular form groups and such, but for one field it's really not worthwhile
      }
      this.backendService.backendPost("frontend/organizations",
        {},
        org)
        .then((result)=>{
          this.msg.create('success', 'Organization added successfully.');
          this.cancelAddOrgModal();
        })
        .catch((err)=>{
          log.error("Unable to create organization", err);
          this.msg.create('error', 'Organization could not be added. ' + (err.error?.error ? err.error.error : ""));
        })
        .finally(()=>{
          this.isOrgSaving = false;
        });

    }

  }

  cancelAddOrgModal(){
    log.debug("closing");
    this.dismissed.emit();

  }

  /**
   * Some `TextableSubscriptionPlan` are restricted, so we want to disable the select box for these plans
   * Allowing the value to be shown in the UI, but not modified
   * 
   * @param planId 
   * @returns 
   */
  public isPlanRestricted(planId: string) {
    const plan =  this.planSelectionDetails.choices.find(p=>p.id == planId)
    if (plan) {
      log.debug("plan " + planId + " is restricted: " + plan.restricted)
      return plan.restricted
    }
    else {
      log.debug("could not find plan " + planId);
      return false;

    }
  }

}
