<nz-row>
  <nz-col nzSpan="6">
    <ul nz-menu [nzMode]="'inline'" style="height:600px;overflow:auto;">
      <ng-container *ngFor="let guide of guides">
        <li nz-menu-item (click)="selectGuide(guide)" [nzSelected]="selectedGuide == guide.name">
          <span title>{{guide.name}}</span>
        </li>
      </ng-container>
    </ul>
  </nz-col>
  <nz-col nzSpan="18">
    <nz-content style="padding:24px;height:600px;overflow-y:auto;" #guideContentPane>
      <markdown #ngPreserveWhitespaces [src]="activeGuide.pathToSource" *ngIf="activeGuide"></markdown>
    </nz-content>
  </nz-col>
</nz-row>