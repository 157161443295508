import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../../../environments/environment';
import { TextableService } from 'src/app/services/textable.service';
import { vars } from 'src/app/app.constants';
import { AuthNZService } from 'src/app/core/authnz.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import "firebase/compat/auth";
import { MessageSettings, UserManagementService } from 'src/app/services/user-management.service';
import { StripeService } from 'src/app/services/stripe.service';
import { ValidateField } from 'src/app/form_validators/ValidateField';
import { MatchesField } from 'src/app/form_validators/MatchesField';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class ProfileComponent implements OnInit {

  public phoneMask = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; //ensures the first digit is not +, 0, or 1. This allows the user to paste '+1##########' formatted numbers.

  public frmProfile: FormGroup;
  public frmPassword: FormGroup;
  public frmPreferences: FormGroup;
  public isUpdatingProfile: boolean = false;
  public isUpdatingPassword: boolean = false;
  public guideSection: string = null;
  public environment: any = environment;
  public userProfile: any;
  public isEmailVerified: boolean;
  public stripeSessionUrl: string;
  public billingModalVisible: boolean = false;
  public isLoading: boolean = false;

  //phonePrefix should change depending on location in the future.
  public phonePrefix: string = '+1';
  public countryCodes = vars.countryCodes;
  public activeUserProfile = this.authnz.contextChanged.pipe(
      map(({ activeUserProfile }) => activeUserProfile),
      distinctUntilKeyChanged("firebase_document_id")
    );
  public activeNumberProfile = combineLatest([
        this.activeUserProfile,
        this.authnz.contextChanged.pipe(
          map(({ activeNumberProfile }) => activeNumberProfile),
          distinctUntilKeyChanged("firebase_document_id")
        )
    ]).pipe(
      map(([activeUser, activeNumber]) => {
        if(activeUser.firebase_document_id == activeNumber.firebase_document_id) {
          return null;
        }
        return activeNumber;
      }
      )
    );

  constructor(
    private notification: NzNotificationService,
    private textable: TextableService,
    private userManagementService: UserManagementService,
    private msg: NzMessageService,
    public authnz: AuthNZService,
    private stripe: StripeService
  ) { }

  ngOnInit() {
    this.initializeForms();
    this.authnz.contextChanged.subscribe(({ activeUserRecord: fireauthUser, activeUserProfile: firestoreUserDocument }) => {
      this.isEmailVerified = fireauthUser.emailVerified;

      // Create a copy of this profile object since the profile component 
      // manipulates this object with `delete` statements
      this.userProfile = Object.assign({}, firestoreUserDocument);
      this.frmProfile.patchValue(this.userProfile);
      this.frmPreferences.patchValue({
        ...this.userProfile,
        awayMessageResponseInterval: typeof this.userProfile.awayMessageResponseInterval == "number" ? (this.userProfile.awayMessageResponseInterval / 3600) : 24
      });

    });
  }


  initializeForms() {
    this.frmProfile = new FormGroup({
      "full_name": new FormControl(null, [
        Validators.required
      ]),
      "email": new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      "provider": new FormControl(null, [
        Validators.required
      ]),
      "phone_number": new FormControl(null, [
        Validators.pattern(vars.regexValidationPatterns.phone),// Require length of 10(on read) then 14 (on write)
        Validators.required
      ])
    });

    this.frmPassword = new FormGroup({
      "password": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.password),
        ValidateField("cPassword")
      ]),
      "cPassword": new FormControl(null, [
        Validators.required,
        MatchesField("password")
      ])
    });

    this.frmPreferences = new FormGroup({
      "awayMessageResponseInterval": new FormControl(24, [
        Validators.min(0)
      ]),
      "signatureEnabled": new FormControl(false),
      "signatureText": new FormControl(""),


    })
  }
  removeCountryCode(phoneNumber) {
    //in the future for international numbers; we must account for different country codes.
    return phoneNumber.replace(this.phonePrefix, "");

  }



  /**
   * Updates the user's profile.
   */
  async updateProfile() {
    this.isUpdatingProfile = true;
    if (!this.frmProfile.valid) {
      this.notification.create(
        'error',
        'Your Profile Was Not Updated',
        'Your profile could not be updated. Please validate your details and try again.'
      );

      this.isUpdatingProfile = false;

    } else {
      let profile = this.frmProfile.value;
      //Replace gets rid of the phone mask
      profile.phone_number = this.textable.addCountryCode(profile.phone_number, this.phonePrefix);

      try {
        await this.userManagementService.editUserProfile(this.frmProfile.value)
        this.notification.create(
          'success',
          'Your profile has been updated.',
          ''
        );
        this.isUpdatingProfile = false;
      } catch (e) {
        if (!e.error) {
          this.notification.create(
            'error',
            'There was a problem updating your profile. Please try again.',
            ''
          );
        } else {
          this.notification.create(
            'error',
            e.error.error,
            ''
          );
        }
        this.isUpdatingProfile = false;
      }
    }
  }

  /**
   * Updates the user's canned message interval.
   */
  async updateMessageSettings() {
    const preferences = { ...this.frmPreferences.value } as MessageSettings
    preferences.awayMessageResponseInterval = this.frmPreferences.value.awayMessageResponseInterval * 3600

    this.isUpdatingProfile = true;
    if (!this.frmPreferences.valid) {
      this.notification.create(
        'error',
        'Your Preferences Were Not Updated',
        'Your preferences could not be updated. Please validate your details and try again.'
      );

      this.isUpdatingProfile = false;

    } else {
      try {
        await this.userManagementService.editUserMessageSettings(preferences)
        this.notification.create(
          'success',
          'Your profile has been updated.',
          ''
        );
        this.isUpdatingProfile = false;
      } catch (e) {
        this.notification.create(
          'error',
          e.error?.error || 'There was a problem updating your profile. Please try again.',
          ""
        );
        this.isUpdatingProfile = false;
      }
    }
  }

  async updatePassword() {
    this.isUpdatingPassword = true;
    if (this.frmPassword.valid) {
      await this.userManagementService.changePassword(this.frmPassword.get('password').value)
      this.isUpdatingPassword = false;
    }
  }

  public async openBillingPortal() {
    try {
      this.stripeSessionUrl = await this.stripe.getPortalSession();
      window.open(this.stripeSessionUrl);
      this.billingModalVisible = true;
      this.isLoading = false
    }
    catch(err) {
      this.msg.create('error', err);
      this.isLoading = false
    }
  }

}
