import { Component, OnInit } from '@angular/core';
import { AuthNZService } from '../../core/authnz.service';

@Component({
  selector: 'app-subscription-status-message',
  templateUrl: './subscription-status-message.component.html',
  styleUrls: ['./subscription-status-message.component.scss']
})
export class SubscriptionStatusMessageComponent implements OnInit {

  planSelectorVisible: boolean = false;
  planSelectorClosable: boolean = false;
  
  constructor(
    public authnz: AuthNZService
  ) { }

  ngOnInit() {
  }

  showPlanSelector() {
    this.planSelectorClosable = true;
    this.planSelectorVisible = true;
  }
  closePlanSelector(event) {
    this.planSelectorClosable = false;
    this.planSelectorVisible = false;
  }

}
