import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ReplaySubject } from 'rxjs';
import { ApplicationStates, LoginProvider, UISecurityProvider } from './auth-nz/interfaces';
import { environment } from '../../environments/environment';
import { PrivateLabelSecurityProvider } from './auth-nz/privatelabel';
import { RetailSecurityProvider } from './auth-nz/retail';
import { ObservableContexUpdateSignal, SubscriptionManagerService } from './subscription-manager.service';
import { BackendService } from '../services/backend.service';
import { VirtualPBXSecurityProvider } from './auth-nz/virtualpbx';
import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
import { ApplicationContextService } from '../services/application-context.service';
import { getLogger } from 'src/shared/logging';
import { mapToTextableBaseFirestoreDocument } from '../backported_types/base';
import { TextableUser } from '../backported_types/users';
import { MinimumCommitmentPrivateLabelSecurityProvider } from './auth-nz/MinimumCommitmentPrivateLabelSecurityProvider';
const log = getLogger("AuthNZ")

async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthNZService {

  currentSecurityProvider:  UISecurityProvider
  currentFireauthUser: firebase.User;
  currentUserOrganizationDocument: firebase.firestore.DocumentData;
  currentUserDocument: TextableUser;
  activeNumberProfile: TextableUser;

  afa: AngularFireAuth;
  afs: AngularFirestore;
  /**
   * Events are published to this observable whenever the current user's authentication or profile chnages
   * In other words, both updates to AngularFireAuth authState and updates to the currently logged in-users 
   * AngularFirestore user document will push events to this observable
   */
  contextChanged: ReplaySubject<{
    activeUserRecord: firebase.User,  
    activeUserProfile: TextableUser,
    activeNumberProfile: TextableUser,
    previousActiveNumberProfile: TextableUser;
  }>;
  /**
   * Events are published to this observable whenever the "Logged in state" of the application changes.
   * Available states are defined in the ApplicationStates ENUM, and include LoggedIn, LoggedOut, PreLogout, and PendingEmailVerification.
   * 
   * The PendingEmailVerification state is only published when the current UISecurityProvider demands. 
   * 
   */
  authChanged: ReplaySubject<ApplicationStates>;
  /**
   * The last AuthState which was published on the authChanged observable.
   */
  authState: ApplicationStates;

  /**
   * boolean to indicate whehter there is a number switch in progress
   */
  isNumberSwitching: boolean = true;

  /**
   * the firestore ID of the active number.  
   * Gets set before retrieving the actual profile of the active number
   */
  activeNumberUID: string;


  /**
   * URL to which users will be redirected after logging out
   */
  public logoutURL: string;

  /**
   * When a user changes their password, the current token becomes invalid; however, we immediately
   * re-sign in the user with a new signInWithCustomToken, so we don't want to "flash" the login 
   * page during this breif moment;
   */
  public selfPasswordChangeInProgress: boolean = false

  constructor(
    afa: AngularFireAuth, 
    afs: AngularFirestore, 
    private router: Router,
    private submanager: SubscriptionManagerService,
    private backendService: BackendService,
    public applicationContextService: ApplicationContextService,
    /**
     * Various security providers (which are not capable of consuming dependency inection provided by Angular) 
     * may need access to other classes or services which are in the scope of Angular's Dependency Injection.
     * 
     * Rather than consume / instantiate those potentially un-needed classes in the authnz constructor, we
     * gain a handle to Angular's dependency injector so that we can conditionally obtain these objects 
     * only when the current runtime configuration demands.
     * 
     * Helpful reading: https://stackoverflow.com/a/41433007
     * 
     */
    private injector: Injector
  ){

    
    this.afa= afa;
    this.afs=afs;

    this.authChanged = new ReplaySubject<ApplicationStates>(null);
    this.contextChanged = new ReplaySubject<{
      activeUserRecord: firebase.User,  
      activeUserProfile: TextableUser,
      activeNumberProfile: TextableUser,
      previousActiveNumberProfile: TextableUser;
    }>(null);

    this.authChanged.subscribe((state) => {
      this.authState = state;
    })

    try {
      this.setupSecurityProvider();
    }
    catch(err){
      log.warn("Error setting up security provider: ", err);
      this.showSetupError();
      return;
    }
    // Setup the profile before the active number 
    // so that the ActiveNumberChanged signal doesn't cause a double
    // trigger of the downstream observable.
    try {
      this.setupProfileObservable();
      this.setupActiveNumberObservable();
    }
    catch(err) {
      log.error("Error setting up application", err);
      this.showSetupError();
    }
  }

  private async showSetupError() {
    await this.router.navigate(["/"])
    this.authChanged.next(ApplicationStates.Error); 
  }

  private setupSecurityProvider() {
    if (environment.hasOwnProperty("securityProvider")){
      switch((environment as any).securityProvider) {
        case "retail":
          log.debug("Retail security");
          this.currentSecurityProvider = new RetailSecurityProvider(this,this.injector);
          break;
        case  "privatelabel":
          log.debug("Private label security");
          this.currentSecurityProvider = new PrivateLabelSecurityProvider(this,this.injector);
          break;
        case "MinimumCommitmentPrivateLabel":
          log.debug("MinimumCommitmentPrivateLabel security");
          this.currentSecurityProvider = new MinimumCommitmentPrivateLabelSecurityProvider(this,this.injector);
          break;
        case  "virtualpbx":
          log.warn("deprecated: virtualpbx security");
          this.currentSecurityProvider = new VirtualPBXSecurityProvider(this,this.injector);
          break;
        default:
          throw new Error("Invalid security provider: '"+(environment as any).securityProvider+"'");
      }
    }
    else {
      log.warn("Legacy environment.ts format detected");
      if ((environment as any).isPrivateLabel) {
        log.debug("Private label security");
        this.currentSecurityProvider = new PrivateLabelSecurityProvider(this,this.injector);
      }
      else{
        log.debug("Retail security");
        this.currentSecurityProvider = new RetailSecurityProvider(this,this.injector);
      }
    }
  }


  getCurrentSecurityProviderClassName() {
    return this.currentSecurityProvider.constructor.name;
  }

  /**
   * Proxy function to sign in with a custom token.
   * @param token 
   */
  public async signInWithCustomToken(token: string) {
    try {
      const nt = await this.afa.signInWithCustomToken(token)
      const c = await firebase.auth().currentUser.getIdTokenResult();
      log.debug("Signed in with custom token", c)    
    }
    catch(err){
      log.error("Error signing in with custom token: ", err);
    }
  }


  emailLogin(email, password) {
    return this.afa.signInWithEmailAndPassword(email, password)
      .then((credential) => {
        this.updateUserData(credential.user);
      });
  }

  public async getLoginProviders(): Promise<LoginProvider[]> {
    let providers: LoginProvider[] = []
    try {
      log.debug("getting auth providers");
      const authProvidersResponse= await this.backendService.backendGet(
        "auth/providers",
        null,
        false) as any

      if (!authProvidersResponse || ! Array.isArray(authProvidersResponse)) {
        throw new Error("No auth providers in response")
      }
      
      authProvidersResponse.forEach((provider) => {
        providers.push({
          backendURL: provider.backendURL,
          name: provider.name,
          type: provider.type,
          color: provider.color,
          showLoginButton: provider.showLoginButton
        })
      })
      log.debug("fetched providers", providers);
      return providers;
    }
    catch (err) {
      log.error("Error fetching providers: ", err);
      return [];
    }
  }

  public async providerLogin(provider: LoginProvider, onError: (message:string)=>void): Promise<Boolean> {
    window.addEventListener("message",event=>{
      if (event.origin+"/" == environment.apiBase) {
        log.debug("Message: ", event);
        // This event data is coming from PB's auth.ts '/saml/callback' endpoint handler
        // is it secure to use postMessage with a JWT; Presumably YES - this is how Firebase Auth signInWithPopup does it.
        if (event.data.error) {
          onError("Provider login returned an error");
          return
        }
        this.signInWithCustomToken(event.data.token)
      }
    });

    if(provider.type == "SAML") {
      const targetURL = environment.apiBase+provider.backendURL+"?ProviderName=" + encodeURIComponent(provider.name);
      log.debug("Opening SAML popup to URL " + targetURL);
      let popup = window.open(targetURL,"Self","width=600,height=500");
      if (!popup || popup.closed || typeof popup == 'undefined') {
        return false
      }
    }

  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afa.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user)
      })
  }


  private updateUserData(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    }

    return userRef.set(data, { merge: true })

  }

  /**
   * 
   * @returns type-safe TextableUser of the currently logged in user.
   */
  public currentUserAsTextableUser(): TextableUser { 
    const tU: TextableUser = {
      id: this.currentFireauthUser.uid,
      firebase_document_id: this.currentFireauthUser.uid,
      ...this.currentUserDocument as any
    }   
    return tU;
  }

  /**
    * Subscribe to changes to the currently logged in user (Fireauth),
    * AND the user's profile document.  Emit events to subscribers
    * when the state of either changes
   */
   private setupProfileObservable() {
    this.afa.authState.subscribe(async (user)=>{
      if (this.selfPasswordChangeInProgress) {
        return;
      }
      try {
        if (!user)  {
          this.currentFireauthUser = null;
          this.authChanged.next(ApplicationStates.LoggedOut);
          return;
        }
        this.currentFireauthUser = user;
        if (user.emailVerified == false && this.currentSecurityProvider.requiresEmailConfirmation()) {
          this.authChanged.next(ApplicationStates.PendingEmailVerification);
          return;
        }
        this.activeNumberUID = user.uid;
        const previousActiveNumberProfile = {...this.activeNumberProfile};
        // wait until we have both the current FireAuth user, and the user profile document (from the Firestore users collection)
        // then ask the current security provider to do "whatever they need to do" upon user changes.
        // Then finally emit an event on the `contextChanged` observable.
        try {
          await this.afs.firestore.waitForPendingWrites()
          const data = await this.afs.firestore.doc('users/'+user.uid).get();
          this.currentUserDocument = mapToTextableBaseFirestoreDocument(data);
          this.activeNumberProfile = mapToTextableBaseFirestoreDocument(data);
        }
        catch (err) {
          throw new Error("Error fetching user document" + err.message);
        }
        try {
          await this.afs.doc('organizations/' + this.currentUserDocument.organizationId).get().toPromise().then((data) => {
            if (!data.exists){ 
              throw new Error("No organization exists for '"+this.currentUserDocument.organizationId+"'")
            }
            this.currentUserOrganizationDocument = data.data();
            log.debug("Fetched current user's organization: ", this.currentUserOrganizationDocument);
          })
        }
        catch (err) { 
          if ((environment as any).securityProvider == "virtualpbx"){
            throw new Error("Error fetching user organization (" + this.currentUserDocument.organizationId + ") " + err.message);
          }
          else {
            log.warn("User document is missing an organization.  This will be a stop-error in the future, but is currently permitted.")
          }
        }
        try {
          await this.applicationContextService.refreshContext();
          await this.currentSecurityProvider.updateUser()
        }
        catch (err) {
          throw new Error("Error updating user security" + err.message);
        }

        log.debug("login user: " + this.currentFireauthUser.email);
        this.submanager.signal(ObservableContexUpdateSignal.LoggedIn);
        this.submanager.signal(ObservableContexUpdateSignal.ActiveNumberChanged);
        this.authChanged.next(ApplicationStates.LoggedIn);
        this.contextChanged.next({
          activeUserRecord: this.currentFireauthUser,
          activeUserProfile: this.currentUserDocument, 
          activeNumberProfile: this.activeNumberProfile,
          previousActiveNumberProfile: previousActiveNumberProfile
        });
      }
      catch(err) {
        log.error("Error setting up profile watcher", err);
        this.authChanged.next(ApplicationStates.Error);
      }
    });

    // create an ongoing subscription to the User profile (Their Firestore user document), and emit events on the `contextChanged` 
    // observable when that profile changes.
    this.submanager.observe(
      "user-profile", 
      ()=>this.afs.doc('users/'+this.currentFireauthUser.uid).snapshotChanges(),
      ObservableContexUpdateSignal.LoggedIn
    )
    .subscribe(async (data) => {  
      this.currentUserDocument = mapToTextableBaseFirestoreDocument(data.payload);
      if (this.currentUserDocument.is_disabled) {
        this.logout();
      }
      await this.currentSecurityProvider.updateUser();
      this.submanager.signal(ObservableContexUpdateSignal.ProfileChanged);
      this.contextChanged.next({
        activeUserRecord: this.currentFireauthUser, 
        activeUserProfile: this.currentUserDocument, 
        activeNumberProfile: this.activeNumberProfile,
        previousActiveNumberProfile: this.activeNumberProfile
      });
    });

    this.submanager.observe(
      "user-organization",
      ()=>this.afs.doc('organizations/' + this.currentUserDocument.organizationId).snapshotChanges(),
      ObservableContexUpdateSignal.LoggedIn
    ).subscribe(async(data)=> {
      this.currentUserOrganizationDocument = data.payload.data();
      log.debug("Updated current user's organization: ", this.currentUserOrganizationDocument);
      await this.currentSecurityProvider.updateUser();
      this.submanager.signal(ObservableContexUpdateSignal.ProfileChanged);
      this.contextChanged.next({
        activeUserRecord: this.currentFireauthUser, 
        activeUserProfile: this.currentUserDocument, 
        activeNumberProfile: this.activeNumberProfile,
        previousActiveNumberProfile: this.activeNumberProfile
      });
    })

  }

  private setupActiveNumberObservable() {
    this.submanager.observe(
      "activenumber-profile", 
      ()=> {
        log.debug("Watching for active number changes on " + this.activeNumberUID)
        return this.afs.doc('users/'+this.activeNumberUID).valueChanges()
      },
      ObservableContexUpdateSignal.ActiveNumberChanged
    ).subscribe((activeNumberProfile: any)=>{
      log.debug("change to active number.  new value: ", activeNumberProfile);
      const isChanged = this.activeNumberProfile.phone_number != activeNumberProfile.phone_number;
      const previousActiveNumberProfile = {...this.activeNumberProfile};
      this.activeNumberProfile=activeNumberProfile;
      this.contextChanged.next({
        activeUserRecord: this.currentFireauthUser, 
        activeUserProfile: this.currentUserDocument, 
        activeNumberProfile: this.activeNumberProfile,
        previousActiveNumberProfile: previousActiveNumberProfile
      });
      this.isNumberSwitching = false;
    })
  }

  /**
   * Logs out the current user.   
   * Emits events on the authChanged observable so that listeners may gracefully clean up any resources requiring authentication
   */
  public async logout() {
    this.submanager.signal(ObservableContexUpdateSignal.Prelogout);
    this.authChanged.next(ApplicationStates.PreLogout)
    await sleep(200) // 
    await this.afa.signOut();
    if (this.logoutURL) {
      log.debug("Redirecting user to logoutURL: " + this.logoutURL)
      window.location.assign(this.logoutURL);
    }
  }

  /**
   * Sets the active number to the user profile identified by the supplied uid
   * @param uid the user id to be used as the active number
   */
  public async setActiveNumber(uid: string) {
    if (uid == this.activeNumberUID) {
      log.debug("Skipping request to set active number because it is already active: " + uid);
      return;
    }
    log.debug("Switching active number uid to: " + uid);
    this.isNumberSwitching = true;
    this.activeNumberUID = uid;
    this.submanager.signal(ObservableContexUpdateSignal.ActiveNumberChanged);
   
  }
  
  public async GetCurrentToken(): Promise<string> {
    return await firebase.auth().currentUser.getIdToken();
  }

  /**
   * Returns a promise that will resolve once a the application is fully logged in.
   * @returns 
   */
  public GetLoggedInPromise(): Promise<void> {
    return new Promise<void>((resolve,reject) => {
      this.authChanged.subscribe( (state) =>{
        if (state == ApplicationStates.LoggedIn) {
          resolve();
        }
      })
    });
  } 
}
