import { Component, OnInit, Input } from '@angular/core';
import { MmsService } from 'src/app/services/mms.service';
import { FrontendAttachment } from '../mms-uploader/mms-uploader.component';
import { getLogger } from 'src/shared/logging';
const log = getLogger("MmsViewerComponent");

@Component({
  selector: 'app-mms-viewer',
  templateUrl: './mms-viewer.component.html',
  styleUrls: ['./mms-viewer.component.scss']
})
export class MmsViewerComponent implements OnInit {

  @Input() attachment: FrontendAttachment;
  
  /**
   * Either a Base64 encoded DataURL, or a real URL
   */
  public src: string

  isZoomed = false;
  
  constructor(public mms: MmsService) {  }

  ngOnInit() {
    log.debug("Opening attachment", this.attachment)
    if (this.attachment.data) {
      this.src = this.attachment.data
    }
    else {
      this.src = this.mms.getAttachmentURL(this.attachment);
    }
  }

  doZoom() {
    this.isZoomed = !this.isZoomed
  }
}
