import { Component, OnInit } from '@angular/core';
import { LoginProvider } from 'src/app/core/auth-nz/interfaces';
import { AuthNZService } from 'src/app/core/authnz.service';

@Component({
  selector: 'app-manage-sso',
  templateUrl: './manage-sso.component.html',
  styleUrls: ['./manage-sso.component.scss'],
  host: {
    "class": "app-view app-view-one-pane"
  }
})
export class ManageSsoComponent implements OnInit {


  isLoadingSSOProviders: boolean = true;
  displayListSSOProviders: LoginProvider[];

  constructor(
    private authnz: AuthNZService
  ) { }

  ngOnInit() {
    this.authnz.getLoginProviders().then((providers) => {
      this.displayListSSOProviders = providers;
      this.isLoadingSSOProviders = false;
    });
  }

}
