<button
  nz-button 
  nz-popover 
  nzPopoverTrigger="click"
  nzType="primary" 
  [nzPopoverTitle]="debugDataTitle" 
  [nzPopoverContent]="debugDataContent"
  nzShape="circle"
  nzSize="large"
  nzPopoverPlacement="rightBottom"
  nzPopoverOverlayClassName="debug-data-viewer"
  >
  <i nz-icon nzType="bug"></i>
</button>

<ng-template #debugDataTitle>
  <div nz-row>
    <div nz-col nzSpan="4"><a (click)="OpenFirestore()">Firebase Console <i nz-icon nzType="control" nzTheme="outline"></i></a></div>
    <div nz-col nzSpan="4"><a (click)="windowShowDocs()">OpenAPI Docs <i nz-icon nzType="file-text" nzTheme="outline"></i></a></div>
    <div nz-col nzSpan="8" style="text-align: center"><b>{{ this.environment.appName }} / {{ this.environment.firebase.projectId }}</b></div>
    <div nz-col nzSpan="4" style="text-align: right">
      <a (click)="copyAuthTokenToClipboard()">Copy Firebase Auth Token <i nz-icon nzType="idcard" nzTheme="outline"></i></a>
      <span>{{tokenCopied}}</span>
    </div>
  </div>
</ng-template>

<ng-template #debugDataContent>
  <nz-tabset>
    <nz-tab nzTitle="Summary">
      <ng-container *ngTemplateOutlet="summaryTab"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Current User">
      <ng-container *ngTemplateOutlet="currentUserTab"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Current Number">
      <ng-container *ngTemplateOutlet="currentNumberTab"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Logs">
      <ng-container *ngTemplateOutlet="logsTab"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Theme">
      <ng-container *ngTemplateOutlet="themeTab"></ng-container>
    </nz-tab>
    <nz-tab nzTitle="Notifications">
      <ng-container *ngTemplateOutlet="notificationsTab"></ng-container>
    </nz-tab>
  </nz-tabset>
  
</ng-template>


<ng-template #summaryTab>
  <div>
    <span>Credential Source: {{ this.environment.credentialSource }}</span><br/>
    <span>Security Provider: {{ this.authnz.getCurrentSecurityProviderClassName() }}</span><br/>
    <span>UserRole: {{ this.authnz.currentSecurityProvider.getDebugUserRole() }}</span><br/>
    <span>UserID: {{ this.authnz.currentFireauthUser.uid }}</span>&nbsp;<a (click)="OpenFirestore('/users/' + this.authnz.currentFireauthUser.uid)">Open Document in Firestore <i nz-icon nzType="control" nzTheme="outline"></i></a><br/>
    <span>UserEmail: {{ this.authnz.currentFireauthUser.email }}</span><br/>
    <span>ActiveNumberID: {{ this.authnz.activeNumberUID }}</span>&nbsp;<a (click)="OpenFirestore('/users/' + this.authnz.activeNumberUID)">Open Document in Firestore <i nz-icon nzType="control" nzTheme="outline"></i></a><br/>
    <span>ActiveNumber: {{ this.authnz.activeNumberProfile?.phone_number  }}</span><br/>
    <span>Backend URL: {{ this.environment.apiBase  }}</span><br/>
  </div>
</ng-template>

<ng-template #currentUserTab>
  <pre>{{ stringify(this.authnz.currentUserDocument) }}</pre>
</ng-template>

<ng-template #currentNumberTab>
  <pre>{{ stringify(this.authnz.activeNumberProfile) }}</pre>
</ng-template>

<ng-template #logsTab>
  <nz-form nz-form nzLayout="vertical">
    <nz-form-item>
      <nz-form-label>Select a Log Level</nz-form-label>
      <nz-form-control>
        <nz-select 
          (ngModelChange)="setLogLevel($event)"
          [(ngModel)]="logLevel"
        >
        <ng-container *ngFor="let logLevel of logLevels">
          <nz-option [nzValue]="logLevel" [nzLabel] = "logLevel"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
    </nz-form-item>
  </nz-form>
</ng-template>

<ng-template #themeTab>
  <nz-form nz-form nzLayout="vertical">
    <ng-container *ngIf="this.themeService.themeDebugMode">
      <nz-form-item>
        <nz-form-label nzRequired>Branding JSON Object</nz-form-label>
        <span *ngIf="stringifiedBrandingError" style="color:red">{{stringifiedBrandingError}}</span>
        <nz-form-control>
          <textarea
            [disabled]="!this.themeService.themeDebugMode"
            [(ngModel)]="stringifiedBranding"
            (ngModelChange)="updateBranding()"
            rows="15"
            cols="120"
            >
          </textarea>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
    <ng-container *ngIf="!this.themeService.themeDebugMode">
      <button 
        [disabled]="this.themeService.themeDebugMode"
        nz-button 
        [nzSize]="size" 
        nzType="primary" 
        (click)="this.themeService.setThemeDebugMode(true);this.updateBrandingString();"
        >Enable theme debug mode
      </button>
    </ng-container>
  </nz-form>
</ng-template>


<ng-template #notificationsTab>
  <div>
    <nz-table #allTokens [nzData]="this.authnz.currentUserDocument?.deviceTokens" nzTitle="All FCM Tokens">
      <thead>
        <td>Token</td>
        <td>Actions</td>
      </thead>
      <tbody>
        <tr *ngFor="let data of allTokens.data"> 
          <td>
            <span [ngClass]="{'thisDeviceToken': data ==  this.messaging.currentToken}">{{ data }}</span>
          </td>
          <td>
            <button 
              nz-button 
              [nzSize]="size" 
              nzType="primary" 
              (click)="this.messaging.TestPushNotification(data)"
              ><i nz-icon nzType="play-circle"></i> 
            </button>
          </td>
        </tr>
        
      </tbody>
    </nz-table>
    <br/>
  </div>

  <button 
    nz-button 
    [nzSize]="size" 
    nzType="primary" 
    (click)="this.messaging.reRegisterServiceWorker()"
    >Re-Register Service worker
  </button><br/>
  <button 
    nz-button 
    [nzSize]="size" 
    nzType="primary" 
    (click)="this.messaging.TestPushNotification()"
    >Push Test Notification to All Devices
  </button>
</ng-template>
