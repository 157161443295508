<nz-input-group nzCompact>
  <nz-select
    [(ngModel)]="timezone"
    (ngModelChange)="pushValuesToForm()"
    [nzDisabled]="disabled"
    style="width:200px;" 
    nzShowSearch 
    nzPlaceHolder="Select a timezone">
    <nz-option 
      [nzLabel]="zone.alias.replace('_', ' ').replace('/', ' / ')" 
      [nzValue]="zone.name"
      *ngFor="let zone of timezones">
    </nz-option>
  </nz-select>
  <nz-date-picker 
    [nzShowTime]=" { nzUse12Hours: true, nzFormat: 'h:mm a', nzMinuteStep: 15 }"
    nzFormat="yyyy-MM-dd h:mm a"
    [(ngModel)]="date"
    (ngModelChange)="pushValuesToForm()"
    [nzDisabled]="disabled"
    id="date" 
    nzPlaceHolder="Time" 
    >
  </nz-date-picker>
  <!-- This Input is to draw the focus away from time picker-->
  <input style="height: 0px;border-width: 0px;width: 0px;" #focusThis>
</nz-input-group>