import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merge-field-popover',
  templateUrl: './merge-field-popover.component.html',
  styleUrls: ['./merge-field-popover.component.scss']
})
export class MergeFieldPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
