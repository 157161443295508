import { BehaviorSubject, Subject, Subscriber } from "rxjs";
import { ReplaySubject } from "rxjs";
import { Observable } from "rxjs";
import { getLogger } from "../logging";

export type Statuses = "Closed" | "Preparing" | "Ready" | "Error"
export const log = getLogger("CoordinatedMessageChannel");

export type CoordinatedControlMessage = {
  type: "CoordinatedControlMessage"
  command: "ping" | "ack" | "messageChannelInit" | "messageChannelInitRequest"
  //data?: any
  ports?: ReadonlyArray<MessagePort>;
  source?: any // Client | ServiceWorker | MessagePort | null;
}
export type DraftCoordinatedControlMessage = Omit<CoordinatedControlMessage,"type">
export const IsCoordinatedControlMessage = (m: any) : m is CoordinatedControlMessage => m.type == "CoordinatedControlMessage"

export type CoordinatedDataMessage = {
  type: "CoordinatedDataMessage"
  command: string
  data?: any
}
export type DraftCoordinatedDataMessage = Omit<CoordinatedDataMessage,"type">
export const IsCoordinatedDataMessage = (m: any) : m is CoordinatedDataMessage => m.type == "CoordinatedDataMessage"



export abstract class BaseCoordinatedMessageChannel {

  public dataMessages: Subject<CoordinatedDataMessage>;
  protected controlMessages: Subject<CoordinatedControlMessage>;
  public status: BehaviorSubject<Statuses>

  constructor() {
    this.status = new BehaviorSubject<Statuses>("Closed")
    this.status.subscribe((s)=>{
      log.debug("Status changed to: " + s);
    });
    this.dataMessages = new Subject<CoordinatedDataMessage>();
    this.controlMessages = new Subject<CoordinatedControlMessage>();
  }

  protected handlePortMessage (ev: MessageEvent<any>) {
    const eventData = ev.data;
    if (IsCoordinatedDataMessage(eventData)) {
      eventData.data = eventData.data;
      log.debug("Received CoordinatedDataMessage via MessagePort: ", ev)
      this.dataMessages.next(eventData.data);
    }
    else {
      log.debug("Received Unknown Message type via MessagePort: ", ev)
    }
  }
  
  /**
   * 
   * 
   * @param event  Either ExtendableMessageEvent or MessageEvent but we need any because TypeScript reference libs are different
   */
  protected handleContextMessage(event: any) {
    const eventData = event.data;
    if (IsCoordinatedControlMessage(eventData)) {
      log.debug("Received control message from context", event)
      this.controlMessages.next({
        ...eventData,
        ports: event.ports,
        source: event.source
      });
    }
    else {
      log.debug("Received unknown message from context: ", event);
    }
  }




  public abstract sendDataMessage(message: DraftCoordinatedDataMessage): void;
  protected abstract sendControlMessage(message: DraftCoordinatedControlMessage, transfer?: Transferable[]): void;

}