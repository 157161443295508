<ng-template let-msg="data" #tplNewMessage>
  <h3>{{ msg.title }}</h3>
  <small>{{ msg.subTitle }}</small>
  <p>{{ msg.body }}</p>
  <button
    nz-button
    nzType="small"
    (click)="goToMessage(msg)"
    *ngIf = "msg.contactId"
  >
    Go to Conversation
  </button>
</ng-template>

<ng-template let-dat="data" #tplRequestPermission>
  <h3>Enable notifications</h3>
  <small>Click below to enable notifications for this site</small>
  <button
    nz-button
    nzType="small"
    (click)="doRequestPermission()"
    [nzLoading]="isRequestingPermission"
  >
    Enable Notifications
  </button>
</ng-template>