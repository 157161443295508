import { AngularFirestore } from "@angular/fire/compat/firestore";
import { PhoneNumber } from "@shared/PhoneNumber";
import { Mutex } from "async-mutex";
import { Logger } from "loglevel";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, shareReplay, tap } from "rxjs/operators";
import { ScavengableCache } from "src/classes/ScavengableCache";
import { getLogger } from "src/shared/logging";
import { TextableContact } from "../backported_types/contact";
import { AuthNZService } from "../core/authnz.service";
import { BackendService } from "./backend.service";
import { ContactDTO } from "../../classes/contactDTO";
import { ContactCreateRequest, ContactSubscription } from "./contacts.service";
import { BackgroundOperationStatusService } from "../background-operation-status.service";
import { AFSBatcher } from "../firestoreBatcher";
import { GetOptionalEnvironmentProperty, sleep } from "../functions";
import { NumberBlock, TextableUser } from 'src/app/backported_types/users';
import { afterAllSubscribersNotified } from "src/classes/afterAllSubscribersNotified";

export type FronendNumberBlock = NumberBlock & {phoneNumber: string};
export type ContactBlockOptions = {
  sendStop?: boolean
  reportSpam?: boolean
  delete?: boolean
}

/**
 * Limits the loading of initial contacts to this size
 */
const DEFAULT_CONTACT_SNAPSHOT_SIZE = 2500;
/**
 * Limits how often this service will emit updates of the contact list 
 * via `UserContactWatcher.contacts$`.  
 * 
 * Debounce is done internally to this class so that UI updates
 * for blank or error updates are passed through without delay,
 * and only actual contact list updates are debounced.
 * 
 */
const CONTACT_OBSERVABLE_UPDATE_DEBOUNCE_MS = 500;

/**
 * This class watches the contacts firebase collection and maintains a client-side cache
 *
 * Simple Angular Fire snapshots were too slow for users with large contact lists,
 * so this class uses the raw Firebase get / onSnapshot methods to more intelligently
 * cache ane emit observables for things we care about.
 * 
 * TODO: Is this worth using localstorage isntead of objects in memory?
 */
export class UserContactWatcher {

  private mergeMutex = new Mutex();
  // #region Properties (11)

  private activeConversationsUnsubscribe: () => void;
  /**
   *  This function is defined when the `onSnapshot` listener begins for the contacts collection,
   *  and when called will end the firebase listener
   *
   *  This function is called when the application is about to be logged out, since the listener
   *  must end before logout occurs if we want to prevent console error
   */
  private allContactsUnsubscribe: () => void;

  /**
   * Local memory cache of all contacts for the active number
   * key is the firebase document id of the contact
   */
  private contactCache: ScavengableCache<ContactDTO>;
  private isLoading: boolean;
  private log: Logger

  /**
   * Observable of contacts for the active number
   *
   * Fires when any contact for the user associated  with this instance of UserContactWatcher changes
   *
   * DO NOT debounce this observable; debounce is handled inside of ContactsService
   */
  public contacts$ = new BehaviorSubject<ContactSubscription>({contacts: [], isLoading: true});
  /**
   * Observable of active conversations for the active number
   *
   * Fires when any active conversations for the active number changes
   * 
   * DO NOT debounce this observable; debounce is handled inside of ContactsService
   */
  public conversations$ = new BehaviorSubject<ContactSubscription>({contacts: [], isLoading: true});
  /**
   * The size of the snapshot to load when the user first logs in
   */
  private ContactSnapshotSize: number;
  /**
   * When LoadContacts is called initiall, we limit the size to INITIAL_CONTACT_SNAPSHOT_SIZE
   * However, there may be cases where a PF user wants to load all contacts
   * so we need to know if there are more
   *
   * We can naievely assume that users having _exactly_ INITIAL_CONTACT_SNAPSHOT_SIZE contacts cached
   * locally probably have more on the server, so we can say that these users have more.
   *
   * TODO: Include this in the contacts$ observable output.
   * 
   * @deprecated
   */
  public hasMoreContacts = false;
  private emitter = new Subject<void>()

    /**
   * Observable of numbers defined as "blokced" for the current user
   * TODO: move this to UserContactWatcher
   */
    public BlockedSenders: Observable<FronendNumberBlock[]>;

    private BlockedNumbers: Observable<string[]>;

  // #endregion Properties (11)

  // #region Constructors (1)

  constructor(
    private backendService: BackendService,
    private authnz: AuthNZService,
    private afs: AngularFirestore,
    private backgroundOperationStatus: BackgroundOperationStatusService,
    /**
     * The firebase document ID of the user for whom this watcher should be started
     */
    private userId: string
  ) {
    this.log = getLogger("UserContactWatcher-"+userId);

    const contactSnapshotSize = GetOptionalEnvironmentProperty("contactSnapshotSize");
    if (typeof contactSnapshotSize == "number") {
      this.ContactSnapshotSize = contactSnapshotSize;
    }
    else {
      this.ContactSnapshotSize = DEFAULT_CONTACT_SNAPSHOT_SIZE;
    }
    this.log.debug(`Starting UserContactWatcher with snapshot size ${this.ContactSnapshotSize}`);
    this.contactCache = new ScavengableCache<ContactDTO>(this.userId);
    this.emitter.pipe(debounceTime(CONTACT_OBSERVABLE_UPDATE_DEBOUNCE_MS)).subscribe(()=>{
      this.emit()
    });
    
    this.BlockedSenders = this.afs.doc(`users/${userId}`).snapshotChanges().pipe(
      map(c=>(c.payload.data() as TextableUser).blockedSenders || {} as Record<string, NumberBlock>),
      distinctUntilChanged(),
      map((p):FronendNumberBlock[] => {
        return Object.entries(p)
          .map(([phoneNumber, numberBlock])=>({
            phoneNumber,
            ...numberBlock
          }))
      }),
      tap((bn)=>{
        this.log.debug("Blocked senders", bn);
      }),
      shareReplay(1)
    );

    this.BlockedNumbers = this.BlockedSenders.pipe(
      map(b=>b.map(b=>b.phoneNumber)),
      afterAllSubscribersNotified(()=>{
        this.emit();
      }),
      shareReplay(1),
    )
  }

  // #endregion Constructors (1)

  // #region Public Methods (6)

  /**
   * Convert the array of existing contacts into a hashmap.
   * TODO: Can this be improved or done elsewhere more efficiently.
   */
  public GetContactMapKeyedByPhoneNumber(): Record<string, TextableContact> {
    const existingContactsHashMap: Record<string, TextableContact> = this.contactCache.values().reduce((pv, cv) => {
      if (cv.contact.phone_number) {
        return {
          ...pv,
          [cv.contact.phone_number]: cv.contact
        }
      } else {
        return pv;
      }
    }, {});
    return existingContactsHashMap;
  }

  /**
   * Gets the cached array of contacts.
   *
   * PREFER a subscription, but sometimes we just want the latest value.
   */
  public GetContacts() {
    return this.contactCache.values()
  }

  /**
   * Starts the listener for All contacts.  The initial download can take a while
   * for large lists.
   * 
   * TODO: Make this intelligently cache results of "all".  
   * As written, it'll re-fetch all contacts every time it's asked; even if it already
   * has all contacts
   *
   * @returns
   */
  public async LoadContacts(
    mode: "initial" | "all" = "initial"
  ): Promise<void> {
    this.log.debug("loading contacts; mode: " + mode);
    let resolve: (value: void | PromiseLike<void>) => void;
    let reject: (value: void | PromiseLike<void>) => void;
    const finishedLoadingPromise = new Promise<void>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    if (this.allContactsUnsubscribe) {
      this.allContactsUnsubscribe();
    }

    let q = this.afs.firestore
      .collection("contacts")
      .where("uid", "==", this.userId);

    if (mode == "initial") {
      q = q.limit(this.ContactSnapshotSize);
    }

    this.allContactsUnsubscribe = q.onSnapshot(async (snapshot) => {
      await this.mergeMutex.runExclusive(async ()=>{
        this.contactCache.incrementSequence("all");
        await this.mergeContactsSnapshot(snapshot, "all");
        this.hasMoreContacts = mode=="initial" && snapshot.size == this.ContactSnapshotSize;
        this.emitter.next();
        resolve();
      });
    });
    return finishedLoadingPromise;
  }

  /**
   *
   * Sets up the Subscription Manager observation for both conversations and for
   * contacts.
   *
   * We first make a direct query to Firebase for the active conversations,
   * since 90% of users will land on this page directly and we want it to load
   * quicky.
   *
   * the results of this load are stored in the `contacts` local object
   * where the object keys are the document ID
   *
   * Once this load completes, we set up an `onSnapshot` subscription to the
   * contacts collection for the actve number to get all contacts.  This can
   * take a while which is why we wait until active conversations are loaded,
   * so there is not contention in the requests.
   *
   * The first time this "all contacts" snapshot fires will be _all_ contacts
   * for the current user; however, subsequent fires will be only changed documents.
   *
   * We use this change notification to update our local cache of `conversations`
   * and of `contacts` using the document Id as the cache identifier
   *
   *
   */
  public async Watch() {
    this.isLoading = true;
    this.log.debug("loading contacts for " + this.userId);
    this.log.debug("resetting contacts cache");
    this.callUnsub();
    this.contactCache.clearCache();
    // Emit empty objects to our listeners; so the screen clears between swtiches of active numbers.
    this.emitter.next()
    await this.LoadActiveConversations()
    this.emitter.next()
    this.log.debug("Caching remaining contacts and watching for changes");
    await this.LoadContacts();
  }

  /**
   * Calls the unsubscribe function (if it exists) which was returned
   * by the Firebase `onSnapshot` listener for "All contacts"
   */
  public callUnsub() {
    if (this.allContactsUnsubscribe) {
      this.allContactsUnsubscribe();
    }
    if (this.activeConversationsUnsubscribe) {
      this.activeConversationsUnsubscribe();
    }
    for (let k of this.contactCache.values()) {
      k.Unsubscribe();
    }
  }

  /**
   * Queries the database for a single phone number.
   * 
   * Any results are merged into the client side contact cache.
   * 
   * If there is more than one result, then the first item is returned (in no particular sort order)
   * 
   * @param phone_number 
   * @returns 
   */
  public async QueryDatabaseForContact(query: {phone_number: PhoneNumber} | {firebase_document_id: string} ): Promise<ContactDTO | null> {

    if ("firebase_document_id" in query) {
      if (!this.contactCache.get(query.firebase_document_id)?.value) {
        const cD = await this.afs.firestore.collection("contacts")
          .where('__name__', '==', query.firebase_document_id)
          .where("uid", "==", this.userId)
          .get();
        if (cD.empty) {
          this.log.warn(`Requested contact ${query.firebase_document_id} does not exist for this user`)
          return;
        }
        await this.mergeContactsSnapshot({ docs: [cD.docs[0]] }, "single-query");
        this.emitter.next()
      }
      return this.contactCache.get(query.firebase_document_id)?.value
    }
    else if ("phone_number" in query)  {
      const cQ = await this.afs.firestore
      .collection("contacts")
      .where("uid", "==", this.userId)
      .where("phone_number", "==", query.phone_number.ToE164())
      .get()
      if (cQ.docs.length == 0) {
        this.log.debug(`No contacts found for query '${query.phone_number.ToE164()}'`)
        return null
      }
      else if (cQ.docs.length == 1) {
        this.log.debug(`Found ${cQ.docs.length} contacts for query '${query.phone_number.ToE164()}'`)
      }
      else if (cQ.docs.length > 1) {
        this.log.warn(`Found ${cQ.docs.length} contacts for query '${query.phone_number.ToE164()}'`)
      }
      await this.mergeContactsSnapshot(cQ,"single-query");
      this.emitter.next()
      return this.contactCache.get(cQ.docs[0].id).value
    }
  }

  public async createContact(contactDetails: ContactCreateRequest): Promise<ContactDTO> {

    const contact: TextableContact = {
      email: "",
      ...contactDetails,
      uid: this.userId
    }

    const newContactDoc = await this.afs.firestore
      .collection("contacts")
      .add(contact)

    await this.mergeContactsSnapshot({docs:[
      {
        data: ()=>contact
      }
    ]},"single-query");

    return this.contactCache.get(newContactDoc.id).value
  }

  public async DeleteAllContacts(){
    this.backgroundOperationStatus.updateStatus({
      operation: `Delete All Contacts`,
      statusText: "Getting ready",
      icon: "delete"
    });
    await this.LoadContacts("all");
    const allContacts = this.contactCache.values().map(c=>c.contact)

    this.callUnsub();
    this.log.debug(`Starting to delete ${allContacts.length} contacts`);
    const batchDelete = AFSBatcher<TextableContact>(
      this.afs,
      allContacts, 
      (batch,item)=>batch.delete(this.afs.collection("contacts").doc(item.id).ref)
    )
    batchDelete.subscribe(progress=> {
      this.backgroundOperationStatus.updateStatus({
        operation: "Delete All Contacts",
        statusText: "In Progress " + progress.doneItems + " / " + progress.totalItems,
        percentComplete: ((progress.doneItems)/progress.totalItems) * 100,
      });
    });
    await batchDelete.toPromise();
   this.log.debug(`Finished deleteing ${allContacts.length} contacts`);

    this.backgroundOperationStatus.updateStatus({
      operation: "Delete All Contacts",
      statusText: "Finishing up",
      percentComplete: 100,
      finished: false,
    });
    
    await Promise.all([this.afs.firestore.waitForPendingWrites(), sleep(2000)]);
    this.backgroundOperationStatus.updateStatus({
      operation: "Delete All Contacts",
      statusText: "Complete",
      percentComplete: 100,
      finished: true,
    });
    this.Watch();
  }
  /**
   * Update the local memory cache of contacts to reflect the new block status
   * 
   * This could lead to an inconsistent state if the backend request fails, but
   * should generally result in a faster UI response.
   * 
   * @param phone_number 
   * @param isBlocked 
   */
  private updateCacheBlock(phone_number: string, isBlocked: boolean) {
    const c = this.contactCache.values().find(c=>c.contact.phone_number == phone_number || c.contact.firebase_document_id == phone_number);
    if (!c) {
      return;
    }
    c.isBlocked = isBlocked;
    this.emit();
  }

  public async blockSender(number: string | string[], options: ContactBlockOptions) {
    const requests = (Array.isArray(number) ? number : [number])
      .map(n=>{
        this.updateCacheBlock(n,true);
        return {
          action: "block",
          sender: n,
          user: this.userId,
          options: options || {}
        }
      });
    try {
      
      this.backendService.backendPost("api/beta/block", null, requests)
    }
    catch (err) {
      this.log.error("Error blocking sender", err)
    }
  }

  public async unblockSender(number: string | string[]) {
    const requests = (Array.isArray(number) ? number : [number])
      .map(n=>{
        this.updateCacheBlock(n,false);
        return {
          action: "unblock",
          sender: n,
          user: this.userId
        }
      });
    try {
      this.backendService.backendPost("api/beta/block", null, requests)
    }
    catch (err) {
      this.log.error("Error unblocking sender", err)
    }
  }

  // #endregion Public Methods (6)

  // #region Private Methods (2)

  /**
   * Loads the active conversations, the promise resolves when
   * all active conversations have been loaded and emitted to the
   * nternalReplaySubject subscription.
   *
   * @returns
   */
  private async LoadActiveConversations(): Promise<void> {
    if (typeof this.activeConversationsUnsubscribe == "function") {
      this.log.warn("Already ran LoadActiveConversations; not re-running")
      return;
    }
    return new Promise((resolve, reject) => {
      this.activeConversationsUnsubscribe = this.afs.firestore
        .collection("contacts")
        .where("uid", "==", this.userId)
        .where("isArchived", "==", false)
        .onSnapshot(async (snapshot) => {
          this.mergeMutex.runExclusive(async ()=>{
            this.contactCache.incrementSequence("active");
            await this.mergeContactsSnapshot(snapshot,"active");
            resolve();
          });
        },
        (err: firebase.default.firestore.FirestoreError)=>{
          this.log.warn("Error watching conversations",err);
          this.activeConversationsUnsubscribe();
        });
    });
  }

  /**
   * Merges contacts from a Firestore snapshot into the ScavengableCache
   * 
   * @param snapshot
   * @param source 
   */
  private async mergeContactsSnapshot(snapshot: {docs: firebase.default.firestore.DocumentData[]}, source: "all"|"active"|"single-query")  {
    this.log.debug("Got snapshot from " + source + "with docs: " + snapshot.docs.length);
    this.isLoading = false;
    for (let doc of snapshot.docs) {
      if (!doc.exists) {
        this.log.warn("Contact document '" + doc.id + "' does not exist")
        //continue;
      }
      const docId = doc.id;

      const newContactObject: TextableContact = {
        ...doc.data(),
        id: docId,
        firebase_document_id: docId
      } as TextableContact;

      const sequenceKey = source == "single-query" ? "all" : source
      if (!this.contactCache.get(doc.id)) {
        const ndto = new ContactDTO(newContactObject, this.afs, this.authnz, this.backendService, this.BlockedNumbers);
        this.contactCache.add(doc.id, ndto, sequenceKey);
      }
      else {
        this.contactCache.update(doc.id, sequenceKey).refreshContactData(newContactObject);
      }
    }
    this.log.debug("Updated caches. Contacts: " + this.contactCache.getLength());
      // TODO log out conversations separately+ " Conversations: " + Object.values(this.conversationCache).length);
  }

  /**
   * Emits the Contacts and Conversations observables from the ScavengableCache
   */
  private emit() {
    this.contactCache.scavenge();
    this.log.debug(
      "Contacts Changed; new count:" + this.contactCache.getLength()
    );

    let contactsEmission: ContactSubscription = {
      isLoading: this.isLoading,
      contacts: this.isLoading ? [] : this.contactCache.values(),
    };
    this.log.debug(
      "Emitting contacts$.  Length: " +
      contactsEmission.contacts.length +
        "; isLoading: " +
        contactsEmission.isLoading
    );
    this.contacts$.next(contactsEmission);

    let conversationEmission: ContactSubscription = {
      isLoading: this.isLoading,
      contacts: this.isLoading ? [] : this.contactCache.values().filter(v=>v.contact.hasOwnProperty("isArchived") && !v.contact.isArchived).sort(
        (a, b) => a.contact.last_message_date <= b.contact.last_message_date ? 1 : -1
        )
    };
    this.log.debug(
      "Emitting conversations$.  Length: " +
      conversationEmission.contacts.length +
        "; isLoading: " +
      conversationEmission.isLoading
    );
    this.conversations$.next(conversationEmission);
  }


  

  // #endregion Private Methods (2)
}
