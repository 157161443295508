import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ExternalScriptLoader } from 'src/classes/ExternalScriptLoader';
import { WaitForURLToLoad } from '../WaitForURLToLoad';

const rewardfulKey = "9bfaf4";

type Rewardful = { 
  referral: string 
  affiliate: {
    first_name: string
    id: string
    last_name: string
    name: string
    token: string
  }
  apiKey: string
  config: {
    id: string
    name: string
  }
  link: {
    _location: Location
  }
  metadata: {
    api_key: string
    location: string
    referrer: string
    title: string
  }
  tracked: boolean
  version: {
    sha: string
    timestamp: number
  }
} 

interface RewardfulWindow extends Window {
  /**
   * Rewardful instance
   * 
   * docs:  https://developers.rewardful.com/javascript-api/overview
   */
  Rewardful: Rewardful | undefined
  /**
   * rewardful function which can be immediately called and passed instructions that Rewardful will execute once loaded. 
   * 
   * The general format of these method calls is rewardful(methodName, arg1, arg2, arg3, ...)
   * 
   * docs:  https://developers.rewardful.com/javascript-api/overview#add-the-javascript-tag-+-snippet
   */
  rewardful: (methodName: string, ...args: any)=>void
}

@Injectable({
  providedIn: 'root'
})
export class RewardfulService extends ExternalScriptLoader {
  protected timeoutMs: number;

  private window: RewardfulWindow
  private via: string;
  private rewardful: Rewardful;

  constructor(
    document: Document,
    router: Router
  ) { 
    super("Rewardful", document);
    this.window = <any>this.document.defaultView;
    this.initialize().catch(err=>{
      this.log.warn("Failed loading rewardful", err)
    });
    router.events.subscribe(event => {
      if (! (event instanceof RoutesRecognized)) {
        return
      }
      if (event.state.root.queryParams.hasOwnProperty("via")) {
        this.via = event.state.root.queryParams.via
      }
      else if (event.state.url.startsWith("/login;redirectUrl=")){
        this.via = new URLSearchParams(event.state.root.children[0]?.params.redirectUrl.split("?")[1]).get("via");
      }
    })
  }

  private rewardfulFunctionExists() {
    return typeof(this.window.rewardful) === "function"
  }

  private waitForReady(): Promise<void> {
    return new Promise<void>((resolve,reject)=>{
      this.window.rewardful("ready",()=>{
        this.rewardful = this.window.Rewardful;
        this.confirmAffiliate();
        return resolve();
      })
    })
  }

  private async confirmAffiliate() {
    if (!this.rewardful.affiliate && this.via) {
      this.log.debug(`Detected un-linked via in URL: '${this.via}'; linking now`)
      const requestMade = WaitForURLToLoad("https://api.getrewardful.com/referrals/track")
      // Tell Rewardful to use the "via" value we discovered
      this.window.rewardful("source", this.via);
      const result = await requestMade;
      if (result.status !== 200) {
        this.log.warn(`Failed setting affiliate token ''${this.via}': ${result.response.error}`);
      }
    }
    
    if (this.rewardful.affiliate) {
      this.log.debug(`Rewardful ready. Affiliate: '${this.rewardful.affiliate.name}'/'${this.rewardful.affiliate.token}'. Title: '${this.rewardful.metadata.title}' Location: '${this.rewardful.metadata.location}'`) 
    }
    else {
      this.log.debug(`Rewardful ready. No Affiliate`);
    }

    
  }


  /**
   * Installs Rewardful into the DOM
   */
  protected async loadExternal() {
    if (!this.rewardfulFunctionExists()) {
      await this.loadScriptFromSource(`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`);
      if (!this.rewardfulFunctionExists()) {
        throw new Error("Rewardful function not loaded")
      }
    }
    if (typeof(this.window.Rewardful) !== "function"){
      await Promise.all([
        await this.loadScriptFromURL("https://r.wdfl.co/rw.js",{"data-rewardful": rewardfulKey || ''}),
        await this.waitForReady()
      ]); 
    }
  }

  public getRewardfulKey(): string {
    try {
      let r = this.rewardful
      if (typeof r.referral == "string") {
        return r.referral;
      }
      else {
        this.log.warn("Rewardful has been loaded; but the referral format is invalid");
      }
    } 
    catch (err) {
      this.log.warn("Rewardful has not been loaded; rewardful tracking will be inactive");
    }
   
  }
}
