<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <nz-table #userTable [nzData]="serviceAccounts" [nzLoading]="serviceAccounts == null || serviceAccounts == undefined" class="txb-list">
    <thead>
      <tr>
        <th>
          Name
        </th>
        <th>
          Revoked
        </th>
        <th>
          Issued
        </th>
        <th>
          Expires
        </th>
        <th>
          Scopes
        </th>
        <th>
          Action
        </th>
      </tr>
    </thead>

      <tbody>
        <tr *ngFor="let data of userTable.data">
          <td>{{ data.name }}</td>
          <td>{{ data.revoked }}</td>
          <td>{{ formatDate(data.issueDate) }}</td>
          <td>{{ formatDate(data.expirationDate) }}</td>
          <td>{{ data.scopes }}</td>
          <td>
            <a *ngIf="!data.revoked" nz-popconfirm nzPopconfirmTitle="Are you sure you want to revoke this token?  This operation cannot be undone!" (nzOnConfirm)="revokeTokenByName(data.name)" >Revoke</a>

          </td>

        </tr>

      </tbody>


    

  </nz-table>
</nz-card>

<nz-modal 
  [nzVisible]="showNewAccountCreationModal" 
  nzTitle="Add New Service Account"
  (nzOnCancel)="cancelNewAccountCreationModal()"
  [nzFooter]="null"
  >
  <ng-container *nzModalContent>
    <ng-container *ngIf="AccountCreationStatus=='request'; then ServiceAccountCreationRequestTemplate else ServiceAccountCreationResponseTemplate"></ng-container>
  </ng-container>
</nz-modal>



<ng-template #ServiceAccountCreationRequestTemplate>
  <form nz-form nzLayout="vertical" [formGroup]="frmAddServiceAccount" (ngSubmit)="SubmitAddServiceAccountRequest()">
    <nz-form-item>
      <nz-form-label nzRequired>Name</nz-form-label>
      <nz-form-control nzHasFeedback>
        <input nz-input formControlName="name" type="text" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>Scopes</nz-form-label>
      <div  *ngFor="let scope of this.scopes">
        <label>{{scope}} </label>
        <input nz-input type="checkbox" [value]="scope" [checked]="frmAddServiceAccount.get('scopes').value.includes(scope)" (change)="onCheckboxChange($event)" />     
      </div>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>Expiration Date</nz-form-label>
      <nz-date-picker 
        [nzFormat]="dateFormat" 
        formControlName="expirationDate" 
        nzPlaceHolder="Date" 
        [nzShowTime]="true"
        [nzStyle]="{'width': '100%'}"
        ></nz-date-picker>
    </nz-form-item>
    <nz-alert *ngIf="ServiceAccountCreationError" nzType="error" nzMessage="{{ServiceAccountCreationError}}"></nz-alert>
    <p>The scopes and expiration date of this token cannot be changed. If any modifications are necessary, a new token must be generated</p> 
    <button nz-button nzType="primary" nzBlock [nzLoading]="isServiceAccountSaving" [disabled]="!frmAddServiceAccount.valid">
      Add Service Account
    </button>
  </form>
</ng-template>

<ng-template #ServiceAccountCreationResponseTemplate>
Token created.  <a (click)="CopyTokenToClipboard()">Copy to Clipboard</a> <span id="copiedToClipboard">{{copiedToClipboard}}</span>
<pre>{{ ServiceAccountCreationResponse.token }}</pre>
<p>The scopes and expiration date of this token cannot be changed. If any modifications are necessary, a new token must be generated</p>
<nz-alert nzType="warning" nzMessage="Store this token securely!  This token cannot be recovered!"></nz-alert>
</ng-template>

<ng-template #extraTemplate>
  <button nz-button nzType="primary" (click)="btnAddServiceAccount()"><i class="far fa-user-plus"></i> Add Service Account</button>
</ng-template>

<ng-template #pageTitleTemplate>
  Manage Service Account Tokens <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="guideVisibleBool = false" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Service Accounts'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>