import { isDocument, TextableBaseFirestoreDocument } from "./base"

export interface TextableContact extends TextableBaseFirestoreDocument {
  /**
   * @deprecated
   */
  cell?: string,
  email: string,
  full_name: string,
  last_message?: string,
  last_message_date?: Date,
  lists?: any[],
  optedOut?: boolean,
  optedOutAt?: Date,
  phone_number: string,
  read_status?: boolean,  
  tags?: any[]
  uid: string,
  contactConsentMessageId?: string,
  createdBy?: string,
  /**
   *  TODO: This is Date on PB, but number on PF because we aren't using withConverter on PF yet
   */
  createdAt?: number,
  isArchived?: boolean,
  last_trigger_message_body?: string,
  last_trigger_message_id?: string,
  companyName?: string,
  title?: string,
  notes?: string,
}

export interface TextableContactList extends TextableBaseFirestoreDocument {
  listName: string
  uid: string
  isDeleted: boolean
  autoSubscribeKeywords?: string[]
  count: number
}

export const  isTextableContact = (data: any): data is TextableContact=>{
  return isDocument<TextableContact>(data, [
    "full_name",
    "phone_number",
    "firebase_document_id"
  ])
}