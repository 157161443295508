<div nz-row nzType="flex" nzJustify="center" nzAlign="middle" style="height:100vh;">
  <div nz-col nzXs="22" nzSm="22" nzMd="8" nzLg="8" nzXl="8">
      <img src="{{logoPath}}" style="width:100%;max-width:200px;display:block;margin:0px auto 20px auto;">
    <nz-card nzTitle="Confirm Your {{appName}} Account">
      <div class="pad-25">
        <ng-template #activationStatusIndicator >
          <ng-container *ngIf="!confirmed" >
            <nz-alert
              nzType="error"
              nzMessage="Invalid / Expired Link"
              nzDescription="There was an error retrieving your account. Please try again. If this problem persists, please contact {{supportEmail}} for further assistance."
            >
            </nz-alert>
          </ng-container>
          <ng-container *ngIf="confirmed">
            <nz-alert
              nzType="success"
              nzMessage="Account Confirmed"
              nzDescription="Your account has been confirmed. You can now login."
            >
            </nz-alert>
            <ng-container *ngIf="(this.afa.user | async) == null">
              <button nzType="success" nz-button nzBlock [routerLink]="['/login']" routerLinkActive="router-link-active" >Go to Login</button>
            </ng-container>
            <ng-container *ngIf="this.afa.user | async">
              <button nzType="success" nz-button nzBlock (click)="goToApp()" routerLinkActive="router-link-active"> Get Started ({{this.countdown}})</button>
            </ng-container>
          </ng-container>
        </ng-template>
        <form nz-form nzLayout="vertical" [formGroup]="frmConfirmAccount" *ngIf="!errorModal && !confirmed; else activationStatusIndicator" (ngSubmit)="confirmAccount()">
          <ng-container *ngIf="confirmationMode === ResetHashConfirmationMode.None">
            Verifying Details
          </ng-container>
          <ng-container *ngIf="confirmationMode === ResetHashConfirmationMode.EmailVerify">
            <p>Please click below to confirm your email address</p>
            <button nz-button nzType="success" nzBlock [nzLoading]="isConfirmingAccount">Confirm</button>
            
          </ng-container>
          <ng-container *ngIf="confirmationMode === ResetHashConfirmationMode.PasswordReset">
            <p>Please enter and confirm a password to activate your account and start sending and receiving messages with your {{appName}} account today.</p>
          <nz-form-item nzRequired>
              <nz-form-label nzRequired>Enter New Password</nz-form-label>
              <nz-form-control 
                nzHasFeedback
                nzErrorTip="Please enter a password containing at least 8 characters, 1 lowercase and uppercase letter, and 1 number."
                >
                <input nz-input formControlName="password" placeholder="**********" type="password" autocomplete="abc123"/>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item nzRequired>
              <nz-form-label nzRequired>Confirm New Password</nz-form-label>
              <nz-form-control 
                nzHasFeedback
                nzErrorTip="Passwords must match.">
                <input nz-input formControlName="cPassword" placeholder="**********" type="password" autocomplete="abc123"/>
              </nz-form-control>
            </nz-form-item>
            <button nz-button nzType="success" nzBlock [disabled]="!frmConfirmAccount.valid" [nzLoading]="isConfirmingAccount">Set Password & Confirm Account</button>
          </ng-container>
        </form>
      </div>
    </nz-card>
  </div>
</div>