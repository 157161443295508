import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-integration-message-banner',
  templateUrl: './integration-message-banner.component.html',
  styleUrls: ['./integration-message-banner.component.scss']
})
export class IntegrationMessageBannerComponent implements OnInit {

  @Input('contact') contact:any = null;
  @Input('page') page:any = null;

  public isContactConnectedToIntegration: Boolean = false;


  constructor(  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.isContactConnectedToIntegration = false;
    if (this.contact && this.contact.hasOwnProperty("connections") && Object.keys(this.contact.connections).length  > 0 )  {
      this.isContactConnectedToIntegration = true;
    }
  }
}
