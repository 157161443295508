import { enableLogs, updateAllLoggers, levels } from './shared/logging';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ApplicationContextService } from './app/services/application-context.service';

if (environment.production) {
  enableProdMode();
  updateAllLoggers(levels.SILENT);
}
else {
  updateAllLoggers(levels.TRACE);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    (window as any).enableDebugMode = ()=> {
      enableLogs();
      ref.injector.get(ApplicationContextService).debugMode = true
    }

    (window as any).showDocs = async ()=> {
      window.open(environment.apiBase+"docs/html", "_blank");
    }

  })
  .catch(err => console.error(err));
