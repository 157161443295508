import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { getLogger } from '@shared/logging';
import { BehaviorSubject, Subscription, combineLatest} from 'rxjs';
import { map, distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { mapToTextableBaseFirestoreDocument } from '../backported_types/base';
import { TextableUser } from '../backported_types/users';
import { ApplicationStates } from '../core/auth-nz/interfaces';
import { AuthNZService } from '../core/authnz.service';
import { UserPreferencesService } from './user-preferences.service';

const log = getLogger("NumberSharingService")

@Injectable({
  providedIn: 'root'
})
export class NumberSharingService {

  public numbersSharedWithLoggedInUser$ = new BehaviorSubject<TextableUser[]>([]);
  private numberSharingSubscription: Subscription;
  private shouldChangeActiveNumber: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private authnz: AuthNZService,
    public router: Router,
    private userPreferences: UserPreferencesService
  ) { 
    authnz.authChanged.subscribe((state) => {
      if (state == ApplicationStates.LoggedIn) {
        if (!this.numberSharingSubscription || this.numberSharingSubscription.closed) {
          log.debug("Loading sharedWith numbers");
          this.watchUserSharedWithNumbers();
          this.shouldChangeActiveNumber = true;
          
        }
      }
    });
  }

  private watchUserSharedWithNumbers() { 
    /**
     * Observable of distinct updates to the current user's phone number
     */
    const activeUserProfile = this.authnz.contextChanged.pipe(map(c=> c.activeUserProfile), distinctUntilKeyChanged("phone_number"))

    this.numberSharingSubscription = combineLatest([
      (this.afs.collection<TextableUser>('users',ref => ref.where('sharedWith', 'array-contains', this.authnz.currentFireauthUser.uid))
      .snapshotChanges()
      .pipe(
        map((otherAccountDocument) => otherAccountDocument.map(n=>mapToTextableBaseFirestoreDocument(n.payload.doc) as TextableUser))
      )),
      activeUserProfile
      ]).pipe(
        map(([otherAccounts, loggedInAccount])=>[...otherAccounts, loggedInAccount].filter(account=> "phone_number" in account && account.phone_number !=="" ))
      ).subscribe(ns=> {
        log.debug("Update to numbersSharedWithLoggedInUser", ns);
        this.evaluatePreferredNumber(ns);
        this.numbersSharedWithLoggedInUser$.next(ns);
      })
  }

  private evaluatePreferredNumber(ns: TextableUser[]) {
    
    if (!this.authnz.currentUserDocument.phone_number && this.authnz.currentFireauthUser.uid == this.authnz.activeNumberUID) { 
      log.debug("Logged in user is active and has no phone_number")
      this.shouldChangeActiveNumber = true;
    }
    else if (!ns.find(n=>n.firebase_document_id == this.authnz.activeNumberUID)) {
      log.debug("Number " + this.authnz.activeNumberUID + " is no longer available to the current user")
      this.shouldChangeActiveNumber = true
    }   

    // TODO: Document: if a numberless user is logged in; has not selected a number; has more than one number, and an admin removes all but one shared number
    // we'll automagically switch the user into the single shared number context.,

    if (! this.shouldChangeActiveNumber) {
      return;
    }

    this.shouldChangeActiveNumber = false;
    log.debug("Considering changing to a new active number");
    let newActiveNumber: TextableUser;
    let preferredActiveNumber = this.userPreferences.getLocalPreference("preferredActiveNumber")
    if(preferredActiveNumber) {
      const preferredNumber = ns.find(n=>n.phone_number == preferredActiveNumber)
      if (preferredNumber) { 
        newActiveNumber = preferredNumber
      }
      else {
        log.warn("Preferred number '" + preferredActiveNumber + "' is not available to the current user")
      }
    }

    if (!newActiveNumber && ns.length == 1) {
      log.debug("The active user has no phone number, and one number shared with them")
      newActiveNumber = ns[0]
    }

    if (newActiveNumber) {
      log.debug("changing active number to " + newActiveNumber.phone_number  +" - " + newActiveNumber.full_name);
      this.authnz.setActiveNumber(newActiveNumber.firebase_document_id)
    }
    else {
      log.debug("No phone numbers available for the logged in user");
      // go to the profile page, since there will be no conversations.
      this.authnz.setActiveNumber(this.authnz.currentFireauthUser.uid)
    }
    
  }
}
