import { Injectable, ViewContainerRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthNZService } from '../core/authnz.service';
import { ObservableContexUpdateSignal, SubscriptionManagerService } from '../core/subscription-manager.service';
import { UserNotificationComponent } from '../components/user-notification/user-notification.component';
import { TitleBarService } from './title-bar.service';
import { getLogger } from 'src/shared/logging';
const log = getLogger("UserNotificationService")

export type FrontendUserNotification =  {
  title: string
  from: string
  to: string
  body: string
  /**
   * The UID of the user to whom the message was sent (not necessarily the active user or logged in user)
   */
  uid: string,
  /**
   * The ID of the contact from whom the message was sent.
   */
  contactId: string
  subTitle: string
}

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  /**
   * The sound to play when a new browser notification arrives
   */
  private notifySound: HTMLAudioElement;
  /**
   * The container into which we should push notification
   */
  private container: ViewContainerRef

  private resolveContainer: ()=>void;
  private hasContainer: Promise<void>;


  /**
   * Local variable to store the DI-instantiated reference to the notification component.
   * 
   * This is really only necessary since we can't create a template within an Angular Service
   * and the NzNotificationModule needs a TemplateRef, so we use the component here as a proxy
   * to create the template ref from this service.
   * 
   * Watch this issue for possible improvements on this approach: 
   * https://github.com/NG-ZORRO/ng-zorro-antd/issues/6119
   */
  private notificationComponentInstance: UserNotificationComponent;


  constructor(
    private submanager: SubscriptionManagerService,
    private authnz: AuthNZService,
    private db: AngularFirestore,
    private titleService: TitleBarService

  ) {
    this.hasContainer = new Promise((resolve,reject)=>{
      this.resolveContainer = resolve;
    })
    this.notifySound = new Audio('assets/sound/new-message.mp3');
  }

  public setContainer(container: ViewContainerRef) {
    this.container = container;
    log.debug("Set container to ", this.container)
    const c = this.container.createComponent(UserNotificationComponent)
    this.notificationComponentInstance = c.instance;
    this.resolveContainer();
  }

  public async showNotification(n: FrontendUserNotification) {
    await this.hasContainer;
    this.notificationComponentInstance.showMessage(n)
    this.notifySound.play();
  }

  public async requestPermission() {
    await this.hasContainer;
    this.notificationComponentInstance.showRequestPermission();
  }
}
