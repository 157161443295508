import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import log from 'loglevel';
import { GenericFrontendFirestoreDocument, KeyedArray, SelectOptionList } from '../interfaces';
import { AvailablePhoneNumber } from './did-suggestion.service';
import { PhoneNumber } from '@shared/PhoneNumber';
import { environment } from 'src/environments/environment';

export interface SkyetelSMSAssignment {
  number: string,
  message_enabled:boolean
}

@Injectable({
  providedIn: 'root'
})
export class SkyetelApiService {

  constructor(
    private backendService: BackendService,
  ) { 

  }

  /**
   * Gets phone numbers from skyetel API
   *   
   * @param phoneNumberFilter Filter by substring of phone number.
   */
     async GetSMSEnabledAssignments(phoneNumber:PhoneNumber, userContext: GenericFrontendFirestoreDocument): Promise<SkyetelSMSAssignment[]> {
      let phoneNumbers:any
      if(environment.credentialSource === 'app'){
        phoneNumbers = await this.backendService.backendPost(
          "api/v2/skyetel/getphonenumbers",
          {},
          {useAppCredentials:true}
        )
      }else{
        phoneNumbers = await this.backendService.backendPost(
          "api/v2/skyetel/getphonenumbers",
          {},
          {sid:userContext.sid,secret:userContext.secret}
        )
      }

      log.debug("Skyetel SMS Enabled phone numbers: ", phoneNumbers);
      return phoneNumbers as SkyetelSMSAssignment[]
    }

    

  /**
   * Converts the Skyetel SMS Enabled Assignments into the `AvailablePhoneNumber` interface
   * by extracting the relevant Skyetel specific fields into a string formatted representation.
   * 
   * @param numbers 
   * @returns 
   */
    async ConvertSMSEnabledAssignmentToDIDComponent(numbers: SkyetelSMSAssignment[]): Promise<AvailablePhoneNumber[]> {
      return Promise.all(numbers.map(async (number)=> {
        const n = {
          metadata: number.message_enabled ? "SMS Enabled" : "SMS Disabled",
          phoneNumber: "+" + number.number,
          allowPick: number.message_enabled,
          additionalInformation: "This number is not available for selection",
          additionalInformationIcon: "stop",
          additionalInformationIconColor: "red"
        }
        
        if (number.message_enabled) { 
          n.allowPick = true;
          n.additionalInformation = "This number is available for selection"
          n.additionalInformationIcon = "check-circle"
          n.additionalInformationIconColor = "green"
        }
        return n;
      }));
    }
}
