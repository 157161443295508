import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { vars } from 'src/app/app.constants';
import { AuthNZService } from 'src/app/core/authnz.service';
import { BackendService } from 'src/app/services/backend.service';
import { TextableService } from 'src/app/services/textable.service';
import { UserManagementService } from 'src/app/services/user-management.service';

@Component({
  selector: 'app-native-add-user-modal',
  templateUrl: './native-user-editor.component.html',
  styleUrls: ['./native-user-editor.component.scss']
})
export class NativeAddNewUserModalComponent implements OnInit, OnChanges {
  // #region Properties (3)

  public frmAddUser: FormGroup;
  /**
   * Whether a save operation is in progress
   */
  public isNewUserSaving: boolean = false;
  @Input() public visible: boolean = false;
  @Input() public samlDef: boolean = false;
  /**
   * Emits when the modal should be dismissed 
   * either via cancellation or completion
   * 
   * Emits the dismissed events to consumers of this component
   */
  @Output() onComplete = new EventEmitter();
  @Output() addOrg = new EventEmitter();

  /**
   * List of organizations; to be updated by Textable service
   */
  public orgs$

  /**
   * In the case of "Copy my Provider", we need a TextableUser document to copy from
   */
  public SourceProviderDocument
  /**
   * In the case of "Copy my Provider", we want to prevent editing of the provider details
   */
  public disableProviderEntry: boolean

  // #endregion Properties (3)

  // #region Constructors (1)

  constructor(
    public authnz: AuthNZService,
    public textable: TextableService,
    private backendservice: BackendService,
    private msg: NzMessageService,
    private userService: UserManagementService
  ) {
    this.initializeForms();
    this.orgs$ = [];
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public async addNewUser() {
    this.isNewUserSaving = true;
    let user = this.frmAddUser.getRawValue();
    try {
      await this.userService.AddUser(user)
      this.onComplete.emit();
      this.msg.create('success', "User has been added. A welcome e-mail has been sent to the user.");
    }
    catch(error){
      // the operation was not successful
      this.msg.create('error', error);
      this.isNewUserSaving = false;
    }
  }


  /**
 * Called when the CopyProviderCredentials form field is toggled
 * @param val - value of the switch/checkbox
 */
  changeCopyProviderCredentials(val) {
    const TeamAdminUser = this.authnz.currentUserDocument;
    if (val) {
      this.SourceProviderDocument = TeamAdminUser
      this.disableProviderEntry = true;
    }
    else {
      this.SourceProviderDocument = {};
      this.disableProviderEntry = false;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.visible) {
      this.frmAddUser.reset();
      this.SourceProviderDocument = {};
    }
    else {
      this.isNewUserSaving = false;
    }
  }

  public ngOnInit() {
    this.textable.getOrganizations()
      .subscribe(
        (orgs) => {
          this.orgs$ = orgs
        })
  }
  // #endregion Public Methods (4)

  // #region Private Methods (1)

  private initializeForms() {
    this.frmAddUser = new FormGroup({
      "full_name": new FormControl(null, [
        Validators.required
      ]),
      "external_id": new FormControl(null, []),
      "email": new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      "phone_number": new FormControl(null, [
        Validators.required,
        Validators.pattern(vars.regexValidationPatterns.phone),// Require length of 12(on read) then 14 (on write)
      ]),
      "provider": new FormControl(null, [
        Validators.required
      ]),
      "is_disabled": new FormControl(false, [

      ]),
      "copyProviderCredentials": new FormControl(false, [

      ]),
      "isAdmin": new FormControl(null, [

      ]),
      "organizationId": new FormControl(null, [
        Validators.required
      ]),
      "cell": new FormControl("")//this is being used as a placeholder for now untill the fix is deployed for provisionRetailTeamUsers
    });
  }


  public btnAddOrg() {
    this.addOrg.emit();
  }

  // #endregion Private Methods (1)
}
