import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TextableService } from 'src/app/services/textable.service';
import { ContactImportAction, ContactsService, ContactWithoutId } from 'src/app/services/contacts.service';
import { AuthNZService } from 'src/app/core/authnz.service';
import { ParseError }  from "papaparse"
import { animationFrameScheduler, Observable } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';
import { getRandomInt } from 'src/app/functions';
import { OperationStatusUpdate } from 'src/app/background-operation-status.service';
import { TextableContact, TextableContactList } from 'src/app/backported_types/contact';
import { arrayOfAll } from 'src/app/interfaces';
import levenshtein from "fast-levenshtein";
import { getLogger } from 'src/shared/logging';
import { ColumnDataFormatHint, CSVParser, ImportColumnMapping} from 'src/classes/import-helper';
import { HashJoiner } from 'src/classes/hash-joiner';
import { arrayUnion } from '@angular/fire/firestore'
import { PhoneNumber, PhoneNumberParseError } from '@shared/PhoneNumber';
import { TextableDate } from 'src/classes/TextableDate';

const log = getLogger("ContactImport")

/**
 * When reviewing an import, show this many rows from the import source
 */
const IMPORT_PREVIEW_COUNT = 10
/**
 * TextableContact import records _should_ follow this schema
 */
export type ImportableContact = Omit<TextableContact,
  "id"|
  "firebase_document_id"|
  "tags"|
  "uid"|
  "contactConsentMessageId"|
  "createdBy"|
  "createdAt"|
  "last_trigger_message_body"|
  "last_trigger_message_id"|
  "lists"|
  "cell"
  >

/**
 * A string array of all fields which are eligible for import.
 * 
 * Since the definition of TypeScript Types are not available at runtime,
 * we need to explicitly define this as a string array.
 * 
 * There are a few ways to do this, but all of them kind of suck:
 * https://stackoverflow.com/a/60932900/11125318
 * https://github.com/kimamula/ts-transformer-keys
 * 
 */
const contactImportFieldHeaders = arrayOfAll<keyof ImportableContact>()(
  "phone_number",
  "email",
  "full_name",
  "companyName",
  "title",
  "notes",
  "last_message",
  "last_message_date",
  "optedOut",
  "optedOutAt",
  "isArchived",
  "read_status"
)

export type ContactImportProblem = {
  description: string
  blocksImport: boolean
  resolution: string
  context?: any
}

/**
 * These tests are designed to quickly see if the data in a column
 * is likely to be of a certain format
 * 
 * These matches are NOT designed to ensure full validity of the target
 * format.
 */
const columnTests: Record<string, ColumnDataFormatHint> = {
  email: (val: string): boolean => val.includes("@"),
  full_name: (val: string): boolean => (val.match(/\d/g)?.length || 0) < 2, // super naieve assumption that a string with less than 2 numbers is a name
  phone_number: (val: string): boolean => (val.match(/\d/g)?.length || 0) > 5  // super naieve assumption that a string with more than 5 numbers is a phone number
}

@Component({
  selector: 'app-contact-import',
  templateUrl: './contact-import.component.html',
  styleUrls: ['./contact-import.component.scss']
})
export class ContactImportComponent implements OnInit {
  // #region Properties (19)

  private allContactsLoadingPromise: Promise<void>;
  private joins: HashJoiner<ImportableContact,TextableContact>;
  private parser: CSVParser<ImportableContact>;

  /**
   * Fires when the user has queued a contact import;
   * Should close the contact import drawer
   */
  @Output() public ContactImportQueued = new EventEmitter();
  public ContactsToCreate: ContactWithoutId[]
  public ContactsToUpdate: {id: string, data: any} []
  
  public busyParsing: boolean
  /**
   * Supplies the list of ContactLists in the import list selection wizard
   */
  public contactList$: Observable<any>;
  public currentStep: number = 0;
  public frmImportContacts: FormGroup;

  public importAlerts: ContactImportProblem[]
  public blockingImportAlerts: number

  public pastedImportData: string = "";
  public importErrors: ParseError[]
  public importPreviewData: ImportableContact[];
  public importedData: ImportableContact[];
  public importColumnMapping: Partial<ImportColumnMapping<ImportableContact>>;
  /**
   * Use the same interface as the BackgroundOperationService but in the context of the import dialog (without a modal)
   */
  public parseStatus: OperationStatusUpdate = {
    operation: "Parse CSV",
    statusText: "Waiting for data"
  }

  public targetList: TextableContactList

  // #endregion Properties (19)

  // #region Constructors (1)

  constructor(
    private textable: TextableService,
    private authnz: AuthNZService,
    private contactService: ContactsService
  ) { 
    this.parser = new CSVParser<ImportableContact>(contactImportFieldHeaders,columnTests)
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public async createAndSelectContactList(newListName: HTMLInputElement) {
    newListName.disabled = true;
    const newListDoc = await this.contactService.createContactList(newListName.value)
    this.frmImportContacts.get("importToList").setValue(newListDoc)
    newListName.value = "";
    newListName.disabled = false;
  }

  public async finishImport() {
    /**
     * Hold a copy of targetList.id if it exists, since the promise resolution 
     * below might clear out the form.
     */
    const targetListId = this.targetList?.id
    const importActions = [
      ...this.ContactsToCreate.map<ContactImportAction>(cc=>({
        action: "create",
        data: cc
      })),
      ...this.ContactsToUpdate.map<ContactImportAction>(cc=>({
        action: "update",
        id: cc.id,
        data: cc.data
      })),
    ]
    const importPromise = this.contactService.uploadImport(importActions)
    this.ContactImportQueued.emit();


    importPromise.then(()=>{
      if (this.targetList){ 
        this.contactService.updateContactListCount(targetListId)
      }      
    })
  }

  public async importCSV(files: FileList | File, hasHeader: boolean) {
    let file: File;
    if (typeof files == "object" && "length" in files) {
      if (files.length == 0) {
        log.debug("No files to process")
        return
      }
      file= files[0]
    }
    else if (typeof files == "object" && "name" in files){
       file = files
    }
    else {
      log.debug("No file to process")
      return
    }

    this.busyParsing = true
    this.parseStatus = {
      operation: "Parse CSV", 
      statusText: "Beginning parse",
      percentComplete: 0
    }
    const importResult = this.parser.ParseCSV(file, hasHeader)
    
    importResult.pipe(
      observeOn(animationFrameScheduler)
    ).subscribe(status=> {
      this.parseStatus ={
        operation: "Parse CSV",
        statusText: (
          status.percentComplete == 100 ? 
          "Complete; parsed " + status.rowsParsed + " contacts" : 
          "Parsed " + status.rowsParsed + " contacts"
        ),
        percentComplete: status.percentComplete
      }
    })
    const [r,x] = await Promise.all([
      importResult.toPromise(),
      this.allContactsLoadingPromise
    ]);
   
    this.importedData = r.result.data;
    this.importErrors = r.result.errors;
    this.importColumnMapping = r.result.columnMapping;

    this.joins = new HashJoiner<ImportableContact,TextableContact>(
      (v)=>{
        try {
          return new PhoneNumber(v.phone_number).ToE164()
        }
        catch (err) {
          return v.phone_number
        }
      },
      this.importedData,
      this.contactService.activeWatcher.GetContactMapKeyedByPhoneNumber()
    )
    log.debug(`Parse Result length: ${this.importedData.length}`);
    this.importAlerts = [];

    this.cleanupContactPhoneNumbers();
    this.populatePreviewData();
    this.scanForAlerts(); 
    this.createActionList();
    this.blockingImportAlerts = this.importAlerts.filter(ia=>ia.blocksImport).length
    this.busyParsing = false;
  }


  public ngOnInit(): void {
    this.allContactsLoadingPromise = this.contactService.activeWatcher.LoadContacts("all");
    this.contactList$ = this.textable.contactLists$.pipe(map(list => list.filter(contact=>!contact.isDeleted)));
    
    this.frmImportContacts = new FormGroup({
      "importFile": new FormControl({value:"",disabled: true}),
      "pastedImportData": new FormControl({value:"",disabled: true}),
      "skipFirstRowFile": new FormControl(null),
      "skipFirstRowPaste": new FormControl(null),
      "importToList": new FormControl(null)
    })

    this.frmImportContacts.get("importToList").valueChanges.subscribe(async change=>{
      this.targetList = await this.contactService.getContactList(change)
      this.createActionList();
    })

    this.frmImportContacts.get("skipFirstRowFile").valueChanges.subscribe(c=>{
      log.debug("skipFirstRowFile changed");
      (this.frmImportContacts.get("skipFirstRowFile").value == null) ? 
         this.frmImportContacts.get("importFile").disable() :
         this.frmImportContacts.get("importFile").enable();
      this.frmImportContacts.get("importFile").updateValueAndValidity();
    })

    this.frmImportContacts.get("skipFirstRowPaste").valueChanges.subscribe(c=>{
      log.debug("skipFirstRowPaste changed");
      (this.frmImportContacts.get("skipFirstRowPaste").value == null) ? 
        this.frmImportContacts.get("pastedImportData").disable() :
        this.frmImportContacts.get("pastedImportData").enable()
      this.frmImportContacts.get("pastedImportData").updateValueAndValidity();
    })

    this.frmImportContacts.get("pastedImportData").valueChanges.subscribe( (pasteData: string)=>{
      const f = new File([pasteData.trim()],"pasted.csv")
      this.importCSV(f, this.frmImportContacts.get("skipFirstRowPaste").value);
    })
  }

  // #endregion Public Methods (4)

  // #region Private Methods (3)

  /**
   * iterate the contacts and ensure the phone numbers conform to E.164
   */
  private cleanupContactPhoneNumbers() {
    this.importedData.forEach(importedContact=>{
      let number: PhoneNumber
      try{
        number = new PhoneNumber(importedContact.phone_number)
        importedContact.phone_number = number.ToE164();
        if (number.GetE161ConversionStatus()){
          this.importAlerts.push({
            description: `E.161 conversion was applied to the phone number '${number.GetRawNumber()}'`,
            blocksImport: false,
            resolution: "Confirm that the conversion was intended",
            context: importedContact.phone_number
          })
        }
      }
      catch(err){ 
        if (err instanceof PhoneNumberParseError) {
          this.importAlerts.push({
            description: err.message,
            blocksImport: true,
            resolution:  err.resolution,
            context: importedContact.phone_number
          })
        }
        else {
          this.importAlerts.push({
            description: "Unable to parse phone number",
            blocksImport: true,
            resolution:  "Ensure that the phone number can be formatted as +1xxxyyyzzzz",
            context: importedContact.phone_number
          })
         }
        return;
      }
    });
  }

  private createActionList() {  
    let importToList = this.frmImportContacts.get('importToList').value;
    log.debug("import to list ", importToList)

    const now = TextableDate.now()

    this.ContactsToCreate = Object.entries(this.joins.NewItemsNotInCurrent).flatMap(([tn,importedContact])=>{
      const c: ContactWithoutId = {
        ...importedContact,
        uid: this.authnz.activeNumberUID,
        createdBy: 'web-import',
        createdAt: now,
        isArchived: true,
        read_status: true
      }
      if (importToList) {
        c.lists = [
          importToList
        ]
      }
      return c;
    })

    log.debug(`Contacts to create ${this.ContactsToCreate.length}`)
    

    this.ContactsToUpdate = Object.entries(this.joins.NewItemsAlreadyInCurrent).map(([tn,matched])=>{
      if (importToList) {
        return {
          id: matched.current.id,
          data: {
            lists: matched.current.lists ? arrayUnion(importToList) : [ importToList ] 
          }
        }
      }
      return;
    }).filter(n=>n)

    log.debug(`Contacts to update ${this.ContactsToUpdate}`);
  }

  private populatePreviewData() {
    const previewImportCount = Math.min(this.importedData.length,IMPORT_PREVIEW_COUNT)
    this.importPreviewData = [
      ...this.importedData.slice(0,previewImportCount-1),
      this.importedData[this.importedData.length-1]
    ]
    log.debug("Import preview data", this.importPreviewData)
  }

  /**
   * Iterates over contacts to be imported looking for duplicates and bad data
   * 
   * Initializes the `this.importAlerts` array
   * 
   */
  private async scanForAlerts(){
    const hasColumnTitleInValue = (c: ImportableContact): string[] =>{
      return Object.values(c)
        .filter((lk: any): lk is string => typeof lk === "string")
        .filter(lk=> typeof lk == "string" && contactImportFieldHeaders.filter(bk=>levenshtein.get(lk,bk) < 3).length >0 )
    }

    const newAlerts = [
      ...this.importedData.flatMap(value=>{
        if (hasColumnTitleInValue(value).length == Object.keys(this.importColumnMapping).length) {
          return ({
            description: "Field title is present in the field value",
            resolution: "Ensure that the CSV header row is correct and that the proper header row import option is selected",
            blocksImport: true,
            context: value.phone_number
          })
        }
      }).filter(n=>n),
      ...Object.entries(this.joins.NewItemsAlreadyInCurrent).map<ContactImportProblem>(([k,v])=>{
        return {
          blocksImport: false,
          description: "Existing contact with same phone number",
          resolution: "Existing contacts will be added to newly selected lists. Other details will not be updated",
          context: v.current.phone_number
        }
      }),
      ...Object.entries(this.joins.NewItemsDuplicated).map<ContactImportProblem>(([k,v])=>{
        return {
          blocksImport: true,
          description: "Duplicate phone number",
          resolution: "Edit the source file and re-run this import",
          context: v[0].phone_number
        }
      }),
    ]

    this.importAlerts = [...this.importAlerts, ...newAlerts].sort((a,b)=>a.blocksImport ? -1 : 1)
  }

  // #endregion Private Methods (3)
}