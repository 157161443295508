import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ExternalScriptLoader } from 'src/classes/ExternalScriptLoader';
import { environment } from 'src/environments/environment';
import { BackendService } from './backend.service';

export type CheckoutSessionRequest = {
  priceId: string
  referralId?: string
}

@Injectable({
  providedIn: 'root'
})
export class StripeService extends ExternalScriptLoader {

  protected async loadExternal(): Promise<void> {
    this.log.debug("Loading Stripe")
    this.stripe = await loadStripe(environment.stripeUrl); 
    this.log.debug("Loaded Stripe")
  }

  // #region Properties (2)

  private stripe: Stripe

  // #endregion Properties (2)

  // #region Constructors (1)

  constructor(
    private backendService: BackendService
  ) { 
    super("Stripe");
    this.initialize().catch(err=>{
      this.log.warn("Failed loading stripe", err)
    });
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  /**
   * Gets a Checkout Session for the requesting user through backend.
   * 
   * @param csr 
   * @returns 
   */
  public async getCheckoutSession(csr: CheckoutSessionRequest): Promise<string> {
    this.log.debug(`Starting checkout session for '${csr.priceId}' with referral code: '${csr.referralId}'`);
    const response = await this.backendService.backendPost("createStripeCheckoutSession",{},csr);
    return response.sessionId
  }

  /**
   * Gets a Billing Portal Session for the requesting user through backend.
   * @returns 
   */
  public async getPortalSession(): Promise<string> {
    try {
      const res = await this.backendService.backendPost("createStripePortalSession", 
        {useBillingConfig:environment.securityProvider != 'retail'}, 
        {}
      )    
      return res.sessionUrl;      
    }
    catch (err) {
      this.log.error("Failed to get a portal session URL", err)
    }
  }

  /**
   * TODO: TXBDEV-2783 - This is deprectaed 
   * @param sessionId 
   */
  public async redirectToCheckout(sessionId: string) {
    await this.loading;
    this.log.debug("Redirecting to checkout")
    await this.stripe.redirectToCheckout({
      sessionId: sessionId
    });
  }

  // #endregion Public Methods (4)
}
