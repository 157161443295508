<div class="pad1">
  <nz-alert nzType="warning" [nzMessage]="subMessage"></nz-alert>
  <ng-template #subMessage>
    <p style="text-align:center;font-size:12px;">
         <!-- This Container is for Team Users only-->
        <ng-container
        *ngIf="(this.authnz.currentUserDocument.managedBy != this.authnz.currentUserDocument.uid) && (this.authnz.currentUserDocument.billing?.plan == 'retail-teams');else notTeamsBlock">
        <b>Your subscription is past due.</b><br><br>
        Please contact your Team Administrator.
      </ng-container>


      <ng-template #notTeamsBlock>
          <ng-container *ngIf="this.authnz.currentUserDocument.billing?.status == 'PastDue'"> <b>Your subscription is past due.</b><br><br></ng-container> 
          <ng-container *ngIf="this.authnz.currentUserDocument.billing?.status == 'Aborted'"> <b>Your subscription has been expired.</b><br><br></ng-container> 
          <ng-container *ngIf="this.authnz.currentUserDocument.billing?.status == 'Cancelled'"> <b> Your subscription has been cancelled.</b><br><br></ng-container>

        <a (click)="showPlanSelector()">Click Here</a> to resume<br>
        or contact your team administrator.
      </ng-template>
    </p>
  </ng-template>
  <app-plan-selector (closeModal)="closePlanSelector($event)" [isVisible]="planSelectorVisible"
    [isClosable]="planSelectorClosable" [userProfile]="this.authnz.currentUserDocument"></app-plan-selector>

  <app-plan-selector [isClosable]="false" 
  [isVisible]="this.authnz.currentSecurityProvider.shouldForcePlanSelector()" 
  [userProfile]="this.authnz.currentUserDocument"></app-plan-selector>

</div>