import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { getLogger } from '@shared/logging';
import { merge, Observable, observable, Subscription } from 'rxjs';
import { DateWithTimezoneToDate } from 'src/classes/TextableDate';
import { vars } from '../../app.constants';
const log = getLogger("TzDateTimePickerComponent")

@Component({
  selector: 'tz-date-time-picker',
  templateUrl: './tz-date-time-picker.component.html',
  styleUrls: ['./tz-date-time-picker.component.scss']
})
export class TzDateTimePickerComponent implements OnInit  {
  @Input() disabled = false;

  private _formGroup: FormGroup

  @Input()
  set formGroup(formGroup: FormGroup){
    log.debug("setter");
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    this._formGroup = formGroup;

    this.timeZoneField = this._formGroup.get(this.timeZoneFieldName)
    log.debug("tzfield", this.timeZoneField)
    this.dateField = this._formGroup.get(this.dateFieldName)
    log.debug("datefield", this.dateField);
    this.formSub = merge(
        this.timeZoneField.valueChanges,
        this.dateField.valueChanges
    ).subscribe(c=>{
      this.updateFromForm()
    })
    this.updateFromForm();
     
  }
  get formGroup(): FormGroup {
    return this._formGroup
  }
  @Input() timeZoneFieldName: string
  @Input() dateFieldName: string

  timeZoneField: AbstractControl
  dateField: AbstractControl


  timezone: string
  date: Date
  /**
   * We don't want the subscription to change events for the date and timezone form fields to propatage to the local model
   * when we're writing back to the form (since we're adjusting the written values by theh localized timezone)
   */
  ignoreUpdates: boolean = false;
  
  timezones = vars.timezones;
  private formSub: Subscription;

  constructor() { }

  ngOnInit(): void {
    log.debug("component date1: ", this.date)
  }

  updateFromForm() {
    if (this.ignoreUpdates) {
      return;
    }
    if (!this.timeZoneField || !this.timeZoneField.value || !this.dateField || !this.dateField.value) {
      log.debug("no value");
      return;
    }

    this.timezone = this.timeZoneField.value
    this.date = new Date(new Date(this.dateField.value).toLocaleString('en-US', { timeZone: this.timezone }));
    log.debug("component date4: ", this.date)
  }


  pushValuesToForm() {
    log.debug("pushing values to form");
    this.ignoreUpdates = true;
    this.timeZoneField.setValue(this.timezone);
    this.dateField.setValue(DateWithTimezoneToDate(this.date,this.timezone))
    this.ignoreUpdates  = false;
  }
}
