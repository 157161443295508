<nz-card [nzExtra]="extraTemplate" nzTitle="Statistics" nzBorderless="true">
    <nz-spin [nzSpinning]="loading" class="pad-25">
      <nz-card>
          <nz-row [nzGutter]="16" class="text-center pad-25">
            <nz-col [nzSpan]="6">
              <nz-statistic [nzValue]="report?.total_messages | number" [nzTitle]="'Total Messages'" [nzPrefix]="prefixTotal"></nz-statistic>
              <ng-template #prefixTotal><i class="far fa-exchange-alt"></i></ng-template>
            </nz-col>

            <nz-col [nzSpan]="6">
              <nz-statistic [nzValue]="report?.outbound_messages | number" [nzTitle]="'Sent'" [nzPrefix]="prefixOutbound"></nz-statistic>
              <ng-template #prefixOutbound><i class="far fa-inbox-out"></i></ng-template>
            </nz-col>

            <nz-col [nzSpan]="6">
              <nz-statistic [nzValue]="report?.inbound_messages | number" [nzTitle]="'Received'" [nzPrefix]="prefixInbound"></nz-statistic>
              <ng-template #prefixInbound><i class="far fa-inbox-in"></i></ng-template>
            </nz-col>

            <nz-col [nzSpan]="6">
              <nz-statistic [nzValue]="report?.failed_sent_messages | number" [nzTitle]="'Failed Sent'" [nzPrefix]="prefixFailed"></nz-statistic>
              <ng-template #prefixFailed><i class="far fa-file-times"></i></ng-template>
            </nz-col>
          </nz-row>
      </nz-card>
    </nz-spin>
  </nz-card>
  <ng-template #extraTemplate>
    <nz-select style="width: 120px;" [(ngModel)]="numDays" nzAllowClear nzPlaceHolder="Select Report Length" (ngModelChange)="getReport()">
      <nz-option nzValue="7" nzLabel="7 Days"></nz-option>
      <nz-option nzValue="14" nzLabel="14 Days"></nz-option>
      <nz-option nzValue="30" nzLabel="30 Days"></nz-option>
      <nz-option nzValue="60" nzLabel="60 Days"></nz-option>
      <nz-option nzValue="90" nzLabel="90 Days"></nz-option>
      <nz-option nzValue="120" nzLabel="120 Days"></nz-option>
    </nz-select>
  </ng-template>