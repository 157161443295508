import { getLogger } from "@shared/logging";
import { Logger } from "loglevel";

type CacheEntry<T> = {
  lastUpdated: SequenceCounters;
  value: T
}

type SequenceCounters = Record<string, number>;

/**
 * Manages a key-value pair of T typed items 
 * with "intelligent" scavenging 
 * 
 * 
 */
export class ScavengableCache<T> {

  private items: Record<string, CacheEntry<T>> = {};
  private log: Logger;
  private updateSequence: SequenceCounters = {};

  constructor(context: string) {
    this.log = getLogger(`ScavengableCache-${context}`);
    this.log.setLevel("INFO");
    (window as any).GetCacheItem = (key: string)=>{
      this.log.info("Sequence", this.updateSequence)
      this.log.info("Cached ",this.get(key))
    }
  }

  /**
   * Call this whenver the sequence increments; 
   * cache entries with a value lower than this 
   * sequence will be removed at the next scavenge.
   * 
   */
  public incrementSequence(sequenceKey: string) {
    if(!this.updateSequence.hasOwnProperty(sequenceKey)) {
      this.updateSequence[sequenceKey] = 0;
    }
    else {
      this.updateSequence[sequenceKey]++;
    }
    this.log.info(`Incrementing cache sequence: '${sequenceKey}: ${this.updateSequence[sequenceKey]}`)
  }
  public clearCache() {
    this.items = {};
  }
  public get(key: string) {
    return this.items[key];
  }

  public getLength(){ 
    return Object.values(this.items).length
  }

  /**
   * 
   * @param key 
   * @param value 
   */
  public add(key: string, value: T, sequenceKey: string) {
    const nk = {[sequenceKey]: this.updateSequence[sequenceKey]}
    this.items[key] = {
      lastUpdated: {...nk},
      value: value
    } 
  }
  /**
   * Updates the object's sequence and returns the object
   * so that any necessary changes can be handled
   * @param itemKey 
   */
  public update(itemKey:string, sequenceKey: string): T {
    this.items[itemKey].lastUpdated[sequenceKey] ++;
    return this.items[itemKey].value;
  }

  public values(): T[] {
    return Object.values(this.items).map(i=>i.value);
  }

  /**
   * Loops through the cached item's sequence keys and determines
   * if any key matches the collection's current sequence value for that key
   * 
   * Returns true if any sequence key is "current"
   * Returns false if all sequence keys are not "current"
   * 
   * @param key 
   * @param item 
   * @returns 
   */
  private testCacheIsCurrent(key: string, item: CacheEntry<T>): boolean {
    const isCurrent = Object.entries(item.lastUpdated)
      .some(([sequenceKey,sequence]) => {
          return this.updateSequence[sequenceKey] == sequence
        }
      )
    this.log.debug(`Item '${key}' is${isCurrent ? ' ': ' not '}current`);
    return isCurrent;
  }

  public scavenge() {
    const oldDocKeys = Object.entries(this.items)
      .filter(([key,value])=>!this.testCacheIsCurrent(key,value))
      .map(([key,value])=>key);

    this.log.info("Documents to be scavenged from contact cache: " + oldDocKeys.length);
    
    oldDocKeys.forEach(oldDoc=>{
      delete this.items[oldDoc]
    });
  }


}