import { environment } from '../../../environments/environment';
import { CollectionReference, Query } from "@angular/fire/compat/firestore";
import { AuthNZService } from '../authnz.service';
import log from 'loglevel'

export class BaseSecurityProvider  {
  
  protected service: AuthNZService;
  protected orgsUserManages: string[];

  constructor(service: AuthNZService) {
    this.service = service;
  }

  /**
   * Checks whether the requested feature name is "permitted" by the current environment file
   * 
   * @param featureName the name of the feature to check; should correspond with `features` array in `environment.ts`
   * @returns true if the feature is permitted; false if the feature is not permitted
   */
  protected getEnvironmentFeatureGrant(featureName: string): boolean {
    if(!environment.features.hasOwnProperty(featureName)){
      return false;
    }

    if(!environment.features[featureName]){
      return false;
    }
    return true;
  }

  /**
   * Checks whether the current user has a restriction set on the given feature name
   * @param featureName the name of the feature to check; should correspond with array values in the `restrictions` property on User documents
   * @returns true if the feature is permitted; false if the feature is not permitted
   */
   protected getUserFeatureGrant(
    featureName: string,
    ContextCheck: "ActiveUser" | "ActiveNumber" = "ActiveNumber"
    ): boolean {
    const profileToCheck = (
      ContextCheck == "ActiveNumber" ?  this.service.activeNumberProfile : 
      this.service.currentUserDocument
      );
     //check activeUser.restrictions.includes(featureName) (activeUser check if they have permission)
     if (
      profileToCheck.restrictions 
       && profileToCheck.restrictions.includes(featureName)
       ) {
      return false;
    }
    return true;
  }

  /**
   * Checks whether the requested feature is allowable based on the current environment and user restrictions
   * @param featureName he name of the feature to check.
   * @returns true if both the environment and the current user grant access; false if either environment or user restrictions block access
   */
  protected getUserAndEnvironmentFeatureGrant(
    featureName: string,
    ContextCheck: "ActiveUser" | "ActiveNumber" = "ActiveNumber"
    ) {
    // Return true ONLY IF the environment permits the feature, 
    //  and the user (either current user or active number) is not restricted from the feature
    return this.getEnvironmentFeatureGrant(featureName) 
      && this.getUserFeatureGrant(featureName, ContextCheck)
  }

  /**
   * Yes, this is a function which returns only true.  It exists so that instances of granting unconditional access are 
   * explicitly clear and readable; instead of letting the developer guess what "true" means in context.
   * @returns true
   */
  public unrestrictedGrant(): boolean {
    return true;
  }

  /**
   * Run any tasks that need to check for changes to authorization grants
   * when the currently logged in user changes
   */
  async updateUser() {
    await this.getOrganizationsManagedByUser();
  }

  /**
   * Query firestore to find all organizations which the currently logged in user
   * is authorized to manage
   * 
   * Store the result in an instance variable.
   */
  private async getOrganizationsManagedByUser() {
    try {
      this.orgsUserManages = await (await
        this.service.afs.collection(
          "organizations",
          (ref) => {
            let query: Query = ref;
            query = query.where('organizationAdmins', 'array-contains', this.service.currentFireauthUser.uid);
            return query;
          }).get().toPromise()).docs.map((doc)=>doc.id);
    }
    catch(err){
      this.orgsUserManages = [];
      log.error("Unable to query organizations managed by current user; double-check database security rules");
    }
  }

  /**
   * 
   * @returns true if the user is designated as an admin of one or more organizations
   */
  isOrgAdmin():boolean {
    return this.orgsUserManages.length > 0;
  }

  protected isUserFullOrSuperAdmin(): boolean {
    return this.service.currentUserDocument.isAdmin || this.service.currentUserDocument.isSuperAdmin;
  }

  public canViewDLRObject(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  canImportContacts(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("importContacts");
  }
  canDeleteAllContacts(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("deleteAll");
  }

  /**
   * Executed while loading messages from Firestore.  
   * Provides an opportunity to trim the message query according to
   * a date-range retention (or other) criteria.
   * 
   * This base implementation does not apply any restrictions, but
   * instead just returns the unmodified query.
   * 
   * Classes extending this `BaseSecurityProvider` may override this method with
   * more specific filters
   * 
   * @param query a query object for the messages collection to which range filtering may be applied
   * @returns a query object with the appropriate filters applied for the current context
   */
  trimFirestoreMessageQuery(query: Query|CollectionReference): Query {
    return query;
  }

  public hasValidPhoneNumber() {
    return this.service.currentUserDocument.hasOwnProperty("phone_number");
  }
 

}