export const vars = {
  timezones: [
    {
      "offset": "-08:00",
      "dst-offset": "-07:00",
      "name": "America/Los_Angeles",
      "alias": "Pacific"
    },
    {
      "offset": "-07:00",
      "dst-offset": "-06:00",
      "name": "America/Denver",
      "alias": "Mountain"
    },
    {
      "offset": "-06:00",
      "dst-offset": "-05:00",
      "name": "America/Chicago",
      "alias": "Central"
    },
    {
      "offset": "-05:00",
      "dst-offset": "-04:00",
      "name": "America/New_York",
      "alias": "Eastern"
    }
  ],

  countryCodes: [
    {
      "name": "USA",
      "code": "+1",
    }
  ],
  regexValidationPatterns: {
    phone: /^[^_]{12}$|^[^_]{14}$/,
    password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
  },
  supportedFormats: {
    imageTypes : ['image/png', 'image/jpg', 'image/jpeg','image/gif'],
    videoTypes: ['video/mp4'],
    audioTypes: ['audio/amr', 'audio/wav', 'audio/x-wav', 'audio/mpeg', 'audio/aac', 'audio/flac', 'audio/oog', 'audio/mp3'],
    textTypes:['text/plain']
  },
  rewardfulKey: "9bfaf4"
}

export const MS_PER_MINUTE = 1000 * 60