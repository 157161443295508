type GetKey<T> = (value:T) => string

export class HashJoiner<Tnew, Tcurrent> {
  
  public AllNewItems: Record<string,Tnew> = {};
  public NewItemsNotInCurrent: Record<string,Tnew> = {};
  public NewItemsAlreadyInCurrent: Record<string,{
    current: Tcurrent,
    new: Tnew[]
  }> = {}
  public NewItemsDuplicated: Record<string,Tnew[]> = {}

  constructor(
    getNewItemKey: GetKey<Tnew>,
    newData: Tnew[],
    currentData: Record<string,Tcurrent>

  ) {

    for( let item of newData) {
      const key = getNewItemKey(item);
      if(this.AllNewItems.hasOwnProperty(key)) {
        if (!Array.isArray(this.NewItemsDuplicated[key])) {
          this.NewItemsDuplicated[key] = []
        }
        this.NewItemsDuplicated[key].push(item)
      }
      else {
        this.AllNewItems[key] = item
      }

      if(currentData.hasOwnProperty(key)) {
        if (!Array.isArray(this.NewItemsAlreadyInCurrent[key])) {
          this.NewItemsAlreadyInCurrent[key] = {
            current: currentData[key],
            new: []
          }
        }
        this.NewItemsAlreadyInCurrent[key].new.push(item);
      }
      else {
        this.NewItemsNotInCurrent[key] = item
      }
    }
  }

}