<a nz-dropdown [nzDropdownMenu]="menu" class="status-dropdown">
  <ng-container *ngIf="authnz.activeNumberProfile">
    <nz-badge *ngIf="!authnz.activeNumberProfile.autoReplyMessage;else elseBadge" nzStatus="success" nzText="Available">
    </nz-badge>
    <ng-template #elseBadge>
      <nz-badge nzStatus="warning" nzText="Away - {{ authnz.activeNumberProfile.autoReplyMessage }}" class="ellipsis">
      </nz-badge>
    </ng-template>
  </ng-container>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu" style="margin-top: 12px;margin-bottom: 12px">


  <ul nz-menu nzSelectable>
    <li nz-menu-item (click)="setAutoReplyMessage(null)">
      <nz-badge nzStatus="success"></nz-badge>Available
    </li>
    <li *ngIf="this.authnz.currentSecurityProvider.isSubscriptionActive()" nz-submenu>
      <span title>Away Message</span>
      <ul>
        <li *ngIf="showEmptyAwayMessage">
          <div class="empty-away-message-alert">
            <b>No available messages.</b><br>
            <span>Set 'Is Away Message' on Canned Responses to see them here.</span>
          </div>
        </li>
        <li *ngFor="let response of canned$ | async" (click)="setAutoReplyMessage(response)" nz-menu-item>
          <b class="ellipsis">{{response.name}}</b>
          <small class="ellipsis">{{response.body}}</small>
        </li>
      </ul>
    </li>
  </ul>
</nz-dropdown-menu>