/**
 * Accepts a value to coerce to boolean, and a default value to use if 
 * the supplied value is undefined
 * 
 * @param value 
 * @param defaultValue 
 * @returns 
 */
export const toBoolean = (value: any | undefined, defaultValue: boolean) => {
  return typeof value == "undefined"
    ? defaultValue
    : String(value).toLowerCase() == "true";
};