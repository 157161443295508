<div [formGroup]="phoneNumberFormGroup">
  <nz-form-control
    nzHasFeedback
    nzErrorTip="Invalid number format. Must match e.164 format. (e.g. +1 (555) 555-5555)"
  >
    <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
      <ng-template #addOnBeforeTemplate>
        <nz-select formControlName="countryCode" 
          style="width: 60px" 
          [nzDropdownMatchSelectWidth]="false">
          <nz-option
            style="width: 200px"
            nzCustomContent
            *ngFor="let country of countryCodes"
            [nzLabel]="country.code"
            [nzValue]="country.code"
            >
              {{ country.name}}<span style="color: lightgray">({{ country.code }})
            </span>
          </nz-option>
        </nz-select>
      </ng-template>
      <input
        nz-input
        placeholder="555-555-5555"
        type="text"
        formControlName="phoneNumber"
        type="tel"
        [textMask]="{ mask: phoneMask }"
      />
    </nz-input-group>
  </nz-form-control>
</div>
