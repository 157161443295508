import { Query, CollectionReference } from "@angular/fire/compat/firestore";
import { PlanSelectionProperties, UISecurityProvider } from './interfaces';
import { BaseSecurityProvider } from "./base";
import { AuthNZService } from "../authnz.service";
import { UserGuideConfig } from "src/app/components/user-guides/user-guides.component";
import { Injector } from "@angular/core";
import { RewardfulService } from "src/app/services/rewardful.service";

export class RetailSecurityProvider extends BaseSecurityProvider implements UISecurityProvider {
  // #region Constructors (1)

  constructor(service: AuthNZService, injector: Injector) {
    super(service);
    /**
     * Initialize the Rewardful service so it's available on the page for later use
     * and so that the user's session is associated with rewards tracking
     */
    injector.get(RewardfulService);
  }

  // #endregion Constructors (1)

  // #region Public Methods (43)

  public allowProviderCopyOnUserCreate(): boolean {
    return true;
  }

  public canAccessAdminTools(): boolean {
    return this.service.currentUserDocument.hasOwnProperty("isSuperAdmin")
      && this.service.currentUserDocument.isSuperAdmin === true
  }

  public canAccessAdminMenu(): boolean  {
    // Since Users is the only sub-menu of admin, we check that here also.
    return this.billingPlanIncludes("retail-teams") 
      && (this.isUserTeamAdmin() || this.isOrgAdmin()) 
      && this.getUserAndEnvironmentFeatureGrant("users")  
      && this.getUserAndEnvironmentFeatureGrant("admin");
  }

  public canAccessBlasts(): boolean {
    return this.isUserTeamsOrPro() &&  this.getUserAndEnvironmentFeatureGrant("blasts");
  }

  public canAccessBulkMMS(): boolean {
  return this.hasValidPhoneNumber() && this.getUserAndEnvironmentFeatureGrant("bulkMMS");
  }

  public canAccessCannedResponses(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("cannedResponses");
  }

  public canAccessContactLists(): boolean {
    return this.isUserTeamsOrPro() &&  this.getUserAndEnvironmentFeatureGrant("lists");
  }

  public canAccessContacts(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("contacts");
  }

  public canAccessConversations(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("conversations");
  }

  public canAccessDripCampaigns(): boolean {
    return this.isUserTeamsOrPro() &&  this.getUserAndEnvironmentFeatureGrant("dripCampaign");
  }

  public canAccessManageUsers(): boolean {
    // Currently, this is the only item in the admin menu, so we'll use the same security check
    // at some point in the future, it may be possible that items exist in the admin menu
    // which are granted, but manage users is still restricted.
    return this.canAccessAdminMenu(); 
  }

  public canAccessNumberSwitcher(): boolean {
    return this.billingPlanIncludes("retail-teams");
  }

  public canAccessOwnProfile(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("profile");
  }

  public canAccessReminders(): boolean {
    return this.isUserTeamsOrPro() &&  this.getUserAndEnvironmentFeatureGrant("reminders");
 }

  public canAccessServiceAccounts(): boolean {
    return false;
  }

  public canAddOrgs(): boolean {
    return this.isUserTeamAdmin();
  }

  public canAddUsers(): boolean {
    return this.isUserTeamAdmin();
  }

  public canAccessDeletedUsers(): boolean{
    return this.service.currentUserDocument.isSuperAdmin
  }

  public canAccessDeletedOrganizations() { 
    return this.canAccessDeletedUsers();
  }

  public canDeleteContacts(): boolean {
    return this.getUserFeatureGrant("disableDelete");
  }

  public canDeleteUsers(): boolean {
    return this.isUserTeamAdmin();
  }

  public canDisableOrganizations(): boolean {
    /**
     * TODO: TXBDEV-2703 / TXBDEV-1372
     * 
     * There is considerable logic required for disabling orgs which is only implemented in PB's APIv2.  
     * 
     * To support disabling organizations in Retail, we need to complete TXBDEV-1372 so that retail 
     * "TeamAdmins" use the APIv2 endpoints
     * 
     */
    return false;
  }

  public canAccessIntegrations(): boolean {
    return this.isUserTeamsOrPro()  && this.getUserAndEnvironmentFeatureGrant("integrations");;
  }

  public canEditAdminIntegrations(){
    return this.canAccessAdminTools();
  }

  public canEditOrgConsentMessages(): boolean {
    return true;
  }

  public canEditOwnFullName(): boolean {
    return ! this.billingPlanIncludes("retail-teams");
  }

  public canEditOwnProviderSettings(): boolean {
    return ! this.billingPlanIncludes("retail-teams");
  }

  public canEditUserDisabled(): boolean {
    return this.isUserTeamAdmin();
  }

  public canEditUserFullName(): boolean {
    return this.isUserTeamAdmin();
  }

  public canEditUserIsAdmin(): boolean {
    return false;
  }

  public canEditUserOrganization(): boolean {
    return this.isUserTeamAdmin();
  }

  public canEditUserPhoneNumber(): boolean {
    return this.isUserTeamAdmin();
  }

  public canExportConversations(): boolean {
    return true;
  }

  public canManageBilling(): boolean {
    return this.isUserTeamAdmin() || this.billingPlanIncludes("retail-pro") || this.billingPlanIncludes("retail-basic");
  }

  public canModifyUserAPITokens(): boolean {
    return false; // TODO: TXBDEV-878 - Discuss adding this.getUserAndEnvironmentFeatureGrant("apiAccess"); 
  }

  public canModifyUserNumberSharing(): boolean {
    return this.isUserTeamAdmin() || this.isOrgAdmin();
  }

  public canModifyUserPermissions(): boolean {
    return this.isUserTeamAdmin();
  }

  public canModifyUserWebhookRelay(): boolean {
    // yeah yeah I know, this is redundant.
    // leaving the second clause here so that we remember to
    // security-check when we make this available on retail
    return false && this.getUserAndEnvironmentFeatureGrant("crmRelay"); 
  }

  public canViewProviderDetails(): boolean {
    return this.isUserTeamAdmin();
  }

  public canViewDLRObject(): boolean {
    return this.isUserTeamAdmin() || this.billingPlanIncludes("retail-pro") || this.billingPlanIncludes("retail-basic");
  }

  public canViewProviderName(): boolean { 
    return this.isUserTeamAdmin() || this.billingPlanIncludes("retail-pro") || this.billingPlanIncludes("retail-basic");
  }

  public canViewMetadata(): boolean{
    // team admins, retail basic / pro can manage billing
    return this.isUserFullOrSuperAdmin() || this.canManageBilling()
  }

  public getUserGudeOverrides(): UserGuideConfig[] {
    return;
  }

  public getDebugUserRole(): string {
    if (this.isUserTeamAdmin()){
      return "Team admin. Plan: " + this.getBillingPlanString();
    }
    else{
      return "Not admin. Plan: " + this.getBillingPlanString();
    }
  }

  public getEditOwnProviderSettingsContact(): string {
    return 'your team administrator';
  }

  public getPlanSelectionDetails(): PlanSelectionProperties {
    return {
      choices: [
        {
          id: "retail-pro",
          description: "",
          displayName: "",
          restricted: false
        },
        {
          id: "retail-teams",
          description: "",
          displayName: "",
          restricted: false
        },{
          id: "retail-basic",
          description: "",
          displayName: "",
          restricted: false
        }],
      granularity: "user"
    }
  }

  public hasFullAdminRole(): boolean {
    return false;
  }

  public isSubscriptionActive(): boolean {
    return this.billingStatusIncludes("Active") 
    || this.billingStatusIncludes("PastDue") 
  }

  public requiresEmailConfirmation(): boolean {
    return true;
  }

  public shouldForcePlanSelector(): boolean {
    return this.billingStatusIncludes("None") 
    || this.billingStatusIncludes("Cancelled") 
    || this.billingStatusIncludes("Aborted");
  }

  public shouldShowSubscriptionStatus(): boolean {
    return this.billingStatusIncludes("None") 
    || this.billingStatusIncludes("PastDue") 
    || this.billingStatusIncludes("Cancelled") 
    || this.billingStatusIncludes("Aborted");
  }

  public showRetailBrandingDetails(): boolean {
    return true;
  }

  public storeManagedByAttributeOnUsers(): boolean {
    return true;
  }

  public trimFirestoreOrgQuery(query: Query | CollectionReference): Query {
    if (this.isUserTeamAdmin()) {
      return query.where('managedBy', '==', this.service.currentFireauthUser.uid);
    }
    if (this.isOrgAdmin())
    { 
      return  query.where('organizationAdmins', 'array-contains', this.service.currentFireauthUser.uid);
    }
  }

  public trimFirestoreUserQuery(query: Query|CollectionReference): Query {
    if (this.isUserTeamAdmin()) {
      return query.where('managedBy' , '==', this.service.currentFireauthUser.uid);
    }
    if (this.isOrgAdmin())
    { 
      return query.where("organizationId","in", this.orgsUserManages);
    }
    return query;
  }

  public async updateUser(): Promise<void> {
    await super.updateUser();
    if (
      ! this.service.currentUserDocument.hasOwnProperty("billing") || 
      ! this.service.currentUserDocument.hasOwnProperty("email") 
    ) {
      throw new Error("Incomplete user profile")
    }
  }

  public usePrivateBackendForUserOperations(): boolean {
    return false;
  }

  // #endregion Public Methods (43)

  // #region Private Methods (5)

  private billingPlanIncludes(planName: string): boolean {
    return this.service.currentUserDocument.hasOwnProperty("billing") &&
      this.service.currentUserDocument.billing.hasOwnProperty("plan") &&
      this.service.currentUserDocument.billing.plan.includes(planName);
  }

  private billingStatusIncludes(status: string): boolean {
    return this.service.currentUserDocument.hasOwnProperty("billing") &&
      this.service.currentUserDocument.billing.hasOwnProperty("status") &&
      this.service.currentUserDocument.billing.status.includes(status);
  }

  private getBillingPlanString() { 
    return this.service.currentUserDocument.hasOwnProperty("billing") &&
      this.service.currentUserDocument.billing.hasOwnProperty("plan") ?
      this.service.currentUserDocument.billing.plan :
      "No Billing Plan"
  }

  private isUserTeamAdmin(): boolean {
    return this.billingPlanIncludes("retail-teams") && this.service.currentFireauthUser.uid == this.service.currentUserDocument.managedBy;
  }

  private isUserTeamsOrPro(): boolean {
    return this.billingPlanIncludes("retail-pro") || this.billingPlanIncludes("retail-teams");
  }

  // #endregion Private Methods (5)
}