import { CSSColorProperty, CSSThemeProperty } from "./theme.service.types";
import { TinyColor } from '@ctrl/tinycolor';

/**
 * Each of these properties must be consumed in the `src\styles.less` file of this project
 * using the `var(--<var name>)` LESS syntax.
 */
 export const CSSThemeProperties = [
  {
    name: "ant-primary-color",
    zorroThemeName: "primaryColor",
    cssType: CSSColorProperty,
    displayName: "Primary Color",
    extra: "Primary color; used for primary buttons, block headers, and some text",
    legacyCustomName: "@custom-primary",
    defaultValue: ()=> "#1890ff"
  },
  { 
    name:"txb-primary-color-contrast",
    cssType: CSSColorProperty,
    displayName: "Primary Color Contrast",
    extra: "Used in places where the primary color is the background to ensure text is readable",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("ant-primary-color"))
    }
  },
  { 
    name:"txb-primary-color-contrast-hover",
    cssType: CSSColorProperty,
    displayName: "Primary Color Contrast Hover",
    extra: "",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("ant-primary-color"),false)
     }
  },
  {
    name:"txb-backdrop-color",
    cssType: CSSColorProperty,
    displayName: "Backdrop Color",
    extra:"Color displayed behind UI Panels",
    defaultValue:()=> "#eee",
    generateDebug: false
  },
  // #region sider
  { 
    name:"txb-layout-sider-background",
    cssType: CSSColorProperty,
    displayName: "Sider Background",
    extra: "Background color of the sider",
    legacyCustomName: "@custom-sider-background",
    defaultValue: ()=>"#001529"
  },
  { 
    name:"txb-layout-sider-foreground",
    cssType: CSSColorProperty,
    displayName: "Sider Foreground color",
    extra: "foreground color for text on the sider",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-layout-sider-background"))
     }
  },
  { 
    name:"txb-layout-sider-foreground-hover",
    cssType: CSSColorProperty,
    displayName: "Sider Foreground color Hover",
    extra: "foreground color for text on the sider",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-layout-sider-background"),false)
    }
  },
  { 
    name:"txb-layout-sider-selected-foreground",
    cssType: CSSColorProperty,
    displayName: "Sider Selected Foreground color",
    extra: "Foreground color for text on the sider",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("txb-primary-color-contrast")
    }
  },
  { 
    name:"txb-layout-sider-selected-foreground-hover",
    cssType: CSSColorProperty,
    displayName: "Sider Selected Foreground color hover",
    extra: "Foreground color for text on the sider",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("txb-primary-color-contrast-hover")
    }
  },
  {
    name: "txb-layout-sider-submenu-bg",
    cssType: CSSColorProperty,
    displayName: "Sider Sub Menu Background",
    extra: "Color of sub menu item on Sider menu",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return makeSubmenuBGColor(getPropertyValue("txb-layout-sider-background"))
    }
  },
  {
    name: "txb-layout-sider-logout-bg",
    cssType: CSSColorProperty,
    displayName: "Sider Logout Background",
    extra: "Color of sub menu item on Sider menu",
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("ant-primary-color")
    }
  },
  {
    name: "txb-layout-sider-logout-bg-hover",
    cssType: CSSColorProperty,
    displayName: "Sider Logout Background Hover",
    extra: "Color of sub menu item on Sider menu",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return makeBGHoverColor(getPropertyValue("txb-layout-sider-logout-bg"))
    }
  },
  {
    name: "txb-layout-sider-logout-fg",
    cssType: CSSColorProperty,
    displayName: "Sider Logout Foreground",
    extra: "Color of sub menu item on Sider menu",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-layout-sider-logout-bg"))
    }
  },
  {
    name: "txb-layout-sider-logout-fg-hover",
    cssType: CSSColorProperty,
    displayName: "Sider Logout Foreground Hover",
    extra: "Color of sub menu item on Sider menu",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-layout-sider-logout-bg"), false)
    }
  },
  // #endregion sider
  {
    name: "txb-header-bg",
    cssType: CSSColorProperty,
    displayName: "Background color for modal and drawer headers",
    extra: "",
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("ant-primary-color")
    }
  },
  {
    name: "txb-header-fg",
    cssType: CSSColorProperty,
    displayName: "Foreground color for modal and drawer headers",
    extra: "",
    legacyCustomName: "",
    generateDebug: false,
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-header-bg"),false)
     }
  },
  // #region messages
  {
    name: "txb-message-out-fg",
    cssType: CSSColorProperty,
    displayName: "Foreground color for outbound messages",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
     return bestContrastGreyscale(getPropertyValue("txb-message-out-bg"),false)
    }
  },
  {
    name: "txb-message-out-link",
    cssType: CSSColorProperty,
    displayName: "Foreground color for outbound message hyperlinks",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return makeHyperlinkColor(getPropertyValue("txb-message-out-fg"));
    }
  },
  {
    name: "txb-message-out-bg",
    cssType: CSSColorProperty,
    displayName: "Background color for outbound messages",
    extra: "",
    legacyCustomName: "",
    defaultValue: ({getPropertyValue, getPropertySource})=>{
      const value = getPropertyValue("ant-primary-color");
      if (getPropertySource("ant-primary-color") == "legacyTheme" || getPropertySource("ant-primary-color") == "calculated") {
        return "#39aefc"
      }
      const hsl = new TinyColor(value).toHsl()
      hsl.s = mapRange(0,1,.2,.3,hsl.s)
      hsl.l = mapRange(0,1,.4,.5,hsl.l)
      const hover = new TinyColor(hsl).toHexString();
      return hover
    }
  },
  {
    name: "txb-message-in-fg",
    cssType: CSSColorProperty,
    displayName: "Foreground color for inbound messages",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-message-in-bg"), false);
    }
  },
  {
    name: "txb-message-in-link",
    cssType: CSSColorProperty,
    displayName: "Foreground color for inbound message hyperlinks",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return makeHyperlinkColor(getPropertyValue("txb-message-in-fg"));
    }
  },
  {
    name: "txb-message-in-bg",
    cssType: CSSColorProperty,
    displayName: "Background color for inbound messages",
    extra: "",
    legacyCustomName: "",
    defaultValue: ({getPropertyValue, getPropertySource})=>{
      const value = getPropertyValue("ant-primary-color");
      if (getPropertySource("ant-primary-color") == "legacyTheme" || getPropertySource("ant-primary-color") == "calculated") {
        return "#dedede"
      }
      const hsl = new TinyColor(value).spin(180).toHsl()
      hsl.s = mapRange(0,1,.2,.3,hsl.s)
      hsl.l = mapRange(0,1,.6,.7,hsl.l)
      const hover = new TinyColor(hsl).toHexString();
      return hover
    }
  },
  // #endregion messages
  // #region list items
  {
    name: "txb-list-item-active-bg",
    cssType: CSSColorProperty,
    displayName: "Background color for active list items",
    extra: "",
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("ant-primary-color");
    }
  },
  {
    name: "txb-list-item-active-fg",
    cssType: CSSColorProperty,
    displayName: "Foreground color for active list items",
    extra: "",
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("txb-primary-color-contrast");
    }
  },
  {
    name: "txb-list-item-active-bg-hover",
    cssType: CSSColorProperty,
    displayName: "Background hover color for active list items",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return makeBGHoverColor(getPropertyValue("txb-list-item-active-bg"));
    }
  },
  {
    name: "txb-list-item-active-fg-hover",
    cssType: CSSColorProperty,
    displayName: "Foreground hover color for active list items",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-list-item-active-bg-hover"));
    }
  },
  {
    name: "txb-item-hover-bg",
    cssType: CSSColorProperty,
    displayName: "Background color for list items",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return makeBGHoverColor(getPropertyValue("ant-primary-color"))
    }
  },
  {
    name: "txb-item-hover-fg",
    cssType: CSSColorProperty,
    displayName: "Background color for hovering list items",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-item-hover-bg"))
    }
  },
  {
    name: "txb-phone-number-fg",
    cssType: CSSColorProperty,
    displayName: "Foreground color for inline phone numbers",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("txb-primary-color-contrast");
    }
  },
  {
    name: "txb-phone-number-bg",
    cssType: CSSColorProperty,
    displayName: "Background color for inline phone numbers",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("ant-primary-color")
    }
  },
  {
    name: "txb-phone-number-border",
    cssType: CSSColorProperty,
    displayName: "Border color for inline phone numbers",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return bestContrastGreyscale(getPropertyValue("txb-phone-number-bg"))
    }
  },
  {
    name: "txb-phone-number-fg-hover",
    cssType: CSSColorProperty,
    displayName: "Foreground color for inline phone numbers",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return getPropertyValue("txb-primary-color-contrast-hover")
    }
  },
  {
    name: "txb-phone-number-bg-hover",
    cssType: CSSColorProperty,
    displayName: "Background color for inline phone numbers",
    extra: "",
    generateDebug: false,
    legacyCustomName: "",
    defaultValue: ({getPropertyValue})=>{
      return makeBGHoverColor(getPropertyValue("txb-phone-number-fg-hover"))
    }
  }
  // #endregion list items
] as CSSThemeProperty []


/**
 * Maps an input number placed in a reference range to a number in a map range
 * 
 * e.g.  with a reference range of 0-100  and a map range of 0-10, the input 55 would translate to 5.5
 * 
 */
 const mapRange = (referenceRangeLow:number, referenceRangeHigh:number, mapRangeLow: number, mapRangeHigh: number,  input:number ): number =>{
  const proportion = input/(referenceRangeHigh-referenceRangeLow) 
  return mapRangeLow + ((mapRangeHigh-mapRangeLow) * proportion)
}

/**
 * Calculates the "best contrasting extreme grey" given a base color
 * for cases where we need text to be readable on a background of arbitrary color
 * 
 * for colors which are "bright", this returns black.
 * for colors which are "dark", this returns white
 *  * 
 * @param baseColor 
 * @returns 
 */
const bestContrastGreyscale = (baseColor, reserveHighlight=true) => {
  const hsl = new TinyColor(baseColor).toHsl()
  hsl.s = 0;
  const light_threshold = 0.55
  if (reserveHighlight) {
    hsl.l = (hsl.l < light_threshold ) ? .9 : .1; 
  }
  else {
    hsl.l = (hsl.l < light_threshold ) ? 1 : 0; 
  }
  const fg = new TinyColor(hsl).toHexString();
  return fg
}

/**
 * Lightens or darkens a base color so it will look like a "hyperlink"
 * @param baseColor 
 * @returns 
 */
const makeHyperlinkColor = (baseColor) => {
  const offset = 0.4
  const hsl = new TinyColor(baseColor).toHsl()
  hsl.s = 0;
  hsl.l = (hsl.l < 0.5 ) ? (hsl.l + offset) : (hsl.l - offset); 
  const fg = new TinyColor(hsl).toHexString();
  return fg
}

const makeBGHoverColor = (baseColor) => {
  const hsl = new TinyColor(baseColor).toHsl()
  hsl.s = mapRange(0,1,0,.64,hsl.s)
  const min_l_offset = 0.3
  hsl.l = Math.max(hsl.l, min_l_offset) // Ensure we get some lightening
  hsl.l = Math.min(hsl.l, 1-min_l_offset) // ensure we get some darkening
  hsl.l = mapRange(0,.6,0,1,hsl.l)
  const hover = new TinyColor(hsl).toHexString();
  return hover
}

const makeSubmenuBGColor = (baseColor) => {
  const hsl = new TinyColor(baseColor).toHsl()
  hsl.s = mapRange(0,1,0,.64,hsl.s)
  const amount = 0.2
  const shouldLighten = (hsl.l <= 0.25) || (hsl.l !<= 0.75)
  hsl.l = shouldLighten ? (hsl.l + amount) : (hsl.l - amount);
  const hover = new TinyColor(hsl).toHexString();
  return hover
}