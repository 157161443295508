import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as log from 'loglevel';
import { IntegrationDefinition, IntegrationInstallation, IntegrationInstallationType, IntegrationService } from '../../services/integration.service';
import { JsonFormControlComponent, JSONFormControlFieldDefinition } from '../json-form-control/json-form-control.component';

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss']
})
export class IntegrationSettingsComponent implements OnInit {

  /**
   * Title to show for this card;
   * 
   * Varies whether we're showing an installed integration, or an installation option
   */
  public IntegrationName: string

  @Output() integrationChangedEvent = new EventEmitter<void>();

  public installationResult: {
    color: string
    message: string
  }
  private _integration: IntegrationDefinition|IntegrationInstallation

  @Input() mode: "Available"|"Installed"
  @Input()
    get integration(): IntegrationDefinition|IntegrationInstallation {
      return this._integration
    }
    set integration(i: IntegrationDefinition|IntegrationInstallation) {
      this._integration = i;
      if ("installationTypes" in i) {
        // This is an available integration; which means the user may choose to install it
        this.integrationDefinition = i;
        this.IntegrationName = this.integrationDefinition.name
        this.installationOptionForm = new FormGroup({
          "installationType": new FormControl(null,[]),
          "InstallationName": new FormControl("",[])
        })

      }
      else {
        // This is an installed integration, so the user may be able to edit it
        this.integrationDefinition = i.definition
        this.integrationInstallation = i
        this.IntegrationName = this.integrationInstallation.InstallationName

        this.installationOptionForm = new FormGroup({
          "InstallationName": new FormControl(this.integrationInstallation.InstallationName,[])
        })
        
        this.selectedInstallationType = this.integrationDefinition.installationTypes
            .find(it=>it.InstallationBaseType == this.integrationInstallation.InstallationBaseType)
      }
    }

  public integrationDefinition: IntegrationDefinition
  public integrationInstallation: IntegrationInstallation

  public selectedInstallationType: IntegrationInstallationType;
  view: "List" | "Install" | "Settings"

  isOkLoading = false;

  public installationOptionForm: FormGroup;
  public guideVisibleBool: boolean = false;
 
  constructor(
      public integrationService: IntegrationService
    ) { 
    this.view = "List";
  }

  ngOnInit() {
    
  }

  /**
   * Registers the callback function for the specified button.
   * 
   * @param f 
   */
  private setupClientSideButton(f: Extract<JSONFormControlFieldDefinition, {type: "button"}>) { 
    f.callback = async ()=>{
      try {
        this.installationResult = await this.integrationService.InstallOAuthIntegration(f.url, this.selectedInstallationType.name)
        setTimeout(() => {
          this.toggleModal();
          this.integrationChangedEvent.emit();
          this.isOkLoading = false;
        }, 1500);
      }
      catch (err) {
        this.installationResult = {
          message: "There was an error installing the integration: " +err.error,
          color: "red"
        }
      }
    }
  }


  public changeSelectedForm() { 
    const newOption = this.installationOptionForm.value['installationType'];
    this.selectedInstallationType = this.integrationDefinition.installationTypes
      .find(io=>io.name == newOption)

    this.installationOptionForm.get("InstallationName").setValidators(this.selectedInstallationType.allowUserDefinedName ? Validators.required : []);
    for (let f of this.selectedInstallationType.fields as JSONFormControlFieldDefinition[]) {
      if (f.type == "button" && f.mode == "client-side-function") {
        this.setupClientSideButton(f);
      }
    }

  }

  public async uninstall() {
    try {
      await this.integrationService.UninstallIntegration(this.integrationInstallation.platform, this.integrationInstallation.InstallationId)
      this.integrationChangedEvent.emit();
    }
    catch(err) {
      log.warn(err);
    }
  }

  toggleModal() {
    log.debug("toggling");
    if (this.view != "List") {
      this.view = "List"
    }
    else {
      this.view = this.mode == "Available" ? "Install" : "Settings"
      if(this.mode == "Available") {
        if (this.integrationDefinition.installationTypes.length == 1) {
          this.selectedInstallationType = this.integrationDefinition.installationTypes[0]
          this.installationOptionForm.get("installationType").setValue(this.selectedInstallationType.name)
          this.changeSelectedForm();
        }
      }
    }
  }

  async handleInstallationOk() {

    const installationRequest = { 
      InstallationBaseType: this.selectedInstallationType.InstallationBaseType, 
      ...this.installationOptionForm.value
    }
    log.debug("Installation Request", installationRequest);

    try {
      await this.integrationService.InstallIntegration( this.integrationDefinition.id, installationRequest)
      setTimeout(() => {
        this.toggleModal();
        this.integrationChangedEvent.emit();
        this.isOkLoading = false;
      }, 3000);
    }
    catch (err) {
      log.warn(err);
    }

    this.isOkLoading = true;
    
  }

  async handleSettingsOk() {
    const updateRequest = { 
      ...this.installationOptionForm.value
    }
    log.debug("Update Request", updateRequest);
    try {
      await this.integrationService.UpdateIntegration(
        this.integrationInstallation.platform, 
        this.integrationInstallation.InstallationId, 
        updateRequest)
      setTimeout(() => {
        this.toggleModal();
        this.integrationChangedEvent.emit();
        this.isOkLoading = false;
      }, 3000);
    }
    catch (err) {
      log.warn(err);
    }

    this.isOkLoading = true;
  }

}


@Component({
  selector: 'integration-installation-form',
  templateUrl: '../json-form-control/json-form-control.component.html',
  styleUrls: ['../json-form-control/json-form-control.component.scss']
})
export class IntegrationInstallationFormControlComponent extends JsonFormControlComponent implements OnInit {

}
