import { Component, OnInit } from '@angular/core';
import * as log from 'loglevel';
import { FrontendAdminTool, AdminToolsService } from '../../services/admin-tools.service';

@Component({
  selector: 'app-admin-tools',
  templateUrl: './admin-tools.component.html',
  styleUrls: ['./admin-tools.component.scss'],
  host: {
    "class": "app-view app-view-two-pane"
  }
})
export class AdminToolsComponent implements OnInit {

  public adminTools: FrontendAdminTool[] = [];
  public activeTool: FrontendAdminTool;

  constructor(
    private adminToolsService: AdminToolsService
  ) { }

  ngOnInit() {
    this.adminToolsService.GetTools().then((tools)=>{
      this.adminTools = tools
    })

  }

  setActiveTool(tool: FrontendAdminTool) {
    this.activeTool = tool
  }

}
