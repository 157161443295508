import { Injectable } from '@angular/core';
import { getLogger } from '@shared/logging';

const log = getLogger("KeypressService")

@Injectable({
  providedIn: 'root'
})
export class KeypressserviceService {

  /**
   * Array of keycodes which are currently "pressed"
   */
  private keysDown = [];

  /**
   * In some cases we may want to prevent the browser from showing a selection indicator 
   * after clicking in a document
   */
  private hideSelection = false;

  constructor() { 
    
    document.addEventListener('keydown',(ev)=> {
      if (!this.keysDown.includes(ev.keyCode)) {
        this.keysDown.push(ev.keyCode);
      }
      if (ev.keyCode == 16 && this.hideSelection) {
        /**
         * In cases where we want to detect a shift key, we also want to prevent shift-range clicking; 
         * since shift key detection is for "extra" behavior on a click.
         * 
         * this might be better https://stackoverflow.com/a/54101500/11125318
         */
        document.getSelection().removeAllRanges();
      }
    });
    document.addEventListener('keyup',(ev)=> {
      if (this.keysDown.includes(ev.keyCode)) {
        this.keysDown = this.keysDown.filter(i => i != ev.keyCode);
      }
    });
  }

  /**
   * Can be called at any time to determine whether the 
   * shift key is currently being pressed.
   * 
   * @returns 
   */
  public isShiftKeyDown() {
    return this.keysDown.includes(16);
  }

  /**
   * If a click is expected (i.e. the user is hovering over something clickable), then
   * we want to prevent the browser from selecting the range
   */
  public PreventSelection(hide: boolean) {
    this.hideSelection = hide
    log.debug("hide selection", this.hideSelection)
  }

}
