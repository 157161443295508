<ng-container *ngIf="(contactDTO.consentRequirement | async)?.sendRequired && page == 'contacts'">
  <nz-alert nzType="warning" [nzMessage]="consentMessageTemplate" nzShowIcon nzBanner nzShowIcon="false"></nz-alert>
</ng-container>

<ng-container *ngIf="(contactDTO.consentRequirement | async)?.sendRequired && page == 'conversations'">
  <i class="fas fa-info-square" style="color: #ffa217 ;font-size: 18px" nz-tooltip [nzTooltipTitle]="consentMessageTemplate"></i>
  
</ng-container>

<ng-template #consentMessageTemplate>
    <span>This contact has not yet received a consent message as required by your administrator.  One will automatically be sent along with the next message:<br/><br/>
    <b>{{ (contactDTO.consentRequirement | async)?.message }}</b><br/>
  </span>
</ng-template>