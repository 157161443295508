<nz-modal [(nzVisible)]="isVisible" [nzTitle]="tplTitle" [nzContent]="tplContent" [nzStyle]="{ top: '6px' }"
  nzWidth="75%" [nzMaskClosable]="isClosable" [nzClosable]="isClosable" [nzFooter]="null" (nzOnCancel)="hideModal()">
  <ng-template #tplTitle>
    <nz-row>
      <nz-col nzSpan="20">
          <span>Textable Plans</span>
      </nz-col>
      <nz-col nzSpan="4">
        <!-- Button top right hand-->
        <ng-container *ngIf="userProfile?.billing?.status == 'None' || userProfile?.billing?.status == 'Aborted' || userProfile?.billing?.status == 'Cancelled'">
            <button  *ngIf="!isLoggingOut" style="width:100%" nz-button nzType="default" (click)="logout()">Logout</button>
            <button  *ngIf="isLoggingOut" style="width:100%" nz-button nzType="default"[nzLoading]="isLoggingOut" >Logging Out...</button>
        </ng-container>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #tplContent>
    <!-- USE ENUMS LATER-->
    <ng-container *ngIf="userProfile?.billing?.status == 'None'">
      <p class="summary">To start using Textable, please select a plan. All accounts come with a <b>7 day trial period</b>. Billing will automatically begin after the trial period.
        <br><small>*Checkout Process may take some time, please wait for up to <b>10 minutes</b> before contacting support.</small>
      </p>
    </ng-container>
    <ng-container *ngIf="userProfile?.billing?.status == 'PastDue'">
      <p class="summary"><b>Your subscription is past due.</b> To continue using Textable, please verify billing information</p>
    </ng-container>
    <ng-container *ngIf="userProfile?.billing?.status == 'Cancelled'">
      <p class="summary"><b>Your subscription has been cancelled.</b> To continue using Textable, please verify billing information</p>
    </ng-container>
    <ng-container *ngIf="userProfile?.billing?.status == 'Aborted'">
      <p class="summary"><b>Your subscription has expired.</b> To continue using Textable, please verify billing information</p>
    </ng-container>
    <div class="plans">
      <ng-container *ngIf="!hasFailed;else failedBlock">
      <div nz-row nzGutter="8" nzJustify="space-evenly">
        <ng-container *ngIf="!isLoading;else loadingBlock">
          <!--TEAM CONTAINER-->
          <ng-container *ngIf="(userProfile?.billing?.plan == 'retail-teams');else notTeams">
            <!--- TEAM ADMIN CONTAINER ONLY-->
            <ng-container *ngIf="(userProfile?.managedBy == userProfile?.uid);else notAdmin">
                <div nz-col nzSpan="22" nzOffset="1">
                    <nz-card class="planCard team">
                      <h3 class="whitesmoke">Team</h3>
                            <p>To continue using Textable Teams for all team users, please verify billing information.</p><br>

                      <button (click)="goToCheckout('retail-teams')" nzType="primary" class="buttons" nz-button nzBlock>Resubscribe to
                        Teams</button>
                    </nz-card>
                  </div>
            </ng-container>
            <ng-template #notAdmin> 
                <div nz-col nzSpan="16" nzOffset="4">
                    <p class="summary">Please contact your Team Administrator about your subscription.</p>
                </div>
            </ng-template>
          </ng-container>

          <!-- The template-->
          <ng-template #notTeams>
              <div nz-col  nzFlex="0 1 320px" >
                  <nz-card class="planCard">
                    <h3>Basic</h3>
                    <span style="font-size:36px">$5</span><span> / mo.</span><br><br>
                    <div class="planSummary">
                      <p><small>The Basic account is perfect for personal or light business use where automations and
                          marketing features are not needed. Basic accounts are currently limited to a single number /
                          login.</small></p>
                    </div>
                    <nz-divider nzText="Includes"></nz-divider>
                    <p>SMS / MMS Messaging</p>
                    <p>Canned Responses</p>
                    <p>Away Messages</p>
                    <button (click)="goToCheckout('retail-basic')" nzType="primary" class="buttons" nz-button nzBlock>Start Trial</button>
                  </nz-card>
                </div>
      
                <div nz-col  nzFlex="0 1 320px" >
                  <nz-card class="planCard pro">
                    <h3 class="whitesmoke">Pro</h3>
                    <span style="font-size:36px;color:whitesmoke">$30</span><span style="color: white"> / mo.</span><br><br>
                    <div class="planSummary">
                      <p><small>The Professional account is perfect for everyday business use and includes time-saving
                          features and automations. Professional accounts are currently limited to a single number /
                          login.</small></p>
                    </div>
                    <nz-divider [nzText]="proIncludesText"></nz-divider>
                    <ng-template #proIncludesText>
                      <span class="whitesmoke">Includes Basic +</span>
                    </ng-template>
                    <p>Drip Campaigns</p>
                    <p>Blasts <small>(Marketing Campaigns)</small></p>
                    <p>Reminders <small>(Scheduled Messages)</small></p>
                    <button (click)="goToCheckout('retail-pro')" class="buttons" nz-button nzBlock>Start Trial</button>
                  </nz-card>
                </div>
      
                <div nz-col  nzFlex="0 1 320px">
                  <nz-card class="planCard team">
                    <h3 class="whitesmoke">Team</h3>
                    <span style="font-size:36px;color: whitesmoke">$75</span><span> / mo.</span><br><br>
                    <div class="planSummary">
                      <p><small>The Team account is perfect for businesses of all sizes and resellers of VoIP services who
                          wish to manage multiple accounts from a single place. Additional users are $7.50/mo.</small></p>
                    </div>
                    <nz-divider [nzText]="teamIncludesText"></nz-divider>
                    <ng-template #teamIncludesText>
                      <span class="whitesmoke">Includes Pro +</span>
                    </ng-template>
                    <p>10 User / Number Licenses</p>
                    <p>Number Sharing</p>
                    <p>Per-Number Permissions</p>
                    <button (click)="goToCheckout('retail-teams')" nzType="primary" class="buttons" nz-button nzBlock>Start Trial</button>
                  </nz-card>
                </div>
          </ng-template>
        </ng-container>
        <ng-template #loadingBlock>
            <div nz-col nzSpan="24" style="height: 65%">
                <nz-spin style="text-align: center;position: relative;
                top: 40%;" nzSimple [nzSize]="'large'" nzTip="Loading Billing..."></nz-spin>
            </div>
        </ng-template>
      </div>
      </ng-container>
      <ng-template #failedBlock>
          <nz-alert
          nzType="error"
          nzMessage="Error"
          [nzDescription]="errorAlert"
        >
        </nz-alert>
        <ng-template #errorAlert>
          <b>Unable to configure billing</b><br><br>
          Please try again at a later time.
        </ng-template>      
      </ng-template>
    </div>
  </ng-template>
</nz-modal>