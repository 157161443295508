<nz-card [nzExtra]="extraTemplate" [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div nz-row>
    <div nz-col class="left-pane">
      <div class="convos">
        <div nz-row class="convo-toolbar">
          <!-- Checkbox and Count-->
          <div nz-col nzFlex="2">
            <label nz-checkbox (ngModelChange)="updateAllChecked.next($event)" [ngModel]="allChecked"
              [nzIndeterminate]="indeterminate">
              <span *ngIf="numberOfChecked > 0">{{numberOfChecked}}</span>
            </label>
          </div>
          <div nz-col nzFlex="17">
            <form nz-form nzLayout="vertical" [formGroup]="filterFormGroup">
              <div nz-row>
                <div nz-col nzFlex="20">
                  <nz-form-item>
                    <nz-input-group [nzSuffix]="inputClearTpl">
                      <input type="text" nz-input placeholder="Search conversations" formControlName="searchText" />
                    </nz-input-group>
                    <ng-template #inputClearTpl>
                      <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="filterFormGroup.get('searchText')?.value?.length > 0"
                        (click)="filterFormGroup.get('searchText').reset()"></i>
                    </ng-template>
                  </nz-form-item>
                </div>
                <div nz-col nzFlex="4">
                  <nz-form-item>
                    <button nz-button nzType="primary" nz-dropdown nzTrigger="click" [nzDropdownMenu]="filterMenu">
                      <span nz-icon nzType="filter" nzTheme="outline"></span>
                    </button>
                    <nz-dropdown-menu #filterMenu="nzDropdownMenu">
                      <div nz-col class="additionalFiltersDropdown" nz-menu>
                        <div nz-row>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="sortBy">Sort By</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                              <nz-radio-group formControlName="sortBy">
                                <label nz-radio-button nzValue="date"><span>Date</span></label>
                                <label nz-radio-button nzValue="name"><span>Name</span></label>
                                <label nz-radio-button nzValue="number"><span>Number</span></label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-row>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="readStatus">Read Status</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                              <nz-radio-group formControlName="readStatus">
                                <label nz-radio-button nzValue="unread"><span>Unread</span></label>
                                <label nz-radio-button nzValue="read"><span>Read</span></label>
                                <label nz-radio-button nzValue="all"><span>All</span></label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-row>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="archiveStatus">Archive Status</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                              <nz-radio-group formControlName="archiveStatus">
                                <label nz-radio-button nzValue="unarchived"><span>Unarchived</span></label>
                                <label nz-radio-button nzValue="archived"><span>Archived</span></label>
                                <label nz-radio-button nzValue="all"><span>All</span></label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-row>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="blockStatus">Blocked Status</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                              <nz-radio-group formControlName="blockStatus">
                                <label nz-radio-button nzValue="unblocked"><span>Unblocked</span></label>
                                <label nz-radio-button nzValue="blocked"><span>Blocked</span></label>
                                <label nz-radio-button nzValue="all"><span>All</span></label>
                              </nz-radio-group>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-row>
                          <nz-form-item>
                            <nz-form-label [nzSpan]="24" nzFor="unknownDate">Include Unknown Date</nz-form-label>
                            <nz-form-control [nzSpan]="24">
                              <nz-switch formControlName="unknownDate"></nz-switch > 
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-row>
                          <div nz-col [nzSpan]="24">
                            <button nz-button nzType="primary" (click)="resetFilters()">
                              Reset Filters
                            </button>
                          </div>
                        </div>
                      </div>
                    </nz-dropdown-menu>
                  </nz-form-item>
                </div>
              </div>
            </form>
          </div>
          <div nz-col nzFlex="5" class="flex-end">
            <nz-button-group>
              <button 
                nz-button nzType="default" 
                nz-tooltip nzTooltipTitle="Bulk Archive"
                nz-popconfirm nzPopconfirmTitle="Are you sure you want to archive these conversations?"
                (nzOnConfirm)="bulkArchive()"
                [disabled]="numberOfChecked == 0">
                <i class="fas fa-archive"></i>
              </button>
              <button 
                nz-button nzType="default" 
                nz-tooltip nzTooltipTitle="Bulk Unarchive"
                nz-popconfirm nzPopconfirmTitle="Are you sure you want to unarchive these conversations?"
                (nzOnConfirm)="bulkUnarchive()"
                [disabled]="numberOfChecked == 0">
                <i class="fas fas fa-box-open"></i>
              </button>
              <button 
                nz-button nzType="default" 
                nz-tooltip nzTooltipTitle="Bulk Mark Unread" 
                nz-popconfirm nzPopconfirmTitle="Are you sure you want to mark these conversations unread?"
                (nzOnConfirm)="bulkMarkUnread()"
                [disabled]="numberOfChecked == 0">
                <i class="fas fa-inbox-out"></i>
              </button>
              <app-sender-blocker
                [bulk]="true"
                [sender]="checkedContactIDs | async"
                ></app-sender-blocker>
            </nz-button-group>
          </div>
        </div>
        <div class="convo-list txb-list" *ngIf="(displayConversationList | async) as displayList">
          <ng-container *ngIf="false && spotlightConversation && !displayList.contacts.includes(spotlightConversation)">
            <ng-container *ngTemplateOutlet="conversationDTOTemplate; context: {$implicit: spotlightConversation, isSpotlight: true, index: -1}"></ng-container>
          </ng-container>
          <ng-container *ngIf="displayList.displayType == 'loading'">
            <div class="text-center"><br>
              <h3>Loading Conversations</h3>
              <small>Tip: If this takes too long, archive some of your older conversations</small>
            </div>
          </ng-container>
          <ng-container *ngIf="displayList.displayType == 'empty'">
            <div class="text-center"><br>
              <h3>No Active Conversations</h3>
              <small>Tip: Start a conversation with someone new today!</small>
            </div>
          </ng-container>
          <ng-container *ngIf="displayList.displayType == 'no-results'">
            <div class="text-center"><br>
              <h3>No Search Results</h3>
              <small>Tip: Try modifying your search text and/or filters to find new results.</small>
              <ng-container *ngIf="contactService.hasMoreContacts"> 
                <button (click)="contactService.LoadContactsWithModal('all')" nz-button nzType="default">Load more (this could take a while)</button>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngFor="let conversationDTO of displayList.contacts; trackBy: trackByDocumentId; let index = index">
            <ng-container *ngTemplateOutlet="conversationDTOTemplate; context: {$implicit: conversationDTO, index: index}"></ng-container>
          </ng-container>
        </div>
        <div class="convo-pagination">
          <div nz-col>
            <nz-pagination (nzPageIndexChange)="conversationListPageIndex.next($event)" [nzPageSize]="conversationListPageSize" [nzTotal]="conversationListSize" nzSimple ></nz-pagination>
          </div>
        </div>
      </div>
    </div>
    <div div nz-col class="right-pane" #rightPane>
      <ng-container *ngIf="activeConversation; else selectConversation;">
        <div class="conversation-header">
          <div nz-row>
            <div nz-col nzSpan="8">
              <div>
                <a [routerLink]="['/contacts', activeConversation.contact.id]" routerLinkActive="router-link-active">{{activeConversation.contact.full_name}}</a>
                &nbsp;<app-opted-out-banner [contact]="activeConversation.contact" [page]="'conversations'"></app-opted-out-banner>
                &nbsp;<app-consent-message-banner [contact]="activeConversation" [page]="'conversations'" ></app-consent-message-banner>
                &nbsp;<app-integration-message-banner [contact]="activeConversation.contact" [page]="'conversations'" ></app-integration-message-banner>
                <br/>
                <small>{{activeConversation.contact.phone_number}}</small>
              </div>
            </div>
            <div nz-col nzSpan="8">
            </div>
            <div nz-col nzSpan="8" class="flex-end">

              <nz-button-group class="action-button-group">
                <button *ngIf="!activeConversation.isArchived" nz-button nzType="default" nz-tooltip nzTooltipTitle="Archive"
                  nz-popconfirm nzPopconfirmTitle="Are you sure you want to archive this conversation?"
                  (nzOnConfirm)="archiveChat(activeConversation)">
                  <i class="fas fa-archive"></i>
                </button>
                <button *ngIf="activeConversation.isArchived" nz-button nzType="default" nz-tooltip
                  nzTooltipTitle="Unarchive" nz-popconfirm nzPopconfirmTitle="Are you sure you want to unarchive this conversation?"
                  (nzOnConfirm)="unarchiveChat(activeConversation)">
                  <i class="fas fa-box-open"></i>
                </button>
                <button *ngIf="(activeConversation.readStatus | async).isRead" nz-button nzType="default" nz-tooltip
                  nzTooltipTitle="Mark Unread" nz-popconfirm
                  nzPopconfirmTitle="Are you sure you want to mark this conversation as unread?"
                  (nzOnConfirm)="markChatUnread(activeConversation)">
                  <i class="fas fa-inbox-out"></i>
                </button>
                <button *ngIf="!activeConversation.isArchived && this.authnz.currentSecurityProvider.canDeleteContacts()" nz-button nzType="default" nz-tooltip
                  nzTooltipTitle="Delete History" nz-popconfirm
                  nzPopconfirmTitle="Are you sure you want to delete this conversation? This CANNOT be undone."
                  (nzOnConfirm)="deleteChat(activeConversation)">
                  <i class="fas fa-trash"></i>
                </button>
                <app-sender-blocker
                  [sender]="activeConversation"
                  ></app-sender-blocker>
              </nz-button-group>

                <button class="add-note-button" nz-button nzType="info" nz-tooltip nzTooltipTitle="Add Note" (click)="openAddNoteModal()"> <i class="far fa-sticky-note"></i> Add Note</button>
                <nz-modal [(nzVisible)]="addNotesModal" nzTitle="Add Note" (nzOnCancel)="handleCancel()" [nzFooter]="addNotesFooter" >
                  <ng-container *nzModalContent>
                    <form nz-form nzLayout="vertical" [formGroup]="frmAddNotes">
                        <nz-form-item>
                          <nz-form-label>Note</nz-form-label>
                          <nz-form-control nzErrorTip="Note message is required and must be more than 2 characters">
                            <textarea style="width: 100%" nz-input formControlName="body" placeholder="Notes..." rows="3"></textarea>
                          </nz-form-control>

                        </nz-form-item>
                      </form>
                    </ng-container>
                </nz-modal>
                <ng-template #addNotesFooter>
                  <button nzBlock nz-button nzType="primary" (click)="addNote()" [disabled]="!frmAddNotes.valid">Save</button>
              </ng-template>

                <button *ngIf="this.authnz.currentSecurityProvider.canExportConversations()" nz-button nzType="info" (click)="openExportModal()"><i class="far fa-file-export"></i> Export</button>
                <nz-modal [(nzVisible)]="showExportModal" nzTitle="Export Messages" (nzOnCancel)="handleCancel()" [nzFooter]="exportFooter" >
                  <ng-container *nzModalContent>
                    <form nz-form nzLayout="vertical" [formGroup]="frmExport">
                        <nz-form-item>
                          <nz-form-label>Date Range</nz-form-label>
                          <nz-form-control 
                              nzErrorTip="Date Range cannot be more than 30 days apart"
                            >
                            <nz-range-picker style="width: 100%" formControlName="range"></nz-range-picker>
                          </nz-form-control>

                        </nz-form-item>

                          <nz-form-item>
                              <nz-form-label>Format</nz-form-label>
                              <nz-form-control >
                                  <nz-select formControlName="format" nzPlaceHolder="Select Format">
                                      <nz-option nzValue="csv" nzLabel="CSV"></nz-option>
                                      <nz-option nzValue="html" nzLabel="HTML"></nz-option>
                                    </nz-select>                              
                                  </nz-form-control>
                            </nz-form-item>
                      </form>
                    </ng-container>
                </nz-modal>
                <ng-template #exportFooter>
                    <button nzBlock nz-button nzType="primary" (click)="exportMessages()" [disabled]="!frmExport.valid">Export</button>
                </ng-template>
            </div>
          </div>
        </div>
        <div class="message-list" >
          <cdk-virtual-scroll-viewport itemSize="90" style="height: 100%" #messageList>
            <nz-list 
              [nzNoResult]="'There are no messages in this conversation'" 
              [nzDataSource]="activeConversation.messages | async" 
              [nzItemLayout]="'horizontal'"
              >
                <nz-list-item *cdkVirtualFor="let item of activeConversation.messages | async; trackBy: trackByDocumentId">
                  <app-message-view
                    [message] = "item"
                  ></app-message-view>
                </nz-list-item>
            </nz-list>
          </cdk-virtual-scroll-viewport>
          <div class="floatingFooter" *ngIf="scrollableMessages.hasNewItems | async" >
            <button 
              nz-button 
              nzType="primary"
              nzShape="round"
              class="hasNewMessages"
              (click)="scrollableMessages.scrollToBottom()"
              >New Messages (jump to bottom)
            </button>
          </div>
        </div>
        <app-message-composer [contactDTO]="activeConversation"></app-message-composer>
      </ng-container>
      <ng-template #selectConversation>
        <p class="text-center pad-25" >Select a conversation from the list.</p>
      </ng-template>
    </div>
  </div>
</nz-card>

<ng-template #markAsReadCountdown><i class="markAsReadIcon" nz-icon nzType="check" nzTheme="outline"></i></ng-template>

<ng-template #conversationDTOTemplate let-conversationDTO let-isSpotlight="isSpotlight" let-index="index">
  <div nz-row class="list-item list-item-conversation-row"  
    (click)="setActiveConversation(conversationDTO)" 
    [ngClass]="{'list-item-active': activeConversation && conversationDTO.contact.id == activeConversation.contact.id, 'spotlightConversation': isSpotlight}"
    (mouseover)="hoveredConvoListIndex=index" 
    (mouseout)="hoveredConvoListIndex=null">
      <div nz-col nzFlex="1" style="padding:1em;" *ngIf="!isSpotlight">
        <label nz-checkbox [nzChecked]="(checkedContacts|async)[conversationDTO.contact.id]" (nzCheckedChange)="setContactCheckStatus(conversationDTO.contact.id, $event)"></label>
      </div>
      <div nz-col nzFlex="2" *ngIf="isSpotlight">
        <span nz-icon nzType="comment" nzTheme="outline" nz-tooltip nzTooltipTitle="The active conversation remains at the top of the list while paging"></span>
        <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to close this conversation?"
          (nzOnConfirm)="spotlightConversation=null; setActiveConversation(null)"><span nz-icon nzType="close-circle" nzTheme="outline"></span></a>
        
        
      </div>
      <div nz-col nzFlex="10">
        <div nz-row>
          <div nz-col nzFlex="8">
            <div class="convo-name">
              {{conversationDTO.contact.full_name || conversationDTO.contact.phone_number  || "Unknown" | ellipsis:25}}
            </div>
            <div class="convo-message">
              <nz-badge nzStatus="processing" *ngIf="!(conversationDTO.readStatus | async).isRead && !(activeConversation && conversationDTO.contact.id == activeConversation.contact.id)" [nzColor]="conversationDTO.contact.id == this.activeConversation?.contact.id ? 'white' : 'blue'"></nz-badge>
              <nz-progress  
                [nzWidth]="24" 
                [nzStrokeWidth] = "10"
                *ngIf="(activeConversation && conversationDTO.contact.id == activeConversation.contact.id && activeConversation.readStatus.value.countdownMS)" 
                [nzPercent]="100-activeConversation.readStatus.value.countdownPercent" 
                [nzFormat]="markAsReadCountdown" 
                nzType="circle"
                ></nz-progress>
              {{conversationDTO.contact.last_message || "" | ellipsis:30}}
              <small *ngIf="conversation?.last_message?.length <= 0">-</small>
              <small *ngIf="conversationDTO.isArchived"><br/>Archived 🗃</small>
            </div>
          </div>
        </div>
      </div>
      <div nz-col nzFlex="5">
        <small><i>{{convertTimestampToDate(conversationDTO.contact)}}</i></small>
      </div>
      <div nz-col nzFlex="2" [ngStyle]="{'visibility': (hoveredConvoListIndex == index) ? 'visible' : 'hidden'}">
        <button nz-button nzType="primary" nzShape="circle" nz-dropdown [nzDropdownMenu]="menu">
            <span nz-icon nzType="more" nzTheme="outline"></span>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable (mouseover)="hoveredConvoListIndex=index" (mouseout)="hoveredConvoListIndex=null">
            <li nz-menu-item *ngIf="(conversationDTO.readStatus | async).isRead">
              <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to mark this conversation as unread?"
                (nzOnConfirm)="markChatUnread(conversationDTO)"><i class="fas fa-inbox-out"></i> Mark Unread</a>
            </li>
            <li nz-menu-item *ngIf="conversationDTO.isArchived">
              <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to unarchive this conversation?"
                (nzOnConfirm)="unarchiveChat(conversationDTO)"><i class="fas fa-box-open"></i> Unarchive</a>
            </li>
            <li nz-menu-item *ngIf="!conversationDTO.isArchived">
              <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to archive this conversation?"
                (nzOnConfirm)="archiveChat(conversationDTO)"><i class="fas fa-archive"></i> Archive</a>
            </li>
            <li nz-menu-item *ngIf="this.authnz.currentSecurityProvider.canDeleteContacts()">
              <a nz-popconfirm nzPopconfirmTitle="Are you sure you want to delete this conversation? This CANNOT be undone." 
                (nzOnConfirm)="deleteChat(conversationDTO)"><i class="fas fa-trash"></i> Delete History
              </a>
            </li>
            <li nz-menu-item>
              <app-sender-blocker
                [sender]="conversationDTO"
                displayStyle="both"
                displayFormat="href">
              </app-sender-blocker>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
</ng-template>

<ng-template #pageTitleTemplate>
  Conversations <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>
<ng-template #extraTemplate>
  <button nz-button nzType="primary" (click)="showNewConversationDrawer=true;"><i class="far fa-comment-plus"></i> New Conversation</button>
</ng-template>

<nz-modal [nzStyle]="{ top: '30px' }" [(nzVisible)]="guideVisibleBool" nzTitle="User Guides" (nzOnCancel)="handleCancel()" [nzFooter]="guideFooter" nzWidth="1200">
  <ng-container *nzModalContent>
    <app-user-guides [selectedGuide]="'Conversations'"></app-user-guides>
  </ng-container>
</nz-modal>
<ng-template #guideFooter>

</ng-template>

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="showNewConversationDrawer"
  nzPlacement="right"
  nzTitle="Start New Conversation"
  (nzOnClose)="handleCancel()"
  [nzWidth]="500"
>
  <ng-container *nzDrawerContent>
    <div class="pad-15">
      <p class="text-center pad-15" style="padding-left:0;">Enter a number or select a contact from the list below to begin a conversation.</p>
      
      <div class="pad-15">
        <form nz-form nzLayout="vertical" [formGroup]="frminitiateConversation" (ngSubmit)="initiateConversation(frminitiateConversation.value)">
          <nz-form-item>
            <nz-form-label nzRequired>Send to Phone Number</nz-form-label>
            <phone-number-input formControlName="phone_number">
            </phone-number-input>
          </nz-form-item>
          <button [disabled]="!frminitiateConversation.valid" nz-button nzType="primary" nzBlock [nzLoading]="loadingInitiateConversation">Start Conversation</button>
        </form>
      </div>

      <nz-divider nzDashed [nzText]="text">
        <ng-template #text> OR</ng-template>
      </nz-divider>
      
      <app-address-book [hasSendMessageAction]="false" [activated]="showNewConversationDrawer" [optedOut]="false" (contactSelected)="initiateConversation($event)" [clearOnSelect]="true" [perPage]="10"></app-address-book>
      
    </div>
  </ng-container>
</nz-drawer>


