import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthNZService } from 'src/app/core/authnz.service';
import { TextableService } from 'src/app/services/textable.service';
import { MmsService } from 'src/app/services/mms.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { FrontendAttachment } from '../mms-uploader/mms-uploader.component';
import { ContactDTO } from 'src/classes/contactDTO';
import { formatTimeZone, TextableDate } from 'src/classes/TextableDate';
import { FormControl, FormGroup } from '@angular/forms';
import { DateIsInFuture } from 'src/app/form_validators/DateIsInFuture';
import { getLogger } from '@shared/logging';
import { MessageSignature, MessageSignatureService } from 'src/app/services/message-signature.service';
import { combineLatest } from 'rxjs';

const log = getLogger("MessageComposerComponent")

export enum DripStartOptions {
  NOW = 'now',
  DELAY = 'delay',
  EXACT = 'exact'
}

export enum DripDelayUnit {
  MINUTE = 'minutes',
  HOUR = 'hours',
  DAY = 'days'
}

@Component({
  selector: 'app-message-composer',
  templateUrl: './message-composer.component.html',
  styleUrls: ['./message-composer.component.scss'],
})
export class MessageComposerComponent implements OnInit {
  @Input() contactDTO: ContactDTO = null;
  
  public newMessage: string = "";
  private newMessageComposeStartTimestamp: number;

  public showMMSModal: boolean = false;
  public showReplyModal: boolean = false;
  public showDripModal: boolean = false;

  public DripStartOptions = DripStartOptions;
  public dripStartOptions = DripStartOptions.NOW;

  public DripDelayUnit = DripDelayUnit;
  public dripDelayUnit = DripDelayUnit.HOUR
  public dripDelay = 1;
  public frmDripSelectedDateTime:FormGroup;

  public approxDateString = ''
  public dripStart:string;

  public activeDripCampaign: any;
  public hasValidProvider: boolean = false;
  public messageAttachment:FrontendAttachment = null
  public isValidDate = true

  /**
   * Signature to be appended to the message
   * 
   * Configured via the signature selector "Extra"
   * 
   */
  public activeSignature: MessageSignature | null = null;

  /**
   * Double-bound propertry for the signature popover visibility
   */
  public signaturePopoverVisible: boolean = false;
  /**
   * Double-bound propertry for the emoji visibility
   */
  public emojiPopoverVisilble:boolean = false
  public currentTextAreaPostion:number;
  constructor(
    private authnz: AuthNZService,
    public textable: TextableService,
    private messagingService: MessagingService,
    private mms: MmsService,
    public signatures: MessageSignatureService
  ) {}

  ngOnInit() {
    this.authnz.contextChanged.subscribe(()=> {
      if (environment.credentialSource == "user") {
        this.hasValidProvider = this.authnz.currentUserDocument.hasOwnProperty("provider") && this.authnz.currentUserDocument.provider !==""
      } else {
        this.hasValidProvider = true;
      }
    });

    /**
     * Setup an observable to watch for changes in the context and the available signatures
     * If the current user is also the logged-in user, then use the signature
     */
    combineLatest([this.authnz.contextChanged, this.signatures.availableSignatures]).subscribe(([context, signatures]) => {
      if (context.activeNumberProfile.firebase_document_id == context.activeUserProfile.firebase_document_id) {
        this.activeSignature = signatures.find(s => s.type == "own") || null;
      }
    })



    this.frmDripSelectedDateTime = new FormGroup({
      "scheduleTimezone": new FormControl(null),
      "scheduleTime": new FormControl(null),
    },
    {
      validators: [DateIsInFuture("scheduleTime", "scheduleTimezone")],
    });
  }
  


  /**
   * Fires when a signature is selected from the dropdown
   * @param event 
   */
  public signatureSelected(event: any) {
    this.activeSignature = event;
    log.debug("Signature selected", event);
    // close signaturePopover
    setTimeout(() => {
      this.signaturePopoverVisible = false;
    },250);
  }
  /**
   * Finds the cursor position in text area
   * @param event Click Handler event for textarea
   */
  public findCursor(event){
    this.currentTextAreaPostion = event.target.selectionStart
  }
  /**
   * Adds the emoji based on cursor position of text area.
   * @param event Click Handler event of emoji selection
   */
  public appendEmoji(event){    
    const split = this.newMessage.substring(0, this.currentTextAreaPostion)
    const split2 = this.newMessage.substring(this.currentTextAreaPostion)
    this.newMessage = `${split}${event.emoji.native}${split2}`
  }
  /**
   * Sends a message then resets the composer.
   */
  public async send() {
    let MessageToSend = this.newMessage;
    if(typeof this.activeSignature?.text == "string"){
      MessageToSend = `${this.newMessage}\n\n${this.activeSignature.text}`;
    }
    await this.messagingService.send({contact: this.contactDTO.contact, newMessage: MessageToSend, messageAttachment: this.messageAttachment, composeStart: this.newMessageComposeStartTimestamp});
    this.newMessage = '';
    this.messageAttachment = null;
  }

  public applyDripCampaign(drip) {
    let msg = {
      contact_id: this.contactDTO.contact.id,
      direction: 'out',
      to: this.contactDTO.contact.phone_number,
      from: this.authnz.activeNumberProfile.phone_number,
      date: TextableDate.now(),
      body: '',
      send_status: 'scheduled',
      uid: this.authnz.activeNumberUID,
      attachments: [],
      isDrip: true,
      dripCampaignId: '',
      disableDripCampaignDelete: drip?.disableDripCampaignDelete ? true : false,
      sentBy: this.authnz.currentFireauthUser.uid,
      sentByLabel: this.authnz.currentUserDocument.full_name
    };
    drip.drips.forEach((d,index) => {
      // Add the messageAttachment bodies if exists.
      if(d.attachments && d.attachments.length > 0){
        msg.attachments = d.attachments;
      }else{
        msg.attachments = []
      }

      msg.body = d.message;
      let additionalSeconds = this.textable.convertIntervalToSeconds(d) * 1000;
      //console.log(drip+ '/' +additionalSeconds);
      msg.date = TextableDate.now() + additionalSeconds;
      if(this.dripStart){
        if(index == 0){
          msg.date = Number(this.dripStart)
        }else{
          msg.date = Number(this.dripStart) + additionalSeconds
        }
      }else{
        msg.date = TextableDate.now() + additionalSeconds
      }
      msg.dripCampaignId = drip.id;
      //console.log('Adding drip '+JSON.stringify(msg)+' to contact.');
      this.textable.sendMessage(msg);
    });
  }

  public applyCannedResponse(response){
    this.newMessage=response.body;
    this.messageAttachment = response.attachments[0]
    
    this.showReplyModal=false;
  }

  public resetAttachment(){
    this.messageAttachment = null;
  }

  public openPhoto(messageAttachment: FrontendAttachment) {
    this.mms.openPhoto(messageAttachment)
  }
  
  public async onPaste(event: ClipboardEvent) {

    // Update the attachment if onPaste gives us one.  Otherwise, use what's already in place
    this.messageAttachment = (await this.mms.onPaste(event, this.messageAttachment !== null)) || this.messageAttachment
  }

  /**
   * Closes the MMSUploader component when the output event
   * is emitted.
   */
  public closeMMSUploader() {
    this.showMMSModal = false;
  }

  public closeDripModal(){
    this.showDripModal=false;
    this.frmDripSelectedDateTime.reset();
  }

  public messageTextChanged(event: any) {
    this.findCursor(event)
    const newMessage = this.newMessage.trim();
    if (newMessage == "") {
      this.newMessageComposeStartTimestamp = null;
    }
    else if (this.newMessageComposeStartTimestamp == null) {
      this.newMessageComposeStartTimestamp = TextableDate.now();
    }
  }

  /**
   * 
   * @param dripIndex Position of the drip
   * @param dripInterval Length of time depending on the interval type
   * @param dripIntervalType Time unit of minutes,hours,days
   * @returns String that shows the approx send dates of each drip.
   *
   */
  public calculateApproxSendDate(dripIndex:number,dripInterval:number,dripIntervalType:DripDelayUnit){
    this.isValidDate = true;
    let now = TextableDate.getMomentObject()
    switch(this.dripStartOptions){
      case this.DripStartOptions.DELAY:
        now.add(this.dripDelay,this.dripDelayUnit)
        if(dripIndex != 0){
          now.add(dripInterval,dripIntervalType)
        }else{
          this.dripStart = now.format("x");
        }
        this.approxDateString = `Will be sent on approximately ${now.format('MM/DD/YY h:mm a')}.`
        break;

      case this.DripStartOptions.EXACT:
        now = TextableDate.getMomentObject(this.frmDripSelectedDateTime.value.scheduleTime)
        if(dripIndex != 0){
          now.add(dripInterval,dripIntervalType)
        }else{
          this.dripStart = now.format("x");
        }
        const date = now.format('MM/DD/YY h:mm a')
        if(date != 'Invalid date'){
          this.approxDateString = `Will be sent on approximately ${formatTimeZone(this.frmDripSelectedDateTime.value.scheduleTime,this.frmDripSelectedDateTime.value.scheduleTimezone)}.`
        }else{
          this.isValidDate = false
          this.approxDateString = ''
        }
        break;

      default:
        this.dripStart = null
        this.approxDateString = `Will be sent approximately ${dripInterval} ${dripIntervalType} from now.`
    }
    return this.approxDateString
  }
  public AttachmentClicked() { 
    if (this.messageAttachment) {
      this.resetAttachment();
    }
    else {
      this.showMMSModal = true;
    }
  }
}
