import { Injectable } from '@angular/core';
import * as log from 'loglevel';
import { BackendService } from './backend.service';


export interface TextableServiceAccountTokenUI {
  name: string
  revoked: boolean
  issueDate: number
  expirationDate: number
  scopes: string[]
}

export interface TextableServiceAccountCreationRequest {
  name: string
  scopes: string[]
  expirationDate: number
}

export interface TextableServiceAccountCreationResponse {
  doc: any //TextableServiceAccountTokenDocument
  token: string
}

@Injectable({
  providedIn: 'root'
})
export class ServiceAccountService {

  constructor(
    private backendservice: BackendService
  ) { }

  /**
   * Gets service account objects by calling PrivateBackend
   */
  public async GetSerivceAccounts(): Promise<TextableServiceAccountTokenUI[]> { 
    
    const serviceAccounts = await (
        this.backendservice.backendGet("service-accounts/")) as {service_account_tokens: TextableServiceAccountTokenUI[]}
    let accounts: TextableServiceAccountTokenUI[] = [];
    
    if (!serviceAccounts.hasOwnProperty("service_account_tokens")) {
      throw new Error("No service account tokens");
    }
    return  serviceAccounts.service_account_tokens; 

  }

  public async CreateSerivceAccount(request: TextableServiceAccountCreationRequest): Promise<TextableServiceAccountCreationResponse> {
    log.debug("Requesting service account creation: ", request);
    const response = await this.backendservice.backendPost("service-accounts/create",{}, {request: request});

    log.debug("Token creation response ", response);

    return response as TextableServiceAccountCreationResponse;

  }

  public async GetScopes(){ 
    const response: any = await this.backendservice.backendGet("service-accounts/scopes");
    log.debug("Possible token scopes ", response.scopes);
    return response.scopes
  }

  public async RevokeTokenByName(tokenName: string) {
    await this.backendservice.backendPost("service-accounts/revoke",{}, {tokenName: tokenName});
  }
}
