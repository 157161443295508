import { Observable } from "rxjs";

export function afterAllSubscribersNotified(callback: () => void) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(observer => {
      const subscription = source.subscribe({
        next(value) {
          observer.next(value);
          callback();
        },
        error(err) {
          observer.error(err);
        },
        complete() {
          observer.complete();
        }
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  };
}