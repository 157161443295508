import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthNZService } from '../../core/authnz.service';
import { TextableService } from '../../services/textable.service';
import {ThemeService} from "../../services/theme.service";
import { ControlMessagingService } from 'src/app/services/control-messaging.service';
import { getLogger, updateAllLoggers } from '@shared/logging';
import { LogLevelDesc } from 'loglevel';

const log = getLogger("DebugDataViewerComponent")

@Component({
  selector: 'app-debug-data-viewer',
  templateUrl: './debug-data-viewer.component.html',
  styleUrls: ['./debug-data-viewer.component.scss']
})
export class DebugDataViewerComponent implements OnInit {

  environment: any;

  public logLevels = Object.keys(log.levels)
  public logLevel: string = Object.entries(log.levels).find((k,v)=> v == log.getLevel())[0];
  public stringifiedBranding: string
  public stringifiedBrandingError: string
  public tokenCopied = "";

  /**
   * Proxy function for JSON Stringify so we can use it in the HTML template
   * 
   * Also sorts object keys alphabetically
   */
  public stringify = (obj:any) => JSON.stringify(
      Object.keys(obj).sort().reduce((accumulator, key) => {
        accumulator[key] = obj[key];
        return accumulator;
      }, {})
    , null
    , '  ');

  constructor(
    public authnz: AuthNZService,
    public textable: TextableService,
    public themeService: ThemeService,
    public messaging: ControlMessagingService
  ) { 
    this.environment = environment;
  }

  ngOnInit() {
    this.updateBrandingString();
  }

  async copyAuthTokenToClipboard() {
    navigator.clipboard.writeText(await this.authnz.GetCurrentToken());
    this.tokenCopied = "Copied!"
    setTimeout(()=>this.tokenCopied = "", 1000)
  }

  async setLogLevel(level: LogLevelDesc) {
    console.log("Changing log level to: " + level); // console.log is used here to ensure this _change_ is always logged (ignoring the currently set log level)
    this.logLevel = log.levels[level];
    updateAllLoggers(level)
  }

  public updateBrandingString() {
    this.stringifiedBranding = JSON.stringify(this.themeService.activeBranding,null,'  ')
  }

  public updateBranding() {
    try{
      this.themeService.activeBranding = JSON.parse(this.stringifiedBranding);
      this.stringifiedBrandingError = null
      this.themeService.setBranding();
    }
    catch (err) {
      log.error("failed to parse json" ,err);
      this.stringifiedBrandingError = err;
    }
    
  }

  /**
   * Generates a link to Firestore page for the optionally provided document ref
   * @param docRef 
   */
  public OpenFirestore(docRef?: string) {
    let subPath = "";
    if (docRef) {
      subPath = "/data/"+ encodeURIComponent(docRef);
    }
    const URL = `https://console.firebase.google.com/project/${this.environment.firebase.projectId}/firestore${subPath}`;
    log.debug("Opening Firestore view: " + URL)
    window.open(URL,"_blank");
  }

  public windowShowDocs() {
    (window as any).showDocs();
  }


}
