import packageInfo from '../../package.json';
export const environment = {
  appName: 'Textable Dev',
  logoPath: 'assets/images/logo.png',
  stripeUrl:'pk_test_Q8d1hJkkBZN6qMUqBfyKgg4q',
  production: false,
  firebase: {
    apiKey: "AIzaSyBsepUDS_zzOH8jnwr_chweniZ0mLKnLLg",
    authDomain: "textabledev.firebaseapp.com",
    databaseURL: "https://textabledev.firebaseio.com",
    projectId: "textabledev",
    storageBucket: "textabledev.appspot.com",
    messagingSenderId: "662076610317",
    appId: "1:662076610317:web:5b794746c30549d34f0244"
  },
  imgCDN: 'https://textabledev.imgix.net/',
  apiBase: 'https://textabledev01.textable.app/',
  supportEmail: 'support@taurix.com',
  securityProvider: 'retail',
  disableDelete: false,
  version: packageInfo.version,
  features: {
      conversations: true,
      blasts: true,
      cannedResponses: true,
      crmRelay: true,
      contacts: true,
      profile: true,
      dripCampaign: true,
      reminders: true,
      admin: true,
      lists: true,
      users: true,
      importContacts: true,
      deleteAll: true,
      bulkMMS:true,
      integrations: true,
      billing:true
  },
  credentialSource: 'user'
};