import { getLogger, levels } from 'src/shared/logging';
const log = getLogger("PhoneNumber");


/**
 * Matches an NANP number and provides named capture groups for the following
 * 
 * countryCode - either +1 or 1 as the first character(s)
 * npa - [2-9] followed by two more digits
 * co - [2-9] followed by two more digits
 * ln - 4 digits
 */
export const NANPRegexStrict = new RegExp(/^(?<countryCode>\+1|1)?[\(\s]*(?<npa>[2-—9]\d\d)[\)\s-—]*(?<co>[2-—9]\d\d)[-—\s]*(?<ln>\d\d\d\d)$/)
/**
* Same as `NANPRegexStrict` except we won't require any of the match groups
 */
export const NANPRegexPartial = new RegExp(/^(?<countryCode>\+1|1)?[\(\s]*(?<npa>[2-—9]\d\d)?[\)\s-—]*(?<co>[2-—9]\d\d)?[-—\s]*(?<ln>\d\d\d\d)?$/)
/**
 * Same as `NANPRegexPartial` except now we can also accept invalid starting characters for NPA and CO
 */
export const NANPRegexLoose = new RegExp(/^(?<countryCode>\+1|1)?[\(\s]*(?<npa>\d\d\d)?[\)\s-—]*(?<co>\d\d\d)?[-—\s]*(?<ln>\d\d\d\d)?$/)
export const shortCodeRegex = new RegExp(/^[02-9]\d{4,5}$/)


export const CountryCodes = [
  {
    "name": "USA",
    "code": "+1",
  }
]

/**
 * Map of letter-to-numer replacements according to E.161
 * https://en.wikipedia.org/wiki/E.161
 */
const E161Replacements = {"a":2,"b":2,"c":2,"d":3,"e":3,"f":3,"g":4,"h":4,"i":4,"j":5,"k":5,"l":5,"m":6,"n":6,"o":6,"p":7,"q":7,"r":7,"s":7,"t":8,"u":8,"v":8,"w":9,"x":9,"y":9,"z":9}


export const FindPhoneNumberInText = (body: string): PhoneNumber[] => {
  log.debug(`Searching body (length ${body.length}) for phone numbers`)
  const numbers: PhoneNumber[] = [];

  if (typeof body != "string") {
    return numbers;
  }
  let multiMatch = new RegExp(NANPRegexStrict.source.substring(1,NANPRegexStrict.source.length-1),"gim");
  const results = body.match(multiMatch) || []
  log.debug(`Found numbers: ${JSON.stringify(results)}`);
  for (let n of results) {
    numbers.push(new PhoneNumber(n))
  }
  return numbers

}

/**
 * Expres phone numbers as objects; parsing the various numbering plans
 * defined in the E.164 standards according to the region, as well as 
 * breaking out NPA/NXX, and supplying utility functions to aid in 
 * interactions with other systems
 * 
 * This class may increase in usage within our codebase, or may end up being 
 * replaced by a third-party library (e.g. https://github.com/google/libphonenumber)
 * 
 * 
 */
export class PhoneNumber {

  /**
   * The raw number supplied to this parsing class
   */
  private rawNumber: string
  /**
   * The E.161 replaced number to parse
   */
  private numberToParse: string
  /**
   * Country code of the phone number
   * e.g. +1
   */
  private countryCode: string
  /**
   * NPA - Numbering Plan Area (Area code)
   * 
   * e.g. NXX
   */
  private areaCode: string;
  /**
   * Central Office Code (Exchange code)
   * 
   * e.g. NXX
   */
  private exchangeCode: string;
  /**
   * Line number (Station Code)
   * 
   * e.g. XXXX
   */
  private stationCode: string;
  private numberType: "Shortcode" | "NANP"
  
  private e161Conversion:boolean = false;

  constructor(rawNumber: string, minimumEntry: "full"|"country"|"npa" = "full") { 
    log.setLevel(levels.SILENT)
    /**
     * Convert the raw nubmer to lower case in case it has E.161 data
     */
    this.rawNumber = rawNumber;
    this.numberToParse = rawNumber;
    this.ParseE161();
    this.RemoveQuotes();
    this.ParseNANP();

    if(minimumEntry == "full") {
      if (! ( this.countryCode && this.areaCode && this.exchangeCode && this.stationCode)) {
        throw new PhoneNumberParseError(`Unable to parse a full NANP phone number from ${rawNumber}`, "Ensure that the phone number is a properly formatted E.164 number");
      }
    }
    else if (minimumEntry == "npa") {
      if (! ( this.countryCode && this.areaCode)) {
        throw new PhoneNumberParseError(`Unable to parse NPA phone number from ${rawNumber}`, "Ensure that the phone number is a properly formatted E.164 number");
      }
    }
    log.debug(`parsed '${rawNumber}' to '${this.ToE164()}' as '${this.numberType}': '${minimumEntry}'`)
  }

  /**
   * Parses a raw string according to the North American Numbering Plan (NANP)
   * 
   * Reference: https://en.wikipedia.org/wiki/North_American_Numbering_Plan#Central_office_codes
   *
   */
  private ParseNANP() {
    const parseRegex = (regex:RegExp) => {
      const matchResults = this.numberToParse.match(regex);
      if (matchResults?.groups){
        this.countryCode = "+1" //NANP will always be +1 country code 
        this.areaCode =  matchResults.groups.npa || null
        this.exchangeCode = matchResults.groups.co || null
        this.stationCode = matchResults.groups.ln || null
        this.numberType = "NANP";
        return true;
      }
      return false;
    }

    if(! (parseRegex(NANPRegexStrict) || parseRegex(NANPRegexPartial))) {
      if(parseRegex(NANPRegexLoose)) {
        throw new PhoneNumberParseError("The phone number is an invalid E.164 number","Verify the Area Code and Exchange Code do not start with '1'")
      }
      else {
        throw new PhoneNumberParseError("Unable to parse number according to NANP", "Ensure that the phone number can be formatted as +1xxxyyyzzzz")
      }
    }
  }

  private RemoveQuotes() {
    this.numberToParse = this.numberToParse.replace(/['"`]/ig,'')
  }

  /**
   * Replace letters in a phone number according to E.161
   * 
   */
  private ParseE161() {
    const replacer = (match: string, ...args: any[])  => {
      this.e161Conversion = true;
      const m = match[0] as keyof typeof E161Replacements
      if (!Object.keys(E161Replacements).includes(m)) {
        return m;
      }
      return String(E161Replacements[m]);
    }
    const preParse = this.numberToParse
    this.numberToParse = this.numberToParse.toLowerCase().replace(/[a-z]/ig, replacer);
    log.debug(`Completed E.161 replacement: ${preParse} => ${this.numberToParse}`)
  }

  ToE164() { 
    return `${this.countryCode || ''}${this.areaCode || ''}${this.exchangeCode || ''}${this.stationCode || ''}`
  }

  /**
   * Returns the phone number represented in various common NANP formats
   * 
   * Useful for string matching against a poorly indexed database
   */
  ToCommonFormats(): String[] {
    return [
      this.ToE164(),
      "+{countryCode}{areaCode}{exchangeCode}{stationCode}",
      "+{countryCode}({areaCode}){exchangeCode}-{stationCode}",
      "+{countryCode} ({areaCode}) {exchangeCode}-{stationCode}",
      "{countryCode}({areaCode}){exchangeCode} {stationCode}",
      "{countryCode}{areaCode}{exchangeCode}{stationCode}",
      "{countryCode} ({areaCode}) {exchangeCode}-{stationCode}",
      "({areaCode}){exchangeCode}{stationCode}",
      "({areaCode}) {exchangeCode}-{stationCode}",
      "({areaCode}){exchangeCode}-{stationCode}",
      "{areaCode}-{exchangeCode}-{stationCode}",
      "{areaCode}{exchangeCode}{stationCode}",
      "{areaCode} {exchangeCode} {stationCode}"
    ].map(x=>this.Format(x))
  }

  /**
   * Supports formatting a phone number in various ways;
   * 
   * Replaces the following tokens
   * {countryCode} - this will not include the "+"
   * {areaCode}
   * {exchangeCode}
   * {stationCode}
   * 
   * @param formatString 
   */
  Format(formatString: string) {
    return formatString
      .replace("{countryCode}",this.countryCode.replace("+",""))
      .replace("{areaCode}",this.areaCode)
      .replace("{exchangeCode}",this.exchangeCode)
      .replace("{stationCode}",this.stationCode)
  }

  GetNPA() {
    return this.areaCode
  } 

  GetExchangeCode() {
    return this.exchangeCode
  }

  GetStationCode() {
    return this.stationCode
  }

  GetE161ConversionStatus() { 
    return this.e161Conversion;
  } 

  GetRawNumber() {
    return this.rawNumber.trim();
  }

}

export class PhoneNumberParseError extends Error {
  public resolution: string
  constructor(message: string, resolution: string){ 
    super (message);
    this.resolution = resolution
  }
}