import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthNZService } from 'src/app/core/authnz.service';
import { TextableDate } from 'src/classes/TextableDate';

@Component({
  selector: 'app-opted-out-banner',
  templateUrl: './opted-out-banner.component.html',
  styleUrls: ['./opted-out-banner.component.scss']
})
export class OptedOutBannerComponent implements OnInit {

  @Input('contact') contact:any = null;
  @Input('page') page:any = null;

  constructor(
    private afs: AngularFirestore,
    private authnz: AuthNZService
  ) { }

  ngOnInit() {
  }

  optBackIn(){ // TODO: this seems unused TXBDEV-904

    this.afs.doc('contacts/'+this.contact.id)
      .update(
        {
          optedOut: false,
          optedBackInAt: TextableDate.now(),
          optedBackInBy: this.authnz.currentFireauthUser.uid
        }
      )

  }

}
