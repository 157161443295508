<ng-container *ngIf="contact.optedOut && page == 'contacts'">
  <nz-alert nzType="warning" [nzMessage]="optOutMessage" nzShowIcon nzBanner></nz-alert>
  <ng-template #optOutMessage>
    Opted-out via keyword at {{contact.optedOutAt | date:'short'}}
    <i class="fas fa-info-square" nz-tooltip nzTooltipTitle="Contact opted-out from automated messages (Blasts, Reminders, Drip Campaigns) via keyword. Contact may need to reply START to allow messages from your phone number again."></i>
  </ng-template>
</ng-container>

<ng-container *ngIf="contact.optedOut && page == 'conversations'">
  <i class="fas fa-info-square" style="color: #f5222d;font-size: 18px" nz-tooltip [nzTooltipTitle]="titleTemplate"></i>
  <ng-template #titleTemplate>
     <span>Contact opted-out<br>
      <b>{{contact.optedOutAt | date:'short'}}</b><br>
        from automated messages (Blasts, Reminders, Drip Campaigns) via keyword. Contact may need to reply START to allow messages from your phone number again.</span>
  </ng-template>
</ng-container>