import { Injectable } from '@angular/core';
import { getLogger } from '@shared/logging';
import { ReplaySubject } from 'rxjs';
import { GenericFrontendFirestoreDocument } from '../interfaces';
import { PhoneNumber } from '@shared/PhoneNumber';
import { SkyetelApiService } from './skyetel-api.service';
import { TextableService } from './textable.service';
import { UserManagementService } from './user-management.service';

const log = getLogger("DIDSuggestionService");

/**
 * When rendering DID suggestions to admins, we display both the phone number, and some string-formatted metadata.
 *
 * Depending on the provider hosting this phone number, the metadata may have various formats.
 */
export interface AvailablePhoneNumber {
  phoneNumber: string,
  metadata: string,
  allowPick: boolean,
  additionalInformationIcon: string
  additionalInformationIconColor: string
  additionalInformation: string
}

/**
 * It may be desireable to explain to the user how we're sourcing the DID suggestions
 * 
 * Since this service is responsible for such suggestions, we expose here a method to query
 * how the service will provide suggestions.
 * 
 * TODO: Make this dynamic based on the selected provider (some may be different than SkySwitch)
 * 
 * @returns 
 */
export interface SuggestionDetails {
  Message: string,
  error: boolean,
  loading: boolean
}

@Injectable({
  providedIn: 'root'
})
export class DidSuggestionService {



  public DIDSuggestionResults: ReplaySubject<{
      SuggestionDetails?: SuggestionDetails,
      Numbers?: AvailablePhoneNumber[]
    }> = new ReplaySubject();

  constructor(
    private skyetelApi:SkyetelApiService,
    private textable:TextableService,
    private userManagementService:UserManagementService
  ) { }




  /**
   * Sets the filter for DID suggestions;  causes lookups to be performed aginst remote servers, and 
   * upon completion of async operations, new values to be published on the DIDSuggestionResults obervable
   * 
   * 
   * @param phone_number 
   * @param organizationId 
   * @returns 
   */
  public async SetFilter(phone_number: string, userformValues:GenericFrontendFirestoreDocument) {
    let phoneNumber: PhoneNumber
    try {
      phoneNumber =  new PhoneNumber(phone_number,"npa");
    }
    catch (e) {
      log.debug("Error parsing number: " + e)
      this.DIDSuggestionResults.next({
        SuggestionDetails: { 
          Message: "Suggestions will be shown after entering at least an NPA",
          loading: false,
          error: true
        },
        Numbers: []
      })

      return;
    }

    this.DIDSuggestionResults.next({
      SuggestionDetails: { 
        Message: "Searching for available DIDs",
        error: false,
        loading: true
      },
      Numbers: []
    })

    switch (userformValues.provider){
      case "skyetel":
        try {
          const numbers = await this.skyetelApi.GetSMSEnabledAssignments(phoneNumber, userformValues )
          const suggestedPhoneNumbers = await this.skyetelApi.ConvertSMSEnabledAssignmentToDIDComponent(numbers);

          if (suggestedPhoneNumbers.length == 0) {
            this.DIDSuggestionResults.next({
              SuggestionDetails: { 
                Message: "No numbers found matching '"+ phoneNumber.ToE164() + "'; Check Skyetel configuration",
                loading: false,
                error: true
              },
              Numbers: []
            })
          }
          else {
            this.DIDSuggestionResults.next({
              SuggestionDetails: { 
                Message: "Numbers from Skyetel Inventory: ",
                loading: false,
                error: false
              },
              Numbers: suggestedPhoneNumbers
            });
          }
        }
        catch(err) {
          log.error("Error on GET Skyetel Phone Numbers", err)
          this.DIDSuggestionResults.next({
            SuggestionDetails: { 
              Message: err.error.error,
              loading: false,
              error: true
            },
            Numbers: []
          });
        }

        break;
    }
    log.debug("showing suggestions for (" + phone_number + ") with provider (" + userformValues.provider + ")");
  }



  
}
