import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-timezone';
import { vars } from './app.constants';
import { GenericFrontendFirestoreDocument } from './interfaces';
import { getLogger } from 'src/shared/logging';
import levenshtein from "fast-levenshtein";
import { DateWithTimezoneToDate, TextableDate } from 'src/classes/TextableDate';
import { environment } from 'src/environments/environment';
const log = getLogger("Functions");

/**
* Tests wheter a given property on a supplied object both _exists_ and _partially matches_ the given search query
* @param object 
* @param propertyName 
* @param query 
* @returns true if the property exists and matches. False if the property doesn't exist, or doesn't match
*/
export function testPropertyMatchesQuery(object: any, propertyName: string, query: string) {
    if (!object.hasOwnProperty(propertyName)) {
      return false;
    }
    /**
      * Value of the object's property.
      * 
      * Type guard will ensure that this is a non-empty string.
      */
    const testValue = object[propertyName]
    if (typeof(testValue) !== "string" || testValue == "") {
      return;
    }
    const lowerCaseQuery = query.toLowerCase()
    const propertyValue = testValue.toLowerCase();
    const partialMatch = propertyValue.split(/\W/)
        .filter(partial_value => partial_value.length > 0)
        .map((partial_value, index)=>{
          if(partial_value.length <= lowerCaseQuery.length && index == 0){
            return lowerCaseQuery.includes(partial_value)
          } 
          return partial_value.substr(0,lowerCaseQuery.length) == lowerCaseQuery;
        })
        .reduce((p,c)=> {   
          return p||c;
        });

    const fullMatch = testValue.indexOf(lowerCaseQuery) >-1
    return fullMatch || partialMatch
}

/**
* Function to assist Angular rendering a large list of GenericFrontendFirestoreDocuments 
* by tracking the document's id when rendering.
* 
* @remarks
* For example, whenever a conversation is opened, the contact's document is updated;
* this triggers an update to the contacts subscription, and a re-render of this whole list.
* 
* In cases where there are more than a few contacts, re-rendering the whole DOM 
* for the conversation list is quite expensive.  This helps Angular *NOT* re-render
* the whole DOM
* 
* See: https://www.thisdot.co/blog/improving-angular-ngfor-using-trackbyid-directive
* 
* @param index 
* @param item 
* @returns 
*/
export function trackByDocumentId(index: number, item: GenericFrontendFirestoreDocument): string {
 return item.id
}

export function removeNullOrUndefinedKeysFromObject(sourceObject: any) {
  Object.keys(sourceObject).forEach(key => {
    if (sourceObject[key] === undefined || sourceObject[key] === null) { 
      delete sourceObject[key]
    }
  })
}

/**
* Renders an idempotent-ly random color hex code based on the input string
* @param str 
* @returns color code
*/
export function stringToColor(str: string): string {
  /**
   * will end up as a 3-byte "char" array
   */
  var hex: number[] = [];
  var exciterRanges = [
    [32,70],
    [71,79],
    [80,95]];
  var exciters =[0,0,0];
  const MAX_COLOR_BRIGHTNESS = 190;
  const MIN_COLOR_BRIGHTNESS = 60;
  const MOD=3;
  for(var i=0;i<3*str.length;i++) {
    for(var y=0;y<3;y++) {
      exciters[y] += (exciterRanges[y][0] < str.toUpperCase().charCodeAt(i%str.length) && 
        exciterRanges[y][1] > str.toUpperCase().charCodeAt(i%str.length)
        )?1 : 0
    }
    hex[i%MOD] =(Math.trunc((str.charCodeAt(i%MOD) + str.charCodeAt(i%str.length)*exciters[i%MOD])/2) % MAX_COLOR_BRIGHTNESS);
    if(hex[i%MOD] < MIN_COLOR_BRIGHTNESS) { hex[i%MOD] = MIN_COLOR_BRIGHTNESS }
    //console.log("hex: @"  + i%MOD + "(" + i%MOD +  "): " + hex[i%MOD] + " exciter: " + exciters[i%MOD])
  }
  // convert the byte array to a string
  var retCode:string = '#';
  for(var i=0;i<MOD;i++) {
    retCode += hex[i].toString(16);
  }
  //console.log("color " + retCode + " from: " + str)
  return retCode;
}

export function isStringBlank(str) {
  return (!str || /^\s*$/.test(str));
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 
 * @param min the minimum is inclusive
 * @param max The maximum is exclusive
 * @returns 
 */
export function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}


export function sleep(time: number): Promise<void> {
  return new Promise((resolve,reject) => {
    setTimeout(()=>resolve(),time)
  })
}


export const pickBestFromList = (needle: string, haystack: string[]): string => {
  if (haystack.includes(needle)) {
    return needle;
  }
  const partial = haystack.filter(v=>v.toLowerCase().indexOf(needle.toLowerCase()) >=0)[0];
  if (partial) {
    return partial
  }
  return haystack.map(hay=>({
    hayName: hay,
    distance: levenshtein.get(needle,hay)
  }))
  .sort((a, b) => b.distance - a.distance)
  .pop().hayName
}

/**
 * Trim dead props from an object
 * 
 * TODO: TXBDEV-2169 - Support "deep scanning" objects here;  see test\utility\index.ts
 * 
 */
export function TrimObject(input:{[key:string]:any}): string[] {
	const removedKeys: string[] = [];
	if (typeof(input) !== "object"){
		return removedKeys
	}
	Object.keys(input).forEach((key: string)=> {
		if (input[key] === undefined ) {
			removedKeys.push(key);
			delete input[key]
		}
	})
	return removedKeys;
}

/**
 * Checks the environment for a property, and returns it if it exists
 * 
 * @param propertyName 
 * @returns 
 */
export function GetOptionalEnvironmentProperty<T>(propertyName: string): T | undefined {
  if (propertyName in environment) {
    return environment[propertyName] as T
  }
  return undefined;
}
