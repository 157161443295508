import { Query, CollectionReference } from "@angular/fire/compat/firestore";
import { PlanSelectionProperties, UISecurityProvider } from './interfaces';
import { BaseSecurityProvider } from "./base";
import { AuthNZService } from "../authnz.service";
import { environment } from "src/environments/environment";
import { UserGuideConfig } from "src/app/components/user-guides/user-guides.component";
import { Injector } from "@angular/core";

export class MinimumCommitmentPrivateLabelSecurityProvider extends BaseSecurityProvider implements UISecurityProvider {
  // #region Constructors (1)

  constructor(service: AuthNZService, injector: Injector) {
    super(service);
  }

  // #endregion Constructors (1)

  // #region Public Methods (56)

  public allowProviderCopyOnUserCreate(): boolean {
    return false;
  }

  public canAccessAdminMenu(): boolean  {
    // Since Users is the only sub-menu of admin, we check that here also.
    return this.isOrgAdmin() || this.isUserFullOrSuperAdmin();
  }

  public canAccessAdminTools(): boolean {
    return this.service.currentUserDocument.hasOwnProperty("isSuperAdmin")
      && this.service.currentUserDocument.isSuperAdmin === true
  }

  public canAccessBlasts(): boolean {
    return this.hasValidPhoneNumber() && this.isUserProfessional() &&  this.getUserAndEnvironmentFeatureGrant("blasts");
  }

  public canAccessBulkMMS(): boolean {
    return this.hasValidPhoneNumber() && this.getUserAndEnvironmentFeatureGrant("bulkMMS");
  }

  public canAccessCannedResponses(): boolean {
    return this.hasValidPhoneNumber() && this.getUserAndEnvironmentFeatureGrant("cannedResponses");
  }

  public canAccessContactLists(): boolean {
    return this.hasValidPhoneNumber() && this.isUserProfessional() &&  this.getUserAndEnvironmentFeatureGrant("lists");
  }

  public canAccessContacts(): boolean {
    return this.hasValidPhoneNumber() && this.getUserAndEnvironmentFeatureGrant("contacts");
  }

  public canAccessConversations(): boolean {
    return this.hasValidPhoneNumber() && this.getUserAndEnvironmentFeatureGrant("conversations");
  }

  public canAccessDeletedOrganizations() { 
    return this.canAccessDeletedUsers();
  }

  public canAccessDeletedUsers(): boolean{
    return this.isUserFullOrSuperAdmin();
  }

  public canAccessDripCampaigns(): boolean {
    return this.hasValidPhoneNumber() && this.isUserProfessional() &&  this.getUserAndEnvironmentFeatureGrant("dripCampaign");
  }

  public canAccessIntegrations(): boolean {
    return this.isUserProfessional()  && this.getUserAndEnvironmentFeatureGrant("integrations");;
  }

  public canAccessManageUsers(): boolean {
    // Currently, this is the only item in the admin menu, so we'll use the same security check
    // at some point in the future, it may be possible that items exist in the admin menu
    // which are granted, but manage users is still restricted.
    return this.canAccessAdminMenu();
  }

  public canAccessNumberSwitcher(): boolean {
    return true;
  }

  public canAccessOwnProfile(): boolean {
    return this.getUserAndEnvironmentFeatureGrant("profile");
  }

  public canAccessReminders(): boolean {
    return  this.hasValidPhoneNumber() && this.isUserProfessional() &&  this.getUserAndEnvironmentFeatureGrant("reminders");
 }

  public canAccessServiceAccounts(): boolean {
  return this.isUserFullOrSuperAdmin();
}

  public canAddOrgs(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canAddUsers(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canDeleteContacts(): boolean {
    return this.isUserFullOrSuperAdmin() || (!environment.disableDelete && this.getUserFeatureGrant("disableDelete"));
  }

  public canDeleteUsers(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canDisableOrganizations(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditAdminIntegrations(){
    return this.isUserFullOrSuperAdmin();
  }

  public canEditOrgConsentMessages(): boolean {
    return true;
  }

  public canEditOwnFullName(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditOwnProviderSettings(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditUserDisabled(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditUserFullName(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditUserIsAdmin(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditUserOrganization(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditUserPhoneNumber(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canEditUserProviderDetails(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canExportConversations(): boolean {
    return this.isUserProfessional() || this.isOrgAdmin() || this.isUserFullOrSuperAdmin();
  }

  // BEGIN COMMON INTERFACE IMPLEMENTATION
  public canManageBilling(): boolean {
    return this.isUserFullOrSuperAdmin() && this.getUserAndEnvironmentFeatureGrant("billing");
  }

  public canModifyUserAPITokens(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canModifyUserNumberSharing(): boolean {
    return this.isUserFullOrSuperAdmin() || this.isOrgAdmin();
  }

  public canModifyUserPermissions(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canModifyUserWebhookRelay(): boolean {
    // yeah yeah I know, this is redundant.
    // leaving the second clause here so that we remember to
    // security-check when we make this available on retail
    return this.isUserFullOrSuperAdmin();
  }

  public canViewProviderDetails(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public canViewProviderName(): boolean { 
    return this.canViewProviderDetails()
  }

  public getAddUserProvider(): string {
    return "native";
  }

  public getDebugUserRole(): string {
    if (this.isUserFullOrSuperAdmin()){
      return "Admin. Plan:" + this.getBillingPlanString();
    }
    else if(this.isOrgAdmin()) {
      return "OrgAdmin. Plan: " + this.getBillingPlanString();
    } 
    else{
      return "Not admin. Plan: " + this.getBillingPlanString();
    }
  }

  public getEditOwnProviderSettingsContact(): string {
    return environment.supportEmail
  }

  public getPlanSelectionDetails(): PlanSelectionProperties {
    return {
      choices: [
        {
          id: "mcpl-pro",
          displayName: "Professional",
          description: "Includes all starter features plus blasts, drip campaigns, reminders and integrations.",
          restricted: false
        },
        {
          id: "mcpl-starter",
          displayName: "Starter",
          description: "Includes access to core messaging features.",
          restricted: false
        }
      ],
      granularity: "organization"
    }
  }

  public getUserGudeOverrides(): UserGuideConfig[] {
    return;
  }

  public hasFullAdminRole(): boolean {
    return this.isUserFullOrSuperAdmin();
  }

  public isSubscriptionActive(): boolean {
    return true;
  }

  public requiresEmailConfirmation(): boolean {
    return false;
  }

  public shouldForcePlanSelector(): boolean {
    return false;
  }

  public shouldShowSubscriptionStatus(): boolean {
    return false;
  }

  public showRetailBrandingDetails(): boolean {
    return false;
  }

  public storeManagedByAttributeOnUsers(): boolean {
    return false;
  }

  public trimFirestoreOrgQuery(query: Query | CollectionReference): Query {
    if (this.isUserFullOrSuperAdmin()) {
      return query;
    }
    if (this.isOrgAdmin())
    { 
      return query.where('organizationAdmins', 'array-contains', this.service.currentFireauthUser.uid);
    }
    return query;
  }

  /**
   * Trims the user query so that:
   * Super Admins and Full admins see all user
   * Org admins see only users in organizations on which they have been designated an admin
   * 
   * @param query 
   * @returns 
   */
  public trimFirestoreUserQuery(query: Query|CollectionReference): Query {
    if (this.isUserFullOrSuperAdmin()) {
      return query;
    }
    if (this.isOrgAdmin())
    { 
      return query.where("organizationId","in", this.orgsUserManages);
    }
    return query;
  }

  public async updateUser(): Promise<void> {
    await super.updateUser();
    if (
      ! this.service.currentUserDocument.hasOwnProperty("email") 
    ) {
      throw new Error("Incomplete user profile")
    }
  }

  public usePrivateBackendForUserOperations(): boolean {
    return true;
  }

  public canViewMetadata(): boolean {
    return this.isUserFullOrSuperAdmin()
  }

  // #endregion Public Methods (56)

  // #region Private Methods (5)

  //TODO: Define how Cloudmessage Billing Plans are Defined
  private billingPlanIncludes(planName: string): boolean {
    return this.service.currentUserOrganizationDocument.hasOwnProperty("billing") &&
      this.service.currentUserOrganizationDocument.billing.hasOwnProperty("plan") &&
      this.service.currentUserOrganizationDocument.billing.plan.includes(planName);
  }

  private getBillingPlanString() { 
    return this.service.currentUserOrganizationDocument.hasOwnProperty("billing") &&
      this.service.currentUserOrganizationDocument.billing.hasOwnProperty("plan") ?
      this.service.currentUserOrganizationDocument.billing.plan :
      "No Billing Plan"
  }

  private isUserProfessional(): boolean {
    return this.billingPlanIncludes("mcpl-pro");
  }

  // END Common Interface Implementation
  private isUserStarter(): boolean {
    return this.billingPlanIncludes("mcpl-starter");
  }

  // #endregion Private Methods (5)
}