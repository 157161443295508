import { Injectable } from '@angular/core';
import { vars } from '../app.constants';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { MmsViewerComponent } from '../components/mms-viewer/mms-viewer.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthNZService } from '../core/authnz.service';
import { FrontendAttachment } from '../components/mms-uploader/mms-uploader.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import mime from "mime"
import { getLogger } from 'src/shared/logging';
import { TextableDate } from 'src/classes/TextableDate';
const log = getLogger("MmsService");

@Injectable({
  providedIn: 'root'
})
export class MmsService {
  public MAX_ATTACHMENT_SIZE = 500000;

  constructor(
    private modalService: NzModalService,
    private authnz: AuthNZService,
    private notification: NzNotificationService,
  ) { }


  /**
  * Determines if File's Mimetype/ContentType is present in vars.supportedFormats.imageTypes
  @param {string} uploadType- String of the file's mimetype/ContentType.
  @returns True if supported.
  */
  isImageFile(uploadType: string) {
    return vars.supportedFormats.imageTypes.indexOf(uploadType) > -1;
  }
  /**
  * Determines if File's Mimetype/ContentType is present in vars.supportedFormats.videoTypes
  @param {string} uploadType- String of the file's mimetype/ContentType.
  @returns True if supported.
  */
  isVideoFile(uploadType: string) {
    return vars.supportedFormats.videoTypes.indexOf(uploadType) > -1;
  }
  /**
  * Determines if File's Mimetype/ContentType is present in vars.supportedFormats.audioTypes
  @param {string} uploadType- String of the file's mimetype/ContentType.
  @returns True if supported.
  */
  isAudioFile(uploadType: string) {
    return vars.supportedFormats.audioTypes.indexOf(uploadType) > -1;
  }
  /**
  * Determines if type is a text, or can be converted to one. Mostly used for pasted content.
  @returns True if text.
  */
  isTextFile(uploadType: string) {
    return vars.supportedFormats.textTypes.indexOf(uploadType) > -1;
  }
  /*
  isVcfFile(uploadType) {
    if (vars.supportedFormats.vcfTypes.indexOf(uploadType) > -1) {
      return  true;
    }
  }*/

  /**
  * Determines if File's Mimetype/ContentType is not of other types.
  * This is for files displayed as download only.
  @param {string} uploadType- String of the file's mimetype/ContentType.
  @returns True if supported.
  */
  isOtherFile(uploadType: string) {
    return !this.isAudioFile(uploadType) && !this.isImageFile(uploadType) && !this.isVideoFile(uploadType);
  }
  /**
  * Gets the File from URL and downloads to storage with filename starting with 'TXB' + datetime.
  @param attachment - FrontendAttachment object to save
  */
  downloadAttachment(attachment: FrontendAttachment) {
    log.debug("saving attachment", attachment);
    const source = attachment.data || this.getAttachmentURL(attachment);
    const targetName = attachment.name || this.authnz.activeNumberUID + '/' + this.authnz.activeNumberUID + '_' + TextableDate.now() * Math.random() + TextableDate.now() + "." + mime.getExtension(attachment.contentType);
    try {
      fs.saveAs(source, targetName);
    }
    catch (e) {
      log.debug(e)
    }
  }

  createPastedFileName(type?: string):string{
    let extension = "";
    if (type){ 
      extension = "." + mime.getExtension(type)
    }
    return `Pasted_${new TextableDate().format('YYYY-MM-DD_hhmmss')}${extension}`
  }
  /**
  * Gets the File's size and converts to KB
  @param size- File Size in Bytes
  @returns File Size in KB to two decimal places with 'KB' String
  */
  getFileSize(size) {
    const bytesInKB = 1024;
    let realSize = size / bytesInKB;
    //TODO: handle MB sizes when larger size dowloads are fixed.
    return realSize.toFixed(2) + " KB";
  }

  /**
  * Checks if file has attributes property and returns url
  @param attach- Selected File
  @returns Selected File's URL
  */
  getAttachmentURL(attach) {
    if (attach.hasOwnProperty("attributes")) {
      return attach.attributes.url;
    }
    return attach.url;
  }

  /*
  test(attach) {
    console.log('ATTACH!!')
    console.log(attach)
  }
  */

  /**
   * Opens up the preview component for image type attachments.
   */
  openPhoto(attachment: FrontendAttachment) {
    this.modalService.create({
      nzTitle: 'Media',
      nzContent: MmsViewerComponent,
      nzComponentParams: {
        attachment: attachment
      },
      nzWidth: '85%',
      nzStyle: {
        top: '12px',
      },
      nzBodyStyle: {
        padding: '6px',
      },
      nzFooter: null,
    });
  }

  async resizeImageToTargetKB(data: string, scale?: number): Promise<{ size: number; data: string }> {
    const upSize = 1.5;
    const downSize = 0.8;

    if (!scale) {
      scale = this.MAX_ATTACHMENT_SIZE / this.getDataURLSize(data);
      if (scale < 0.5) {
        scale = 2 * scale;
      }
    }
    log.debug('Scaling ' + scale);
    const newData = await this.scaleImage(data, scale);
    const newSize = this.getDataURLSize(newData);
    if (newSize < 0.9 * this.MAX_ATTACHMENT_SIZE) {
      log.debug('rescaled image was too small. tryting ' + scale * upSize);
      return await this.resizeImageToTargetKB(data, scale * upSize);
    }
    if (newSize < this.MAX_ATTACHMENT_SIZE) {
      log.debug('rescaled image was just right');
      return { size: newSize, data: newData };
    } else {
      log.debug('rescaled image was too big. trying ' + scale * downSize);
      return await this.resizeImageToTargetKB(data, scale * downSize);
    }
  }

  getDataURLSize(data: string): number {
    var binary = atob(data.split(',')[1]);
    // Create 8-bit unsigned array
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    // Return our Blob object
    const b = new Blob([new Uint8Array(array)]);
    return b.size;
  }

  async scaleImage(data: string, scale: number) {
    return new Promise<string>((resolve, reject) => {
      const img = document.createElement('img');
      img.src = data;
      img.onload = async () => {
        var width = img.width;
        var height = img.height;

        const resizeString = 'Resizing from' + width + 'x' + height;

        if (width > height) {
          const MAX_WIDTH = width * scale;
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          const MAX_HEIGHT = height * scale;
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        console.log(resizeString + ' to ' + width + 'x' + height);
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const scaledImage = canvas.toDataURL('image/png');
        resolve(scaledImage);
      };
    });
  }

  /**
   * Creates an in-memory FrontendAttachment containing the file data and 
   * metadata about an attachement
   * 
   * Does not actually upload anything; but the returned object is ready to be uploaded 
   * 
   * @param files 
   * @param fromOnPaste 
   * @returns 
   */
  public createTextableAttachmentFromFiles(files: File[], fromOnPaste = false): Promise<FrontendAttachment>  {
    return new Promise<FrontendAttachment> ( (resolve,reject) => {
      log.debug('Processing files: ', files);
      let messageAttachment:FrontendAttachment  = {
        contentType: files[0].type,
        contentLength: files[0].size,
        name: files[0].name,
      };

      if(fromOnPaste){
        const newName = this.createPastedFileName(files[0].type)
        messageAttachment.filename = newName
        messageAttachment.name = newName
      }

      //We let this download all files. As mms-viewer now handles filtering types.
      const reader = new FileReader();
      reader.onloadend = async (ev) => {
        const result = reader.result as string;
        if (messageAttachment.contentLength > 500000 && messageAttachment.contentType.startsWith('image')) {
          let { size, data } = await this.resizeImageToTargetKB(result);
          messageAttachment.data = data;
          messageAttachment.scaledSize = size;
        } else {
          messageAttachment.data = result;
        }
        log.debug('Processed attachment:', messageAttachment);
        resolve(messageAttachment);
      };
      reader.readAsDataURL(files[0]);
    })
  }

    /**
   * Handles onPaste events.
   * For text-only paste events, 
   * the default handler is not prevented, and this function returns
   * 
   * For non-text paste events,
   * the default handler is prevented, and this function will attempt to handle the attachment.
   * 
   * Presently, only image type data is accepted.  
   * 
   * An error will be displayed to the user if non-image data is pasted
   * 
   */
     public async onPaste(event: ClipboardEvent, confirmReplace): Promise<FrontendAttachment> {
      const clipboardData = event.clipboardData
     
      log.debug("types", clipboardData.types)
      
      if(! (clipboardData.types.includes("Files") || clipboardData.files.length > 0)){ // This is a text-only event
        return;
      }
      
      event.preventDefault();
      log.debug(clipboardData);

      let file =clipboardData.files[0];

      const isImage = this.isImageFile(file.type);
      
      if (!isImage) {
        this.notification.create(
          'error',
          'Unsupported File!',
          'Only images are supported by pasting.',
        );
        return;
      }
      if (confirmReplace) {
        if (window.confirm("Would you like to replace the existing media with the media you are pasting from your clipboard?")) {
        } else {
          return;
        }
      }

      return await(this.createTextableAttachmentFromFiles([file], true))

    }
  



}
