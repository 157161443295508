import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { getLogger } from "src/shared/logging";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { APIError } from "src/classes/Errors/APIError";
const log = getLogger("BackendService");
require("../../polyfills/FirstEmitAsPromise");

// This module generates a bunch of logspam, which isn't necessarily that useful.
// set this to silent.  If a developer needs to debug subscriptions,
// then this can be set to debug.`
//log.disableAll();
@Injectable({
  providedIn: "root",
})
export class BackendService {
  // #region Constructors (1)

  constructor(private http: HttpClient, private afa: AngularFireAuth) {}

  // #endregion Constructors (1)

  // #region Public Methods (3)

  /**
   * Makes a DELETE against the backend server configuired in environment.ts apiBase
   * Automatically supplies an auth token valid for the current user
   *
   *
   *
   * @param endpoint
   * @param data URL query string parameters
   * @param authenticated include a Bearer token for the currently logged-in frontend User. Defaults to True
   * @returns
   */
  public async backendDelete(
    endpoint: string,
    data?: { [key: string]: any },
    authenticated: boolean = true
  ) {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: new HttpParams({
        // TODO Remove when patched upstream: TXBDEV-989
        // Construct this according to the workaround at
        // https://github.com/angular/angular/issues/18261#issuecomment-338354119
        fromObject: data,
        encoder: new CustomEncoder(),
      }),
    };

    if (authenticated) {
      const authToken = await this.getCurrentToken();
      httpOptions.headers = httpOptions.headers.append(
        "Authorization",
        "Bearer " + authToken
      );
    }

    log.debug(
      "Making " +
        (authenticated ? "" : "un") +
        "authenticated DELETE to backend: " +
        endpoint +
        " with options",
      httpOptions
    );
    return this.handlePromise(
      this.http.delete(environment.apiBase + endpoint, httpOptions).toPromise()
    );
  }

  /**
   * Makes a GET against the backend server configuired in environment.ts apiBase
   * Automatically supplies an auth token valid for the current user
   *
   *
   *
   * @param endpoint
   * @param data URL query string parameters
   * @param authenticated include a Bearer token for the currently logged-in frontend User. Defaults to True
   * @returns
   */
  public async backendGet(
    endpoint: string,
    data?: { [key: string]: any },
    authenticated: boolean = true,
    responseType?: "json" | "text" | "blob"
  ): Promise<any> {
    const httpOptions: any = {
      headers: new HttpHeaders(),
      params: new HttpParams({
        // TODO Remove when patched upstream: TXBDEV-989
        // Construct this according to the workaround at
        // https://github.com/angular/angular/issues/18261#issuecomment-338354119
        fromObject: data,
        encoder: new CustomEncoder(),
      })
    };

    if (responseType) {
      httpOptions.responseType = responseType;
    }

    if (authenticated) {
      const authToken = await this.getCurrentToken();
      httpOptions.headers = httpOptions.headers.append(
        "Authorization",
        "Bearer " + authToken
      );
    }

    log.debug(
      "Making " +
        (authenticated ? "" : "un") +
        "authenticated GET to backend: " +
        endpoint +
        " with options",
      httpOptions
    );
    return this.handlePromise(
      this.http.get(environment.apiBase + endpoint, httpOptions).toPromise()
    );
  }

  /**
   * Makes a POST against the backend server configuired in environment.ts apiBase
   * Automatically supplies an auth token valid for the current user
   *
   * @param endpoint
   * @param parameters Query String Parameters
   * @param body POST body data
   * @param options optional; object for additional request properties
   *
   * *  `authenticated`: optional; include a Bearer token for the currently logged-in frontend User. Defaults to True
   *
   * *  `responseType`: optional; define the expected response type; Defaults to JSON
   *
   * *  `method`: optional; defaults to post.  Can be changed to patch
   * @returns
   */
  public async backendPost(
    endpoint: string,
    parameters: { [key: string]: any },
    body: { [key: string]: any },
    options?: {
      authenticated?: boolean;
      responseType?: "json" | "blob";
      method?: "patch" | "post";
    }
  ): Promise<any> {
    if (!options) {
      options = {};
    }
    if (!options.hasOwnProperty("authenticated")) {
      options.authenticated = true; // Default to true if not set
    }
    if (!options.hasOwnProperty("responseType")) {
      options.responseType = "json";
    }
    if (!options.hasOwnProperty("method")) {
      options.method = "post";
    }
    const httpOptions = {
      headers: new HttpHeaders(),
      params: new HttpParams({
        // TODO Remove when patched upstream: TXBDEV-989
        // Construct this according to the workaround at
        // https://github.com/angular/angular/issues/18261#issuecomment-338354119
        fromObject: parameters,
        encoder: new CustomEncoder(),
      }),
      responseType: options.responseType,
    };

    if (options.authenticated) {
      const authToken = await this.getCurrentToken();
      httpOptions.headers = httpOptions.headers.append(
        "Authorization",
        "Bearer " + authToken
      );
    }
    log.debug(
      "Making " +
        (options.authenticated ? "" : "un") +
        "authenticated " +
        options.method +
        " to backend: " +
        endpoint
    );
    let p: Promise<any>;
    if (options.method == "post") {
      p = this.http
        .post(environment.apiBase + endpoint, body, httpOptions as any)
        .nThEmitAsPromise(0);
    } else if (options.method == "patch") {
      p = this.http
        .patch(environment.apiBase + endpoint, body, httpOptions as any)
        .nThEmitAsPromise(0);
    }
    return this.handlePromise(p);
  }

  private async handlePromise<T>(p: Promise<T>): Promise<T> {
    try {
      return await p;
    } catch (err) {
      if (err.error._errType == "TXBDEV_API_ERROR_V1") {
        throw new APIError(err.error);
      }
      throw err;
    }
  }

  // #endregion Public Methods (3)

  // #region Private Methods (1)

  /**
   * Proxy method to get the current user's token from the active
   * Firebase app for use when querying backend.
   * @returns The currently signed-in user's JWT token used to identify the user to a Firebase service
   */
  private async getCurrentToken() {
    return await this.afa.idToken.nThEmitAsPromise(0);
  }

  // #endregion Private Methods (1)
}

/**
 * Class definition from the workaround at
 * https://github.com/angular/angular/issues/18261#issuecomment-338354119
 * TODO Remove when patched upstream: TXBDEV-989
 */
class CustomEncoder implements HttpParameterCodec {
  // #region Public Methods (4)

  public decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  public decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  // #endregion Public Methods (4)
}
