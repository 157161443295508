import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NzModalService } from 'ng-zorro-antd/modal';
import { getLogger } from "src/shared/logging";
import { BackgroundOperationStatusService } from '../background-operation-status.service';
import { TextableReminder } from '../backported_types/messaging';
import { AuthNZService } from '../core/authnz.service';
import { AFSBatcher } from '../firestoreBatcher';
import { sleep } from '../functions';
import { BackendService } from './backend.service';

const log = getLogger("ReminderService");

export type ReminderImportAction = {
  action: "create"
  data: TextableReminder
}

@Injectable({
  providedIn: 'root'
})
export class ReminderService {

  constructor(
    private afs: AngularFirestore,
    public authnz: AuthNZService,
    private nzModal: NzModalService,
    public backendService: BackendService,
    private backgroundOperationStatus: BackgroundOperationStatusService
  ) { }

    /**
   * Uploads the imported reminders
   * @param list
   * @returns a promise of the backend operation
   */
     public async uploadImport(list: ReminderImportAction[]) {
      log.debug("Processing Reminder Import", list);
      this.backgroundOperationStatus.updateStatus({
        operation: "Processing Reminder Import",
        statusText: "Preparing to batch Reminders",
        percentComplete: 0,
        icon: "cloud-upload"
      });
  
      try {
  
        this.backgroundOperationStatus.updateStatus({
          operation: "Processing Reminder Import",
          statusText: "Processing Reminder Import.  Length: " + list.length,
          percentComplete: 0,
        });
  
        const batchImport = AFSBatcher<ReminderImportAction>(
          this.afs,
          list, 
          (batch,item)=>{
            if (item.action == "create") {
              //log.debug("Creating item", item)
              batch.set(this.afs.collection("messages").doc().ref, item.data)
            }
          }
        )
        batchImport.subscribe(progress=> {
          this.backgroundOperationStatus.updateStatus({
            operation: "Processing Reminder Import",
            statusText: "In Progress " + progress.doneItems + " / " + progress.totalItems,
            percentComplete: ((progress.doneItems)/progress.totalItems) * 100,
          });
        });
        await batchImport.toPromise();
  
        this.backgroundOperationStatus.updateStatus({
          operation: "Processing Reminder Import",
          statusText: "Finishing up",
          percentComplete: 100,
          finished: false,
        });

        await Promise.all([
          this.afs.firestore.waitForPendingWrites(),
          sleep(2000),
        ]);
        this.backgroundOperationStatus.updateStatus({
          operation: "Processing Reminder Import",
          statusText: "Complete",
          percentComplete: 100,
          finished: true,
        });
      } catch (err) {
        log.warn("Failed processing import", err);
        this.backgroundOperationStatus.updateStatus({
          operation: "Processing Reminder Import",
          statusText: "Failed",
        });
      }
    }
}
