<ng-container *ngIf="this.isContactConnectedToIntegration && page == 'contacts'">
  <nz-alert nzType="warning" [nzMessage]="integrationMessage" nzShowIcon nzBanner></nz-alert>
  <ng-template #integrationMessage>
    This contact is connected to an external integration, and may receive metadata updates from or push message details to the other platform.
  </ng-template>
</ng-container>

<ng-container *ngIf="this.isContactConnectedToIntegration && page == 'conversations'">
  <i class="fas fa-link" style="color: #6a0dad; font-size: 18px" nz-tooltip [nzTitle]="titleTemplate"></i>
  <ng-template #titleTemplate>
     <span>This contact is connected to an external integration, and may receive metadata updates from or push message details to the other platform.<br/><br/>
    </span>
  </ng-template>
</ng-container>