<!--
  ngTemplateOutlet requires a TemplateRef, but the TemplateRef object is a pain to access
  from the TypeScript class, SO
  Let's create a key-value pair here where Angular's template compiler resolves the "values" to 
  the actual TemplateRef, and we access that object by the key supplied in the currentTempalte 
  string.

  https://stackoverflow.com/a/53164529
-->

<ng-container 
  *ngTemplateOutlet="
    { tplFullApp: tplFullApp, tplError: tplError, tplLogin: tplLogin }[currentTemplate]
  ">
</ng-container>


<ng-template #tplFullApp>
  <nz-layout>
    <nz-sider nzWidth="200px">
      <div id="siderPrimaryContents">
        <div>
          <img id="sider-logo" [src]="this.themeService.activeBranding?.siderLogoURL" />
          <app-number-switcher></app-number-switcher>
          <app-status-message></app-status-message
          ><br />
        </div>

        <app-navigation-menu></app-navigation-menu>
        
        <ng-container *ngIf="applicationContext.debugMode">
          <app-debug-data-viewer></app-debug-data-viewer>
        </ng-container>

        <ng-container
          *ngIf="this.authnz.currentSecurityProvider.shouldShowSubscriptionStatus()"
        >
          <app-subscription-status-message></app-subscription-status-message>
        </ng-container>
      </div>
      <div id="siderBottomContents">
      <ul
        nz-menu
        [nzTheme]="'dark'"
        [nzMode]="'inline'"
        id="logout-list"
        >
        <li nz-menu-item (click)="authnz.logout()">
          <span>
            <i class="far fa-sign-out-alt"></i>
            <span>Logout</span>
          </span>
        </li>
      </ul>
    </div>
    </nz-sider>
    <nz-layout>
      <nz-content>
        <nz-spin 
          *ngIf="authnz.isNumberSwitching"
          [nzSpinning]="authnz.isNumberSwitching" 
          nzTip="Loading...">
        </nz-spin>
        <router-outlet
          *ngIf="!authnz.isNumberSwitching">
        </router-outlet>
      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-template>

<ng-template #tplError>
  <div
    nz-row
    nzType="flex"
    nzJustify="space-around"
    nzAlign="middle"
    class="loginBody"
  >
    <div nz-col nzSpan="10">
      <nz-card class="border-radius-10px" nzTitle="Application Error">
        <p style="text-align: center">
          Uh oh. Something went wrong. Please contact
          {{ environment.supportEmail }}.
        </p>
        <br />
        <div style="width: 100%; text-align: center">
          <button
            style="width: 50%"
            nz-button
            nzType="default"
            (click)="authnz.logout()"
          >
            Logout
          </button>
        </div>
      </nz-card>
    </div>
  </div>
</ng-template>

<ng-template #tplLogin>
  <router-outlet></router-outlet>
</ng-template>
