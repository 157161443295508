import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { TextableContact } from "../../backported_types/contact";
import { ContactDTO } from "src/classes/contactDTO";
import { getLogger } from "@shared/logging";
import { ContactsService } from "../../services/contacts.service";
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, from, fromEvent } from "rxjs";
import { map, shareReplay, switchMap, withLatestFrom } from "rxjs/operators";
import { FronendNumberBlock, UserContactWatcher } from "../../services/contacts.service.UserContactWatcher";
import { FormControl, FormGroup } from "@angular/forms";

const log = getLogger("SenderBlockerComponent");

type Blockable = string | TextableContact | ContactDTO | FronendNumberBlock

@Component({
  selector: "app-sender-blocker",
  templateUrl: "./sender-blocker.component.html",
  styleUrls: ["./sender-blocker.component.scss"],
})
export class SenderBlockerComponent
  implements OnInit, AfterViewInit, OnChanges
{
  /**
   * Reference to the button template
   */
  @ViewChild("tplButton")
  private tplButton: TemplateRef<any>;

  /**
   * Reference to the HREF template
   */
  @ViewChild("tplHref")
  private tplHref: TemplateRef<any>;

  public currentTemplate: TemplateRef<any> = null;
  public enabled = false;
  public _sender: BehaviorSubject<Blockable[]> = new BehaviorSubject<Blockable[]>([]);

  @Output()
  public onComplete: EventEmitter<void> = new EventEmitter<void>();
  
  @Input()
  public disabled: boolean = false;

  @Input()
  public bulk: boolean = false;

  public nzTooltipTitle: Observable<string>;

  public confirmationMessage: Observable<string>;

  @Input()
  public displayStyle: "icon" | "text" | "both" = "icon";
  @Input()
  public displayFormat: "button" | "href" = "button";

  @Input()
  public sender: Blockable | Blockable[];

  @Input()
  private UserId: string;

  public blockOptionsPopoverVisible: boolean = false;

  public mode: Observable<"block" | "unblock">;

  public blockOptionsForm: FormGroup;

  constructor(
    private contactsService: ContactsService,
  ) {
    this.watchUpdateMode();
    this.watchUpdateTitle();
    this.updateBulk();
    this.blockOptionsForm = new FormGroup({
      delete: new FormControl(false),
      reportSpam: new FormControl(false),
      sendStop: new FormControl(false)
    })
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.updateFormat();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty("sender")) {
      if (Array.isArray(this.sender)) {
        this._sender.next(this.sender);
      } else {
        this._sender.next([this.sender]);
      }
    }
    if (changes.hasOwnProperty("displayFormat")) {
      this.updateFormat();
    }
    if(changes.hasOwnProperty("bulk")) {
      this.updateBulk();
    }
  }

  private updateBulk() {
    this.nzTooltipTitle = this.mode.pipe(
      map((mode) => {
        if (this.bulk) {
          return `Bulk ${mode } sender`;
        } else {
          return `${mode } sender`;
        }
      })
    )
  }

  private watchUpdateTitle() {
    this.confirmationMessage = this._sender.pipe(
      withLatestFrom(this.mode),
      map(([senders,mode]) => {
        if (senders.length > 1) {
          return  `Are you sure you want to ${mode} ${senders.length} senders?`;
        } else {
          if (senders[0] instanceof ContactDTO) {
            return `Are you sure you want to ${mode} ${senders[0].contact.full_name}?`;
          }
          else if (typeof senders[0] == "object" && "full_name" in senders[0]) {
            return `Are you sure you want to ${mode} ${senders[0].full_name}?`;
          }
          else {
            return `Are you sure you want to ${mode} this sender?`
          }
        }
      }),
      shareReplay(1)
    )

  }
  private updateFormat() {
    if (!this.tplButton || !this.tplHref) {
      return;
    }
    if (this.displayFormat === "button") {
      this.currentTemplate = this.tplButton;
    }
    if (this.displayFormat === "href") {
      this.currentTemplate = this.tplHref;
    }
  }

  private watchUpdateMode() {
    this.mode = combineLatest([
      from(this._sender),
      (this.getWatcher().BlockedSenders)
    ]).pipe(
        map(([senders, blockedSenders]) => {
          if (senders.length == 0) {
            // short circuit the (possibly costly) lookup 
            return "block";
          }
          const blockedNumbers = blockedSenders.map((sender) => {
            return sender.phoneNumber;
          });

          const sendersPhoneNumbers= senders.map((sender) => {
            if (typeof sender === "string") {
              return sender;
            }
            if (sender instanceof ContactDTO) {
              return sender.contact.phone_number;
            }
            if (typeof sender === "object") {
              if ("phone_number" in sender) {
                return sender.phone_number 
              }
              else if ("phoneNumber" in sender) {
                return sender.phoneNumber
              }
            }
            return null;
          })

          const Blocked = sendersPhoneNumbers.filter((sender) => {
            return blockedNumbers.includes(sender);
          });
          const Unblocked = sendersPhoneNumbers.filter((sender) => {
            return !blockedNumbers.includes(sender);
          });
          return (Blocked.length >= 1 ) ? "unblock" : "block";
        }),
        shareReplay<"block"|"unblock">(1)
    );
  }

  private getWatcher(): UserContactWatcher {
    if(this.UserId) {
      return this.contactsService.getContactWatcher(this.UserId);
    }
    return this.contactsService.activeWatcher;
  }

  public async execute() {
    try { 
      const mode = await this.mode.nThEmitAsPromise(0);
      const senders: string[] =  (await this._sender.nThEmitAsPromise(0)).map((sender) => {
        if (typeof sender === "string") {
          return sender;
        }
        if (sender instanceof ContactDTO) {
          return sender.contact.firebase_document_id;
        }
        if (typeof sender === "object") {
          if ("firebase_document_id" in sender) {
            return sender.firebase_document_id;
          }
          if ("phoneNumber" in sender) {
            return sender.phoneNumber;
          }
        }
        return null;
      })
      log.debug(`SenderBlockerComponent: ${mode}`, senders);
      this.blockOptionsPopoverVisible = false;
      if (mode === "block") {
        await (this.getWatcher()).blockSender(senders, this.blockOptionsForm.value);
      }
      if (mode === "unblock") {
        await (this.getWatcher()).unblockSender(senders);
      }
      this.onComplete.emit();
    }
    catch(err){
      log.warn("Error executing sender block", err);
    }
  }
}
