<nz-card [nzTitle]="pageTitleTemplate" nzBorderless="true">
  <div nz-row>
    <div nz-col class="left-pane">
      <nz-table 
        *ngIf="adminTools.length > 0; else loadingTools"
        #listTable
        nzSize="middle"
        [nzNoResult]="empty"
        class="txb-list"
      >

        <tbody>
          <ng-container *ngFor="let tool of adminTools">
            <tr (click)="setActiveTool(tool)" class="list-item list-item-single-select" [ngClass]="{'list-item-active': activeTool && tool.name == activeTool.name}">
              <td>
                <nz-row>
                  <nz-col nzXs="8" nzSm="8" nzMd="12" nzLg="12" nzXl="12">
                    {{tool.name}}
                  </nz-col>
                </nz-row>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>

    <div nz-col class="right-pane">
      <app-admin-tool
        *ngIf="activeTool"
        [tool]="activeTool" >
      </app-admin-tool>
      <p class="text-center pad-25" *ngIf="!activeTool">Select an Admin Tool from the list</p>
    </div>
  </div>
</nz-card>

<ng-template #empty>
</ng-template>

<ng-template #loadingTools>
  <p class="text-center">Loading Admin Tools...</p>
</ng-template>

<ng-template #pageTitleTemplate>
  Admin Tools <i class="far fa-question-circle guideButton" (click)="guideVisibleBool = !guideVisibleBool"></i>
</ng-template>